import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import validations from '@/fixtures/validations';
import { ISelectOption } from '@/interfaces';
import { TClientCreate } from '@/services/clients/create';
import useGetLicenses from '@/services/licenses';
import ButtonActions from '@/shared/custom/ButtonActions';
import Head from '@/shared/custom/Head';
import HeadingWithLinks from '@/shared/custom/HeadingWithLinks';
import { Fields } from '@/shared/form';
import { TField } from '@/types';

const validationSchema = Yup.object().shape({
  name: validations.name,
  fullName: Yup.string().min(3).max(255).nullable(),
  domain: Yup.string().min(3).url().max(255),
  licenseId: Yup.string().required(),
  tier: Yup.number().min(1).max(10).nullable(),
  isActive: Yup.boolean().required(),
});

type FormProps = {
  title: string;
  isSubmitting: boolean;
  onSubmit: (values: TClientCreate) => void;
  initialValues: TClientCreate;
};

export default function Form({ title, isSubmitting, onSubmit, initialValues }: FormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useGetLicenses();

  const tierOptions: ISelectOption<string>[] = Array.from({ length: 10 }).map((_, i) => ({
    value: (i + 1).toString(),
    label: (i + 1).toString(),
  }));

  const licenses = useMemo(() => {
    if (!data) return [];
    return data.items.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [data]);

  const fields: TField[] = [
    {
      isRequired: true,
      type: 'input',
      name: 'name',
      label: t('Name'),
      fieldProps: { type: 'text' },
    },
    {
      isRequired: false,
      type: 'input',
      name: 'domain',
      label: t('Domain'),
      fieldProps: { type: 'url' },
    },
    {
      isRequired: true,
      type: 'input',
      name: 'fullName',
      label: t('Full Name'),
      fieldProps: { type: 'text' },
    },
    {
      isRequired: true,
      type: 'select',
      name: 'licenseId',
      label: t('License'),
      fieldProps: { options: licenses },
    },
    {
      isRequired: false,
      type: 'select',
      name: 'tier',
      label: 'Tier',
      fieldProps: {
        options: tierOptions,
      },
    },
    {
      isRequired: false,
      type: 'select',
      name: 'isActive',
      label: t('Status'),
      fieldProps: {
        options: [
          { label: 'Active', value: true },
          { label: 'Disabled', value: false },
        ],
      },
    },
  ];

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
    >
      <FormikForm className="space-y-4">
        <Head title={title} description={title} />
        <HeadingWithLinks
          links={[
            { text: 'Clients', to: '/clients' },
            { text: title, to: '#' },
          ]}
        />
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <Fields fields={fields} />
        </div>
        <ButtonActions
          cancelProps={{ onClick: () => navigate('/clients') }}
          submitProps={{ disabled: isSubmitting, isLoading: isSubmitting }}
        />
      </FormikForm>
    </Formik>
  );
}
