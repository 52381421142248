import React from 'react';
import { Link as MainLink } from 'react-router-dom';

type LinkProps = {
  to: string;
  text: string;
  onClick?: () => void;
};

export default function Link({ text, ...props }: LinkProps) {
  return (
    <MainLink
      {...props}
      className="btn-link col-span-full block font-medium text-base-content/70 no-underline"
    >
      {text}
    </MainLink>
  );
}
