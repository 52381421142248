import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IFilter, ISelectOption } from '@/interfaces';
import ReactSelect from '@/shared/form/Fields/ReactSelect';
import useSettingsState from '@/states/settingsState';
import { optionsContext } from '../../Options';
import Container from '../Container';
import Header from '../Header';

export default function Filters() {
  const { filters, resource } = useContext(optionsContext);
  const { t } = useTranslation();
  const setResourceSettings = useSettingsState((state) => state.setResourceSettings);
  const params = useSettingsState((state) => state[resource].params);

  const getFilterValues = (filter: IFilter) => {
    const currentFilters = params[filter.name] as string[];
    return filter.options
      .filter((option) => currentFilters?.includes(option.value))
      .map((option) => ({
        ...option,
        label: typeof option.label === 'function' ? option.label(t) : option.label,
      })) as ISelectOption<string>[];
  };

  if (!filters || filters?.length === 0) return null;
  return (
    <Container id="optionsFiltersContainer">
      <Header>{t('Filters')}</Header>
      {filters.map((filter) => (
        <ReactSelect
          {...filter}
          options={filter.options.map((option) => ({
            ...option,
            label: typeof option.label === 'function' ? option.label(t) : option.label,
          }))}
          key={filter.name}
          placeholder={filter.placeholder(t)}
          menuPlacement="top"
          value={getFilterValues(filter)}
          onChange={(selected: unknown) => {
            const selectedFilters = filter.isMulti
              ? (selected as ISelectOption<string>[]).map(({ value }) => value)
              : [(selected as ISelectOption<string>).value];

            setResourceSettings(resource, {
              params: { ...params, [filter.name]: selectedFilters },
            });
          }}
        />
      ))}
    </Container>
  );
}
