import React from 'react';
import { Button } from '@/shared/form';
import { ButtonProps } from '@/shared/form/Button';
import { cn } from '@/utils';

interface SwitchProps extends ButtonProps {
  isActive: boolean;
}

export default function Switch({ isActive, children, ...props }: SwitchProps) {
  return (
    <div className="join">
      <Button
        {...props}
        className={cn('btn join-item btn-sm rounded-none', {
          'btn-primary': isActive,
          'btn-outline-primary': !isActive,
        })}
      >
        {children}
      </Button>
    </div>
  );
}
