import React, { createContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
// import Actions from '@/components/applicants/show/Actions';
import Detail from '@/components/applicants/show/Detail';
import DistributionChart from '@/components/applicants/show/DistributionChart';
import LinkedinInfo from '@/components/applicants/show/LinkedinInfo';
import NextAndPrevious from '@/components/applicants/show/NextAndPrevious';
import { ApplicantAnswerFull, QuestionType } from '@/generated';
import { useDialogModal, useTable } from '@/hooks';
import { TIndexLayoutContext } from '@/layouts/applicants/IndexLayout';
import useGetApplicantsScores from '@/services/applicants/scores';
import useGetApplicant from '@/services/applicants/show';
import Head from '@/shared/custom/Head';
import HeadingWithLinks from '@/shared/custom/HeadingWithLinks';
import DialogModal from '@/shared/modals/DialogModal';
import Table from '@/shared/views/Table';
import { TModelId } from '@/types';
import { formatArrayToString } from '@/utils';

const columnHelper = createColumnHelper<ApplicantAnswerFull & { id: TModelId }>();

const columns = [
  columnHelper.accessor('questionTitle', {
    enableSorting: false,
    enableHiding: false,
    meta: { header: (t) => t('Question') },
  }),
  columnHelper.accessor('answerValue', {
    enableSorting: false,
    enableHiding: false,
    cell: (info) => formatArrayToString(info.getValue()),
    meta: { header: (t) => t('Answer') },
  }),
  columnHelper.accessor('scoreValueNormalized', {
    enableSorting: false,
    enableHiding: false,
    cell: (info) => (info.row.original.questionType === QuestionType.TEXT ? '--' : info.getValue()),
    meta: { header: (t) => t('Score') },
  }),
  columnHelper.accessor('questionWeight', {
    enableSorting: false,
    enableHiding: false,
    cell: (info) => (info.row.original.questionType === QuestionType.TEXT ? '--' : info.getValue()),
    meta: { header: (t) => t('Weight') },
  }),
  // columnHelper.display({
  //   id: 'actions',
  //   cell: (info) => <Actions info={info} />,
  //   enableSorting: false,
  //   enableHiding: false,
  // }),
];

type TApplicantsShowContext = {
  setActiveQuestion: React.Dispatch<React.SetStateAction<TActiveQuestion>>;
};

export const applicantsShowContext = createContext<TApplicantsShowContext>(
  {} as TApplicantsShowContext,
);

export type TActiveQuestion = { id: TModelId; score: number } | null;

export default function Show() {
  const { t } = useTranslation();
  const { queryResult } = useOutletContext<TIndexLayoutContext>();
  const { data: applicants, isLoading: isApplicantsLoading } = queryResult;
  const { applicantId, questionsetId } = useParams<'applicantId' | 'questionsetId'>();
  const { data: applicantsScores } = useGetApplicantsScores({ questionSetId: questionsetId! });
  const [activeQuestion, setActiveQuestion] = useState<TActiveQuestion>(null);
  const { modalRef } = useDialogModal();

  const foundApplicant = applicants?.items.find((applicant) => applicant.id === applicantId);
  const { data: applicant, isLoading: isApplicantLoading } = useGetApplicant(applicantId!, {
    enabled: !isApplicantsLoading && !foundApplicant,
  });
  const data = foundApplicant || applicant;
  const isLoading = isApplicantsLoading || isApplicantLoading;
  const answers = useMemo(
    () =>
      Object.entries(data?.answersFull ?? {}).map(([questionId, value], i) => ({
        ...value,
        id: questionId,
        index: i + 1,
      })),
    [data],
  );
  const table = useTable({ isLoading, data: answers, columns });

  const value = useMemo(() => ({ setActiveQuestion }), [setActiveQuestion]);

  return (
    <applicantsShowContext.Provider value={value}>
      <Head title="Applicant" description="Applicant" />
      <div className="flex items-center justify-between">
        <HeadingWithLinks
          links={[
            { text: 'Applicants', to: '/applicants' },
            { text: t('Applicant Details'), to: '#' },
          ]}
        />
        {applicants && <NextAndPrevious applicants={applicants.items} applicantId={applicantId!} />}
      </div>
      {data && <Detail {...data} />}
      <Table table={table} />
      <LinkedinInfo info={data?.extraInfo} />
      <DialogModal
        closeOnClickOutside
        onClose={() => setActiveQuestion(null)}
        modalRef={modalRef}
        open={Boolean(activeQuestion)}
      >
        <h2 className="h3">{t('Applicant Score Distribution')}</h2>
        <DistributionChart scores={applicantsScores} activeQuestion={activeQuestion} />
      </DialogModal>
    </applicantsShowContext.Provider>
  );
}
