/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicantExtraInfo } from '@/generated';
import { Button } from '@/shared/form';

type THasDates = {
  startDate?: string | null | undefined;
  endDate?: string | null | undefined;
};

function sortByDates(a: THasDates, b: THasDates): number {
  const startDateA = a?.startDate ? new Date(a.startDate) : null;
  const endDateA = a?.endDate ? new Date(a.endDate) : null;
  const startDateB = b?.startDate ? new Date(b.startDate) : null;
  const endDateB = b?.endDate ? new Date(b.endDate) : null;

  if (endDateA && endDateB) {
    if (endDateA > endDateB) return -1;
    if (endDateA < endDateB) return 1;
  }

  if (startDateA && startDateB) {
    if (startDateA > startDateB) return -1;
    if (startDateA < startDateB) return 1;
  }

  return 0;
}

type LinkedinInfoProps = {
  info: ApplicantExtraInfo | undefined | null;
};

export default function LinkedinInfo({ info }: LinkedinInfoProps) {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  if (!info?.experiences?.length && !info?.educations?.length) return null;

  if (!show) {
    return (
      <div className="card card-bordered bg-base-200 md:w-1/2">
        <div className="card-body">
          <h2 className="card-title">{t('linkedinInfoReveal.title')}</h2>
          <p>{t('linkedinInfoReveal.description')}</p>
          <div className="card-actions">
            <Button onClick={() => setShow(true)} className="btn btn-primary">
              {t('linkedinInfoReveal.actionText')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="divider" />
      <h2 className="h2">{t('Linkedin Educations')}</h2>
      <ol className="steps overflow-x-scroll py-4">
        {info.educations?.sort(sortByDates).map((education, i) => {
          const fields = [
            { title: t('Degree'), value: education.degree },
            { title: t('Field'), value: education.field },
            { title: t('Institution'), value: education.institution },
            { title: t('Start Date'), value: education.startDate },
            { title: t('End Date'), value: education.endDate },
          ];

          return (
            <li key={i} className="step w-max">
              <div className="card mx-4 h-full w-max max-w-[18rem] bg-base-200">
                <div className="card-body text-left">
                  {fields.map((field) => (
                    <p key={field.title}>
                      <span className="text-primary/80">{field.title}:</span> {field.value ?? '--'}
                    </p>
                  ))}
                </div>
              </div>
            </li>
          );
        })}
      </ol>
      <h2 className="h2">{t('Linkedin Experiences')}</h2>
      <ol className="steps overflow-x-scroll py-4">
        {info.experiences?.sort(sortByDates).map((experience, i) => {
          const fields = [
            { title: t('Title'), value: experience.title },
            { title: t('Company'), value: experience.company },
            { title: t('Start Date'), value: experience.startDate },
            { title: t('End Date'), value: experience.endDate },
          ];

          return (
            <li key={i} className="step w-max">
              <div className="card mx-4 h-full w-max max-w-[18rem] bg-base-200">
                <div className="card-body text-left">
                  {fields.map((field) => (
                    <p key={field.title}>
                      <span className="text-primary/80">{field.title}:</span> {field.value ?? '--'}
                    </p>
                  ))}
                </div>
              </div>
            </li>
          );
        })}
      </ol>
    </>
  );
}
