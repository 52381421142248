import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import validations from '@/fixtures/validations';
import useEditMe from '@/services/users/me/edit';
import { Button, Fields } from '@/shared/form';
import useAuthState from '@/states/authState';
import { userInfoFields } from '../users/Form';

export default function UpdateInformation() {
  const { t } = useTranslation();
  const authUser = useAuthState((state) => state.user);
  const { mutate: updateMe, isPending } = useEditMe();

  const validationSchema = Yup.object().shape({
    firstName: validations.name,
    lastName: validations.name,
    email: validations.email,
    address: validations.description,
    phoneNumber: validations.phoneNumber(t),
  });

  return (
    <div className="card w-full bg-base-200">
      <div className="card-body gap-4">
        <h2 className="h3">{t('Update your Information')}</h2>
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={{
            firstName: authUser!.firstName,
            lastName: authUser!.lastName,
            email: authUser!.email,
            address: authUser!.address,
            phoneNumber: authUser!.phoneNumber,
          }}
          onSubmit={(values, { resetForm }) => {
            updateMe(values, {
              onSuccess: () => {
                resetForm();
                toast.success(t('toast.updatedSuccessfully', { model: 'Your information' }));
              },
            });
          }}
        >
          <Form className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Fields fields={userInfoFields} />
            <div className="card-actions col-span-full">
              <Button
                isLoading={isPending}
                disabled={isPending}
                type="submit"
                className="btn btn-primary"
              >
                {t('Update')}
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
