import React from 'react';
import { cn } from '@/utils';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  isTheFirst?: boolean;
}

export default function Container({
  children,
  className,
  isTheFirst = false,
  ...props
}: ContainerProps) {
  return (
    <div
      {...props}
      className={cn('space-y-2', className, {
        'pt-4': !isTheFirst,
      })}
    >
      {children}
    </div>
  );
}
