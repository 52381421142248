const rangeSize = 0.1;

export function calculateScoreIndex(score: number) {
  return Math.min(9, Math.floor(score / rangeSize));
}

export function groupScoresIntoRanges(scores: number[]): Map<number, number[]> {
  const ranges = new Map<number, number[]>();

  for (let i = 0; i < 10; i += 1) {
    // Create the key for the range
    const key = i;

    // Initialize the range array
    ranges.set(key, []);
  }

  // Loop through the input floats and add them to the appropriate range
  for (let i = 0; i < scores.length; i += 1) {
    const score = scores[i];
    ranges.get(calculateScoreIndex(score))?.push(score);
  }

  return ranges;
}
