import React, { createContext, useEffect, useMemo, useState } from 'react';
import { ICard } from '@/interfaces';
import Body from './Body';
import Head from './Head';

type TCardContext = {
  isMaximized: boolean;
  setIsMaximized: React.Dispatch<React.SetStateAction<boolean>>;
  card: ICard;
};

export const cardContext = createContext<TCardContext>({
  card: { title: '', data: [] },
  isMaximized: true,
  setIsMaximized: () => {},
});

interface CardProps extends ICard {
  isMaximizedGlobally: boolean;
}

export default function Card({ isMaximizedGlobally, ...card }: CardProps) {
  const [isMaximized, setIsMaximized] = useState(isMaximizedGlobally);

  useEffect(() => {
    setIsMaximized(isMaximizedGlobally);
  }, [isMaximizedGlobally]);

  const value = useMemo(() => ({ isMaximized, setIsMaximized, card }), [isMaximized, card]);

  return (
    <div className="card card-bordered">
      <cardContext.Provider value={value}>
        <div className="card-body">
          <Head />
          <Body />
        </div>
      </cardContext.Provider>
    </div>
  );
}
