import React from 'react';
import { useTranslation } from 'react-i18next';
import LogoIcon from '@/assets/svg/logo.svg?react';
import { cn } from '@/utils';

type LogoProps = React.SVGProps<SVGSVGElement> & {
  title?: string | undefined;
};

export default function Logo({ className, ...props }: LogoProps) {
  const { t } = useTranslation();
  return <LogoIcon aria-label={t('Logo')} {...props} className={cn('fill-primary', className)} />;
}
