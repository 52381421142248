/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ClientSettingName } from '@/generated';
import { useDialogModal } from '@/hooks';
import useGetDefaultClientSettings from '@/services/clientSettings/defaults';
import useDeleteClientSettings from '@/services/clientSettings/delete';
import useMutateClientSetting from '@/services/clientSettings/mutate';
import ButtonActions from '@/shared/custom/ButtonActions';
import Head from '@/shared/custom/Head';
import { Button, Fields } from '@/shared/form';
import DialogModal from '@/shared/modals/DialogModal';

const availablePlaceholders: Record<ClientSettingName, string[]> = {
  APPLICANT_CONFIRM_EMAIL: ['position', 'company'],
  APPLICANT_REJECT_EMAIL: ['company', 'position', 'score'],
  QUESTIONNAIRE_INTRO: ['position', 'company'],
  QUESTIONNAIRE_SUBMIT: ['company'],
};

const clientSettingNames = Object.keys(ClientSettingName);

export default function Settings() {
  const { t } = useTranslation();
  const { data: clientSettings } = useGetDefaultClientSettings();
  const { mutate, isPending } = useMutateClientSetting();
  const { mutate: deleteClientSettings, isPending: isDeleting } = useDeleteClientSettings();
  const { modalRef, handleOpenModal, handleCloseModal } = useDialogModal();
  const title = t('Settings');

  const availableSettings: Record<ClientSettingName, { title: string; description: string }> = t(
    'platform.settings.availableSettings',
    { returnObjects: true },
  );

  const handleReset = () => {
    deleteClientSettings(undefined, {
      onSuccess: () => {
        handleCloseModal();
        toast.success(t('toast.restoredSuccessfully', { model: t('Settings') }));
      },
    });
  };

  const validationSchema = Yup.object(
    Object.keys(clientSettings).reduce(
      (prev, curr) => {
        prev[curr as ClientSettingName] = Yup.string().min(3).max(2024).required();
        return prev;
      },
      {} as Record<ClientSettingName, Yup.StringSchema>,
    ),
  );

  return (
    <>
      <Head title={title} description={title} />
      <DialogModal closeOnClickOutside modalRef={modalRef}>
        <h3 className="text-2xl font-bold">{t('Reset to Default')}</h3>
        <p className="description">
          {t('This action cannot be undone. Are you sure you want to do this?')}
        </p>
        <ButtonActions
          submitProps={{
            isLoading: isDeleting,
            disabled: isDeleting,
            type: 'button',
            text: t('Reset'),
            onClick: handleReset,
          }}
          cancelProps={{ onClick: handleCloseModal }}
        />
      </DialogModal>
      <p>{t('platform.settings.description')}</p>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values) => {
          Object.entries(values).forEach(([name, value]) => {
            const typedName = name as ClientSettingName;
            const initialValue = clientSettings[typedName];
            if (initialValue !== value) {
              mutate({ name: typedName, value });
            }
          });
          toast.success(t('toast.updatedSuccessfully', { model: title }));
        }}
        initialValues={clientSettings}
      >
        <Form className="space-y-4">
          <Fields
            fields={Object.keys(clientSettings)
              // only current build setting names should be rendered
              .filter((name) => clientSettingNames.includes(name))
              .map((name) => {
                const nameTyped = name as ClientSettingName;

                return {
                  isRequired: true,
                  label: availableSettings[nameTyped].title,
                  tooltip: { text: availableSettings[nameTyped].description },
                  bottomLabel: {
                    left: `${t('platform.settings.availablePlaceholders')} : ${availablePlaceholders[nameTyped].map((value) => `{${value}}`).join(', ')}`,
                  },
                  name,
                  type: 'input',
                  fieldProps: { as: 'textarea', maxLimitHelper: 2024, rows: 8 },
                };
              })}
          />
          <div className="flex items-center gap-4">
            <Button
              disabled={isPending}
              isLoading={isPending}
              type="submit"
              className="btn btn-primary btn-md"
            >
              {t('Submit')}
            </Button>
            <Button onClick={handleOpenModal} className="btn btn-secondary btn-md">
              {t('Reset to Default')}
            </Button>
          </div>
        </Form>
      </Formik>
    </>
  );
}
