import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';
import useRefreshToken from '../auth/refreshToken';

async function deleteApplicant(id: TModelId) {
  const resp = await api.api.deleteApplicant(id);
  return resp.data;
}

export default function useDeleteApplicant() {
  const { mutate: refreshUser } = useRefreshToken();

  return useMutation({
    mutationFn: deleteApplicant,
    onSuccess: () => {
      refreshUser();
    },
  });
}
