import compact from 'lodash/compact';
import { QuestionSetStatus, UserRecruitmentRoleInput, UserRole } from '@/generated';
import { IFilter, ISelectOption } from '@/interfaces';
import useAuthState from '@/states/authState';

export default function useRecruitmentFilters(): IFilter[] {
  const userRole = useAuthState((state) => state.user!.userRole);

  return [
    {
      name: 'roleAs',
      placeholder: (t) => t('Role'),
      isMulti: false,
      options: compact([
        // { label: 'All', value: UserRecruitmentRoleInput.ALL },
        { label: (t) => t('Yours'), value: UserRecruitmentRoleInput.OWNER },
        { label: (t) => t('Collaborations'), value: UserRecruitmentRoleInput.COLLABORATOR },
        [UserRole.ADMIN, UserRole.CLIENT].includes(userRole) && {
          label: (t) => t('Team'),
          value: UserRecruitmentRoleInput.RESPONSIBLE,
        },
      ]) satisfies ISelectOption<UserRecruitmentRoleInput>[],
    },
  ];
}

export const recruitmentFilters: IFilter[] = [
  {
    name: 'questionSetStatus',
    placeholder: (t) => `${t('Questionset')} ${t('Status')}`,
    isMulti: true,
    options: [
      { label: (t) => t('Draft'), value: QuestionSetStatus.DRAFT },
      { label: (t) => t('Published'), value: QuestionSetStatus.PUBLISHED },
      { label: (t) => t('Screening'), value: QuestionSetStatus.SCREENING },
    ] satisfies ISelectOption<QuestionSetStatus>[],
  },
];
