import React from 'react';
import {
  ContainerProps,
  withSectionStyles,
} from '@/components/questionsets/details/sections/section/Container';
import { cn } from '@/utils';

function Section({ children, className }: ContainerProps) {
  return <li className={cn(className, 'text-2xl')}>{children}</li>;
}

export default withSectionStyles(Section);
