/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';

export default function AgreeToPolicy() {
  const { t } = useTranslation();
  const { handleBlur, handleChange, values } = useFormikContext<any>();

  return (
    <div className="col-span-full flex items-center gap-4">
      <input
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.agree}
        className="checkbox-primary checkbox"
        id="agree"
        name="agree"
        type="checkbox"
      />
      <label htmlFor="agree" className="w-full text-sm font-medium">
        {t('By checking this box you agree to our')}{' '}
        <Link className="btn-link btn-primary" to="https://diverspros.com/policy">
          {t('Policy')}
        </Link>
      </label>
    </div>
  );
}
