import { UserRole } from '@/generated';
import { IFilter, ISelectOption } from '@/interfaces';

export const userFilters: IFilter[] = [
  {
    name: 'userRole',
    placeholder: (t) => t('Role'),
    isMulti: true,
    options: [
      { label: (t) => t('User'), value: UserRole.USER },
      { label: (t) => t('Admin'), value: UserRole.ADMIN },
      { label: (t) => t('Client'), value: UserRole.CLIENT },
    ] satisfies ISelectOption<UserRole>[],
  },
];
