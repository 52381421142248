import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GetClientsParams } from '@/generated';
import api from '@/libs/api';

async function getClients(params: GetClientsParams) {
  const resp = await api.api.getClients(params);
  return resp.data;
}

export default function useGetClients(params: GetClientsParams, enabled = true) {
  return useQuery({
    queryKey: ['clients', params],
    queryFn: () => getClients(params),
    placeholderData: keepPreviousData,
    enabled,
  });
}
