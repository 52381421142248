import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import useAsideState from '@/states/asideState';
import { cn } from '@/utils';

export default function Footer() {
  const [isMaximized, toggleIsMaximized] = useAsideState((state) => [
    state.isMaximized,
    state.toggleIsMaximized,
  ]);
  const { t } = useTranslation();

  return (
    <footer className={cn('py-4', { 'mt-auto': isMaximized })}>
      <motion.button
        transition={{ duration: 0.2 }}
        animate={{ rotate: isMaximized ? 180 : 0 }}
        type="button"
        className="btn btn-circle btn-ghost"
        onClick={toggleIsMaximized}
        aria-label={isMaximized ? t('Minimize Aside') : t('Maximize Aside')}
      >
        <ChevronDoubleRightIcon strokeWidth={2} className="delay h-6 w-6" />
      </motion.button>
    </footer>
  );
}
