import { useSuspenseQuery } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

async function getClient(id: TModelId) {
  const resp = await api.api.getClient(id);
  return resp.data;
}

export default function useGetClient(id: TModelId) {
  return useSuspenseQuery({
    queryKey: ['clients', id],
    queryFn: () => getClient(id),
  });
}
