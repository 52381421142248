/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { EHelpCategory } from '@/enums';
import { IFaq } from '@/interfaces';

const faqs: IFaq[] = [
  {
    category: EHelpCategory.Recruitments,
    question: 'How can I get started?',
    answer: `1. Click create new recruitment.
    2. Add your job title and an optional description.
    3. The new job will now be under recruitments, and you continue by clicking on create.
    4. Once you click create you can choose between either Qualification or Personality.
        ◦ Qualification is for hard skills assessment of the candidates.
        ◦ Personality gives you an option of providing your candidates with the Big 5 personality Test. (We advise that the personality test is only used as a second question set, for the shortlisted applicants based on their qualification assessment).
    5. Choosing Qualification and enter a description (optional)
    6. Click next and choose between
    7. AI (Let our AI create the questions for you),
        ◦ Choose AI and click Next.
        ◦ Enter your job description (Candidate qualifications and requirements).
        ◦ Once the AI has extracted the qualifications click Next.
        ◦ AI generates a question set for candidates, if you require a wider variety of questions you can click on Generate More Questions. If you want to delete questions just press the trash bin button on the individual questions you don't want to use.
        ◦ Once you are happy with the selection of question click on Next
        ◦ Our AI now generates the scores for the question set.
        ◦ Once you see the weighted scores for each question (0-100, 100 being the highest), click Next.
        ◦ It is now time to finalize your question set, by providing a section title for the question set, which the candidates will be able to see and to decide how many questions you want each page to feature (You can add as many or as few questions per page as you like, and the candidate will just click through the pages to continue to the next group of questions). Once you are satisfied click submit.
        ◦ When you see the question set you now have the option to edit the questions, answers, and weighting, ensuring that it perfectly matches with your requirements.
        ◦ Once you are done editing your question set, it is now time to publish it. Here you choose the date of the deadline (the question set will only be available to candidates until this date), and you will be provided with a link or a QR code that you then add to your job description with a message that all candidates need to answer the question set.
    8. Templates (pre-defined templates).
    9. Blank (create your own question sets).
    `,
  },
  {
    category: EHelpCategory.Recruitments,
    question: 'How are the scores of the candidates calculated?',
    answer:
      'The scores of the candidates are calculated using a weighted system between 0-100, where 100 is the highest score. You can choose your own weighting for each question and answer by editing the question and choosing how the weighting should be for each answer.',
  },
  {
    category: EHelpCategory.Recruitments,
    question: 'Is it possible to change the Question Set after it has been published?',
    answer:
      'No it is not possible to change the question set after it has been published. To ensure a fair assessment of all candidates it is important that all candidates are subjected to the same questions. Should you wish to change the question set, you will need to create a new question set and you will be provided with a new link for the candidates.',
  },
  {
    category: EHelpCategory.Recruitments,
    question:
      'I am not only interested in a candidate’s hard skills, but I also want to ensure that their personality is a good fit. Do you have an option for this?',
    answer:
      'Yes, we do. Once you have attained a short list of your preferred candidates based on their hard skills, then you have the option of creating a new question set, based on the Big 5 personality test, that you can share with the candidates ensuring not only a match in terms of qualifications but also in terms of personality. ',
  },
  {
    category: EHelpCategory.Recruitments,
    question: 'Can applicants access the question set after the deadline?',
    answer:
      'No, as soon as the pre-defined deadline is passed, the question set will be locked and can no longer be accessed by the applicants.',
  },
  {
    category: EHelpCategory.Recruitments,
    question: 'What are the system requirements to use this AI tool?',
    answer: 'You can use options (the three dots) and filter based on your needs.',
  },
  {
    category: EHelpCategory.Recruitments,
    question: 'How does the tool handle data privacy and candidate information?',
    answer:
      'DivERS follows all GDPR standards. All the candidate information that we hold is the candidates email address which we receive from the candidate when they sign in to answer the questions. This email will be stored with us for 1 year, after which time it will be deleted unless the candidate actively chooses for us to continue storing it.',
  },
  {
    category: EHelpCategory.Recruitments,
    question: 'Can the AI tool be customized for different job roles?',
    answer:
      'Our AI Assistant can help formulate diverse and unbiased question sets for any type of job role you might have open. Not only does our AI-Assistant help you in screening for the best candidates, it also helps you save time by automating the shortlisting process and creating a scoreboard for all candidates, so that you only have to spend your time and focus on the candidates with the best fit.',
  },
  {
    category: EHelpCategory.Recruitments,
    question: 'What languages does the AI support?',
    answer:
      'Currently our AI Assistant supports the following languages English, Danish, Swedish, Norwegian, German, Spanish, and French. More languages will follow as we expand our reach and product offering. ',
  },
  {
    category: EHelpCategory.Recruitments,
    question: 'How does the weighting system work?',
    answer:
      'Whether you are using our AI-generated question sets, our templates or you create your own question set from scratch, you have the opportunity to provide weights scores for each multiple-choice question and answer. In our AI-generated question sets and templates, the answers will already have weights attached, while if you make your own question set you define the weighted scores for each question. Our weighted scores range from 0-1, where 1 is the highest score.',
  },
  {
    category: EHelpCategory.Recruitments,
    question: 'How do we ensure that the recruitment process is unbiased?',
    answer:
      'The DivERS platform is completely anonymized, and the only candidate information that we possess is the email address that a candidate uses to log in and answer a given question set. We hold no other demographic information on candidates. At the same time our innovative approach of using AI or user generated questions and scoring ensures that all candidates are measured using the same metrics, focusing on their hard skills and qualifications and are scored according to the pre-defined score weighting for each multiple-choice question and answer. ',
  },
  {
    category: EHelpCategory.Recruitments,
    question: 'What is AI-driven recruitment and how does it work?',
    answer:
      "As humans we are all biased. We like what we like, and dislike what we don't. Our AI-assistant does not have these biases, in stead it creates a series of questions for candidate assessment that is free from bias and therefore ensuring that every candidate gets a fair chance at landing their dream job.",
  },
  {
    category: EHelpCategory.Recruitments,
    question: 'What types of questions does the AI generate?',
    answer: `Below you can find a series of questions and answer options with weightings to better understand our formatting. Please note that only you can see the scores/weights for each answer, candidates/applicants will not be able to see the scores.

Question: Consider your proficiency in content creation and media production. Which statement best reflects your level of expertise?
a) I have basic content creation skills but limited experience in media production. (Score: 20%)
b) I can create written content and have some experience with multimedia production. (Score: 40%)
c) I am skilled in developing a variety of content formats, including written, visual, and multimedia assets. (Score: 70%)
d) I excel in crafting engaging, multi-format content tailored to diverse audiences and channels. (Score: 90%)
e) I have extensive expertise in strategic content creation and media production for global brand campaigns. (Score: 100%)

Question: Reflect on your experience in leading thought leadership research initiatives. Which statement best describes your level of involvement?
a) I have limited experience in research initiatives, primarily focusing on content creation. (Score: 20%)
b) I have contributed to research projects, assisting with data collection and analysis. (Score: 40%)
c) I have designed and implemented qualitative and quantitative research studies to explore relevant topics. (Score: 70%)
d) I have spearheaded comprehensive thought leadership research programs, from conception to analysis and storytelling. (Score: 90%)
e) I have a proven track record of leading multi-market, global research initiatives that shape industry narratives. (Score: 100%)


Question: Reflect on your leadership skills and experience in fostering inclusive team environments. Which statement best describes your approach?
a) I have limited leadership experience and am still developing my team management skills. (Score: 20%)
b) I can lead small teams but struggle to promote professional growth and inclusivity. (Score: 40%)
c) I can effectively lead teams, promote professional growth, and foster inclusive environments. (Score: 70%)
d) I excel in leading high-performing, diverse teams and prioritize creating inclusive and supportive environments. (Score: 90%)
e) I have a proven track record of leading diverse, global teams and driving positive cultural change. (Score: 100%)

    `,
  },

  {
    category: EHelpCategory.Users,
    question: 'What is users menu?',
    answer:
      'Here you can see an overview of your team account on DivERS and get an overview of the users and also see who are registered as Admins and who are registered as users.',
  },
  {
    category: EHelpCategory.Applicants,
    question: 'Do you store any personal or demographic information about the candidates?',
    answer: 'No, all the information we have about the candidates are their email addresses.',
  },
  {
    category: EHelpCategory.Applicants,
    question: 'Is there a set time for applicants to answer the questions?',
    answer:
      'No, there is no time limit to answer the questions. To be as inclusive and fair as possible, all applicants are allowed the time they need to answer the questions. Depending on the type of questions and the number it should not take candidates more than 5-10 minutes to answer all questions in a question set.',
  },
  {
    category: EHelpCategory.Applicants,
    question: 'Is there a set time for applicants to answer the questions?',
    answer:
      'No, there is no time limit to answer the questions. To be as inclusive and fair as possible, all applicants are allowed the time they need to answer the questions. Depending on the type of questions and the number it should not take candidates more than 5-10 minutes to answer all questions in a question set.',
  },
  {
    category: EHelpCategory.Users,
    question: 'What & Who is the client?',
    answer:
      'The Client is the team lead on the DivERS platform and is the overall Administrator. The Client can invite team members to join the DivERS platform and is in charge of all user rights. The Client can also choose who in their team should be given admin rights.',
  },
  {
    category: EHelpCategory.Users,
    question: 'What & Who are admins?',
    answer:
      'Admins can assign users to specific recruitments and communicate with the specific teams regarding individual recruitments through the message function.',
  },
  {
    category: EHelpCategory.Users,
    question: 'What & Who are users?',
    answer:
      'Once you receive an invitation from your DivERS Account Administrator, you will have the opportunity to create other users (they will get an invitation email automatically).',
  },
  {
    category: EHelpCategory.Messages,
    question: 'What is messages?',
    answer:
      'Messages is a platform that owner and collaborators can chat and communicate about a recruitment.',
  },
  {
    category: EHelpCategory.Account,
    question: 'What is Two factor Authentication?',
    answer:
      'Two factor authentication is a way to increase security by requiring users to verify their identity using two different forms of identification before gaining access to their accounts. This typically involves something they know (like a password), and something they have (like a mobile device that receives a verification code).',
  },
];

export default function Faqs() {
  const [params] = useSearchParams();
  const category = params.get('category');
  const searchQuery = (params.get('searchQuery') ?? '').toLowerCase();

  return (
    <div className="flex flex-col space-y-4">
      {faqs
        .filter((faq) => {
          if (category && faq.category !== category) {
            return false;
          }

          if (
            searchQuery &&
            !faq.question.toLowerCase().includes(searchQuery) &&
            !faq.answer.toLowerCase().includes(searchQuery)
          ) {
            return false;
          }

          return true;
        })
        .map((faq, i) => (
          <div key={i} className="collapse collapse-plus bg-base-200">
            <input type="radio" name="my-accordion-3" />
            <div className="collapse-title text-xl font-medium">{faq.question}</div>
            <div className="collapse-content">
              <p className="description whitespace-pre-line">{faq.answer}</p>
            </div>
          </div>
        ))}
    </div>
  );
}
