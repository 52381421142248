import { useInfiniteQuery } from '@tanstack/react-query';
import { GetNotificationsParams } from '@/generated';
import api from '@/libs/api';
import { getNextPageParam } from '@/utils';

async function getNotifications(params: GetNotificationsParams) {
  const resp = await api.api.getNotifications(params);
  return resp.data;
}

export default function useGetNotifications(params: GetNotificationsParams) {
  return useInfiniteQuery({
    queryKey: ['notifications', params],
    refetchInterval: 60 * 1000,
    queryFn: ({ pageParam }) => getNotifications({ ...params, page: pageParam }),
    initialPageParam: params.page,
    getNextPageParam,
  });
}
