import React from 'react';
import { useTranslation } from 'react-i18next';
import useClearAllNotifications from '@/services/notifications/clearAll';
import { Button } from '@/shared/form';

export default function Header() {
  const { t } = useTranslation();
  const { mutate: clearAll, isPending } = useClearAllNotifications();

  const handleClearNotifications = () => {
    clearAll();
  };

  return (
    <div className="flex items-center justify-between font-semibold">
      <p className="text-xl">{t('Notifications')}</p>
      <Button
        isLoading={isPending}
        onClick={handleClearNotifications}
        className="btn btn-ghost btn-sm underline"
      >
        {t('Mark all as read')}
      </Button>
    </div>
  );
}
