import { useQuery } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

async function getCollaborators(recruitmentId: TModelId) {
  const resp = await api.api.getRecruitmentRoles(recruitmentId);
  return resp.data;
}

export default function useGetRecruitmentCollaborators(recruitmentId: TModelId | undefined) {
  return useQuery({
    queryKey: ['collaborators', recruitmentId],
    queryFn: () => getCollaborators(recruitmentId!),
    enabled: Boolean(recruitmentId),
  });
}
