import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/form';
import { cn } from '@/utils';
import ButtonActions, { ButtonActionProps } from './ButtonActions';

type WizardButtonsProps = {
  submitProps?: ButtonActionProps;
  previousProps?: ButtonActionProps;
  cancelProps?: ButtonActionProps;
};

export default function WizardButtons({
  submitProps,
  previousProps,
  cancelProps,
}: WizardButtonsProps) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-between">
      <Button {...cancelProps} className={cn('btn btn-error btn-md', cancelProps?.className)}>
        {cancelProps?.text || t('Cancel')}
      </Button>
      <ButtonActions
        firstButton="cancel"
        submitProps={{ text: t('Next'), ...submitProps }}
        cancelProps={{ text: t('Previous'), ...previousProps }}
      />
    </div>
  );
}
