import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDialogModal } from '@/hooks';
import { Button } from '@/shared/form';
import DeleteModal from '@/shared/modals/DeleteModal';
import useSettingsState from '@/states/settingsState';

export default function ResetToDefault() {
  const { modalRef, handleCloseModal, handleOpenModal } = useDialogModal();
  const resetSettingsToDefault = useSettingsState((state) => state.resetSettingsToDefault);
  const { t } = useTranslation();

  const handleReset = () => {
    resetSettingsToDefault();
    toast.success(t('Your Settings has been updated to default.'));
    handleCloseModal();
  };

  return (
    <>
      <DeleteModal
        title={t('Reset your Settings to Default')}
        deleteText={t('Reset')}
        onClose={handleCloseModal}
        onDelete={handleReset}
        modalRef={modalRef}
      />
      <Button onClick={handleOpenModal} className="btn btn-neutral btn-md">
        {t('Reset to Default')}
      </Button>
    </>
  );
}
