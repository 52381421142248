import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ClientSettingCreate, ClientSettingName, ClientSettingUpdate } from '@/generated';
import api from '@/libs/api';
import { TModelId } from '@/types';
import useGetClientSettings from '.';

type TClientSettingEdit = ClientSettingUpdate & {
  clientSettingId: TModelId;
};

async function editClientSetting({ clientSettingId, ...data }: TClientSettingEdit) {
  const resp = await api.api.updateClientSetting(clientSettingId, data);
  return resp.data;
}

async function createClientSetting(params: ClientSettingCreate) {
  const resp = await api.api.createClientSetting(params);
  return resp.data;
}

type MutateSetting = {
  name: ClientSettingName;
  value: string;
};

export default function useMutateClientSetting() {
  const queryClient = useQueryClient();
  const { data: settings } = useGetClientSettings();

  return useMutation({
    mutationFn: (data: MutateSetting) => {
      const setting = settings.items.find((s) => s.name === data.name);
      return setting
        ? editClientSetting({ clientSettingId: setting.id, ...data })
        : createClientSetting(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clientSettings'] });
    },
  });
}
