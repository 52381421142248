import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { format, formatDistanceToNow } from 'date-fns';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDialogModal } from '@/hooks';
import useEditDeadline from '@/services/questionsets/editDeadline';
import ButtonActions from '@/shared/custom/ButtonActions';
import { Button, Fields } from '@/shared/form';
import DialogModal from '@/shared/modals/DialogModal';
import { TField, TModelId } from '@/types';

type DeadlineProps = {
  deadline: string | undefined | null;
  questionsetId: TModelId;
};

const validationSchema = Yup.object({
  deadline: Yup.date()
    .min(new Date(new Date().getTime() + 1000), 'This field must be bigger than today.')
    .required(),
});

const fields: TField[] = [
  {
    isRequired: true,
    type: 'input',
    name: 'deadline',
    label: (t) => t('Deadline'),
    fieldProps: { type: 'datetime-local', min: new Date().toISOString().slice(0, -8) },
  },
];

export default function Deadline({ deadline, questionsetId }: DeadlineProps) {
  const { modalRef, handleCloseModal, handleOpenModal } = useDialogModal();
  const { mutate: editDeadline, isPending } = useEditDeadline();
  const { t } = useTranslation();

  if (!deadline) return null;

  const tomorrow = new Date(new Date().setHours(0, 0, 0, 0));
  tomorrow.setDate(tomorrow.getDate() + 1);
  const showDeadlineChange = new Date(deadline).getTime() >= tomorrow.getTime();

  return (
    <div className="flex flex-wrap items-center justify-between gap-2">
      <DialogModal closeOnClickOutside parentElement="div" modalRef={modalRef}>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ deadline: format(new Date(deadline), "yyyy-MM-dd'T'HH:mm") }}
          onSubmit={async (values) => {
            editDeadline(
              {
                deadline: new Date(values.deadline).toISOString(),
                questionSetId: questionsetId,
              },
              {
                onSuccess: () => {
                  handleCloseModal();
                  toast.success("You've changed the deadline successfully.");
                },
              },
            );
          }}
        >
          <Form className="space-y-4">
            <h3 className="h3">
              {t('Change')} {t('Deadline')}
            </h3>
            <Fields fields={fields} />
            <ButtonActions
              submitProps={{ disabled: isPending, isLoading: isPending }}
              cancelProps={{ onClick: handleCloseModal }}
            />
          </Form>
        </Formik>
      </DialogModal>
      <div className="flex gap-4">
        <span className="description">{t('Deadline')}: </span>
        <span> {formatDistanceToNow(deadline, { addSuffix: true })}</span>
      </div>
      {showDeadlineChange && (
        <Button onClick={handleOpenModal} className="btn btn-outline btn-primary btn-sm">
          {t('Change')}
        </Button>
      )}
    </div>
  );
}
