import { useSuspenseQuery } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

async function getRecruitment(id: TModelId) {
  const resp = await api.api.getRecruitment(id);
  return resp.data;
}

export default function useGetRecruitment(id: TModelId) {
  return useSuspenseQuery({ queryKey: ['recruitments', id], queryFn: () => getRecruitment(id) });
}
