import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import compact from 'lodash/compact';
import * as Yup from 'yup';
import validations from '@/fixtures/validations';
import { TInformation } from '@/pages/Questionnaire';
import { Button, Fields } from '@/shared/form';

type InformationStarterProps = {
  jobTitle: string;
  questionnaireIntro: string | null | undefined;
  onSubmit: (values: TInformation) => void;
  askForLinkedInProfile: boolean;
  isDisabled: boolean;
};

export default function InformationStarter({
  jobTitle,
  questionnaireIntro,
  askForLinkedInProfile,
  isDisabled,
  onSubmit,
}: InformationStarterProps) {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    email: validations.email,
    linkedinProfileUrl: Yup.string().when('email', {
      is: () => askForLinkedInProfile,
      then: Yup.string()
        .max(255)
        .matches(
          /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/,
          t('errors.invalid'),
        )
        .required(),
    }),
  });

  return (
    <div className="mx-auto flex max-w-4xl flex-1 items-center justify-center">
      <div className="card bg-base-300">
        <div className="card-body gap-4">
          <h1 className="card-title flex-wrap text-2xl font-bold">
            {t("Let's answer the")} <span className="text-primary">{jobTitle}</span>
          </h1>
          <p className="description">
            {questionnaireIntro ??
              t("Enter your email to answer the questions and let's start. we Believe in you!")}
          </p>
          <Formik
            validationSchema={validationSchema}
            onSubmit={(values) => {
              onSubmit(values);
            }}
            initialValues={{ email: '', linkedinProfileUrl: '' }}
          >
            <Form className="space-y-4">
              <Fields
                fields={compact([
                  {
                    isRequired: true,
                    name: 'email',
                    label: t('Email'),
                    type: 'input',
                    fieldProps: {},
                  },
                  askForLinkedInProfile && {
                    isRequired: true,
                    name: 'linkedinProfileUrl',
                    label: t('Linkedin Profile'),
                    tooltip: {
                      text: t('questionnaire.linkedinProfileTooltip'),
                    },
                    type: 'input',
                    fieldProps: {
                      placeholder: 'https://www.linkedin.com/in/yourusername',
                    },
                  },
                ])}
              />
              <div className="card-actions">
                <Button disabled={isDisabled} type="submit" className="btn btn-primary btn-md">
                  {t("Let's do it")}
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}
