import React from 'react';
import { SectionSummary } from '@/generated';
import { Button } from '@/shared/form';
import { TModelId } from '@/types';
import Section from './Section';

type SectionsProps = {
  sections?: SectionSummary[];
  activeSectionId: TModelId;
  setSectionId: (sectionId: TModelId) => void;
};

export default function Sections({ sections, activeSectionId, setSectionId }: SectionsProps) {
  return (
    <ol className="scroll-none flex items-center space-x-4 overflow-x-auto">
      {sections?.map((section) => (
        <Section key={section.id} isActive={activeSectionId === section.id}>
          <Button onClick={() => setSectionId(section.id)}>{section.title}</Button>
        </Section>
      ))}
    </ol>
  );
}
