import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectRecruitmentMessage from '@/components/messages/index/SelectRecruitmentMessage';
import Head from '@/shared/custom/Head';

export default function Index() {
  const { t } = useTranslation();
  const title = t('Messages');

  return (
    <>
      <Head title={title} description={title} />
      <SelectRecruitmentMessage />
    </>
  );
}
