import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { useDialogModal } from '@/hooks';
import { TIndexLayoutContext } from '@/layouts/applicants/IndexLayout';
import useRejectApplicants from '@/services/applicants/reject';
import { Button } from '@/shared/form';
import useApplicantsState from '@/states/applicantsState';
import RejectModal, { TOnRejectValues } from '../RejectModal';

export default function RejectSelectedApplicants() {
  const applicantsId = useApplicantsState((state) => state.applicantsId);
  const { t } = useTranslation();
  const clearApplicantsId = useApplicantsState((state) => state.clearApplicantsId);
  const { modalRef, handleCloseModal, handleOpenModal } = useDialogModal();
  const { mutate: rejectApplicants, isPending } = useRejectApplicants();
  const { queryResult, questionset } = useOutletContext<TIndexLayoutContext>();
  const { data: applicants } = queryResult;

  const handleReject = (values: TOnRejectValues) => {
    rejectApplicants(
      {
        includeApplicantScore: values.includeApplicantScore,
        questionsetId: questionset.id,
        rejectedApplicants: applicants!.items
          .filter(
            (applicant) => applicantsId.includes(applicant.id) && !applicant.rejectedTimestamp,
          )
          .map((applicant) => ({ applicantId: applicant.id, rejectedReason: values.reason })),
      },
      {
        onSuccess: () => {
          handleCloseModal();
          toast.success(`You've rejected ${applicantsId.length} applicants successfully.`);
          clearApplicantsId();
        },
      },
    );
  };

  return (
    <>
      <RejectModal
        modalRef={modalRef}
        onClose={handleCloseModal}
        title={`Reject ${applicantsId.length} Applicants`}
        onReject={handleReject}
      />
      <Button
        onClick={handleOpenModal}
        isLoading={isPending}
        disabled={isPending}
        className="text-primary"
      >
        <XCircleIcon className="h-5 w-5" />
        {t('Reject Selected')}
      </Button>
    </>
  );
}
