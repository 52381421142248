import React from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useOutletContext } from 'react-router-dom';
import Form from '@/components/questionsets/Form';
import { QuestionSetCreate } from '@/generated';
import { TQuestionsetsEditLayout } from '@/layouts/questionsets/EditLayout';
import useEditQuestionset from '@/services/questionsets/edit';
import Head from '@/shared/custom/Head';
import WizardButtons from '@/shared/custom/WizardButtons';

export default function Create() {
  const { questionset, cancelProps, questionsetId, isInWizardMode } =
    useOutletContext<TQuestionsetsEditLayout>();
  const { t } = useTranslation();
  const title = t('Edit Questionset');
  const navigate = useNavigate();
  const { mutate: editQuestionset, isPending } = useEditQuestionset();

  const handleSubmit = (values: QuestionSetCreate) => {
    editQuestionset(
      { ...values, questionsetId },
      {
        onSuccess: () => {
          navigate(
            isInWizardMode
              ? `/questionsets/${questionset?.id}/setup?${createSearchParams({ mode: 'wizard' })}`
              : `/questionsets/${questionset?.id}/details`,
          );
        },
      },
    );
  };

  return (
    <>
      <Head title={title} description={title} />
      <h1 className="h1">{title}</h1>
      <ul className="steps steps-vertical md:steps-horizontal">
        <li className="step step-primary">{t('Information')}</li>
        <li className="step">{t('Finish')}</li>
      </ul>
      <Form
        hasTypeSelection={false}
        onSubmit={handleSubmit}
        initialValues={{
          title: questionset?.title ?? '',
          description: questionset?.description ?? '',
          deadline: questionset?.deadline ?? null,
          type: questionset?.type ?? ('' as any),
          userOptions: questionset?.userOptions,
        }}
      >
        <WizardButtons
          previousProps={{ disabled: true }}
          submitProps={{
            type: 'submit',
            text: isInWizardMode ? t('Next') : t('Submit'),
            isLoading: isPending,
            disabled: isPending,
          }}
          cancelProps={cancelProps}
        />
      </Form>
    </>
  );
}
