import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useWizard } from 'react-use-wizard';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TQuestionsetsEditLayout } from '@/layouts/questionsets/EditLayout';
import { aiContext } from '@/pages/questionsets/Ai';
import useEditRecruitment from '@/services/recruitments/edit';
import WizardButtons from '@/shared/custom/WizardButtons';
import Field from '@/shared/form/Field';

const validationSchema = Yup.object().shape({
  advertisement: Yup.string().min(100).max(10_000).required(),
  nbQuestions: Yup.number().min(1).max(15),
});

export default function Advertisement() {
  const { aiCancelProps, isInWizardMode, questionsetId, questionset, urlParameters } =
    useOutletContext<TQuestionsetsEditLayout>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate: editRecruitment, isPending: isUpdatingAdvertisement } = useEditRecruitment();
  const { recruitment, setQualifications, setQuestions, nbQuestions, setNbQuestions } =
    useContext(aiContext);
  const { nextStep } = useWizard();

  const handlePrevious = () => navigate(`/questionsets/${questionsetId}/setup${urlParameters}`);

  return (
    <Formik
      initialValues={{ advertisement: recruitment?.advertisement ?? '', nbQuestions }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        setQualifications(values.advertisement);
        setNbQuestions(values.nbQuestions);
        setQuestions([]);
        editRecruitment(
          { ...values, recruitmentId: questionset!.recruitmentId },
          { onSuccess: nextStep },
        );
      }}
    >
      <Form className="space-y-4">
        <Field
          isRequired
          label={t('Number of Generated Questions')}
          name="nbQuestions"
          type="input"
          fieldProps={{ type: 'number', min: 1 }}
        />
        <Field
          isRequired
          label={t('Job Description')}
          name="advertisement"
          type="input"
          fieldProps={{ as: 'textarea', maxLimitHelper: 10_000, rows: 10 }}
        />
        <WizardButtons
          submitProps={{ isLoading: isUpdatingAdvertisement, text: t('Next') }}
          cancelProps={aiCancelProps}
          previousProps={{ onClick: handlePrevious, disabled: !isInWizardMode }}
        />
      </Form>
    </Formik>
  );
}
