import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  ChevronUpDownIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { QuestionSetStatus } from '@/generated';
import { useDialogModal } from '@/hooks';
import { detailsContext } from '@/pages/questionsets/Details';
import DeleteModal from '@/shared/modals/DeleteModal';
import { TModelId } from '@/types';
import Action from './Action';

type ActionsProps = {
  useDelete: any;
  resourceId: TModelId;
  resourceName: string;
  onEdit: () => void;
  onSort: () => void;
  onCreate: () => void;
  onSuccessfullyDeleted?: () => void;
  canSort: boolean;
};

export default function Actions({
  useDelete,
  resourceId,
  resourceName,
  onEdit,
  onSort,
  onSuccessfullyDeleted,
  onCreate,
  canSort,
}: ActionsProps) {
  const { t } = useTranslation();
  const { clearQueryCache, data } = useContext(detailsContext);
  const isStatusDraft = data.status === QuestionSetStatus.DRAFT;
  const { mutate: deleteResource, isLoading: isDeleting } = useDelete();
  const { modalRef, handleCloseModal, handleOpenModal } = useDialogModal();

  const handleDelete = () => {
    deleteResource(resourceId, {
      onSuccess: () => {
        if (onSuccessfullyDeleted) onSuccessfullyDeleted();
        clearQueryCache();
        toast.success(t('toast.deletedSuccessfully', { model: resourceName }));
        handleCloseModal();
      },
    });
  };

  const actions = [
    {
      Element: ChevronUpDownIcon,
      action: t('Sort'),
      className: 'hover:text-secondary',
      onClick: onSort,
      isActive: canSort,
    },
    {
      Element: PencilSquareIcon,
      action: t('Edit'),
      className: 'hover:text-success',
      onClick: onEdit,
      isActive: true,
    },
    {
      Element: TrashIcon,
      action: t('Delete'),
      className: 'hover:text-error',
      onClick: handleOpenModal,
      isActive: true,
    },
    {
      Element: PlusIcon,
      action: t('Create'),
      className: 'hover:text-primary',
      onClick: onCreate,
      isActive: true,
    },
  ].filter((action) => action.isActive);

  return (
    <div className="flex">
      <DeleteModal
        isDeleting={isDeleting}
        title={`${t('Delete')} ${resourceName}`}
        onDelete={handleDelete}
        onClose={handleCloseModal}
        modalRef={modalRef}
      />
      {actions.map((action) => (
        <Action
          key={action.action}
          {...action}
          disabled={!isStatusDraft}
          tooltip={isStatusDraft ? `${action.action} ${resourceName}` : 'Questionset is Published'}
        />
      ))}
    </div>
  );
}
