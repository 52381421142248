import React, { useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { Field, useFormikContext } from 'formik';
import { IInput } from '@/interfaces';
import { cn } from '@/utils';
import Button from '../Button';

interface InputProps extends IInput {
  hasError?: boolean;
}

export default function Input({ hasError, maxLimitHelper, className = '', ...props }: InputProps) {
  const { values } = useFormikContext<any>();
  const [showPassword, setShowPassword] = useState(false);
  const Icon = showPassword ? EyeSlashIcon : EyeIcon;
  const isPassword = props.type === 'password';
  const passwordType = showPassword ? 'text' : 'password';

  const handleToggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      <div className="relative w-full">
        <Field
          {...props}
          type={isPassword ? passwordType : props.type}
          className={cn(
            'w-full',
            {
              'pe-10': isPassword,
              'input input-bordered': props.as === undefined && props.type !== 'range',
              'input-error': props.as === undefined && props.type !== 'range' && hasError,
              'textarea textarea-bordered': props.as === 'textarea',
              'textarea-error': props.as === 'textarea' && hasError,
              'range range-sm': props.type === 'range',
              'range-error': props.type === 'range' && hasError,
            },
            className,
          )}
        />
        {maxLimitHelper && props.as === 'textarea' && (
          <div className="absolute bottom-4 right-8 text-sm">
            <p>
              <span className={cn({ 'text-error': values[props.name!].length > maxLimitHelper })}>
                {values[props.name!].length}
              </span>
              /<span className="text-primary">{maxLimitHelper}</span>
            </p>
          </div>
        )}
      </div>
      {isPassword && (
        <Button
          onClick={handleToggleShowPassword}
          className="btn btn-circle btn-ghost btn-sm absolute right-2 top-11"
        >
          <Icon className="h-6 w-6" />
        </Button>
      )}
    </>
  );
}
