import React from 'react';
import { Button } from '@/shared/form';
import { cn } from '@/utils';

type TabsProps = {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<any>>;
  tabs: { label: string; value: string }[];
};

export default function Tabs({ activeTab, setActiveTab, tabs }: TabsProps) {
  return (
    <ul className="flex items-center space-x-2">
      {tabs.map((tab) => (
        <li key={tab.value}>
          <Button
            onClick={() => setActiveTab(tab.value)}
            className={cn('btn btn-sm', {
              'btn-primary': activeTab === tab.value,
              'btn-secondary': activeTab !== tab.value,
            })}
          >
            {tab.label}
          </Button>
        </li>
      ))}
    </ul>
  );
}
