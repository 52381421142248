import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import Link from '@/components/auth/Link';
import validations from '@/fixtures/validations';
import useForgotPassword from '@/services/auth/forgotPassword';
import { Button, Fields } from '@/shared/form';
import { TField } from '@/types';
import { isAxiosError } from '@/utils';

const validationSchema = Yup.object().shape({
  email: validations.email,
});

export default function Form() {
  const { t } = useTranslation();
  const { mutate: forgotPassword, isPending } = useForgotPassword();

  const fields: TField[] = [
    {
      isRequired: true,
      type: 'input',
      name: 'email',
      label: t('Email'),
      fieldProps: { type: 'text' },
    },
  ];

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setErrors }) => {
        forgotPassword(values.email, {
          onSuccess: () => {
            toast.success(t("We've sent you instructions to reset your password"));
          },
          onError: (e) => {
            if (isAxiosError(e, 404)) {
              setErrors({ email: t('These credentials do not match our records.') });
            }
          },
        });
      }}
    >
      <FormikForm className="w-full space-y-4">
        <Fields fields={fields} />
        <Button
          disabled={isPending}
          isLoading={isPending}
          type="submit"
          className="btn btn-primary w-full text-lg font-semibold"
        >
          {t('Reset Password')}
        </Button>
        <Link to="/login" text={t('Remember your password ?')} />
      </FormikForm>
    </Formik>
  );
}
