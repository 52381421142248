import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Select from '@/components/applicants/Select';
import Head from '@/shared/custom/Head';
import useSettingsState from '@/states/settingsState';

export default function Selection() {
  const { t } = useTranslation();
  const title = t('Applicants');
  const navigate = useNavigate();
  const questionsetId = useSettingsState((state) => state.applicantsCompare.questionsetId);

  useEffect(() => {
    if (!questionsetId) return;
    navigate(`/questionsets/${questionsetId}/applicants`);
  }, [questionsetId]);

  return (
    <>
      <Head title={title} description={title} />
      <h1 className="h1">{title}</h1>
      <Select />
    </>
  );
}
