import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { useWizard } from 'react-use-wizard';
import { TQuestionsetsEditLayout } from '@/layouts/questionsets/EditLayout';
import { cn } from '@/utils';

export default function Steps() {
  const { isInWizardMode } = useOutletContext<TQuestionsetsEditLayout>();
  const { activeStep } = useWizard();
  const { t } = useTranslation();

  return (
    <ul className="steps steps-vertical md:steps-horizontal">
      {isInWizardMode && (
        <>
          <li className="step step-primary">{t('Information')}</li>
          <li className="step step-primary">{t('Setup')}</li>
        </>
      )}
      <li className="step step-primary">{t('Advertisement')}</li>
      <li className={cn('step', { 'step-primary': activeStep >= 1 })}>{t('Questions')}</li>
      <li className="step">{t('Finish')}</li>
    </ul>
  );
}
