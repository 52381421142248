import { useMutation } from '@tanstack/react-query';
import { MessageUpdate } from '@/generated';
import api from '@/libs/api';
import { TModelId } from '@/types';

export type TMessageEdit = MessageUpdate & {
  messageId: TModelId;
};

async function editMessage({ messageId, ...data }: TMessageEdit) {
  const resp = await api.api.updateMessage(messageId, data);
  return resp.data;
}

export default function useEditMessage() {
  return useMutation({ mutationFn: editMessage });
}
