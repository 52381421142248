import React from 'react';
import { TooltipRenderProps } from 'react-joyride';
import { XMarkIcon } from '@heroicons/react/24/outline';
import WizardButtons from '@/shared/custom/WizardButtons';
import { Button } from '@/shared/form';
import useThemeState from '@/states/themeState';
import { cn } from '@/utils';
import ShowAgain from './ShowAgain';

interface TooltipProps extends TooltipRenderProps {}

export default function Tooltip({
  backProps,
  step,
  continuous,
  index,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps,
  size,
}: TooltipProps) {
  const theme = useThemeState((state) => state.theme);

  return (
    <div data-theme={theme}>
      <div
        className="w-[95vw] space-y-4 overflow-hidden bg-base-200 p-3 text-base-content shadow-xl sm:w-[85vw] md:p-5 lg:w-[50vw]"
        {...tooltipProps}
      >
        {step.title && (
          <div className="border-colors flex items-center justify-between rounded-t border-b border-solid pb-2">
            <h3 className="text-2xl font-bold">{step.title}</h3>
            <Button {...closeProps} className={cn({ hidden: continuous })}>
              <XMarkIcon className="h-6 w-6" />
            </Button>
          </div>
        )}
        <div className="description py-2 text-lg">{step.content}</div>
        <ShowAgain />
        <WizardButtons
          submitProps={{
            ...primaryProps,
            text: `${primaryProps.title} ${index + 1}/${size}`,
            className: cn({ hidden: !continuous }),
          }}
          cancelProps={{
            ...skipProps,
            text: skipProps.title,
            className: cn({ hidden: !continuous }),
          }}
          previousProps={{
            ...backProps,
            text: backProps.title,
            className: cn({ hidden: index <= 0 }),
          }}
        />
      </div>
    </div>
  );
}
