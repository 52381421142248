/* eslint-disable i18next/no-literal-string */
import React from 'react';
import config from '@/fixtures/config';
import Logo from '@/shared/icons/Logo';
import useAsideState from '@/states/asideState';

export default function Header() {
  const isMaximized = useAsideState((state) => state.isMaximized);
  const frontendUrl = encodeURI(config.websiteUrl!);

  return (
    <a href={frontendUrl} className="mx-auto py-4">
      {isMaximized ? (
        <Logo width={150} />
      ) : (
        <span className="hover:text-primary-focus font-bold tracking-tight">DivERS</span>
      )}
    </a>
  );
}
