import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApplicantRejected } from '@/generated';
import api from '@/libs/api';
import { TModelId } from '@/types';

type TRejectApplicants = {
  questionsetId: TModelId;
  rejectedApplicants: ApplicantRejected[];
  includeApplicantScore: boolean;
};

async function rejectApplicants({
  questionsetId,
  includeApplicantScore,
  ...data
}: TRejectApplicants) {
  const resp = await api.api.rejectApplicants(
    { questionSetId: questionsetId, includeApplicantScore },
    data,
  );
  return resp.data;
}

export default function useRejectApplicants() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: rejectApplicants,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['applicants'] });
    },
  });
}
