import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';
import useRefreshToken from '../auth/refreshToken';

async function deleteUser(id: TModelId) {
  const resp = await api.api.deleteUser(id);
  return resp.data;
}

export default function useDeleteUser() {
  const { mutate: refreshUser } = useRefreshToken();

  return useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      refreshUser();
    },
  });
}
