import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '@/libs/api';
import useAuthState from '@/states/authState';
import useSettingsState from '@/states/settingsState';

async function logout() {
  await api.api.clearToken({});
}

export default function useLogout() {
  const queryClient = useQueryClient();
  const setIsNotLoggedIn = useAuthState((state) => state.setAuthUserIsNotLoggedIn);
  const setApplicantsCompare = useSettingsState((state) => state.setApplicantsCompare);

  return useMutation({
    mutationFn: logout,
    onSuccess: () => {
      setApplicantsCompare({ recruitmentId: null, questionsetId: null });
      queryClient.clear();
      setIsNotLoggedIn();
    },
  });
}
