import React from 'react';

type TagProps = {
  children: React.ReactNode;
  backgroundColor: string;
};

export default function Tag({ children, backgroundColor }: TagProps) {
  return (
    <div className="btn btn-sm text-white" style={{ backgroundColor }}>
      {children}
    </div>
  );
}
