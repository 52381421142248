import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

type PaginationProps = {
  onPageChange: (page: number) => void;
  total: number;
  perPage: number;
  page: number;
};

const itemClassName = 'join-item btn';

export default function Pagination({ total, perPage, onPageChange, page }: PaginationProps) {
  const { t } = useTranslation();
  const pageCount = Math.ceil(total / perPage);

  const handlePageChange = ({ selected }: { selected: number }) => {
    onPageChange(selected + 1);
  };

  return (
    <div className="">
      <ReactPaginate
        className="join"
        nextLinkClassName={itemClassName}
        previousLinkClassName={itemClassName}
        pageLinkClassName={itemClassName}
        breakLinkClassName={itemClassName}
        disabledLinkClassName="btn-disabled"
        activeLinkClassName="btn-primary"
        breakLabel="..."
        nextLabel=">"
        previousLabel="<"
        renderOnZeroPageCount={() => null}
        pageRangeDisplayed={3}
        forcePage={page - 1}
        pageCount={pageCount}
        onPageChange={handlePageChange}
        nextAriaLabel={t('Next Page')}
        previousAriaLabel={t('Previous Page')}
      />
    </div>
  );
}
