import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ClientUpdateMe } from '@/generated';
import api from '@/libs/api';

async function editClientMe(data: ClientUpdateMe) {
  const resp = await api.api.updateClientMe(data);
  return resp.data;
}

export default function useEditClientMe() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editClientMe,
    onSuccess: (data) => {
      queryClient.setQueryData(['clients', 'me'], data);
    },
  });
}
