import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import commonFields from '@/fixtures/commonFields';
import { QuestionCreate, QuestionType } from '@/generated';
import { ISelectField } from '@/interfaces';
import { Fields, Label } from '@/shared/form';

const questionType: ISelectField<QuestionType> = {
  isRequired: true,
  type: 'select',
  name: 'type',
  label: (t) => t('Question Type'),
  fieldProps: {
    options: [
      { value: QuestionType.TEXT, label: (t) => t('Open Ended') },
      // { value: QuestionType.NUMBER, label: (t) => t('Number') },
      // { value: QuestionType.DECIMAL, label: (t) => t('Decimal') },
      { value: QuestionType.SINGLE_CHOICE, label: (t) => t('Single Choice') },
      { value: QuestionType.MULTIPLE_CHOICES, label: (t) => t('Multiple Choices') },
    ],
  },
};

export default function CommonFields() {
  const { setFieldValue, values } = useFormikContext<QuestionCreate>();
  const { t } = useTranslation();

  return (
    <>
      <Fields
        fields={[
          { ...commonFields.title, label: t('Question') },
          {
            ...commonFields.description,
            tooltip: {
              className: 'tooltip-right',
              text: t('You can provide extra information about your question to the applicants.'),
            },
          },
        ]}
      />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <Fields fields={[questionType]} />
        {values.type !== QuestionType.TEXT && (
          <div className="w-full space-y-2">
            <Label isRequired htmlFor="weight" label={t('Weight')} />
            <input
              name="weight"
              type="range"
              min="1"
              max="3"
              className="range range-primary range-sm"
              step="1"
              value={values.weight}
              onChange={(e) => setFieldValue('weight', +e.target.value)}
            />
            <div className="flex w-full justify-between px-2 text-xs">
              <span>{t('Less Important')}</span>
              <span>{t('Neutral')}</span>
              <span>{t('More Important')}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
