/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState } from 'react';
import { defaultParams } from '@/fixtures';
import { useGetInfiniteRecruitments } from '@/services/recruitments';
import LoadMore from '@/shared/custom/LoadMore';
import { cn } from '@/utils';
import Recruitment from './Recruitment';
import RecruitmentSkeleton from './RecruitmentSkeleton';
import Search from './Search';

type RecruitmentsProps = {
  className?: string;
  currentRecruitmentId?: string;
};

export default function Recruitments({ className, currentRecruitmentId }: RecruitmentsProps) {
  const [params, setParams] = useState({ ...defaultParams });
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading } =
    useGetInfiniteRecruitments(params);

  const handleSearchDebounced = (searchQuery: string) => {
    setParams((prev) => ({ ...prev, searchQuery }));
  };

  return (
    <div
      className={cn(
        'border-colors scroll-none w-full flex-col space-y-2 divide-y overflow-y-auto md:w-3/5',
        className,
      )}
    >
      <Search onSearchDebounced={handleSearchDebounced} />
      {isLoading && Array.from({ length: 10 }).map((_, i) => <RecruitmentSkeleton key={i} />)}
      {data?.pages.map((page) => (
        <Fragment key={page.page}>
          {page?.items.map((job) => (
            <Recruitment activeId={currentRecruitmentId!} key={job.id} {...job} />
          ))}
        </Fragment>
      ))}
      {hasNextPage && (
        <LoadMore
          disabled={isFetchingNextPage}
          isLoading={isFetchingNextPage}
          onClick={() => fetchNextPage()}
        />
      )}
    </div>
  );
}
