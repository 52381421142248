import { useMutation } from '@tanstack/react-query';
import { RefreshQuestionParams } from '@/generated';
import api from '@/libs/api';

async function refreshQuestion(params: RefreshQuestionParams) {
  const resp = await api.api.refreshQuestion(params);
  return resp.data;
}

export default function useRefreshQuestion() {
  return useMutation({ mutationFn: refreshQuestion });
}
