import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormikHelpers } from 'formik';
import Form from '@/components/users/Form';
import { UserRole } from '@/generated';
import useCreateUser, { TUserCreate } from '@/services/users/create';
import useAuthState from '@/states/authState';
import { handleValidationError } from '@/utils';

export const initialValues: TUserCreate = {
  firstName: '',
  lastName: '',
  address: '',
  email: '',
  phoneNumber: '',
  password: '',
  userRole: UserRole.USER,
  adminId: '',
  clientId: '',
};

export default function Create() {
  const { t } = useTranslation();
  const authUser = useAuthState((state) => state.user!);
  const { mutate: createUser, isPending } = useCreateUser();
  const navigate = useNavigate();

  const handleSubmit = (values: TUserCreate, { setErrors }: FormikHelpers<TUserCreate>) => {
    const adminId = authUser.userRole === 'ADMIN' ? authUser.id : values.adminId;
    const clientId =
      authUser.userRole === 'SUPERUSER' && values.userRole === 'CLIENT'
        ? values.clientId
        : authUser.clientId;
    createUser(
      {
        ...values,
        adminId,
        clientId,
      },
      {
        onSuccess: () => {
          toast.success(t('toast.createdSuccessfully', { model: t('User') }));
          navigate('/users');
        },
        onError: (e) => {
          handleValidationError(e, setErrors);
        },
      },
    );
  };
  return (
    <Form
      showPasswords
      title={t('Create User')}
      onSubmit={handleSubmit}
      isSubmitting={isPending}
      initialValues={{ ...initialValues, adminId: authUser.id, clientId: authUser.clientId }}
    />
  );
}
