import React from 'react';
import { useTranslation } from 'react-i18next';
import useExportApplicants from '@/services/applicants/export';
import { Button } from '@/shared/form';
import useAuthState from '@/states/authState';
import { TModelId } from '@/types';

type ExportApplicantsProps = {
  hasAnyApplicants: boolean;
  questionsetId: TModelId;
};

export default function ExportApplicants({
  hasAnyApplicants,
  questionsetId,
}: ExportApplicantsProps) {
  const { t } = useTranslation();
  const { mutate: exportApplicants } = useExportApplicants();
  const canExport = useAuthState((state) => state.user?.userAccess.exportApplicant);
  const baseTooltip = hasAnyApplicants ? 'Export as Excel' : 'No Applicants';

  const handleExport = () => {
    exportApplicants(questionsetId!);
  };

  return (
    <div
      data-tip={canExport ? baseTooltip : t('You have to upgrade your plan to access this feature')}
      className="tooltip tooltip-left tooltip-secondary"
    >
      <Button
        disabled={!hasAnyApplicants || !canExport}
        onClick={handleExport}
        className="btn btn-primary btn-md"
      >
        {t('Export')}
      </Button>
    </div>
  );
}
