import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';
import useAuthState from '@/states/authState';

async function updateSetting(data: Parameters<typeof api.api.updateSetting>) {
  const resp = await api.api.updateSetting(...data);
  return resp.data;
}

export default function useUpdateSetting() {
  const setAuthUserExistingSetting = useAuthState((state) => state.setAuthUserExistingSetting);

  return useMutation({ mutationFn: updateSetting, onSuccess: setAuthUserExistingSetting });
}
