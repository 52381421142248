import React from 'react';
import { ToastContainer } from 'react-toastify';
import { MODAL_ROOT_ID } from '@/shared/modals/Modal';
import useThemeState from '@/states/themeState';

type ThemeProviderProps = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: ThemeProviderProps) {
  const theme = useThemeState((state) => state.theme);

  return (
    <div data-theme={theme}>
      <ToastContainer toastClassName="font-sans" theme={theme} />
      <div className="flex min-h-screen flex-col">
        <div id={MODAL_ROOT_ID} />
        {children}
      </div>
    </div>
  );
}
