import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import QuestionsWizard from '@/components/questions/QuestionsWizard';
import validations from '@/fixtures/validations';
import { useDialogModal } from '@/hooks';
import useCreateTestApplicant from '@/services/applicants/test/create';
import useGetQuestionsetDetails from '@/services/questionsets/details';
import ButtonActions from '@/shared/custom/ButtonActions';
import { Fields } from '@/shared/form';
import DialogModal from '@/shared/modals/DialogModal';
import useAuthState from '@/states/authState';
import useSettingsState from '@/states/settingsState';
import { isAxiosError } from '@/utils';

const validationSchema = Yup.object().shape({
  externalId: validations.title,
});

export default function Preview() {
  const { mutate: createTestApplicant, isPending: isCreating } = useCreateTestApplicant();
  const { questionsetId } = useParams<'questionsetId'>();
  const { t } = useTranslation();
  const [answers, setAnswers] = useState<Record<string, any>>({});
  const { modalRef, handleOpenModal, handleCloseModal } = useDialogModal();
  const canCreateApplicant = useAuthState((state) => state.user?.userAccess.createApplicant);

  const navigate = useNavigate();

  const { data } = useGetQuestionsetDetails(questionsetId!);
  const setApplicantsSettings = useSettingsState((state) => state.setApplicantsSettings);

  return (
    <>
      <DialogModal parentElement="div" closeOnClickOutside modalRef={modalRef}>
        <h3 className="text-2xl font-bold md:text-3xl">{t('Create a Test Applicant')}</h3>
        <div className="divider" />
        <Formik
          validationSchema={validationSchema}
          initialValues={{ externalId: '', goToApplicant: false }}
          onSubmit={({ externalId, goToApplicant }, { setFieldError, resetForm }) => {
            createTestApplicant(
              { answers, tag: externalId, externalId, questionSetId: questionsetId! },
              {
                onSuccess: (applicant) => {
                  toast.success(t('toast.createdSuccessfully', { model: t('Test Applicant') }));
                  handleCloseModal();
                  resetForm();
                  if (goToApplicant) {
                    navigate(`/questionsets/${questionsetId}/applicants/${applicant.id}`);
                    setApplicantsSettings({ isTestingMode: true });
                  }
                },
                onError: (e) => {
                  if (isAxiosError(e, 409))
                    setFieldError('externalId', t('errors.nameAlreadyTaken'));
                },
              },
            );
          }}
        >
          <Form className="space-y-4">
            <Fields
              fields={[
                {
                  isRequired: true,
                  name: 'externalId',
                  label: t('Name'),
                  tooltip: { text: 'Please provide a name for your test applicant' },
                  type: 'input',
                  fieldProps: { type: 'text' },
                },
                {
                  isRequired: false,
                  name: 'goToApplicant',
                  label: t('Go to Applicants'),
                  type: 'checkbox',
                  fieldProps: {},
                },
              ]}
            />
            <ButtonActions
              cancelProps={{ onClick: handleCloseModal }}
              submitProps={{ isLoading: isCreating, disabled: isCreating || !canCreateApplicant }}
            />
          </Form>
        </Formik>
      </DialogModal>
      {!canCreateApplicant && (
        <div role="alert" className="alert alert-warning">
          <ExclamationTriangleIcon className="size-6" />
          <span>{t('You have to upgrade your plan to access this feature')}</span>
        </div>
      )}
      <QuestionsWizard
        data={data}
        onCancel={() => navigate(`/questionsets/${questionsetId}/details`)}
        onSubmit={(values) => {
          setAnswers(values);
          handleOpenModal();
        }}
      />
    </>
  );
}
