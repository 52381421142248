import { useMutation } from '@tanstack/react-query';
import { CreateUserParams, UserCreate } from '@/generated';
import api from '@/libs/api';
import useRefreshToken from '../auth/refreshToken';

export type TUserCreate = CreateUserParams & UserCreate;

async function createUser({ userRole, ...data }: TUserCreate) {
  const resp = await api.api.createUser({ userRole }, data);
  return resp.data;
}

export default function useCreateUser() {
  const { mutate: refreshUser } = useRefreshToken();

  return useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      refreshUser();
    },
  });
}
