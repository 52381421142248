import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { cn } from '@/utils';

export default function Tabs() {
  const { t } = useTranslation();

  const options = [
    { title: t('Account'), name: 'account' },
    { title: t('Settings'), name: 'settings' },
  ];

  return (
    <div className="join flex-wrap">
      {options.map((option) => (
        <NavLink
          to={`/${option.name}`}
          key={option.title}
          className={({ isActive }) => cn('btn join-item btn-md', { 'btn-primary': isActive })}
        >
          {option.title}
        </NavLink>
      ))}
    </div>
  );
}
