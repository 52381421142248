import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '@/libs/api';

async function deleteNotifications() {
  const resp = await api.api.deleteNotifications({ onlyActive: true });
  return resp.data;
}

export default function useDeleteNotifications() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteNotifications,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
}
