import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TrashIcon } from '@heroicons/react/24/solid';
import { useDialogModal } from '@/hooks';
import { TIndexLayoutContext } from '@/layouts/applicants/IndexLayout';
import useDeleteApplicants from '@/services/applicants/deleteMany';
import { Button } from '@/shared/form';
import DeleteModal from '@/shared/modals/DeleteModal';
import useApplicantsState from '@/states/applicantsState';

export default function DeleteSelectedApplicants() {
  const applicantsId = useApplicantsState((state) => state.applicantsId);
  const clearApplicantsId = useApplicantsState((state) => state.clearApplicantsId);
  const { modalRef, handleCloseModal, handleOpenModal } = useDialogModal();
  const { mutate: deleteApplicants, isPending } = useDeleteApplicants();
  const { queryResult, questionset } = useOutletContext<TIndexLayoutContext>();
  const { data: applicants } = queryResult;
  const { t } = useTranslation();

  const handleDelete = () => {
    deleteApplicants(
      {
        questionSetId: questionset.id,
        applicantIds: applicants!.items
          .filter((applicant) => applicantsId.includes(applicant.id))
          .map((applicant) => applicant.id),
      },
      {
        onSuccess: () => {
          handleCloseModal();
          toast.success(`You've deleted ${applicantsId.length} applicants successfully.`);
          clearApplicantsId();
        },
      },
    );
  };

  return (
    <>
      <DeleteModal
        modalRef={modalRef}
        onClose={handleCloseModal}
        title={`Delete ${applicantsId.length} Applicants`}
        onDelete={handleDelete}
        isDeleting={isPending}
      />
      <Button
        onClick={handleOpenModal}
        isLoading={isPending}
        disabled={isPending}
        className="text-error"
      >
        <TrashIcon className="h-5 w-5" />
        {t('Delete Selected')}
      </Button>
    </>
  );
}
