import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/form';
import { ButtonProps } from '@/shared/form/Button';
import { cn } from '@/utils';

export interface ButtonActionProps extends ButtonProps {
  text?: string;
  tooltip?: string;
  tooltipClassName?: string;
}

type ButtonActionsProps = {
  submitProps?: ButtonActionProps;
  cancelProps?: ButtonActionProps;
  firstButton?: 'submit' | 'cancel';
};

export default function ButtonActions({
  submitProps,
  cancelProps,
  firstButton = 'submit',
}: ButtonActionsProps) {
  const { t } = useTranslation();

  return (
    <div
      className={cn('flex space-x-4', {
        'flex-row-reverse space-x-reverse': firstButton === 'cancel',
      })}
    >
      <div
        data-tip={submitProps?.tooltip}
        className={cn('tooltip tooltip-secondary', submitProps?.tooltipClassName)}
      >
        <Button
          type="submit"
          {...submitProps}
          className={cn('btn btn-primary btn-md', submitProps?.className)}
        >
          {submitProps?.text || t('Submit')}
        </Button>
      </div>
      <div
        data-tip={cancelProps?.tooltip}
        className={cn('tooltip tooltip-secondary', cancelProps?.tooltipClassName)}
      >
        <Button {...cancelProps} className={cn('btn btn-neutral btn-md', cancelProps?.className)}>
          {cancelProps?.text || t('Cancel')}
        </Button>
      </div>
    </div>
  );
}
