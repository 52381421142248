import React from 'react';
import { useTranslation } from 'react-i18next';
import useGenerateOtpQrCode from '@/services/otp/generate';

export default function QrCode() {
  const { data, isPending } = useGenerateOtpQrCode();
  const { t } = useTranslation();

  return (
    <div className="space-y-2">
      <h3 className="h3">{t('Scan the QR Code')}</h3>
      <p>
        {t(
          "Scan the QR Code using the authenticator app that you've installed on the previous step",
        )}
      </p>
      {isPending ? (
        <div className="skeleton h-96 w-96" />
      ) : (
        <img
          className="mx-auto h-full w-full max-w-sm"
          src={URL.createObjectURL(data)}
          alt="Qr Code"
        />
      )}
    </div>
  );
}
