import React from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Recruitment as TRecruitment } from '@/generated';
import { TModelId } from '@/types';
import { cn } from '@/utils';

interface RecruitmentProps extends TRecruitment {
  activeId: TModelId;
}

export default function Recruitment({ id, title, activeId, lastMessageText }: RecruitmentProps) {
  const { t } = useTranslation();

  return (
    <Link
      to={`/messages/${id}`}
      className={cn('card card-compact hover:bg-base-200', {
        'bg-base-300': activeId === id,
      })}
    >
      <div className="card-body flex-row gap-4">
        <Avatar name={title} round size="80" />
        <div className="space-y-2">
          <h2 className="card-title line-clamp-1">{title}</h2>
          <p className="description line-clamp-2 text-sm">
            {lastMessageText ?? t('Send the first Message')}
          </p>
        </div>
      </div>
    </Link>
  );
}
