import { useMutation } from '@tanstack/react-query';
import { SaveQuestionnaireAnswersParams } from '@/generated';
import api from '@/libs/api';

type Params = SaveQuestionnaireAnswersParams & {
  data: object;
};

async function createQuestionnaire({ data, ...params }: Params) {
  const resp = await api.api.saveQuestionnaireAnswers(params, data);
  return resp.data;
}

export default function useCreateQuestionnaire() {
  return useMutation({
    mutationFn: createQuestionnaire,
    meta: { error: { disableToastForStatusCodes: [400] } },
  });
}
