import React from 'react';
import { TResourceKeys } from '@/states/settingsState';
import { cn } from '@/utils';
import ToggleOptions from './ToggleOptions';

export interface HeadingProps {
  title: string;
  resource: TResourceKeys;
  children?: React.ReactNode;
  className?: string;
  badge?: string;
}

export default function Heading({ title, badge, children, className, resource }: HeadingProps) {
  return (
    <div className={cn('flex flex-wrap items-center justify-between', className)}>
      <div className="flex items-center gap-2">
        <h1 className="h1 pb-2">{title}</h1>
        {badge && <span className="badge badge-primary badge-lg font-bold">{badge}</span>}
      </div>
      <div className="flex items-center justify-center space-x-2">
        {children}
        <ToggleOptions resource={resource} />
      </div>
    </div>
  );
}
