import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/shared/form';
import { TImportedSvg } from '@/types';

type CardProps = {
  description: string;
  Icon: TImportedSvg;
  button: { onClick?: () => void; linkTo?: string; text: string };
};

export default function Card({ description, Icon, button }: CardProps) {
  const navigate = useNavigate();

  return (
    <div className="card bg-base-200">
      <div className="card-body flex-col-reverse justify-between gap-4 md:flex-row">
        <div className="space-y-2">
          <p className="text-lg">{description}</p>
          <div className="card-actions">
            <Button
              onClick={() => {
                if (button.linkTo) navigate(button.linkTo);
                if (button.onClick) button.onClick();
              }}
              className="btn btn-primary btn-md"
            >
              {button.text}
            </Button>
          </div>
        </div>
        <Icon className="md:min-w-32 md:max-w-32" />
      </div>
    </div>
  );
}
