import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { TModelId } from '@/types';

export type TQuestionsetsLayout = {
  recruitmentId: TModelId;
  basePath: string;
};

export default function IndexLayout() {
  const { recruitmentId } = useParams<'recruitmentId'>();

  return (
    <Outlet
      context={
        {
          recruitmentId: recruitmentId as string,
          basePath: `/recruitments/${recruitmentId}/questionsets`,
        } satisfies TQuestionsetsLayout
      }
    />
  );
}
