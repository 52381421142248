import React from 'react';
import { Link } from 'react-router-dom';
import config from '@/fixtures/config';
import Logo from '@/shared/icons/Logo';
import ToggleTheme from '../navigation/navbar/header/ToggleTheme';

export default function Navbar() {
  const frontendUrl = encodeURI(config.websiteUrl!);

  return (
    <header className="navbar bg-base-200 px-4 py-4 md:px-8">
      <div className="flex-1">
        <Link to={frontendUrl}>
          <Logo width={150} />
        </Link>
      </div>
      <div className="flex-none">
        <ToggleTheme />
      </div>
    </header>
  );
}
