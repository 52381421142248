import React, { useContext } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { Message as TMessage } from '@/generated';
import { showContext } from '@/pages/messages/Show';
import { TModelId } from '@/types';
import { cn } from '@/utils';
import Options from './Options';

interface MessageProps extends TMessage {
  userId: TModelId;
}

export default function Message(message: MessageProps) {
  const { t } = useTranslation();
  const { setEditMessage, recruitmentId } = useContext(showContext);
  const { id, sender, createdAt, body, userId, senderId } = message;
  const isTheSender = senderId === userId;
  const senderName = `${sender.firstName} ${sender.lastName}`;

  const handleEditMessage = () => {
    setEditMessage(message);
  };

  return (
    <div
      className={cn('chat', {
        'chat-end': isTheSender,
        'chat-start': !isTheSender,
      })}
    >
      <div className="avatar chat-image">
        <Avatar round name={senderName} size="50" />
      </div>
      <div className="chat-header flex items-center font-medium">
        {isTheSender ? t('You') : senderName}
        {isTheSender && (
          <Options jobId={recruitmentId} onEditMessage={handleEditMessage} messageId={id} />
        )}
      </div>
      <div
        className={cn('chat-bubble', {
          'chat-bubble-primary': isTheSender,
          'chat-bubble-secondary': !isTheSender,
        })}
      >
        {body}
      </div>
      <div className="chat-footer mt-2">
        <time className="text-xs">{new Date(createdAt!).toLocaleString()}</time>
      </div>
    </div>
  );
}
