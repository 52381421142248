import { useMutation } from '@tanstack/react-query';
import { UpdateSectionsOrderParams } from '@/generated';
import api from '@/libs/api';
import { TModelId } from '@/types';

export type TSectionSort = UpdateSectionsOrderParams & {
  ids: TModelId[];
};

async function sortSections({ questionSetId, ids }: TSectionSort) {
  const resp = await api.api.updateSectionsOrder({ questionSetId }, ids);
  return resp.data;
}

export default function useSortSections() {
  return useMutation({ mutationFn: sortSections });
}
