import { useMutation } from '@tanstack/react-query';
import { ApplicantCreate, ApplicantType, CreateApplicantParams } from '@/generated';
import api from '@/libs/api';
import useRefreshToken from '@/services/auth/refreshToken';

type TApplicantCreate = CreateApplicantParams & Omit<ApplicantCreate, 'type'>;

async function createTest({ questionSetId, ...data }: TApplicantCreate) {
  const resp = await api.api.createApplicant(
    { questionSetId },
    { ...data, type: ApplicantType.TEST },
  );
  return resp.data;
}

export default function useCreateTestApplicant() {
  const { mutate: refreshUser } = useRefreshToken();

  return useMutation({
    mutationFn: createTest,
    onSuccess: () => {
      refreshUser();
    },
  });
}
