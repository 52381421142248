import { useMutation } from '@tanstack/react-query';
import { QuestionUpdate } from '@/generated';
import api from '@/libs/api';
import { TModelId } from '@/types';

export type TQuestionEdit = QuestionUpdate & {
  questionId: TModelId;
};

async function editQuestion({ questionId, ...data }: TQuestionEdit) {
  const resp = await api.api.updateQuestion(questionId, data);
  return resp.data;
}

export default function useEditQuestion() {
  return useMutation({ mutationFn: editQuestion });
}
