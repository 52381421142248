import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import commonFields from '@/fixtures/commonFields';
import validations from '@/fixtures/validations';
import useLogin from '@/services/auth/login';
import useAuthState from '@/states/authState';
import { TField } from '@/types';
import { isAxiosError } from '@/utils';
import ButtonActions from '../custom/ButtonActions';
import { Fields } from '../form';
import DialogModal, { DialogModalProps } from './DialogModal';

type EnterPasswordModalProps = {
  modalRef: DialogModalProps['modalRef'];
  onSuccess: () => void;
  onCancel: () => void;
};

const validationSchema = Yup.object().shape({
  enterPassword: validations.password,
});

const fields: TField[] = [{ ...commonFields.password, name: 'enterPassword' }];

export default function EnterPasswordModal({
  onSuccess,
  onCancel,
  ...props
}: EnterPasswordModalProps) {
  const { mutate: login } = useLogin();
  const { t } = useTranslation();
  const email = useAuthState((state) => state.user!.email);
  const setAuth = useAuthState((state) => state.setAuth);

  return (
    <DialogModal parentElement="div" {...props}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ enterPassword: '' }}
        onSubmit={(values, { setErrors }) => {
          login(
            { username: email, password: values.enterPassword },
            {
              onSuccess: (data) => {
                setAuth(data);
                onSuccess();
              },
              onError: (e) => {
                if (isAxiosError(e, 400)) {
                  setErrors({ enterPassword: t('These credentials do not match our records.') });
                }
              },
            },
          );
        }}
      >
        <Form className="space-y-4">
          <h3 className="h3">{t('Enter your Current Password')}</h3>
          <Fields fields={fields} />
          <ButtonActions cancelProps={{ onClick: onCancel }} submitProps={{ type: 'submit' }} />
        </Form>
      </Formik>
    </DialogModal>
  );
}
