import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWizard } from 'react-use-wizard';
import WizardButtons from '@/shared/custom/WizardButtons';

type ActionsProps = {
  onCancel: () => void;
  onSubmit: () => void;
  isValidating: boolean;
  isSubmitDisabled: boolean;
};

export default function Actions({
  onCancel,
  onSubmit,
  isValidating,
  isSubmitDisabled,
}: ActionsProps) {
  const { isFirstStep, isLastStep, nextStep, previousStep } = useWizard();
  const { t } = useTranslation();

  return (
    <WizardButtons
      submitProps={{
        isLoading: isValidating,
        disabled: isLastStep && (isValidating || isSubmitDisabled),
        onClick: isLastStep ? onSubmit : nextStep,
        text: isLastStep ? t('Submit') : t('Next'),
      }}
      previousProps={{ disabled: isFirstStep, onClick: previousStep }}
      cancelProps={{ onClick: onCancel }}
    />
  );
}
