import React from 'react';
import { useTranslation } from 'react-i18next';
import DesktopContainer from './DesktopContainer';
import MobileContainer from './MobileContainer';

type ContainerProps = {
  children: React.ReactNode;
};

export default function Container({ children }: ContainerProps) {
  const { t } = useTranslation();
  const heading = t('Options');

  return (
    <>
      <DesktopContainer heading={heading}>{children}</DesktopContainer>
      <MobileContainer heading={heading}>{children}</MobileContainer>
    </>
  );
}
