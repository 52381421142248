import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { CellContext } from '@tanstack/react-table';
import BaseActions from '@/components/resources/index/actions';
import { Client } from '@/generated';
import useDeleteClient from '@/services/clients/delete';

type ActionsProps = {
  info: CellContext<Client, unknown>;
};

export default function Actions({ info }: ActionsProps) {
  const { t } = useTranslation();
  const { mutate: deleteClient, isPending: isDeleting } = useDeleteClient();
  const queryClient = useQueryClient();

  return (
    <BaseActions
      editAction={{ link: `/clients/${info.row.original.id}/edit` }}
      deleteAction={{
        modalTitle: `${t('Delete')} ${info.row.original.name}`,
        isDeleting,
        onDelete: (deleteModalRef) => {
          deleteClient(info.row.original.id, {
            onSuccess: () => {
              toast.success(t('toast.deletedSuccessfully', { model: t('Client') }));
              deleteModalRef.current?.close();
              queryClient.invalidateQueries({ queryKey: ['clients'] });
            },
          });
        },
      }}
    />
  );
}
