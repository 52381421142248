/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { produce } from 'immer';
import { QuestionChoice, QuestionCreate } from '@/generated';
import { Button } from '@/shared/form';
import Field from '@/shared/form/Field';
import { alphabetsArray } from '@/utils';
import Actions from './Actions';

type ChoicesProps = {
  choices: QuestionChoice[];
};

export default function Choices({ choices }: ChoicesProps) {
  const { setValues, values } = useFormikContext<QuestionCreate>();
  const { t } = useTranslation();

  const handleAddEmptyChoice = () => {
    const newValues = produce(values, (draft) => {
      draft.choices!.push({ title: '', score: 0 });
    });
    setValues(newValues);
  };

  const handleMoveUp = (index: number) => {
    const newValues = produce(values, (draft) => {
      const [removed] = draft.choices!.splice(index, 1);
      draft.choices!.splice(index - 1, 0, removed);
    });
    setValues(newValues);
  };
  const handleDelete = (index: number) => {
    const newValues = produce(values, (draft) => {
      draft.choices!.splice(index, 1);
    });
    setValues(newValues);
  };

  return (
    <>
      {choices.map((_, i) => (
        <div key={i} className="grid gap-4">
          <Field
            isRequired
            label={`${t('Title')} ${alphabetsArray[i]}`}
            name={`choices[${i}].title`}
            type="input"
            fieldProps={{ type: 'text' }}
          >
            <Actions
              onMoveUp={() => handleMoveUp(i)}
              onDelete={() => handleDelete(i)}
              canMoveUp={i !== 0}
              canDelete={values.choices!.length > 2}
              index={i}
            />
          </Field>
          <div className="w-full space-y-2">
            <Field
              isRequired
              label={`${t('Score')} ${alphabetsArray[i]}`}
              name={`choices[${i}].score`}
              type="input"
              fieldProps={{
                type: 'range',
                min: 0,
                max: 1,
                step: 0.1,
                className: 'range-secondary',
              }}
            />
            <div className="flex w-full items-center justify-between px-2 text-xs">
              {Array.from({ length: 11 }).map((_r, index) => (
                <span key={index}>{index * 10}%</span>
              ))}
            </div>
          </div>
        </div>
      ))}
      <Button onClick={handleAddEmptyChoice} className="btn btn-outline btn-primary btn-block">
        {t('Add Choice')}
      </Button>
    </>
  );
}
