import React from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import dataSent from '@/assets/animations/dataSent.json';

type AnswerCreatedProps = {
  description: string | null | undefined;
};

export default function AnswerCreated({ description }: AnswerCreatedProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 items-center justify-center">
      <div className="card max-w-xl bg-base-300">
        <div className="card-body gap-4">
          <h1 className="card-title flex-wrap text-2xl font-bold">{t('Answer Submitted')}</h1>
          <p className="description">
            {description ??
              t(
                "Your answer has been sent to our servers successfully now you gotta wait for the recruiter to check the top applicants hopefully you're there too.",
              )}
          </p>
          <Lottie
            loop={false}
            animationData={dataSent}
            play
            className="mx-auto h-60 w-60 md:h-72 md:w-72"
          />
        </div>
      </div>
    </div>
  );
}
