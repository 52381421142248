import React from 'react';
import { useTranslation } from 'react-i18next';
import { ENotificationFilter } from '@/enums';
import { Button } from '@/shared/form';
import { cn } from '@/utils';

type FiltersProps = {
  allCount?: number;
  unreadCount?: number;
  currentFilter: ENotificationFilter;
  setCurrentFilter: React.Dispatch<React.SetStateAction<ENotificationFilter>>;
};

type TFilter = {
  value: ENotificationFilter;
  label: string;
  count: number;
};

export default function Filters({
  currentFilter,
  setCurrentFilter,
  allCount = 0,
  unreadCount = 0,
}: FiltersProps) {
  const { t } = useTranslation();

  const filters: TFilter[] = [
    {
      value: ENotificationFilter.All,
      label: t('All'),
      count: allCount,
    },
    {
      value: ENotificationFilter.Unread,
      label: t('Unread'),
      count: unreadCount,
    },
  ];

  return (
    <div className="flex space-x-4 border-b border-secondary font-medium">
      {filters.map(({ value, label, count }) => {
        const isActive = currentFilter === value;

        return (
          <Button
            key={value}
            onClick={() => setCurrentFilter(value)}
            className={cn('flex items-center space-x-2 px-2 py-2', {
              'border-b-2 border-b-primary': isActive,
              'text-secondary': !isActive,
            })}
          >
            <span className="text-lg">{label}</span>
            <span
              className={cn('px-2 py-0.5 text-xs', {
                'btn btn-square btn-secondary btn-xs': isActive,
                'btn btn-square btn-neutral btn-xs': !isActive,
              })}
            >
              {count}
            </span>
          </Button>
        );
      })}
    </div>
  );
}
