import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

async function deleteMessage(id: TModelId) {
  const resp = await api.api.deleteMessage(id);
  return resp.data;
}

export default function useDeleteMessage() {
  return useMutation({ mutationFn: deleteMessage });
}
