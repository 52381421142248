import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useWizard } from 'react-use-wizard';
import { ArrowPathIcon, TrashIcon } from '@heroicons/react/24/outline';
import Field from '@/components/questionsets/details/questions/question/Field';
import { QuestionTypeML } from '@/generated';
import { useDialogModal } from '@/hooks';
import { TQuestionsetsEditLayout } from '@/layouts/questionsets/EditLayout';
import { aiContext } from '@/pages/questionsets/Ai';
import useExtractQuestions from '@/services/ml/extract';
import useRefreshQuestion from '@/services/ml/refresh';
import useCreateManyQuestions from '@/services/questions/createMany';
import WizardButtons from '@/shared/custom/WizardButtons';
import { Button, Label } from '@/shared/form';
import AiWorkingModal from '../create/AiWorkingModal';
import { calculateQuestionWeightFromSlider } from '../details/questions/modal/Modal';
import FinalizeModal, { TFinalizeAiValues } from './FinalizeModal';

export default function Questions() {
  const { t } = useTranslation();
  const { mutate: extractQuestions, data } = useExtractQuestions();
  const { aiCancelProps, questionsetId } = useOutletContext<TQuestionsetsEditLayout>();
  const { modalRef, handleOpenModal, handleCloseModal } = useDialogModal();
  const { previousStep } = useWizard();
  const {
    modalRef: finalModalRef,
    handleCloseModal: handleCloseFinalModal,
    handleOpenModal: handleOpenFinalModal,
  } = useDialogModal();
  const { mutate: refreshQuestion, isPending: isRefreshingQuestion } = useRefreshQuestion();
  const { questions, setQuestions, qualifications, nbQuestions } = useContext(aiContext);
  const { mutate: generateQuestions, isPending: isCreatingQuestions } = useCreateManyQuestions();
  const navigate = useNavigate();

  const handleRemoveQuestion = (index: number) => {
    setQuestions((prev) => prev.filter((_, i) => i !== index));
  };

  const handleRefreshQuestion = (index: number) => {
    refreshQuestion(
      { mlQuestionScoreId: data!.id, refreshingQuestionNumber: index + 1 },
      {
        onSuccess: (result) => {
          setQuestions(
            questions.map((question, i) => {
              if (i !== index) return question;
              return { ...result.generatedQuestions![index], weight: 2 };
            }),
          );
        },
      },
    );
  };

  const handleChangeWeight = (index: number, newWeight: number) => {
    setQuestions((prev) =>
      prev.map((question, i) => ({
        ...question,
        weight: i === index ? newWeight : question.weight,
      })),
    );
  };

  const handleGenerateQuestions = (temperature = 0.5) => {
    handleOpenModal();
    extractQuestions(
      { data: qualifications, nbQuestions, temperature, questionSetId: questionsetId },
      {
        onSuccess: (result) => {
          if (result.generatedQuestions!.length <= 0) {
            setQuestions([]);
            previousStep();
            toast.warning(
              t(
                "Our AI couldn't generate questions based on your provided job description. Please change your job description and try again.",
              ),
            );
          }
          setQuestions(result.generatedQuestions!.map((question) => ({ ...question, weight: 2 })));
        },
        onSettled: handleCloseModal,
      },
    );
  };

  const handleSubmit = (values: TFinalizeAiValues) => {
    generateQuestions(
      {
        ...values,
        questionSetId: questionsetId,
        questions: questions.map((question) => ({
          type: question.type as any,
          title: question.question,
          weight: calculateQuestionWeightFromSlider(question.weight),
          choices: question.choices,
        })),
      },
      {
        onSuccess: () => {
          navigate(values.goToDetails ? `/questionsets/${questionsetId}/details` : '/recruitments');
        },
      },
    );
  };

  useEffect(() => {
    if (questions.length <= 0) {
      handleGenerateQuestions();
    }
  }, []);

  return (
    <>
      <AiWorkingModal
        heading={t('questionsets.ai.modals.questions.heading')}
        description={t('questionsets.ai.modals.questions.description')}
        modalRef={modalRef}
      />
      <FinalizeModal
        handleSubmit={handleSubmit}
        handleCloseModal={handleCloseFinalModal}
        modalRef={finalModalRef}
        isPending={isCreatingQuestions}
      />
      <section className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        {questions.map((question, i) => (
          <div className="card card-bordered" key={question.question}>
            <div className="card-body">
              <h3 className="card-title">
                {i + 1}- {question.question}
              </h3>
              <div className="flex flex-col-reverse gap-4 md:flex-row md:items-center md:justify-between">
                {question?.type !== QuestionTypeML.TEXT && (
                  <div className="w-full space-y-2">
                    <Label isRequired htmlFor={`importance-${i}`} label={t('Importance')} />
                    <input
                      name={`importance-${i}`}
                      type="range"
                      min="1"
                      max="3"
                      className="range range-primary range-sm"
                      step="1"
                      value={question.weight}
                      onChange={(e) => handleChangeWeight(i, +e.target.value)}
                    />
                    <div className="flex w-full justify-between px-2 text-xs">
                      <span>{t('Less Important')}</span>
                      <span>{t('Neutral')}</span>
                      <span>{t('More Important')}</span>
                    </div>
                  </div>
                )}
                <div className="flex items-center gap-2">
                  <div data-tip="Delete Question" className="tooltip tooltip-secondary">
                    <Button
                      onClick={() => handleRemoveQuestion(i)}
                      className="btn btn-outline btn-error btn-sm"
                    >
                      <TrashIcon strokeWidth={1.5} className="h-6 w-6" />
                    </Button>
                  </div>
                  <div
                    data-tip={`${t('Refresh')} ${t('Question')}`}
                    className="tooltip tooltip-secondary"
                  >
                    <Button
                      disabled={isRefreshingQuestion}
                      isLoading={isRefreshingQuestion}
                      onClick={() => handleRefreshQuestion(i)}
                      className="btn btn-outline btn-secondary btn-sm"
                    >
                      <ArrowPathIcon strokeWidth={1.5} className="h-6 w-6" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="divider" />
              <Field
                id={i.toString()}
                type={(question.type ?? QuestionTypeML) as any}
                showScores
                choices={question.choices}
              />
            </div>
          </div>
        ))}
      </section>
      <div className="card card-bordered">
        <div className="card-body flex-row flex-wrap gap-4">
          <p className="card-title">{t('Do you want to generate whole new questions?')}</p>
          <Button
            onClick={() => handleGenerateQuestions(0.5)}
            className="btn btn-md animate-text bg-gradient-to-r from-teal-600 via-primary to-orange-600 text-primary-content"
          >
            {t('Refresh')} {t('Questions')}
          </Button>
        </div>
      </div>
      <WizardButtons
        submitProps={{
          disabled: isCreatingQuestions || questions.length <= 0,
          onClick: handleOpenFinalModal,
          isLoading: isCreatingQuestions,
        }}
        cancelProps={aiCancelProps}
        previousProps={{ onClick: previousStep }}
      />
    </>
  );
}
