import { useMutation } from '@tanstack/react-query';
import { CheckRedemptionCodeParams } from '@/generated';
import api from '@/libs/api';

async function checkRedemption(params: CheckRedemptionCodeParams) {
  const resp = await api.api.checkRedemptionCode(params);
  return resp.data;
}

export default function useCheckRedemption() {
  return useMutation({ mutationFn: checkRedemption });
}
