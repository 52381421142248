import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { TIndexLayoutContext } from '@/layouts/applicants/IndexLayout';
import useApplicantsState from '@/states/applicantsState';

export default function CheckboxHeader() {
  const { customCompareApplicantsId, setCompareApplicantsId } = useApplicantsState((state) => ({
    customCompareApplicantsId: state.applicantsId,
    setCompareApplicantsId: state.setApplicantsId,
  }));

  const { queryResult } = useOutletContext<TIndexLayoutContext>();
  const { data } = queryResult;

  const hasSelectedAll =
    data?.items.every((applicant) => customCompareApplicantsId.includes(applicant.id)) ?? false;

  const handleDeselectAll = () => {
    setCompareApplicantsId([]);
  };

  const handleSelectAll = () => {
    if (!data) return;
    setCompareApplicantsId([...data.items.map((applicant) => applicant.id)]);
  };

  return (
    <>
      <input
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        onChange={hasSelectedAll ? handleDeselectAll : handleSelectAll}
        checked={hasSelectedAll}
        type="checkbox"
        className="checkbox-primary checkbox checkbox-sm"
      />
      {customCompareApplicantsId.length > 0 && (
        <div className="badge badge-secondary badge-outline badge-md">
          {customCompareApplicantsId.length}
        </div>
      )}
    </>
  );
}
