import React from 'react';
import { BookmarkIcon as OutlineBookmarkIcon } from '@heroicons/react/24/outline';
import { BookmarkIcon as SolidBookmarkIcon } from '@heroicons/react/24/solid';
import { useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import config from '@/fixtures/config';
import useCreateOrUpdateSetting from '@/services/settings/createOrUpdate';
import { Button } from '@/shared/form';
import { TModelId } from '@/types';
import { cn } from '@/utils';

type BookmarkProps = {
  id: TModelId;
};

export default function Bookmark({ id }: BookmarkProps) {
  const queryClient = useQueryClient();
  const { mutate, isLoading, setting } = useCreateOrUpdateSetting(
    config.recruitmentBookmarkSettingName,
  );
  const bookmarksId = Object.keys(setting?.value ?? {});
  const isBookmarked = bookmarksId.find((i) => i === id);
  const BookmarkIcon = isBookmarked ? SolidBookmarkIcon : OutlineBookmarkIcon;

  const handleToggleBookmark = () => {
    const result = produce(setting?.value ?? {}, (draft) => {
      if (isBookmarked) delete draft[id];
      else draft[id] = new Date().toISOString();
    });
    mutate(result, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['recruitments'] });
      },
    });
  };

  return (
    <div
      data-tip={isBookmarked ? 'UnPin Recruitment' : 'Pin Recruitment'}
      className="tooltip tooltip-right tooltip-secondary flex items-center"
    >
      <Button disabled={isLoading} onClick={handleToggleBookmark}>
        <BookmarkIcon className={cn('size-6', { 'text-primary': isBookmarked })} />
      </Button>
    </div>
  );
}
