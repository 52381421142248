import React from 'react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { cn } from '@/utils';

type BooleanColumnProps = {
  isChecked: boolean;
};

export default function BooleanColumn({ isChecked }: BooleanColumnProps) {
  const Icon = isChecked ? CheckIcon : XMarkIcon;

  return (
    <Icon
      strokeWidth={4}
      className={cn('h-5 w-5 rounded-full border-2 p-0.5', {
        'border-success text-success': isChecked,
        'border-error text-error': !isChecked,
      })}
    />
  );
}
