import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArchiveBoxArrowDownIcon } from '@heroicons/react/24/outline';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ExportFileType } from '@/generated';
import { useDialogModal } from '@/hooks';
import useExportQuestionset from '@/services/questionsets/export';
import ButtonActions from '@/shared/custom/ButtonActions';
import { Button } from '@/shared/form';
import Field from '@/shared/form/Field';
import DialogModal from '@/shared/modals/DialogModal';
import useAuthState from '@/states/authState';
import { TModelId } from '@/types';

const validationSchema = Yup.object({
  type: Yup.string().required(),
});

type ExportQuestionsetProps = {
  questionsetId: TModelId;
};

export default function ExportQuestionset({ questionsetId }: ExportQuestionsetProps) {
  const { t } = useTranslation();
  const { modalRef, handleCloseModal, handleOpenModal } = useDialogModal();
  const canExport = useAuthState((state) => state.user?.userAccess.exportRecruitment);
  const { mutate: exportQuestionset, isPending } = useExportQuestionset();

  return (
    <>
      <DialogModal parentElement="div" closeOnClickOutside modalRef={modalRef}>
        <h3 className="h3">
          {t('Export')} {t('Questionset')}
        </h3>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ type: ExportFileType.EXCEL }}
          onSubmit={(values) => {
            exportQuestionset(
              { fileType: values.type, questionSetId: questionsetId },
              { onSuccess: handleCloseModal },
            );
          }}
        >
          <Form className="space-y-4">
            <Field
              isRequired={false}
              label={t('Type')}
              name="type"
              type="select"
              fieldProps={{
                options: Object.entries(ExportFileType).map(([key, value]) => ({
                  label: key,
                  value,
                })),
              }}
            />
            <ButtonActions
              submitProps={{ text: t('Export'), disabled: isPending, isLoading: isPending }}
              cancelProps={{ onClick: handleCloseModal }}
            />
          </Form>
        </Formik>
      </DialogModal>
      <div
        data-tip={
          canExport ? t('Export') : t('You have to upgrade your plan to access this feature')
        }
        className="tooltip tooltip-secondary"
      >
        <Button
          isLoading={isPending}
          disabled={!canExport || isPending}
          onClick={handleOpenModal}
          className="action"
        >
          <ArchiveBoxArrowDownIcon strokeWidth={2} className="action-icon hover:text-primary" />
        </Button>
      </div>
    </>
  );
}
