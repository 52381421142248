import { useSuspenseQuery } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

async function getUser(id: TModelId) {
  const resp = await api.api.getUser(id);
  return resp.data;
}

export default function useGetUser(id: TModelId) {
  return useSuspenseQuery({ queryKey: ['users', id], queryFn: () => getUser(id) });
}
