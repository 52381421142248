import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EyeIcon } from '@heroicons/react/24/outline';
import { optionsContext } from '../../Options';
import Visibility from './Visibility';

export default function Shown() {
  const { table } = useContext(optionsContext);
  const { t } = useTranslation();
  const columns = table
    .getAllColumns()
    .filter((column) => column.getCanHide() && column.getIsVisible());

  if (columns.length === 0) return null;

  const handleHideAll = () => table.toggleAllColumnsVisible(false);

  return (
    <Visibility
      heading={t('Shown')}
      onTextButtonClick={handleHideAll}
      textButton={t('Hide All')}
      columns={columns}
      ActionButtonIcon={EyeIcon}
    />
  );
}
