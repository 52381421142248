import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

export interface IModalState {
  key: string | null;
}

export interface IModalAction {
  setModalKey: (key: string) => void;
  clearModalKey: () => void;
}

const useModalState = createWithEqualityFn<IModalState & IModalAction>(
  (set) => ({
    key: null,
    setModalKey: (key) => set((state) => ({ ...state, key })),
    clearModalKey: () => set((state) => ({ ...state, key: null })),
  }),
  shallow,
);

export default useModalState;
