import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClientProvider, QueryErrorResetBoundary } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import '@/assets/styles/tailwind.css';
import ErrorBoundary from '@/components/ErrorBoundary';
import ThemeProvider from '@/components/ThemeProvider';
import Tour from '@/components/Tour';
import '@/libs/i18n';
import queryClient from '@/libs/queryClient';
import '@/libs/sentry';
import App from './App';

window.global ||= window;

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <BrowserRouter>
            <QueryErrorResetBoundary>
              <ErrorBoundary>
                <Tour />
                <App />
              </ErrorBoundary>
            </QueryErrorResetBoundary>
          </BrowserRouter>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>,
);

// reportWebVitals();
