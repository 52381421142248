import { useEffect } from 'react';
import useCreateOrUpdateSetting from '@/services/settings/createOrUpdate';
import useSettingsState from '@/states/settingsState';

type SettingsSyncProps = {
  children: JSX.Element;
};

export default function SettingsSync({ children }: SettingsSyncProps) {
  const settings = useSettingsState((state) => state);
  const resetSettingsToDefault = useSettingsState((state) => state.resetSettingsToDefault);
  useCreateOrUpdateSetting('settings');

  useEffect(() => {
    if (settings.type === 'reset') resetSettingsToDefault();
  }, []);

  return children;
}
