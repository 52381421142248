import { TField } from '@/types';

const title: TField = {
  isRequired: true,
  type: 'input',
  name: 'title',
  label: (t) => t('Title'),
  fieldProps: { type: 'text' },
};

const email: TField = {
  isRequired: true,
  type: 'input',
  name: 'email',
  label: (t) => t('Email'),
  fieldProps: { type: 'email' },
};

const description: TField = {
  isRequired: false,
  type: 'input',
  name: 'description',
  label: (t) => t('Description'),
  fieldProps: { as: 'textarea', rows: 5 },
};

const advertisement: TField = {
  isRequired: false,
  type: 'input',
  name: 'advertisement',
  label: (t) => t('Advertisement'),
  fieldProps: { as: 'textarea', rows: 5 },
};

const password: TField = {
  isRequired: true,
  type: 'input',
  name: 'password',
  label: (t) => t('Password'),
  fieldProps: { type: 'password', autoComplete: 'new-password' },
};

const passwordWithConfirmation: TField[] = [
  password,
  { ...password, name: 'passwordConfirmation', label: (t) => t('Password Confirmation') },
];

const commonFields = {
  email,
  title,
  description,
  advertisement,
  password,
  passwordWithConfirmation,
};

export default commonFields;
