import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

export interface IAsideState {
  isOpen: boolean;
  isMaximized: boolean;
}

export interface IAsideAction {
  setIsOpen: (isOpen: boolean) => void;
  setIsMaximized: (isMaximized: boolean) => void;
  toggleIsMaximized: () => void;
  toggleIsOpen: () => void;
}

const useAsideState = createWithEqualityFn<IAsideState & IAsideAction>()(
  persist(
    (set) => ({
      isOpen: false,
      isMaximized: true,
      setIsOpen: (isOpen) => set((state) => ({ ...state, isOpen })),
      setIsMaximized: (isMaximized) => set((state) => ({ ...state, isMaximized })),
      toggleIsMaximized: () => set((state) => ({ ...state, isMaximized: !state.isMaximized })),
      toggleIsOpen: () => set((state) => ({ ...state, isOpen: !state.isOpen })),
    }),
    { name: 'aside' },
  ),
  shallow,
);

export default useAsideState;
