import { useMutation } from '@tanstack/react-query';
import { CreateQuestionParams, QuestionCreate } from '@/generated';
import api from '@/libs/api';

export type TQuestionCreate = CreateQuestionParams & QuestionCreate;

async function createQuestion({ sectionId, ...data }: TQuestionCreate) {
  const resp = await api.api.createQuestion({ sectionId }, data);
  return resp.data;
}

export default function useCreateQuestion() {
  return useMutation({ mutationFn: createQuestion });
}
