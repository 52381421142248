import React from 'react';
import { useTranslation } from 'react-i18next';
import { Applicant } from '@/generated';

export default function Detail({ id, externalId, scoreTotal, tag }: Applicant) {
  const { t } = useTranslation();

  const data = [
    { label: t('ID'), value: id },
    { label: t('Unique ID'), value: externalId },
    { label: t('Tag'), value: tag },
    { label: t('Score'), value: scoreTotal?.scoreValueNormalized },
  ];

  return (
    <div className="border-colors flex items-center justify-around gap-4 overflow-x-auto border p-4 text-center">
      {data.map(({ label, value }) => (
        <div key={label!} className="flex flex-col items-center space-y-2 text-lg">
          <p className="description font-medium">{label}</p>
          <p>{value}</p>
        </div>
      ))}
    </div>
  );
}
