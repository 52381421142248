import { useQuery } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

async function getApplicant(id: TModelId) {
  const resp = await api.api.getApplicant(id);
  return resp.data;
}

export default function useGetApplicant(id: TModelId, options?: { enabled: boolean }) {
  return useQuery({
    queryKey: ['applicants', id],
    queryFn: () => getApplicant(id),
    ...options,
  });
}
