import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ERenderAs } from '@/enums';
import { ISelectOption } from '@/interfaces';
import Select from '@/shared/form/Fields/ReactSelect';
import { optionsContext } from '../../Options';

export default function RenderAs() {
  const { t } = useTranslation();
  const {
    options: { renderAs },
    setOptions,
  } = useContext(optionsContext);

  const selectOptions = [
    { label: t('Table'), value: ERenderAs.Table },
    { label: t('Card'), value: ERenderAs.Card },
  ];

  const handleRenderAsChange = (selected: unknown) => {
    const { value } = selected as ISelectOption<ERenderAs>;
    setOptions({ renderAs: value });
  };
  return (
    <Select
      options={selectOptions}
      defaultValue={selectOptions.find((option) => option.value === renderAs)}
      onChange={handleRenderAsChange}
    />
  );
}
