import React from 'react';
import { Button } from '@/shared/form';
import { cn } from '@/utils';

export type ContainerProps = {
  children: React.ReactNode;
  isActive: boolean;
  onClick?: () => void;
  className?: string;
};

export function withSectionStyles(Component: React.ComponentType<ContainerProps>) {
  return (props: ContainerProps) => {
    const { isActive } = props;
    const className = cn('h2 text-xl font-medium', {
      'border-b-2 border-b-primary text-primary dark:border-b-primary': isActive,
    });
    return <Component {...props} className={className} />;
  };
}

function Container({ children, onClick, isActive, className }: ContainerProps) {
  return (
    <li className={className}>
      {isActive ? <div>{children}</div> : <Button onClick={onClick}>{children}</Button>}
    </li>
  );
}

export default withSectionStyles(Container);
