import React from 'react';
import { splitFirstWord } from '@/utils';

type HeadingProps = {
  heading: string;
  children?: React.ReactNode;
};

export default function Heading({ heading, children }: HeadingProps) {
  const [first, rest] = splitFirstWord(heading);

  return (
    <h1 className="text-center text-4xl font-bold leading-relaxed">
      <span className="text-primary">{first}</span> {rest}
      <span className="text-5xl text-primary">.</span>
      {children}
    </h1>
  );
}
