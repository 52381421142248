import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QuestionSetUpdate } from '@/generated';
import api from '@/libs/api';
import { TModelId } from '@/types';

type TQuestionsetEdit = QuestionSetUpdate & {
  questionsetId: TModelId;
};

async function editQuestionset({ questionsetId, ...data }: TQuestionsetEdit) {
  const resp = await api.api.updateQuestionSet(questionsetId, data);
  return resp.data;
}

export default function useEditQuestionset() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editQuestionset,
    onSuccess: (questionset) => {
      queryClient.setQueryData(['questionsets', questionset.id], questionset);
    },
  });
}
