import React from 'react';
import { ISelectOption } from '@/interfaces';
import ReactSelect from '@/shared/form/Fields/ReactSelect';
import useApplicantsState from '@/states/applicantsState';

const options = [3, 5, 10].map((number) => ({ value: number, label: number.toString() }));

export default function CountSelection() {
  const { applicantsId, compareCount, setCompareCount } = useApplicantsState((state) => state);

  if (applicantsId.length > 0) return null;

  return (
    <ReactSelect
      onChange={(value) => {
        setCompareCount((value as ISelectOption<number>).value);
      }}
      options={options}
      defaultValue={options.find((option) => option.value === compareCount)}
    />
  );
}
