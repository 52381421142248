/* eslint-disable i18next/no-literal-string */
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AuthenticatorApp() {
  const { t } = useTranslation();

  return (
    <div className="space-y-2">
      <h3 className="h3">{t('Download any Authenticator App on your Phone')}</h3>
      <p>
        {t(
          "You can download any app that supports OTP (One Time Password) authentication Here's. our recommendation",
        )}
      </p>
      <ul className="list-disc px-4">
        <li>Google Authenticator</li>
        <li>Microsoft Authenticator</li>
        <li>2FAS</li>
      </ul>
    </div>
  );
}
