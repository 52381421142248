import { useQuery } from '@tanstack/react-query';
import api from '@/libs/api';
import useAuthState from '@/states/authState';

async function generateQr() {
  const resp = await api.api.generateQrCode({ format: 'blob' });
  return resp.data;
}

export default function useGenerateOtpQrCode() {
  const otpEnabled = useAuthState((state) => state.user?.otpEnabled);

  return useQuery({
    queryKey: ['otp', 'qrCode'],
    queryFn: generateQr,
    staleTime: Infinity,
    enabled: !otpEnabled,
  });
}
