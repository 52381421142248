import { ApplicantStatus } from '@/generated';
import { IFilter, ISelectOption } from '@/interfaces';

export const applicantFilters: IFilter[] = [
  {
    name: 'applicantStatus',
    placeholder: (t) => t('Status'),
    isMulti: false,
    options: [
      { label: (t) => t('All (Rejected + Screening)'), value: ApplicantStatus.ALL },
      { label: (t) => t('Screening'), value: ApplicantStatus.UNDER_SCREENING },
      { label: (t) => t('Rejected'), value: ApplicantStatus.REJECTED },
    ] satisfies ISelectOption<ApplicantStatus>[],
  },
];
