import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useActivateUser from '@/services/auth/activate';
import LoadingFallback from '@/shared/LoadingFallback';
import Head from '@/shared/custom/Head';

export default function ActivateUser() {
  const { t } = useTranslation();
  const heading = t('Active your Account');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const { mutate: activate } = useActivateUser();

  useEffect(() => {
    if (!token) navigate('/login');
    activate(token!, {
      onSuccess: () => {
        toast.success(t('Your account has been activated.'));
      },
      onSettled: () => {
        navigate('/login');
      },
    });
  }, [token]);

  return (
    <>
      <Head
        title={heading}
        description="Active your account to access all of the DivERS recruitment features"
      />
      <LoadingFallback />
    </>
  );
}
