import React from 'react';
import { useTranslation } from 'react-i18next';
import { TTranslation } from '@/types';
import { cn } from '@/utils';
import Tooltip from '../Tooltip';

export type TLabel = string | TTranslation;

type LabelProps = {
  label: TLabel;
  htmlFor: string;
  isRequired?: boolean;
  tooltip?: { text?: string; className?: string };
  className?: string;
};

export default function Label({
  label,
  htmlFor,
  tooltip,
  isRequired = false,
  className,
}: LabelProps) {
  const { t } = useTranslation();

  return (
    <label htmlFor={htmlFor} className={cn('w-full text-sm font-medium', className)}>
      {typeof label === 'string' ? label : label(t)}{' '}
      {isRequired && <span className="text-error">*</span>}{' '}
      {tooltip?.text && <Tooltip {...tooltip} text={tooltip.text} />}
    </label>
  );
}
