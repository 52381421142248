import React from 'react';
import { useTranslation } from 'react-i18next';
import Illustration from '@/assets/svg/auth/login.svg?react';
import Heading from '@/components/auth/Heading';
import Form from '@/components/auth/login/Form';
import AuthLayout from '@/layouts/AuthLayout';
import Head from '@/shared/custom/Head';

export default function Login() {
  const { t } = useTranslation();
  const heading = t('Login to your Account');

  return (
    <AuthLayout illustration={<Illustration className="md:h-[500px] md:w-[500px]" />}>
      <Head
        title={heading}
        description="Login to your account and access all of the DivERS recruitment features"
      />
      <Heading heading={heading} />
      <Form />
    </AuthLayout>
  );
}
