import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageIcon } from '@heroicons/react/24/outline';
import langs from '@/fixtures/langs';
import DropDown from './DropDown';

export default function Language() {
  const { t, i18n } = useTranslation();
  const selectValue = langs.find(({ value }) => value === i18n.resolvedLanguage);

  const handleLanguageChange = (value: string) => {
    if (value === i18n.language) return;
    i18n.changeLanguage(value);
  };

  return (
    <DropDown
      dropdownClassName="dropdown-bottom"
      dropdownContentClassName="w-52"
      tooltip={t('Languages')}
      Icon={LanguageIcon}
      aria-label={t('Languages')}
    >
      <label htmlFor="languageSelect" className="text-xl font-semibold">
        {t('Languages')}
      </label>
      <select
        defaultValue={selectValue?.value}
        onChange={(e) => handleLanguageChange(e.target.value)}
        className="select select-bordered"
        name="languageSelect"
        id="languageSelect"
      >
        {langs.map((lang) => (
          <option key={lang.value} value={lang.value}>
            {lang.label}
          </option>
        ))}
      </select>
    </DropDown>
  );
}
