import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { QuestionCreate, QuestionType } from '@/generated';
import { detailsContext } from '@/pages/questionsets/Details';
import useUpdateQuestion from '@/services/questions/edit';
import { TModelId } from '@/types';
import Modal from './modal';
import { calculateQuestionWeightFromApi, calculateQuestionWeightFromSlider } from './modal/Modal';

type EditProps = {
  isOpen: boolean;
  onClose: () => void;
  questionId: TModelId;
  initialValues: QuestionCreate;
};

export default function Edit({ isOpen, onClose, questionId, initialValues }: EditProps) {
  const { t } = useTranslation();
  const { mutate: updateQuestion, isPending } = useUpdateQuestion();
  const { clearQueryCache } = useContext(detailsContext);

  const handleSubmit = (values: QuestionCreate) => {
    updateQuestion(
      {
        ...values,
        weight: calculateQuestionWeightFromSlider(values.weight!),
        choices: values.type === QuestionType.TEXT ? [] : values.choices,
        type: values.type!,
        questionId,
      },
      {
        onSuccess: () => {
          toast.success(t('toast.updatedSuccessfully', { model: t('Question') }));
          clearQueryCache();
          onClose();
        },
      },
    );
  };

  return (
    <Modal
      modalTitle={t('Edit Question')}
      isOpen={isOpen}
      initialValues={{
        ...initialValues,
        weight: calculateQuestionWeightFromApi(initialValues.weight!),
      }}
      isSubmitting={isPending}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
}
