import React from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Button } from '@/shared/form';
import useSettingsState, { TResourceKeys } from '@/states/settingsState';

type ToggleOptionsProps = {
  resource: TResourceKeys;
};

export default function ToggleOptions({ resource }: ToggleOptionsProps) {
  const isOpen = useSettingsState((state) => state[resource].isOpen);
  const setResourceSettings = useSettingsState((state) => state.setResourceSettings);
  const handleToggleOptions = () => setResourceSettings(resource, { isOpen: !isOpen });

  return (
    <Button
      id="toggleOptions"
      onClick={handleToggleOptions}
      className="btn btn-neutral btn-md space-x-1"
      aria-label="Options"
    >
      <EllipsisVerticalIcon className="h-6 w-6" />
    </Button>
  );
}
