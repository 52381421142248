import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import Tabs from '@/components/platform/Tabs';

export default function PlatformLayout() {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="h1">{t('Platform')}</h1>
      <Tabs />
      <Outlet />
    </>
  );
}
