import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DeleteApplicantsParams, DeleteApplicantsPayload } from '@/generated';
import api from '@/libs/api';
import useRefreshToken from '../auth/refreshToken';

type TDeleteApplicants = DeleteApplicantsParams & DeleteApplicantsPayload;

async function deleteApplicants({ questionSetId, applicantIds }: TDeleteApplicants) {
  const resp = await api.api.deleteApplicants({ questionSetId }, { applicantIds });
  return resp.data;
}

export default function useDeleteApplicants() {
  const queryClient = useQueryClient();
  const { mutate: refreshUser } = useRefreshToken();

  return useMutation({
    mutationFn: deleteApplicants,
    onSuccess: () => {
      refreshUser();
      queryClient.invalidateQueries({ queryKey: ['applicants'] });
    },
  });
}
