import React from 'react';
import ReactJoyride, { BeaconRenderProps, Props } from 'react-joyride';
import Beacon from '@/components/joyride/Beacon';
import Tooltip from '@/components/joyride/Tooltip';

export interface JoyRideProps extends Props {}

export default function JoyRide(props: JoyRideProps) {
  return (
    <ReactJoyride
      {...props}
      beaconComponent={Beacon as React.ElementType<BeaconRenderProps>}
      tooltipComponent={Tooltip}
    />
  );
}
