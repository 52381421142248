import React, { Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import ButtonActions from '@/shared/custom/ButtonActions';
import { Fields } from '@/shared/form';
import DialogModal from '@/shared/modals/DialogModal';
import { TField } from '@/types';

const fields: TField[] = [
  {
    isRequired: true,
    label: (t) => t('Section Title'),
    type: 'input',
    name: 'sectionTitle',
    fieldProps: { type: 'text' },
  },
  {
    isRequired: true,
    label: (t) => t('Questions per Page'),
    type: 'input',
    name: 'nbQuestionsPerPage',
    fieldProps: { type: 'number' },
  },
  {
    isRequired: false,
    label: (t) => t('Go to Questionset Details'),
    name: 'goToDetails',
    type: 'checkbox',
    fieldProps: {},
  },
];

const initialValues = {
  nbQuestionsPerPage: 1,
  sectionTitle: 'Questions',
  goToDetails: true,
};

export type TFinalizeAiValues = typeof initialValues;

type FinalizeModalProps = {
  modalRef: Ref<HTMLDialogElement>;
  handleCloseModal: () => void;
  handleSubmit: (values: TFinalizeAiValues) => void;
  isPending: boolean;
};

export default function FinalizeModal({
  modalRef,
  handleCloseModal,
  handleSubmit,
  isPending,
}: FinalizeModalProps) {
  const { t } = useTranslation();

  return (
    <DialogModal
      closeOnClickOutside
      modalRef={modalRef}
      parentElement="div"
      parentClassName="w-11/12 max-w-2xl"
    >
      <h3 className="text-2xl font-bold">{t('Finalize')}</h3>
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        <Form className="space-y-4">
          <Fields fields={fields} />
          <ButtonActions
            submitProps={{ isLoading: isPending, disabled: isPending }}
            cancelProps={{ onClick: handleCloseModal }}
          />
        </Form>
      </Formik>
    </DialogModal>
  );
}
