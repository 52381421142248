import { useQuery } from '@tanstack/react-query';
import { GetTemplatesParams } from '@/generated';
import api from '@/libs/api';

async function getTemplates(query: GetTemplatesParams) {
  const resp = await api.api.getTemplates({ ...query, size: 50 });
  return resp.data;
}

export default function useGetTemplates(query: GetTemplatesParams) {
  return useQuery({ queryKey: ['templates'], queryFn: () => getTemplates(query) });
}
