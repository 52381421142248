import React from 'react';
import { createSameColors } from '@/fixtures/chartColors';
import { TActiveQuestion } from '@/pages/applicants/Show';
import Chart from '@/shared/Chart';
import { calculateScoreIndex, groupScoresIntoRanges } from '@/utils/applicants';

type DistributionChartProps = {
  scores?: Record<string, number[]>;
  activeQuestion: TActiveQuestion;
};

export default function DistributionChart({ scores, activeQuestion }: DistributionChartProps) {
  if (!scores || !activeQuestion) return null;

  const result = groupScoresIntoRanges(scores[activeQuestion.id]);
  const data: number[] = [];
  result.forEach((values) => data.push(values.length));
  const scoreIndex = calculateScoreIndex(activeQuestion.score);
  const colors = createSameColors('#475569').fill('#4f46e5', scoreIndex, scoreIndex + 1);

  return (
    <div className="mx-auto max-w-screen-lg">
      <Chart
        options={{
          labels: [
            '0.0-0.1',
            '0.1-0.2',
            '0.2-0.3',
            '0.3-0.4',
            '0.4-0.5',
            '0.5-0.6',
            '0.6-0.7',
            '0.7-0.8',
            '0.8-0.9',
            '0.9-1.0',
          ],
          plotOptions: { bar: { distributed: true } },
          colors,
          xaxis: { title: { text: 'Score Categories' } },
          yaxis: { title: { text: 'Number of Applicants' } },
          legend: { show: false },
          grid: { show: false },
          dataLabels: {
            enabled: true,
            style: { fontSize: '8' },
            formatter: (value, options) => (options.dataPointIndex === scoreIndex ? 'Current' : ''),
          },
        }}
        series={[{ data, name: 'Number of Applicants' }]}
        type="bar"
      />
    </div>
  );
}
