import { useQuery } from '@tanstack/react-query';
import api from '@/libs/api';

async function getCollaborators() {
  const resp = await api.api.getCollaborators({ size: 50 });
  return resp.data;
}

export default function useGetCollaborators() {
  return useQuery({
    queryKey: ['collaborators'],
    queryFn: getCollaborators,
  });
}
