import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import HeatMap from '@/components/applicants/HeatMap';
import NextAndPrevious from '@/components/applicants/NextAndPrevious';
import Tabs from '@/components/applicants/Tabs';
import Questions from '@/components/applicants/sectionCompare/questions';
import { IAnswerQuestion } from '@/components/applicants/sectionCompare/questions/Question';
import { TIndexLayoutContext } from '@/layouts/applicants/IndexLayout';
import Head from '@/shared/custom/Head';
import HeadingWithLinks from '@/shared/custom/HeadingWithLinks';

export type TTab = 'heatmap' | 'tableAndChart';

export const ZERO_CUSTOM_VALUE = 8.99;

export default function SectionCompare() {
  const { t } = useTranslation();
  const { queryResult, topApplicants } = useOutletContext<TIndexLayoutContext>();
  const navigate = useNavigate();
  const { sectionId } = useParams<'sectionId'>();
  const { isLoading } = queryResult;
  const [activeTab, setActiveTab] = useState<TTab>('tableAndChart');

  if (isLoading) return null;
  if (topApplicants.length === 0) return <Navigate to="/applicants" />;
  const sectionIds = Object.keys(topApplicants[0].scoreSections!);
  const currentIndex = sectionIds.indexOf(sectionId!);

  const tabs: { label: string; value: TTab }[] = [
    { value: 'tableAndChart', label: t('Table') },
    { value: 'heatmap', label: t('HeatMap') },
  ];

  if (currentIndex === -1) return <Navigate to="/applicants/compare" />;

  const { sectionTitle } = topApplicants[0].scoreSections![sectionId!];
  const title = `${t('Compare')} ${sectionTitle}`;

  const answer = Object.entries(topApplicants[0].answersFull!);
  // get all questions for specific sectionId
  const questions = answer.reduce((acc, [key, value]) => {
    if (sectionId === value.sectionId) {
      acc.push({ id: key, title: value.questionTitle });
    }
    return acc;
  }, [] as IAnswerQuestion[]);

  const props = { topApplicants, questions };

  const handleNext = () => {
    const nextIndex = currentIndex + 1;
    navigate(`../${sectionIds[nextIndex]}`, { relative: 'path' });
  };

  const handlePrevious = () => {
    const previousIndex = currentIndex - 1;
    navigate(`../${sectionIds[previousIndex]}`, { relative: 'path' });
  };

  return (
    <>
      <Head title={title} description={title} />
      <div className="flex items-center justify-between">
        <HeadingWithLinks
          links={[
            { text: 'Applicants', to: '/applicants' },
            { text: 'Compare', to: '../compare' },
            { text: title, to: '#' },
          ]}
        />
        {!isLoading && (
          <NextAndPrevious
            onNext={handleNext}
            onPrevious={handlePrevious}
            hasNext={currentIndex !== sectionIds.length - 1}
            hasPrevious={currentIndex > 0}
          />
        )}
      </div>
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === 'heatmap' ? (
        <HeatMap
          categories={topApplicants.map((applicant) => applicant.tag!)}
          series={questions.map((question) => ({
            data: topApplicants.map((applicant) => {
              const score = applicant.answersFull![question.id].scoreValueNormalized!;
              return score === 0 ? ZERO_CUSTOM_VALUE : score;
            }),
            name: question.title,
          }))}
          tooltip={{
            custom({ seriesIndex, dataPointIndex }) {
              const question = questions[seriesIndex];
              const applicantId = topApplicants[dataPointIndex].tag!;
              const score =
                topApplicants[dataPointIndex].answersFull![question.id].scoreValueNormalized!;
              return `${question.title} ${applicantId}=${score}`;
            },
          }}
        />
      ) : (
        <Questions {...props} />
      )}
    </>
  );
}
