import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

async function seenNotification(id: TModelId) {
  const resp = await api.api.updateNotification(id, { seenDate: new Date().toISOString() });
  return resp.data;
}

export default function useSeenNotification() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: seenNotification,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
}
