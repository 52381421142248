import React from 'react';
import { useTranslation } from 'react-i18next';
import { IFilter } from '@/interfaces';
import Button from '@/shared/form/Button';
import useSettingsState, { TResourceKeys } from '@/states/settingsState';
import { cn } from '@/utils';

type JoinButtonFilterProps = {
  filter: IFilter;
  resource: TResourceKeys;
};

export default function JoinButtonFilter({ filter, resource }: JoinButtonFilterProps) {
  const { name, options } = filter;
  const params = useSettingsState((state) => state[resource].params);
  const setResourceSettings = useSettingsState((state) => state.setResourceSettings);
  const { t } = useTranslation();

  return (
    <div className="join flex-wrap">
      {options.map((option) => (
        <Button
          key={option.value}
          className={cn('btn join-item btn-md', {
            'btn-primary': (params[name] as string[]).includes(option.value),
          })}
          onClick={() => {
            setResourceSettings(resource, { params: { ...params, [name]: [option.value] } });
          }}
        >
          {typeof option.label === 'function' ? option.label(t) : option.label}
        </Button>
      ))}
    </div>
  );
}
