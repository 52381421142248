import React from 'react';
import { useTranslation } from 'react-i18next';
import { SwatchIcon } from '@heroicons/react/24/outline';
import useThemeState from '@/states/themeState';
import Action from './Action';

export default function ToggleTheme() {
  const toggleTheme = useThemeState((state) => state.toggleTheme);
  const { t } = useTranslation();

  return (
    <Action
      tooltip="Toggle Theme"
      Icon={SwatchIcon}
      onClick={toggleTheme}
      aria-label={t('Themes')}
    />
  );
}
