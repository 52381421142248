import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { parse } from 'date-fns';
import get from 'lodash/get';
import config from '@/fixtures/config';
import useAuthState from '@/states/authState';
import Action from './Action';

export default function WhatIsNew() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const settings = useAuthState((state) => state.user?.settings);
  const currentSetting = settings?.find((setting) => setting.name === config.whatIsNewKey);
  const changelog = t('changelog', { returnObjects: true });
  const lastViewedDate = currentSetting
    ? new Date(get(currentSetting.value, 'lastViewedAt') as string)
    : null;

  const latestReleaseDate = parse(changelog.at(0)!.date, 'dd-MM-yyyy', new Date());

  if (lastViewedDate && lastViewedDate.getTime() > latestReleaseDate.getTime()) return null;

  return (
    <Action
      hasIndicator
      tooltip={t("What's New")}
      Icon={InformationCircleIcon}
      aria-label={t("What's New")}
      onClick={() => navigate('/what-is-new')}
    />
  );
}
