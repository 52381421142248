import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { ISelect } from '@/interfaces';
import { cn } from '@/utils';

interface SelectProps extends ISelect {
  hasError: boolean;
}

export default function Select({ hasError, options, ...props }: SelectProps) {
  const { t } = useTranslation();

  return (
    <Field
      {...props}
      as="select"
      className={cn('select select-bordered w-full', {
        'select-error': hasError,
      })}
    >
      <option value="">{t('Select an Option')}</option>
      {options?.map(({ value, label }) => (
        <option key={value} value={value}>
          {typeof label === 'string' ? label : label(t)}
        </option>
      ))}
    </Field>
  );
}
