import React from 'react';
import { useTranslation } from 'react-i18next';
import { ZERO_CUSTOM_VALUE } from '@/pages/applicants/SectionCompare';
import Chart from '@/shared/Chart';

type HeatMapProps = {
  categories: any[];
  series: ApexAxisChartSeries;
  tooltip?: ApexTooltip;
};

export default function HeatMap({ categories, series, tooltip }: HeatMapProps) {
  const { t } = useTranslation();

  return (
    <div className="block">
      <Chart
        options={{
          tooltip,
          dataLabels: {
            formatter: (value): any => {
              if (value === ZERO_CUSTOM_VALUE) return 0;
              return value;
            },
            style: { fontWeight: 'bold', fontSize: '1.25rem', fontFamily: 'Poppins' },
          },
          chart: {
            toolbar: {
              export: { csv: { headerCategory: t('Tag') } },
            },
          },
          xaxis: {
            categories,
            title: { text: t('Tag') },
          },
          colors: ['#4f46e5'],
          plotOptions: {
            heatmap: {
              radius: 2,
              shadeIntensity: 0,
            },
          },
        }}
        series={series}
        type="heatmap"
      />
    </div>
  );
}
