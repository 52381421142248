import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, flexRender } from '@tanstack/react-table';
import Card from '@/components/cards/Card';
import Empty from '@/shared/views/Empty';
import useAsideState from '@/states/asideState';
import useSettingsState, { TResourceKeys } from '@/states/settingsState';
import { TViewLink } from '@/types';
import { cn } from '@/utils';

type CardsProps = {
  resource: TResourceKeys;
  table: Table<any>;
  viewLink?: TViewLink;
  isLoading?: boolean;
  isCardsFull?: boolean;
};

export default function Cards({
  resource,
  table,
  viewLink,
  isCardsFull = false,
  isLoading = false,
}: CardsProps) {
  const { t } = useTranslation();
  const isCardMaximized = useSettingsState((state) => state[resource].isCardMaximized);
  const isAsideMaximized = useAsideState((state) => state.isMaximized);
  const isEmpty = !isLoading && table.getRowModel().rows.length <= 0;

  return (
    <div
      className={cn('grid grid-cols-1 gap-4', {
        'lg:grid-cols-2': !isCardsFull && isAsideMaximized,
        'md:grid-cols-2': !isCardsFull && !isAsideMaximized,
      })}
    >
      {isEmpty && <Empty />}
      {isLoading &&
        table
          .getRowModel()
          .flatRows.map((row) => <div key={row.id} className="skeleton h-64 w-full" />)}
      {table.getRowModel().flatRows.map((row) => {
        const actions = row.getAllCells().find((cell) => cell.column.id === 'actions');
        const title = row.getAllCells().at(0)!;

        return (
          <Card
            key={row.id}
            viewLink={viewLink && viewLink(row)}
            actions={actions && flexRender(actions.column.columnDef.cell, actions.getContext())}
            title={flexRender(title.column.columnDef.cell, title.getContext())}
            data={row
              .getVisibleCells()
              .filter(
                (cell) => !['actions', row.getAllCells().at(0)?.column.id].includes(cell.column.id),
              )
              .map((cell) => ({
                id: cell.column.id,
                label: cell.column.columnDef.meta?.header(t),
                value: flexRender(cell.column.columnDef.cell, cell.getContext()),
              }))}
            isMaximizedGlobally={isCardMaximized}
          />
        );
      })}
    </div>
  );
}
