import {
  AcademicCapIcon,
  BriefcaseIcon,
  ChatBubbleLeftIcon,
  ComputerDesktopIcon,
  FireIcon,
  HomeIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { User } from '@/generated';
import { TIcon, TTranslation } from '@/types';

export type TAsideLink = {
  to: string;
  translation: TTranslation;
  Icon: TIcon;
  showOnMinimized: boolean;
  canRender?: (user: User) => boolean;
  hasWarning?: (user: User) => boolean;
};

export type TAsideParent = {
  name: string;
  translation: TTranslation;
  links: TAsideLink[];
  showGroupTitle?: boolean;
};

export type TAsideLinks = TAsideParent;

const asideLinks: TAsideLinks[] = [
  {
    name: 'main',
    translation: (t) => t('Home'),
    links: [{ to: '/', translation: (t) => t('Home'), Icon: HomeIcon, showOnMinimized: true }],
    showGroupTitle: false,
  },
  {
    name: 'recruitments',
    translation: (t) => t('Recruitments'),
    links: [
      {
        to: '/recruitments',
        translation: (t) => t('Recruitments'),
        Icon: FireIcon,
        showOnMinimized: true,
      },
      {
        to: '/applicants',
        translation: (t) => t('Applicants'),
        Icon: AcademicCapIcon,
        showOnMinimized: true,
      },
      {
        to: '/messages',
        translation: (t) => t('Messages'),
        Icon: ChatBubbleLeftIcon,
        showOnMinimized: true,
      },
    ],
  },
  {
    name: 'management',
    translation: () => 'Management',
    links: [
      {
        to: '/users',
        translation: (t) => t('Users'),
        Icon: UsersIcon,
        canRender: (user) => Boolean(user.userAccess.viewUserPage),
        showOnMinimized: true,
      },
      {
        to: '/platform',
        translation: (t) => t('Platform'),
        Icon: ComputerDesktopIcon,
        canRender: (user) => Boolean(user.userAccess.viewPlatformPage),
        showOnMinimized: true,
        hasWarning: (user) => user.screeningsIsRunningOut,
      },
      {
        to: '/clients',
        translation: (t) => t('Clients'),
        Icon: BriefcaseIcon,
        canRender: (user) => Boolean(user.userAccess.viewClientPage),
        showOnMinimized: true,
      },
      { to: '/account', translation: (t) => t('Account'), Icon: UserIcon, showOnMinimized: true },
    ],
  },
  {
    name: 'help',
    translation: (t) => t('Help'),
    links: [
      {
        to: '/help',
        translation: (t) => t('Help'),
        Icon: InformationCircleIcon,
        showOnMinimized: false,
      },
      {
        to: '/what-is-new',
        translation: (t) => t("What's New"),
        Icon: QuestionMarkCircleIcon,
        showOnMinimized: false,
      },
    ],
  },
];

export default asideLinks;
