import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import commonFields from '@/fixtures/commonFields';
import validations from '@/fixtures/validations';
import { RecruitmentCreate } from '@/generated';
import ButtonActions from '@/shared/custom/ButtonActions';
import Head from '@/shared/custom/Head';
import HeadingWithLinks from '@/shared/custom/HeadingWithLinks';
import { Fields } from '@/shared/form';
import { TModelId } from '@/types';
import Collaborators from './Collaborators';

const validationSchema = Yup.object().shape({
  title: validations.longTitle,
  description: validations.description.max(1024),
  advertisement: validations.longDescription,
});

type CreateProps = {
  title: string;
  isSubmitting: boolean;
  onSubmit: (values: RecruitmentCreate, collaborators: TModelId[]) => void;
  initialValues: RecruitmentCreate;
  recruitmentId: TModelId | undefined;
};

export default function Create({
  onSubmit,
  recruitmentId,
  initialValues,
  isSubmitting,
  title,
}: CreateProps) {
  const navigate = useNavigate();
  const [collaborators, setCollaborators] = useState<TModelId[]>([]);

  return (
    <Formik
      onSubmit={(values) => onSubmit(values, collaborators)}
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
    >
      <FormikForm className="space-y-4">
        <Head title={title} description={title} />
        <HeadingWithLinks
          links={[
            { text: 'Recruitments', to: '/recruitments' },
            { text: title, to: '#' },
          ]}
        />
        <Fields
          fields={[
            commonFields.title,
            {
              ...commonFields.description,
              fieldProps: { ...commonFields.description.fieldProps, maxLimitHelper: 1024 },
            },
          ]}
        />
        <Collaborators
          collaborators={collaborators}
          setCollaborators={setCollaborators}
          recruitmentId={recruitmentId}
        />
        <ButtonActions
          cancelProps={{ onClick: () => navigate(-1) }}
          submitProps={{ disabled: isSubmitting, isLoading: isSubmitting }}
        />
      </FormikForm>
    </Formik>
  );
}
