import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

async function deleteSection(id: TModelId) {
  const resp = await api.api.deleteSection(id);
  return resp.data;
}

export default function useDeleteSection() {
  return useMutation({ mutationFn: deleteSection });
}
