import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ISelectOption } from '@/interfaces';
import Select from '@/shared/form/Fields/ReactSelect';
import useSettingsState from '@/states/settingsState';
import { optionsContext } from '../../Options';
import Container from '../Container';
import Header from '../Header';

export default function SortBy() {
  const { table, resource } = useContext(optionsContext);
  const setResourceSettings = useSettingsState((state) => state.setResourceSettings);
  const params = useSettingsState((state) => state[resource].params);
  const { t } = useTranslation();

  const sortableItems = table
    .getAllColumns()
    .filter((column) => column.getCanSort())
    .map((column) => ({
      value: column.id,
      label: column.columnDef.meta?.header(t),
    }));
  const defaultSortByValue = sortableItems.find((option) => option.value === params.sortBy);

  const handleSortByChange = (selected: unknown) => {
    const { value } = selected as ISelectOption<string>;
    table.setSorting(() => [{ id: value, desc: !params.ascending }]);
    setResourceSettings(resource, { params: { ...params, sortBy: value } });
  };

  const handleSortTypeChange = (isAscending: boolean) => {
    table.setSorting(() => [{ id: params.sortBy, desc: !isAscending }]);
    setResourceSettings(resource, { params: { ...params, ascending: isAscending } });
  };

  return (
    <Container id="optionsSortByContainer">
      <div className="flex items-center justify-between">
        <Header>
          {t('Sort By')}
          <span className="badge badge-outline">
            {params.ascending ? t('Ascending') : t('Descending')}
          </span>
        </Header>
        <input
          defaultChecked={params.ascending}
          onChange={(e) => handleSortTypeChange(e.target.checked)}
          id="sortType"
          type="checkbox"
          className="toggle toggle-primary"
        />
      </div>
      <Select
        placeholder={t('Sort By')}
        onChange={handleSortByChange}
        value={defaultSortByValue}
        options={sortableItems}
      />
    </Container>
  );
}
