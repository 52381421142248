import { useEffect, useState } from 'react';

export default function useDebounce<T>(defaultValue: T, callback: (value: T) => void, delay = 500) {
  const [value, setValue] = useState<T>(defaultValue);

  useEffect(() => {
    const timer = setTimeout(() => callback(value), delay);
    return () => clearTimeout(timer);
  }, [value]);

  return [value, setValue] as const;
}
