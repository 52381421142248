import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISettingOption } from '@/pages/settings/Index';
import { Button } from '@/shared/form';
import { cn } from '@/utils';

interface OptionProps extends ISettingOption {
  isActive: boolean;
  onActive: () => void;
}

export default function Option({ Icon, isActive, onActive, type }: OptionProps) {
  const { t } = useTranslation();

  return (
    <li className="card card-bordered">
      <Icon className="mx-auto p-4" width={300} height={300} />
      <div className="card-body">
        <h2 className={cn('h2 text-center')}>{t(`settings.options.${type}.heading`)}</h2>
        <p className="description text-center">{t(`settings.options.${type}.description`)}</p>
        <div className="card-actions justify-center">
          <Button
            onClick={onActive}
            className={cn('btn btn-primary btn-md mt-2', {
              'btn-primary': isActive,
              'btn-outline': !isActive,
            })}
          >
            {isActive ? t('Currently Active') : t('Active')}
          </Button>
        </div>
      </div>
    </li>
  );
}
