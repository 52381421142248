import React from 'react';
import { useTranslation } from 'react-i18next';
import { ISetupOption } from '@/pages/questionsets/Setup';
import { Button } from '@/shared/form';
import { cn } from '@/utils';

interface OptionProps extends ISetupOption {
  isSelected: boolean;
  onClick?: () => void;
}

export default function Option({ type, Icon, isSelected, onClick }: OptionProps) {
  const { t } = useTranslation();

  return (
    <li
      className={cn('card border-2', {
        'border-primary': isSelected,
        'border-colors': !isSelected,
      })}
    >
      <Button onClick={onClick} className="card-body">
        <Icon className="mx-auto" width={300} height={300} />
        <h2 className="h2 w-full text-center">{t(`questionsets.setup.${type}.heading`)}</h2>
        <p className="description">{t(`questionsets.setup.${type}.description`)}</p>
      </Button>
    </li>
  );
}
