import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Welcome from '@/assets/svg/home/welcome.svg?react';
import useGetQuestionsets from '@/services/questionsets';
import useAsideState from '@/states/asideState';
import { cn } from '@/utils';
import Card from './Card';

export default function InteractiveHelp() {
  const { isMaximized, isOpen } = useAsideState((state) => state);
  const { data: questionsets } = useGetQuestionsets({ size: 5 });
  const { t } = useTranslation();

  return (
    <motion.div
      transition={{ delay: 0.25 }}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div
        className={cn('grid grid-cols-1 gap-4 2xl:grid-cols-2', {
          'xl:grid-cols-2': !isOpen || !isMaximized,
        })}
      >
        <Card
          description={t('home.interactiveHelp.description')}
          Icon={Welcome}
          button={{ text: t('home.interactiveHelp.actionText'), linkTo: '/recruitments/create' }}
        />
        {questionsets?.total > 0 && (
          <div className="card bg-base-200">
            <div className="card-body">
              <h2 className="h2">{t('Your Recent Questionsets')}</h2>
              <div className="flex flex-wrap items-center gap-4">
                {questionsets?.items.map((questionset) => (
                  <Link
                    to={`/questionsets/${questionset.id}/details`}
                    key={questionset.id}
                    className="group btn btn-outline btn-primary"
                  >
                    <span className="text-primary group-hover:text-base-100">
                      {questionset.recruitmentTitle}
                    </span>{' '}
                    - <span className="text-base-content">{questionset.title}</span>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
}
