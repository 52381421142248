import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { ExtractQuestionsNewParams } from '@/generated';
import api from '@/libs/api';
import { isAxiosError } from '@/utils';

type TExtractQuestions = ExtractQuestionsNewParams & {
  data: string;
};

async function extractQuestions({ data, ...params }: TExtractQuestions) {
  const resp = await api.api.extractQuestionsNew(params, data);
  return resp.data;
}

export default function useExtractQuestions() {
  return useMutation({
    mutationFn: extractQuestions,
    onError: (e) => {
      if (isAxiosError(e, 400)) {
        toast.error((e.response!.data as any).detail);
      }
    },
  });
}
