import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

type Props = {
  recruitmentId: TModelId;
  collaborators: TModelId[];
};
async function assignCollaborators({ recruitmentId, collaborators }: Props) {
  const resp = await api.api.assignRecruitmentRole(recruitmentId, {
    collaboratorIds: collaborators,
  });
  return resp.data;
}

export default function useAssignCollaborator() {
  return useMutation({ mutationFn: assignCollaborators });
}
