import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { QuestionCreate, QuestionType } from '@/generated';
import { detailsContext } from '@/pages/questionsets/Details';
import useCreateQuestion from '@/services/questions/create';
import { TModelId } from '@/types';
import Modal from './modal';
import { calculateQuestionWeightFromSlider } from './modal/Modal';

type CreateProps = {
  isOpen: boolean;
  onClose: () => void;
  sectionId: TModelId;
};

export default function Create({ isOpen, onClose, sectionId }: CreateProps) {
  const { t } = useTranslation();
  const { mutate: createQuestion, isPending } = useCreateQuestion();
  const { clearQueryCache } = useContext(detailsContext);

  const handleSubmit = (values: QuestionCreate) => {
    const data = {
      ...values,
      choices: values.type === QuestionType.TEXT ? [] : values.choices,
      weight: calculateQuestionWeightFromSlider(values.weight!),
      type: values.type!,
      sectionId,
    };
    if (![QuestionType.NUMBER, QuestionType.DECIMAL].includes(data.type)) {
      delete data.properties;
    }

    createQuestion(data, {
      onSuccess: () => {
        toast.success(t('toast.createdSuccessfully', { model: t('Question') }));
        clearQueryCache();
        onClose();
      },
    });
  };

  return (
    <Modal
      modalTitle={t('Create Question')}
      isOpen={isOpen}
      initialValues={{
        title: '',
        description: '',
        type: QuestionType.TEXT,
        weight: 2,
        properties: {
          minValue: '' as any,
          maxValue: '' as any,
          expectedMean: '' as any,
          expectedVar: '' as any,
        },
        choices: [
          { title: '', score: 0 },
          { title: '', score: 0 },
        ],
      }}
      isSubmitting={isPending}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
}
