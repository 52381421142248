import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowUpIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '@/shared/form';
import { cn } from '@/utils';

type ActionsProps = {
  index: number;
  onDelete: () => void;
  onMoveUp: () => void;
  canDelete?: boolean;
  canMoveUp?: boolean;
};

export default function Actions({
  index: i,
  canDelete = true,
  canMoveUp = true,
  onDelete,
  onMoveUp,
}: ActionsProps) {
  const { t } = useTranslation();
  const className = '';

  return (
    <>
      {canMoveUp && (
        <Button
          onClick={onMoveUp}
          className={cn(className, 'btn btn-secondary')}
          aria-label={`${t('Move Up')} ${i + 1}`}
        >
          <ArrowUpIcon strokeWidth={2} className="h-5 w-5" />
        </Button>
      )}
      {canDelete && (
        <Button
          onClick={onDelete}
          className={cn(className, 'btn btn-error')}
          aria-label={`${t('Delete')} ${i + 1}`}
        >
          <XMarkIcon strokeWidth={2} className="h-5 w-5" />
        </Button>
      )}
    </>
  );
}
