import React from 'react';
import { useTranslation } from 'react-i18next';
import useAsideState from '@/states/asideState';
import { cn } from '@/utils';
import Footer from './Footer';
import Header from './Header';
import Links from './links';

export default function Aside() {
  const { isOpen, isMaximized, setIsOpen } = useAsideState((state) => state);
  const { t } = useTranslation();

  const handleCloseAside = () => setIsOpen(false);

  return (
    <>
      {isOpen && (
        <button
          type="button"
          onClick={handleCloseAside}
          className="absolute z-10 h-screen w-full bg-base-100/70 text-transparent md:hidden"
        >
          {t('Close')}
        </button>
      )}
      <aside
        className={cn(
          'transition-aside fixed inset-y-0 left-0 z-20 flex h-screen flex-col items-center overflow-y-auto overflow-x-hidden bg-base-200 drop-shadow-xl duration-300',
          {
            'w-20 justify-between': !isMaximized,
            'w-72': isMaximized,
            'translate-x-0': isOpen,
            '-translate-x-full': !isOpen,
          },
        )}
      >
        <Header />
        <nav className="w-full">
          <Links />
        </nav>
        <Footer />
      </aside>
    </>
  );
}
