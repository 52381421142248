import React from 'react';
import { QuestionSetTemplate } from '@/generated';
import { Button } from '@/shared/form';
import { cn } from '@/utils';

interface TemplateProps extends Pick<QuestionSetTemplate, 'title' | 'imagePath'> {
  isSelected: boolean;
  onClick?: () => void;
}

export default function Template({ title, imagePath, isSelected, onClick }: TemplateProps) {
  return (
    <Button
      onClick={onClick}
      className={cn('card card-bordered card-compact', {
        'border-2 border-primary': isSelected,
      })}
    >
      <div className="card-body gap-4">
        <figure>
          <img src={imagePath!} alt={title} />
        </figure>
        <h2 className="card-title mx-auto text-center">{title}</h2>
      </div>
    </Button>
  );
}
