import React, { useContext, useEffect, useState } from 'react';
import { PaperAirplaneIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { showContext } from '@/pages/messages/Show';
import useCreateMessage from '@/services/messages/create';
import useEditMessage from '@/services/messages/edit';
import { Button } from '@/shared/form';
import { TModelId } from '@/types';

type FormProps = {
  recruitmentId: TModelId;
};

export default function Form({ recruitmentId }: FormProps) {
  const { scrollToTop, editMessage: editMessageState, setEditMessage } = useContext(showContext);
  const [body, setBody] = useState('');
  const queryClient = useQueryClient();
  const { mutate: createMessage, isPending: isCreating } = useCreateMessage();
  const { mutate: editMessage, isPending: isEditing } = useEditMessage();

  const isLoading = isCreating || isEditing;

  useEffect(() => {
    setBody(editMessageState?.body || '');
  }, [editMessageState]);

  const handleCancelEdit = () => setEditMessage(null);
  const handleInvalidQueries = () =>
    queryClient.invalidateQueries({
      queryKey: ['messages', recruitmentId],
    });
  const mutateOptions = (callback?: Function) => ({
    onSuccess: () => {
      setBody('');
      handleInvalidQueries();
      scrollToTop();
      if (callback) callback();
    },
  });

  const handleCreate = () => {
    const data = { recruitmentId, body };
    createMessage(data, mutateOptions());
  };

  const handleEdit = () => {
    const data = { messageId: editMessageState!.id, body };
    editMessage(data, mutateOptions(handleCancelEdit));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!body) return;
    if (editMessageState) {
      handleEdit();
      return;
    }
    handleCreate();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="border-colors sticky -bottom-1 flex w-full items-center justify-center space-x-4 border-t bg-base-300 p-4"
    >
      <div className="relative w-full">
        <input
          value={body}
          onChange={(e) => setBody(e.target.value)}
          type="text"
          className="input input-bordered h-10 w-full"
        />
        {editMessageState && (
          <Button
            onClick={handleCancelEdit}
            className="btn btn-circle btn-ghost btn-sm absolute right-2 top-1"
          >
            <XMarkIcon className="h-6 w-6" />
          </Button>
        )}
      </div>
      <Button
        className="btn btn-circle btn-ghost"
        type="submit"
        disabled={!body || isLoading}
        isLoading={isLoading}
      >
        {!isLoading && <PaperAirplaneIcon strokeWidth={2} className="h-6 w-6" />}
      </Button>
    </form>
  );
}
