/* eslint-disable react/no-array-index-key */
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BoltIcon } from '@heroicons/react/24/solid';
import config from '@/fixtures/config';
import useCreateOrUpdateSetting from '@/services/settings/createOrUpdate';
import Head from '@/shared/custom/Head';

export default function WhatIsNew() {
  const { t } = useTranslation();
  const title = t("What's New");
  const { mutate: createOrUpdateSetting } = useCreateOrUpdateSetting(config.whatIsNewKey);
  const changes = t('changelog', { returnObjects: true });

  useEffect(() => {
    createOrUpdateSetting({ lastViewedAt: new Date() });
  }, []);

  return (
    <>
      <Head title={title} description={title} />
      <h1 className="h1">{title}</h1>
      {changes.map((change, i) => (
        <Fragment key={change.date}>
          <div className="flex items-center gap-4">
            <BoltIcon className="size-6 text-warning" />
            <h2 className="text-xl font-medium">{change.date}</h2>
          </div>
          <ul className="list-disc space-y-4 ps-8 text-base-content/70">
            {change.changes.map((text, j) => (
              <li key={j}>{text}</li>
            ))}
          </ul>
          {i !== changes.length - 1 && <div className="divider" />}
        </Fragment>
      ))}
    </>
  );
}
