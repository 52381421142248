import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Form from '@/components/recruitments/Form';
import { RecruitmentCreate } from '@/generated';
import useCreateRecruitment from '@/services/recruitments/create';
import useAssignCollaborator from '@/services/recruitments/roles/assign';
import { TModelId } from '@/types';

export default function Create() {
  const { t } = useTranslation();
  const title = t('Create Recruitment');
  const { mutateAsync: createRecruitment, isPending: isCreating } = useCreateRecruitment();
  const { mutateAsync: assignCollaborators, isPending: isAssigning } = useAssignCollaborator();
  const navigate = useNavigate();

  const handleSubmit = async (values: RecruitmentCreate, collaborators: TModelId[]) => {
    const { id } = await createRecruitment(values);
    await assignCollaborators({ recruitmentId: id, collaborators });
    toast.success(t('toast.createdSuccessfully', { model: t('Recruitment') }));
    navigate('/recruitments');
  };

  return (
    <Form
      recruitmentId={undefined}
      title={title}
      isSubmitting={isCreating || isAssigning}
      onSubmit={handleSubmit}
      initialValues={{ title: '', description: '', advertisement: '' }}
    />
  );
}
