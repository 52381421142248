import React from 'react';
import { motion } from 'framer-motion';
import Logo from '@/shared/icons/Logo';

export default function Splash() {
  return (
    <motion.div
      className="container flex min-h-screen items-center justify-center"
      initial={{ y: -200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
    >
      <Logo width={300} />
    </motion.div>
  );
}
