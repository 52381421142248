import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';

async function resendActivation(email: string) {
  await api.api.resendActivationEmail(email);
}

export default function useResendActivation() {
  return useMutation({ mutationFn: resendActivation });
}
