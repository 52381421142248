/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { UsersIcon } from '@heroicons/react/24/solid';
import { Button } from '@/shared/form';
import useApplicantsState from '@/states/applicantsState';
import DeleteSelectedApplicants from './DeleteSelectedApplicants';
import RejectSelectedApplicants from './RejectSelectedApplicants';

export default function BulkActionsContainer() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { applicantsId, clearApplicantsId } = useApplicantsState((state) => ({
    applicantsId: state.applicantsId,
    setApplicantsId: state.setApplicantsId,
    clearApplicantsId: state.clearApplicantsId,
  }));

  const handleCompareSelect = () => {
    if (applicantsId.length > 10) toast.warning('You could custom compare maximum 10 Applicants');
    else if (applicantsId.length < 2)
      toast.warning('Please select at least 2 applicants to compare them.');
    else navigate('compare');
  };

  if (applicantsId.length < 1) return null;

  return (
    <div className="flex items-center justify-between rounded-t-box bg-base-300 p-4">
      <div className="dropdown">
        <div tabIndex={0} role="button" className="btn btn-outline btn-sm m-1">
          <EllipsisVerticalIcon className="h-6 w-6" /> {t('Bulk Actions')}
        </div>
        <ul
          tabIndex={0}
          className="menu dropdown-content z-[1] w-60 rounded-box bg-base-100 p-2 font-medium shadow"
        >
          <li>
            <DeleteSelectedApplicants />
          </li>
          <li>
            <RejectSelectedApplicants />
          </li>
          <li>
            <Button onClick={handleCompareSelect} className="text-secondary">
              <UsersIcon className="h-5 w-5" />
              {t('Compare Selected')}
            </Button>
          </li>
        </ul>
      </div>
      <div className="space-x-4">
        <Button
          onClick={clearApplicantsId}
          className="btn btn-link font-medium text-error no-underline"
        >
          {t('Deselect All')}
        </Button>
      </div>
    </div>
  );
}
