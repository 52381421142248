import React, { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { UseQueryResult } from '@tanstack/react-query';
import { Applicant, ApplicantType, PageApplicant, QuestionSet } from '@/generated';
import useGetApplicants from '@/services/applicants';
import useGetQuestionset from '@/services/questionsets/show';
import useApplicantsState from '@/states/applicantsState';
import useSettingsState from '@/states/settingsState';

export type TIndexLayoutContext = {
  queryResult: UseQueryResult<PageApplicant, Error>;
  topApplicants: Applicant[];
  questionset: QuestionSet;
  revealEmail: boolean;
  setRevealEmail: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function IndexLayout() {
  const isTestingMode = useSettingsState((state) => state.applicants.isTestingMode);
  const { questionsetId } = useParams<'questionsetId'>();
  const [revealEmail, setRevealEmail] = useState(false);
  const params = useSettingsState((state) => state.applicants.params);
  const { data: questionset } = useGetQuestionset(questionsetId!);
  const queryResult = useGetApplicants({
    ...params,
    revealEmails: revealEmail,
    applicantTypes: isTestingMode ? [ApplicantType.FAKE, ApplicantType.TEST] : [ApplicantType.REAL],
    questionSetId: questionsetId!,
  });
  const { compareCount, applicantsId } = useApplicantsState((state) => state);

  const topApplicants =
    applicantsId.length > 0
      ? queryResult.data?.items.filter((applicant) => applicantsId.includes(applicant.id))
      : queryResult.data?.items.sort((a, b) => b.rank! - a.rank!).slice(0, compareCount) || [];

  return (
    <Outlet
      context={
        {
          queryResult,
          topApplicants: topApplicants ?? [],
          questionset,
          revealEmail,
          setRevealEmail,
        } satisfies TIndexLayoutContext
      }
    />
  );
}
