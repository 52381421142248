import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useIsScreenMd } from '@/hooks/useMediaQuery';
import { detailsContext } from '@/pages/questionsets/Details';
import HeadingWithLinks from '@/shared/custom/HeadingWithLinks';
import { cn } from '@/utils';
import AskForLinkedinProfileToggle from './AskForLinkedinProfileToggle';

type HeadingProps = {
  hasPreview: boolean;
  isStatusDraft: boolean;
};

export default function Heading({ isStatusDraft, hasPreview }: HeadingProps) {
  const { questionsetId } = useContext(detailsContext);
  const isScreenMd = useIsScreenMd();
  const { t } = useTranslation();

  return (
    <div className="flex flex-wrap items-center justify-between gap-2">
      <HeadingWithLinks
        links={[
          { text: 'Recruitments', to: '/recruitments' },
          { text: 'Questionset Details', to: '#' },
        ]}
      />
      <div
        className={cn('dropdown', {
          'dropdown-right': !isScreenMd,
          'dropdown-end': isScreenMd,
        })}
      >
        <div tabIndex={0} role="button" className="btn btn-secondary btn-md">
          {t('Actions')}
        </div>
        <ul
          role="menu"
          tabIndex={0}
          className="menu dropdown-content z-[1] w-60 rounded-box border bg-base-100 p-2 text-base shadow-md md:w-72"
        >
          {hasPreview && (
            <li>
              <Link to={`/questionsets/${questionsetId}/preview`}>
                {t('Preview Questionnaire')}
              </Link>
            </li>
          )}
          {isStatusDraft && (
            <>
              {/* <li>
                <Link to={`/questionsets/${questionsetId}/templates`}>{t('Templates')}</Link>
              </li> */}
              <li>
                <Link to={`/questionsets/${questionsetId}/ai`}>
                  {t('Generate Questions by AI')}
                </Link>
              </li>
              <AskForLinkedinProfileToggle />
              <li>
                <Link to={`/questionsets/${questionsetId}/copy`}>{t('Copy Questions')}</Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}
