/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EllipsisVerticalIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { useDialogModal } from '@/hooks';
import useDeleteMessage from '@/services/messages/delete';
import { Button } from '@/shared/form';
import DeleteModal from '@/shared/modals/DeleteModal';
import { TModelId } from '@/types';

type OptionsProps = {
  messageId: TModelId;
  onEditMessage: () => void;
  jobId: TModelId;
};

export default function Options({ messageId, onEditMessage, jobId }: OptionsProps) {
  const { t } = useTranslation();
  const { modalRef, handleCloseModal, handleOpenModal } = useDialogModal();
  const queryClient = useQueryClient();
  const { mutate: deleteMessage, isPending: isDeleting } = useDeleteMessage();

  const handleDelete = () => {
    deleteMessage(messageId, {
      onSuccess: () => {
        handleCloseModal();
        queryClient.invalidateQueries({
          queryKey: ['messages', jobId],
        });
      },
    });
  };

  const options = [
    { name: t('Delete'), Icon: TrashIcon, onClick: handleOpenModal },
    { name: t('Edit'), Icon: PencilSquareIcon, onClick: onEditMessage },
  ];

  return (
    <>
      <DeleteModal
        title={`${t('Delete')} ${t('Message')}`}
        onDelete={handleDelete}
        onClose={handleCloseModal}
        modalRef={modalRef}
        isDeleting={isDeleting}
      />
      <div className="dropdown dropdown-left">
        <div
          aria-label="options"
          tabIndex={0}
          role="button"
          className="btn btn-square btn-ghost btn-sm m-1"
        >
          <EllipsisVerticalIcon strokeWidth={2} className="h-4 w-4" />
        </div>
        <ul
          tabIndex={0}
          className="menu dropdown-content z-10 w-40 rounded-box bg-base-100 p-2 shadow"
        >
          {options.map((option) => (
            <li key={option.name}>
              <Button onClick={option.onClick}>
                <option.Icon className="h-5 w-5" />
                <span>{option.name}</span>
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
