import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GetUsersParams } from '@/generated';
import api from '@/libs/api';

async function getUsers(params: GetUsersParams) {
  const resp = await api.api.getUsers(params);
  return resp.data;
}

export default function useGetUsers(params: GetUsersParams) {
  return useQuery({
    queryKey: ['users', params],
    queryFn: () => getUsers(params),
    placeholderData: keepPreviousData,
  });
}
