import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import commonFields from '@/fixtures/commonFields';
import validations from '@/fixtures/validations';
import { useDialogModal } from '@/hooks';
import useChangePassword from '@/services/users/changePassword';
import { Button, Fields } from '@/shared/form';
import EnterPasswordModal from '@/shared/modals/EnterPasswordModal';
import useAuthState from '@/states/authState';

export default function ChangePassword() {
  const { t } = useTranslation();
  const isTokenFresh = useAuthState((state) => state.isTokenFresh);
  const { modalRef, handleOpenModal, handleCloseModal } = useDialogModal();
  const { mutate: changePassword, isPending } = useChangePassword();

  const validationSchema = Yup.object().shape({
    password: validations.password,
    passwordConfirmation: validations.passwordConfirmation(t),
  });

  const handleChangePassword = (newPassword: string, resetForm: () => void) => {
    handleCloseModal();
    changePassword(
      { newPassword },
      {
        onSuccess: () => {
          resetForm();
          toast.success('Your password has been changes successfully.');
        },
      },
    );
  };

  return (
    <div className="card w-full bg-base-200">
      <div className="card-body gap-4">
        <h2 className="h3">{t('Change your Password')}</h2>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ password: '', passwordConfirmation: '' }}
          onSubmit={(values, { resetForm }) => {
            if (isTokenFresh) handleChangePassword(values.password, resetForm);
            else handleOpenModal();
          }}
        >
          {({ values, resetForm }) => (
            <>
              <EnterPasswordModal
                onCancel={handleCloseModal}
                onSuccess={() => handleChangePassword(values.password, resetForm)}
                modalRef={modalRef}
              />
              <Form className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <Fields fields={commonFields.passwordWithConfirmation} />
                <div className="card-actions">
                  <Button
                    isLoading={isPending}
                    disabled={isPending}
                    type="submit"
                    className="btn btn-primary"
                  >
                    {t('Change')}
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
}
