import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

export interface ITourState {
  isRunning: boolean;
  showAgain: boolean;
}

export interface ITourAction {
  setTour: (values: Partial<ITourState>) => void;
}

const useTourState = createWithEqualityFn<ITourState & ITourAction>(
  (set) => ({
    isRunning: false,
    showAgain: true,
    setTour: (values) => set((state) => ({ ...state, ...values })),
  }),
  shallow,
);

export default useTourState;
