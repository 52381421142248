import React, { Ref } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonActions from '@/shared/custom/ButtonActions';
import DialogModal from './DialogModal';

interface DeleteModalProps {
  title: string;
  modalRef: Ref<HTMLDialogElement>;
  onDelete: () => void;
  onClose: () => void;
  deleteText?: string;
  isDeleting?: boolean;
}

export default function DeleteModal({
  modalRef,
  onClose,
  onDelete,
  isDeleting,
  deleteText,
  title,
}: DeleteModalProps) {
  const { t } = useTranslation();
  const deleteTextTranslated = deleteText ?? t('Delete');

  return (
    <DialogModal parentClassName="w-11/12 max-w-xl" modalRef={modalRef} closeOnClickOutside>
      <h3 className="text-2xl font-bold">{title}</h3>
      <p className="description">
        {t('This action cannot be undone. Are you sure you want to do this?')}
      </p>
      <ButtonActions
        submitProps={{
          className: 'btn-error',
          type: 'button',
          onClick: onDelete,
          text: deleteTextTranslated,
          isLoading: isDeleting,
          disabled: isDeleting,
        }}
        cancelProps={{ onClick: onClose, text: t('Cancel') }}
      />
    </DialogModal>
  );
}
