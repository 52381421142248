import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

export interface TCopyTemplate {
  templateId: TModelId;
  questionsetId: TModelId;
}

async function copyToQuestionset({ questionsetId, templateId }: TCopyTemplate) {
  const resp = await api.api.copyToQuestionSet(templateId, questionsetId);
  return resp.data;
}

export default function useCopyTemplateToJob() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: copyToQuestionset,
    onSuccess: (data) => {
      queryClient.setQueryData(['questionsets', 'details', data.id], data);
    },
  });
}
