import React from 'react';
import { useSearchParams } from 'react-router-dom';

export default function Search() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <input
      value={searchParams.get('searchQuery') ?? ''}
      onChange={(e) =>
        setSearchParams((prev) => {
          prev.set('searchQuery', e.target.value);
          return prev;
        })
      }
      placeholder="Search ..."
      type="search"
      className="input input-bordered max-w-xl"
    />
  );
}
