import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

async function deleteClient(id: TModelId) {
  const resp = await api.api.deleteClient(id);
  return resp.data;
}

export default function useDeleteClient() {
  return useMutation({ mutationFn: deleteClient });
}
