import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '@/libs/api';

async function deleteClientSettings() {
  const resp = await api.api.deleteClientSettings();
  return resp.data;
}

export default function useDeleteClientSettings() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteClientSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clientSettings'] });
    },
  });
}
