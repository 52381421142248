import { useInfiniteQuery } from '@tanstack/react-query';
import config from '@/fixtures/config';
import { GetRecruitmentMessagesParams } from '@/generated';
import api from '@/libs/api';
import { getNextPageParam } from '@/utils';

async function getRecruitmentMessages(data: GetRecruitmentMessagesParams) {
  const resp = await api.api.getRecruitmentMessages(data);
  return resp.data;
}

export default function useGetRecruitmentMessages(params: GetRecruitmentMessagesParams) {
  return useInfiniteQuery({
    queryKey: ['messages', params.recruitmentId],
    queryFn: ({ pageParam }) => getRecruitmentMessages({ ...params, page: pageParam }),
    initialPageParam: params.page,
    refetchInterval: config.messageRefreshIntervalMs,
    getNextPageParam,
  });
}
