import React, { Ref } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { cn } from '@/utils';
import Modal from './Modal';

export interface CenterModalProps {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  modalRef?: Ref<HTMLDivElement>;
  maxWidth?: string;
  onClose?: () => void;
}

export default function CenterModal({
  title,
  children,
  isOpen,
  maxWidth = 'max-w-xl',
  onClose,
  modalRef,
}: CenterModalProps) {
  return (
    <Modal>
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              transition={{ duration: 0.3 }}
              initial={{ opacity: 0, y: -100, scale: 0 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 100, scale: 0 }}
              className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden"
            >
              <div ref={modalRef} className={cn('relative mx-auto my-6 w-full px-4', maxWidth)}>
                <div className="flex w-full flex-col rounded-sm bg-base-100 shadow-xl">
                  <div className="border-colors rounded-t border-b border-solid p-3 md:p-5">
                    <div className="flex items-center justify-between">
                      <h3 className="text-2xl font-bold">{title}</h3>
                      {onClose && (
                        <button type="button" onClick={onClose} aria-label="Close">
                          <XMarkIcon className="h-6 w-6" />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="scroll-none max-h-[84vh] overflow-y-auto p-3 md:p-5">
                    {children}
                  </div>
                </div>
              </div>
            </motion.div>
            <div className="fixed inset-0 z-30 bg-black opacity-50" />
          </>
        )}
      </AnimatePresence>
    </Modal>
  );
}
