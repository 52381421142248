import React from 'react';
import { Link } from 'react-router-dom';

type TLink = {
  text: string;
  to: string;
};

type HeadingWithLinksProps = {
  links: TLink[];
};

export default function HeadingWithLinks({ links }: HeadingWithLinksProps) {
  return (
    <div className="breadcrumbs">
      <ul>
        {links.map((link, i) => (
          <li key={link.to}>
            {i + 1 === links.length ? (
              <h1 className="h1">{link.text}</h1>
            ) : (
              <Link to={link.to}>{link.text}</Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
