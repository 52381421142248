import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';
import useLogout from '@/services/auth/logout';
import Action from './Action';

export default function Logout() {
  const { t } = useTranslation();
  const { mutate: logout } = useLogout();

  return (
    <Action
      tooltip={t('Logout')}
      Icon={ArrowLeftOnRectangleIcon}
      onClick={() => logout()}
      aria-label={t('Logout')}
    />
  );
}
