import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import { Row, createColumnHelper } from '@tanstack/react-table';
import Select from '@/components/applicants/Select';
import Actions from '@/components/applicants/index/Actions';
import BulkActionsContainer from '@/components/applicants/index/BulkActionsContainer';
import Checkbox from '@/components/applicants/index/Checkbox';
import CheckboxHeader from '@/components/applicants/index/CheckboxHeader';
import ExportApplicants from '@/components/applicants/index/ExportApplicants';
import Container from '@/components/resources/index/Container';
import HeadingWithCreateLink from '@/components/resources/index/heading/HeadingWithCreateLink';
import Options from '@/components/resources/index/options';
import Header from '@/components/resources/index/options/sections/Header';
import { applicantFilters } from '@/fixtures/resources/applicants';
import { Applicant, QuestionSetType } from '@/generated';
import { useTable } from '@/hooks';
import { TIndexLayoutContext } from '@/layouts/applicants/IndexLayout';
import Head from '@/shared/custom/Head';
import Pagination from '@/shared/custom/Pagination';
import ActiveFilters from '@/shared/resources/activeFilters';
import ReactTable from '@/shared/views/Table';
import BooleanColumn from '@/shared/views/columns/BooleanColumn';
import DateColumn from '@/shared/views/columns/DateColumn';
import TextCopyColumn from '@/shared/views/columns/TextCopyColumn';
import useApplicantsState from '@/states/applicantsState';
import useSettingsState from '@/states/settingsState';
import { cn } from '@/utils';

const columnHelper = createColumnHelper<Applicant>();

const columns = [
  columnHelper.display({
    id: 'select',
    cell: (info) => <Checkbox id={info.row.original.id} />,
    enableSorting: true,
    enableHiding: false,
    header: () => <CheckboxHeader />,
  }),
  columnHelper.accessor('tag', {
    enableSorting: true,
    enableHiding: false,
    meta: { header: (t) => t('Tag') },
  }),
  columnHelper.accessor('externalId', {
    enableSorting: true,
    enableHiding: true,
    meta: { header: (t) => t('Unique ID') },
  }),
  columnHelper.accessor('email', {
    enableSorting: false,
    enableHiding: false,
    cell: (info) => (
      <TextCopyColumn canCopy={Boolean(info.getValue())} text={info.getValue() ?? '***********'} />
    ),
    meta: { header: (t) => t('Email') },
  }),
  columnHelper.accessor('scoreTotal.isCompleted', {
    enableSorting: false,
    enableHiding: false,
    cell: (info) => <BooleanColumn isChecked={info.getValue()} />,
    meta: { header: () => 'Answered all Questions' },
  }),
  columnHelper.accessor('rejectedTimestamp', {
    enableSorting: false,
    enableHiding: false,
    cell: (info) => (
      <span
        className={cn('badge badge-md font-medium', {
          'badge-error': info.getValue(),
          'badge-secondary': !info.getValue(),
        })}
      >
        {info.getValue() ? 'Rejected' : 'Screening'}
      </span>
    ),
    meta: { header: (t) => t('Status') },
  }),
  columnHelper.accessor('rank', {
    enableSorting: true,
    enableHiding: true,
    meta: { header: (t) => t('Rank') },
  }),
  columnHelper.accessor('scoreTotal.scoreValueNormalized', {
    enableSorting: false,
    enableHiding: true,
    meta: { header: (t) => t('Score') },
  }),
  columnHelper.accessor('id', {
    enableSorting: false,
    enableHiding: true,
    meta: { header: (t) => t('ID') },
  }),
  columnHelper.accessor('completedTimestamp', {
    enableSorting: true,
    enableHiding: true,
    cell: (info) => (info.getValue() ? <DateColumn date={info.getValue()!} /> : '--'),
    meta: { header: (t) => t('Completed Date') },
  }),
  columnHelper.accessor('createdAt', {
    cell: (info) => <DateColumn date={info.getValue()} />,
    enableSorting: true,
    enableHiding: true,
    meta: { header: (t) => t('Created at') },
  }),
  columnHelper.accessor('updatedAt', {
    cell: (info) => <DateColumn date={info.getValue()} />,
    enableSorting: true,
    enableHiding: true,
    meta: { header: (t) => t('Updated at') },
  }),
  columnHelper.display({
    id: 'actions',
    cell: (info) => <Actions info={info} />,
    enableSorting: false,
    enableHiding: false,
  }),
];

const viewLink = (row: Row<Applicant>) => row.original.id;

export default function Index() {
  const { t } = useTranslation();
  const title = t('Applicants');
  const resource = 'applicants';

  const { questionsetId } = useParams<{ questionsetId: 'string' }>();
  const params = useSettingsState((state) => state[resource].params);
  const { queryResult, questionset, revealEmail, setRevealEmail } =
    useOutletContext<TIndexLayoutContext>();
  const { data, isLoading } = queryResult;
  const { visibility, isTestingMode } = useSettingsState((state) => state[resource]);
  const setResourceSettings = useSettingsState((state) => state.setResourceSettings);
  const setApplicantsSettings = useSettingsState((state) => state.setApplicantsSettings);
  const clearApplicantsId = useApplicantsState((state) => state.clearApplicantsId);
  const applicantsId = useApplicantsState((state) => state.applicantsId);

  const table = useTable({
    isLoading,
    data: data?.items,
    visibility,
    columns,
    sorting: { defaultSort: [{ id: params.sortBy, desc: !params.ascending }] },
    onVisibilityChange: (change) => {
      const value = typeof change === 'function' ? change(visibility) : change;
      setResourceSettings(resource, { visibility: value });
    },
  });
  const hasAnyApplicants = (data?.total ?? 0) > 0;
  const hasMoreThanOneApplicants = (data?.total ?? 0) > 1;

  const revealEmailsTooltip = () => {
    if (isTestingMode) return 'You cannot use this feature in testing mode.';
    if (!hasAnyApplicants) return 'There are no applicants';
    return 'You can reveal the applicants emails';
  };

  return (
    <div className="flex justify-between md:space-x-4">
      <Head title={title} description={title} />
      <Container resource={resource}>
        <HeadingWithCreateLink
          badge={isTestingMode ? 'Testing Mode' : undefined}
          canCreate={isTestingMode}
          resource={resource}
          title={title}
          createLink="./create"
        />
        <div className="flex flex-col flex-wrap items-end justify-between gap-4 md:flex-row md:items-center">
          <Select urlQuestionsetId={questionsetId!} />
          <div className="flex flex-wrap gap-2 text-sm md:w-auto md:text-base">
            {questionset.type === QuestionSetType.QUALIFICATION && (
              <div
                data-tip={
                  hasMoreThanOneApplicants ? 'Compare Top Applicants' : 'Not enough Applicants'
                }
                className="tooltip tooltip-left tooltip-secondary"
              >
                <Link
                  onClick={clearApplicantsId}
                  to="compare"
                  className={cn('btn btn-secondary btn-md flex-1', {
                    'btn-disabled': !hasMoreThanOneApplicants,
                  })}
                >
                  {t('Compare Top')}
                </Link>
              </div>
            )}
            <ExportApplicants hasAnyApplicants={hasAnyApplicants} questionsetId={questionsetId!} />
          </div>
        </div>
        <ActiveFilters resource={resource} filters={applicantFilters} />
        <section>
          <BulkActionsContainer />
          <ReactTable selectedIds={applicantsId} viewLink={viewLink} table={table} />
        </section>
        <Pagination resource={resource} total={data?.total} />
      </Container>
      <Options
        bottomChildren={
          <div className="flex items-center justify-between gap-4 pt-4">
            <Header>{t('Testing Mode')}</Header>
            <input
              defaultChecked={isTestingMode}
              onChange={(e) => {
                setApplicantsSettings({ isTestingMode: e.target.checked });
                clearApplicantsId();
              }}
              id="sortType"
              type="checkbox"
              className="toggle toggle-primary"
            />
          </div>
        }
        topChildren={
          <div className="flex items-center justify-between gap-4">
            <Header tooltip={revealEmailsTooltip()}>{t('Reveal Emails')}</Header>
            <input
              disabled={isTestingMode || !hasAnyApplicants}
              defaultChecked={revealEmail}
              onChange={(e) => setRevealEmail(e.target.checked)}
              id="sortType"
              type="checkbox"
              className="toggle toggle-primary"
            />
          </div>
        }
        hasMultipleViews={false}
        resource={resource}
        table={table}
        filters={applicantFilters}
      />
    </div>
  );
}
