import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { SectionCreate } from '@/generated';
import { detailsContext } from '@/pages/questionsets/Details';
import useUpdateSection from '@/services/sections/edit';
import { TModelId } from '@/types';
import Modal from './Modal';

type EditProps = {
  initialValues: SectionCreate;
  sectionId: TModelId;
  isOpen: boolean;
  onClose: () => void;
};

export default function Edit({ initialValues, sectionId, isOpen, onClose }: EditProps) {
  const { t } = useTranslation();
  const { clearQueryCache } = useContext(detailsContext);
  const { mutate: updateSection, isPending } = useUpdateSection();

  const handleSubmit = (values: SectionCreate) => {
    updateSection(
      { ...values, sectionId },
      {
        onSuccess: () => {
          toast.success(t('toast.updatedSuccessfully', { model: t('Sections') }));
          clearQueryCache();
          onClose();
        },
      },
    );
  };

  return (
    <Modal
      modalTitle={t('Edit Section')}
      isOpen={isOpen}
      initialValues={initialValues}
      isSubmitting={isPending}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
}
