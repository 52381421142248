import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FakeIllustration from '@/assets/svg/applicants/create/fake.svg?react';
import { useDialogModal } from '@/hooks';
import useCreateFakeApplicants from '@/services/applicants/fake/create';
import ButtonActions from '@/shared/custom/ButtonActions';
import { Fields } from '@/shared/form';
import DialogModal from '@/shared/modals/DialogModal';
import useAuthState from '@/states/authState';
import { TField, TModelId } from '@/types';
import Option from './Option';

type FakeApplicantsProps = {
  questionsetId: TModelId;
};

const validationSchema = Yup.object().shape({
  count: Yup.number().required().min(1).max(50),
});

export default function FakeApplicants({ questionsetId }: FakeApplicantsProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { modalRef, handleOpenModal, handleCloseModal } = useDialogModal();
  const { mutate: createFakeApplicants, isPending } = useCreateFakeApplicants();
  const canCreateApplicant = useAuthState((state) => state.user?.userAccess.createApplicant);
  const navigate = useNavigate();

  const fields: TField[] = [
    {
      name: 'count',
      label: t('Number of Fake Applicants'),
      isRequired: true,
      type: 'input',
      fieldProps: { type: 'text' },
    },
  ];

  return (
    <>
      <DialogModal parentElement="div" modalRef={modalRef}>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ count: 10 }}
          onSubmit={(values) => {
            createFakeApplicants(
              { nbOfApplicants: values.count, questionSetId: questionsetId },
              {
                onSuccess: () => {
                  handleCloseModal();
                  queryClient.invalidateQueries({ queryKey: ['applicants'] });
                  toast.success(t('toast.createdSuccessfully', { model: t('Fake Applicants') }));
                  navigate('../');
                },
              },
            );
          }}
        >
          <Form className="space-y-4">
            <Fields fields={fields} />
            <ButtonActions
              submitProps={{
                tooltipClassName: 'tooltip-right',
                tooltip: canCreateApplicant
                  ? undefined
                  : t('You have to upgrade your plan to access this feature'),
                disabled: isPending || !canCreateApplicant,
                isLoading: isPending,
              }}
              cancelProps={{ onClick: handleCloseModal }}
            />
          </Form>
        </Formik>
      </DialogModal>
      <Option
        onClick={handleOpenModal}
        heading={t('applicants.create.options.fakeApplicants.heading')}
        description={t('applicants.create.options.fakeApplicants.description')}
        Illustration={FakeIllustration}
      />
    </>
  );
}
