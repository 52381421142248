import React from 'react';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { QuestionSet } from '@/generated';
import useDeleteQuestionset from '@/services/questionsets/delete';
import useGetQuestionset from '@/services/questionsets/show';
import { ButtonActionProps } from '@/shared/custom/ButtonActions';
import { TModelId } from '@/types';

export type TQuestionsetsEditLayout = {
  questionsetId: TModelId;
  cancelProps: ButtonActionProps;
  aiCancelProps: ButtonActionProps;
  questionset: QuestionSet | undefined;
  urlParameters: string;
  isInWizardMode: boolean;
};

export default function EditLayout() {
  const [searchParams] = useSearchParams();
  const isInWizardMode = searchParams.get('mode') === 'wizard';

  const navigate = useNavigate();
  const location = useLocation();
  const { questionsetId } = useParams<'questionsetId'>();
  const urlParameters = location.search;

  const { data: questionset } = useGetQuestionset(questionsetId!);
  const { mutate: deleteQuestionset, isPending: isDeleting } = useDeleteQuestionset();

  const handleCancel = () => {
    if (isInWizardMode) deleteQuestionset(questionsetId!);
    // /Questionsets
    navigate('/recruitments');
  };

  const cancelProps: ButtonActionProps = {
    isLoading: isDeleting,
    disabled: isDeleting,
    onClick: handleCancel,
  };

  const handleNavigateToDetails = () => {
    navigate(`/questionsets/${questionsetId}/details`);
  };

  const aiCancelProps: ButtonActionProps = {
    onClick: handleNavigateToDetails,
  };

  return (
    <Outlet
      context={
        {
          questionset,
          questionsetId: questionsetId!,
          urlParameters,
          cancelProps,
          isInWizardMode,
          aiCancelProps: isInWizardMode ? cancelProps : aiCancelProps,
        } satisfies TQuestionsetsEditLayout
      }
    />
  );
}
