import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CellContext } from '@tanstack/react-table';
import { RecruitmentWithQuestionSet } from '@/generated';
import useAuthState from '@/states/authState';
import { cn } from '@/utils';
import Questionset from './Questionset';

type ActionsProps = {
  info: CellContext<RecruitmentWithQuestionSet, unknown>;
};

export default function Questionsets({ info }: ActionsProps) {
  const { t } = useTranslation();
  const authId = useAuthState((state) => state.user?.id);
  const createdTypesCount = info.row.original.questionSetsShort?.length || 0;
  const isTheOwner = info.row.original.ownerId === authId;
  const canCreate = createdTypesCount < 2;

  return (
    <div className="w-full space-y-2">
      <div className="flex items-center justify-between">
        <p className="description">{t('Questionsets')}: </p>
        {canCreate && (
          <Link
            title={isTheOwner ? undefined : t('You have to be the owner.')}
            className={cn('btn btn-outline btn-primary btn-sm', { 'btn-disabled': !isTheOwner })}
            to={isTheOwner ? `/recruitments/${info.row.original.id}/questionsets/create` : '#'}
          >
            {t('Create')}
          </Link>
        )}
      </div>
      {info.row.original.questionSetsShort?.map((questionset) => (
        <Questionset key={questionset.id} isTheOwner={isTheOwner} {...questionset} />
      ))}
    </div>
  );
}
