import React, { memo } from 'react';
import { Applicant } from '@/generated';
import Question, { IAnswerQuestion } from './Question';

type QuestionsProps = {
  questions: IAnswerQuestion[];
  topApplicants: Applicant[];
};

function Questions({ questions, topApplicants }: QuestionsProps) {
  return (
    <>
      {questions.map((question) => (
        <Question key={question.id} question={question} topApplicants={topApplicants} />
      ))}
    </>
  );
}

export default memo(Questions);
