/* eslint-disable react/no-array-index-key */
import React from 'react';
import Section, { TSection } from './Section';

type SectionsProps = {
  sections: TSection[];
  isLoading: boolean;
};

export default function Sections({ sections, isLoading }: SectionsProps) {
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
      {isLoading &&
        Array.from({ length: 4 }).map((_, i) => <div className="h-64 w-full" key={i} />)}
      {sections.map((section) => (
        <Section key={section.sectionId} {...section} />
      ))}
    </div>
  );
}
