import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { optionsContext } from '../../Options';
import Container from '../Container';
import Header from '../Header';
import CardMode from './CardMode';
import RenderAs from './RenderAs';

type ViewsProps = {
  isTheFirst: boolean;
};

export default function Views({ isTheFirst }: ViewsProps) {
  const { t } = useTranslation();
  const { hasMultipleViews } = useContext(optionsContext);

  return (
    <Container isTheFirst={isTheFirst}>
      {hasMultipleViews && (
        <>
          <Header tooltip="You can view the data on multiple ways">{t('Views')}</Header>
          <RenderAs />
        </>
      )}
      <CardMode />
    </Container>
  );
}
