import { useMutation } from '@tanstack/react-query';
import { CreateQuestionsParams, QuestionCreate } from '@/generated';
import api from '@/libs/api';

export type TQuestionsCreate = CreateQuestionsParams & {
  questions: QuestionCreate[];
};

async function createQuestions({ questions, ...params }: TQuestionsCreate) {
  const resp = await api.api.createQuestions(params, questions);
  return resp.data;
}

export default function useCreateManyQuestions() {
  return useMutation({ mutationFn: createQuestions });
}
