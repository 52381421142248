import React from 'react';
import { useTranslation } from 'react-i18next';
import { TIcon } from '@/types';

type StatProps = {
  Icon: TIcon;
  title: string;
  value: string | number;
  coloredValue?: string | number;
};

export default function Stat({ Icon, title, value, coloredValue }: StatProps) {
  const simpleStat = coloredValue === undefined;
  const { t } = useTranslation();

  return (
    <div className="stat text-center">
      <div className="stat-figure">
        <Icon className="h-8 w-8 text-secondary" />
      </div>
      <div className="stat-title text-left text-secondary">{title}</div>
      <div className="stat-value flex items-center gap-2 text-left">
        {!simpleStat && (
          <>
            <span className="text-primary">{coloredValue}</span>
            <span className="text-base">{t('From')}</span>
          </>
        )}
        {value}
      </div>
    </div>
  );
}
