import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bars3Icon } from '@heroicons/react/24/outline';
import useAsideState from '@/states/asideState';
import Notifications from '../notifications';
import Action from './Action';
import Language from './Language';
import Logout from './Logout';
import ToggleTheme from './ToggleTheme';
import WhatIsNew from './WhatIsNew';

export default function Header() {
  const toggleIsOpen = useAsideState((state) => state.toggleIsOpen);
  const { t } = useTranslation();

  return (
    <header className="bg-base-200 py-4 shadow">
      <div className="flex items-center justify-between px-4 md:px-8">
        <Action
          tooltip="Menu"
          Icon={Bars3Icon}
          aria-label={t('Hamburger menu')}
          onClick={toggleIsOpen}
        />
        <div className="flex items-center">
          <WhatIsNew />
          <Language />
          <ToggleTheme />
          <Notifications />
          <Logout />
        </div>
      </div>
    </header>
  );
}
