/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { ErrorBoundary as ErrorBoundaryLib } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import Error404 from '@/pages/errors/404';
import Error500 from '@/pages/errors/500';
import * as Sentry from '@sentry/react';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

export default function ErrorBoundary({ children }: ErrorBoundaryProps) {
  const navigate = useNavigate();

  return (
    <ErrorBoundaryLib
      fallbackRender={({ error, resetErrorBoundary }) => {
        const handleGoBackHome = () => {
          resetErrorBoundary();
          navigate('/');
        };

        if (error instanceof AxiosError) {
          if ([404, 422].includes(error.response?.status!)) {
            return <Error404 onGoBackHome={handleGoBackHome} />;
          }
        }

        Sentry.captureException(error);
        return <Error500 onTryAgain={() => resetErrorBoundary()} onGoBackHome={handleGoBackHome} />;
      }}
    >
      {children}
    </ErrorBoundaryLib>
  );
}
