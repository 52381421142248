import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';
import useRefreshToken from './refreshToken';

async function rememberDevice() {
  const resp = await api.api.rememberDevice({});
  return resp.data;
}

export default function useRememberDevice() {
  const { mutate: refreshToken } = useRefreshToken();
  return useMutation({
    mutationFn: rememberDevice,
    onSuccess: () => {
      // refresh token to have the current device on devices list
      refreshToken();
    },
  });
}
