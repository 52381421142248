import React from 'react';
import { QuestionChoice, QuestionType } from '@/generated';
import Chart from '@/shared/Chart';
import { Label } from '@/shared/form';
import { TModelId } from '@/types';
import { alphabetsArray, cn } from '@/utils';

type FieldProps = {
  id: TModelId;
  choices: QuestionChoice[];
  type: QuestionType;
  showScores?: boolean;
};

export default function Field({ choices, id, type, showScores }: FieldProps) {
  const inputTypes: Partial<QuestionType>[] = [
    QuestionType.TEXT,
    QuestionType.NUMBER,
    QuestionType.DECIMAL,
  ];

  if (inputTypes.includes(type)) {
    return (
      <input
        id={`question-${id}`}
        type={type === 'TEXT' ? 'text' : 'number'}
        className="input input-bordered w-full"
      />
    );
  }

  return (
    <div className="flex flex-col gap-2">
      {choices.map((choice, i) => (
        <div className="flex items-center justify-between gap-2" key={choice.title}>
          <div className="flex gap-4">
            <input
              name={`question-${id}`}
              defaultChecked={false}
              type={type === QuestionType.SINGLE_CHOICE ? 'radio' : 'checkbox'}
              className={cn({
                'radio-primary radio radio-sm': type === QuestionType.SINGLE_CHOICE,
                'checkbox-primary checkbox checkbox-sm': type === QuestionType.MULTIPLE_CHOICES,
              })}
            />
            <Label label={`${alphabetsArray[i]}) ${choice.title}`} htmlFor={`question-${id}`} />
          </div>
          {showScores && (
            <Chart
              options={{
                labels: ['Score'],
                colors: ['#4f46e5'],
                plotOptions: {
                  radialBar: {
                    startAngle: -45,
                    endAngle: 45,
                    track: {
                      background: '#333',
                      startAngle: -45,
                      endAngle: 45,
                    },
                    dataLabels: {
                      name: { show: false },
                      value: { show: true, fontFamily: 'Poppins' },
                    },
                  },
                },
              }}
              series={[choice.score * 100]}
              type="radialBar"
              width={150}
              height={150}
            />
          )}
        </div>
      ))}
    </div>
  );
}
