import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { compact } from 'lodash';
import useAuthState from '@/states/authState';
import { cn } from '@/utils';

export default function Tabs() {
  const { t } = useTranslation();
  const showInformation = useAuthState((state) => state.user?.userAccess.viewClientInformationPage);
  const screeningsIsRunningOut = useAuthState((state) => state.user?.screeningsIsRunningOut);

  const options = compact([
    { title: t('Settings'), name: 'settings' },
    showInformation && { title: t('Information'), name: 'information' },
    { title: t('License'), name: 'license', hasIndicator: screeningsIsRunningOut },
  ]);

  return (
    <div className="join flex-wrap">
      {options.map((option) => (
        <NavLink
          to={`./${option.name}`}
          key={option.title}
          className={({ isActive }) => cn('btn join-item btn-md', { 'btn-primary': isActive })}
        >
          {option.title}
          {option.hasIndicator && (
            <span className="badge indicator-item badge-primary badge-xs animate-pulse" />
          )}
        </NavLink>
      ))}
    </div>
  );
}
