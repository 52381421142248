import React, { Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ClientSettingName } from '@/generated';
import useGetDefaultClientSettings from '@/services/clientSettings/defaults';
import ButtonActions from '@/shared/custom/ButtonActions';
import Field from '@/shared/form/Field';
import DialogModal from '@/shared/modals/DialogModal';

export type TOnRejectValues = {
  reason: string;
  includeApplicantScore: boolean;
};

const validationSchema = Yup.object().shape({
  reason: Yup.string().min(3).max(2024).nullable(),
});

interface RejectModalProps {
  title: string;
  modalRef: Ref<HTMLDialogElement>;
  onReject: (data: TOnRejectValues) => void;
  onClose: () => void;
  isRejecting?: boolean;
}

export default function RejectModal({
  modalRef,
  onClose,
  title,
  onReject,
  isRejecting,
}: RejectModalProps) {
  const { t } = useTranslation();
  const { data: clientSettings } = useGetDefaultClientSettings();

  return (
    <DialogModal
      parentElement="div"
      parentClassName="w-11/12 max-w-xl"
      modalRef={modalRef}
      closeOnClickOutside
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          reason: clientSettings[ClientSettingName.APPLICANT_REJECT_EMAIL],
          includeApplicantScore: false,
        }}
        onSubmit={(values) => {
          onReject(values);
        }}
      >
        <Form className="space-y-4">
          <h3 className="text-2xl font-bold">{title}</h3>
          <p className="description">
            {t('This action cannot be undone. Are you sure you want to do this?')}
          </p>
          <Field
            isRequired={false}
            label={t('Rejection Reason')}
            tooltip={{ text: 'The reason is gonna be sent to the applicants' }}
            name="reason"
            type="input"
            fieldProps={{ as: 'textarea', maxLimitHelper: 2024, rows: 5 }}
          />
          <Field
            isRequired={false}
            label={t('Include Applicant Score')}
            name="includeApplicantScore"
            type="checkbox"
            fieldProps={{}}
            tooltip={{ text: "When active it's gonna send the applicant scores to them too." }}
          />
          <ButtonActions
            submitProps={{
              className: 'btn-error',
              type: 'submit',
              text: 'Reject',
              isLoading: isRejecting,
              disabled: isRejecting,
            }}
            cancelProps={{ onClick: onClose, text: t('Cancel') }}
          />
        </Form>
      </Formik>
    </DialogModal>
  );
}
