import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Chart from '@/shared/Chart';

export type TSection = {
  data: number[];
  labels: string[];
  sectionTitle: string;
  sectionId: number;
};

export default function Section({ sectionId, sectionTitle, labels, data }: TSection) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="border-colors space-y-2 border p-4 text-center shadow">
      <div className="flex flex-wrap items-center justify-between">
        <h2 className="h2 text-secondary">{sectionTitle}</h2>
        <Link to={sectionId.toString()} className="btn btn-secondary btn-sm space-y-2 px-2 py-1">
          {t('View More')}
        </Link>
      </div>
      <Chart
        options={{
          chart: {
            toolbar: { export: { csv: { headerCategory: t('Tag') } } },
            events: {
              dataPointSelection: (e, chart, config) => {
                const applicantId = config.w.config.labels[config.dataPointIndex];
                navigate(`../${applicantId}`, { relative: 'path' });
              },
            },
          },
          legend: { show: false },
          labels,
          plotOptions: { bar: { distributed: true } },
          dataLabels: { enabled: false },
        }}
        series={[{ data, name: t('Score') }]}
        type="bar"
      />
    </div>
  );
}
