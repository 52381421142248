export const chartColors = [
  '#ea580c',
  '#4f46e5',
  '#e11d48',
  '#0c4a6e',
  '#65a30d',
  '#facc15',
  '#673ab7',
  '#365314',
  '#f44336',
  '#881337',
];

export const heatMapColors = {
  low: '#86efac',
  medium: '#16a34a',
  high: '#14532d',
};

export function createSameColors(color: string, count = 10) {
  return Array.from({ length: count }).map(() => color);
}
