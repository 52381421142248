import React from 'react';
import { cn } from '@/utils';

export type ActionProps = {
  Element: any;
  tooltip: string;
  className: string;
  onClick: () => void;
  disabled?: boolean;
};

export default function Action({ Element, disabled, tooltip, className, onClick }: ActionProps) {
  return (
    <div className="tooltip tooltip-secondary" data-tip={tooltip}>
      <button
        disabled={disabled}
        type="button"
        className="action group"
        onClick={onClick}
        aria-label={tooltip}
      >
        <Element strokeWidth={2} className={cn('h-5 w-5', className)} />
      </button>
    </div>
  );
}
