import React from 'react';
import ReactApexChart, { Props } from 'react-apexcharts';
import { chartColors } from '@/fixtures/chartColors';
import useThemeState from '@/states/themeState';

export default function Chart({ options, ...props }: Props) {
  const theme = useThemeState((state) => state.theme);

  return (
    <ReactApexChart
      options={{
        colors: chartColors,
        ...options,
        theme: { mode: theme },
      }}
      {...props}
    />
  );
}
