import React from 'react';
import { t } from 'i18next';
import Illustration from '@/assets/svg/errors/403.svg?react';
import Container from '@/components/errors/Container';
import Description from '@/components/errors/Description';
import Head from '@/shared/custom/Head';
import { Button } from '@/shared/form';

type Error403Props = {
  onGoBackHome: () => void;
};

export default function Error403({ onGoBackHome }: Error403Props) {
  return (
    <Container>
      <Head title="Page Forbidden" description="Page Forbidden" />
      <Illustration className="md:w-1/3" />
      <h1 className="h1">{t("Uh oh. You don't have enough permission")}</h1>
      <Description>{t('Head to our homepage that you have the permission to visit.')}</Description>
      <Button onClick={onGoBackHome} className="btn btn-primary btn-md">
        {t('Go back Home')}
      </Button>
    </Container>
  );
}
