import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { components } from 'react-select';
import { BookmarkIcon } from '@heroicons/react/24/solid';
import { defaultParams } from '@/fixtures';
import config from '@/fixtures/config';
import { Recruitment } from '@/generated';
import { ISelectOption } from '@/interfaces';
import useGetRecruitments from '@/services/recruitments';
import useCreateOrUpdateSetting from '@/services/settings/createOrUpdate';
import ReactSelect from '@/shared/form/Fields/ReactSelect';
import useApplicantsState from '@/states/applicantsState';
import useAuthState from '@/states/authState';
import useSettingsState from '@/states/settingsState';
import { TModelId } from '@/types';

type TOptionType = 'owner' | 'collaborator' | 'team';

type SelectProps = {
  urlQuestionsetId?: TModelId;
};

function CustomOption({ children, data, ...props }: any) {
  const { t } = useTranslation();
  const type = data.type as TOptionType;

  const badgeText = {
    owner: t('Owner'),
    collaborator: t('Collaborator'),
    team: t('Team'),
  }[type];

  return (
    <components.Option {...props}>
      <div className="flex items-center gap-4">
        {data.isPinned && <BookmarkIcon className="size-5 text-primary" />}
        {children}
        {type !== 'owner' && <span className="badge badge-secondary badge-sm">{badgeText}</span>}
      </div>
    </components.Option>
  );
}

export default function Select({ urlQuestionsetId }: SelectProps) {
  const navigate = useNavigate();
  const authId = useAuthState((state) => state.user!.id);
  const setApplicantsCompare = useSettingsState((state) => state.setApplicantsCompare);
  const setCompareApplicantsId = useApplicantsState((state) => state.setApplicantsId);
  const { data: recruitments, isLoading } = useGetRecruitments({
    ...defaultParams,
    size: 50,
  });
  const { setting: bookmarkSetting } = useCreateOrUpdateSetting(
    config.recruitmentBookmarkSettingName,
  );
  const getOptionsType = (recruitment: Recruitment): TOptionType => {
    if (recruitment.ownerId === authId) return 'owner';
    if (recruitment.collaboratorIds?.includes(authId)) return 'collaborator';
    return 'team';
  };
  const bookmarksId = Object.keys(bookmarkSetting?.value ?? {});
  const options = recruitments?.items.flatMap((recruitment) =>
    recruitment.questionSetsShort!.map((questionset) => ({
      type: getOptionsType(recruitment),
      isCollaborator: recruitment.collaboratorIds?.includes(authId),
      isPinned: bookmarksId.find((id) => id === recruitment.id),
      label: `${recruitment.title} - ${questionset.title}`,
      value: questionset.id,
    })),
  );

  return (
    <div className="w-full flex-1">
      <ReactSelect
        components={{ Option: CustomOption }}
        isLoading={isLoading}
        options={options}
        value={options?.find((option) => option.value === urlQuestionsetId)}
        isSearchable
        onChange={(selected) => {
          const typed = selected as ISelectOption<TModelId>;
          setCompareApplicantsId([]);
          setApplicantsCompare({
            questionsetId: typed?.value || null,
          });
          if (!selected) navigate('/applicants');
          else navigate(`/questionsets/${typed.value}/applicants`);
        }}
        isClearable
        placeholder="Select a Questionset"
      />
    </div>
  );
}
