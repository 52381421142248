import React from 'react';
import { useTranslation } from 'react-i18next';
import ChangePassword from '@/components/account/ChangePassword';
import OtpSettings from '@/components/account/OtpSettings';
import Tabs from '@/components/account/Tabs';
import UpdateInformation from '@/components/account/UpdateInformation';
import useLogout from '@/services/auth/logout';
import Head from '@/shared/custom/Head';
import { Button } from '@/shared/form';

export default function Account() {
  const { t } = useTranslation();
  const title = t('Account');
  const { mutate: logout } = useLogout();

  return (
    <>
      <Head title={title} description={title} />
      <div className="flex flex-wrap items-center justify-between gap-4">
        <h1 className="h1">{title}</h1>
        <Button onClick={() => logout()} className="btn btn-md">
          {t('Logout')}
        </Button>
      </div>
      <Tabs />
      <UpdateInformation />
      <ChangePassword />
      <OtpSettings />
    </>
  );
}
