import { useMutation } from '@tanstack/react-query';
import { CreateFakeApplicantsParams } from '@/generated';
import api from '@/libs/api';
import useRefreshToken from '@/services/auth/refreshToken';

async function createFake(params: CreateFakeApplicantsParams) {
  const resp = await api.api.createFakeApplicants(params);
  return resp.data;
}

export default function useCreateFakeApplicants() {
  const { mutate: refreshUser } = useRefreshToken();

  return useMutation({
    mutationFn: createFake,
    onSuccess: () => {
      refreshUser();
    },
  });
}
