import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonActions from '@/shared/custom/ButtonActions';
import DialogModal, { DialogModalProps } from '@/shared/modals/DialogModal';
import { TModelId } from '@/types';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Card, { TSortItem } from './Card';

type SortModalProps = Pick<DialogModalProps, 'modalRef'> & {
  sortableItems: TSortItem[];
  onCancel: () => void;
  onSort: (values: TModelId[]) => void;
  isSorting: boolean;
};

export default function SortModal({
  sortableItems,
  onCancel,
  onSort,
  isSorting,
  ...props
}: SortModalProps) {
  const { t } = useTranslation();
  const [items, setItems] = useState<TSortItem[]>(sortableItems);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    setItems(sortableItems);
  }, [sortableItems]);

  const handleDragEnd = ({ active, over }: any) => {
    if (active.id !== over.id) {
      const oldIndex = items.findIndex((value) => value.id === active.id);
      const newIndex = items.findIndex((value) => value.id === over.id);

      setItems(arrayMove(items, oldIndex, newIndex));
    }
  };

  const handleSort = () => {
    onSort(items.map(({ id }) => id));
  };

  return (
    <DialogModal {...props}>
      <h3 className="h3">{t('Sort Items')}</h3>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={items.map(({ id }) => id)} strategy={verticalListSortingStrategy}>
          {items.map((data) => (
            <Card key={data.id} {...data} />
          ))}
        </SortableContext>
      </DndContext>
      <ButtonActions
        submitProps={{ text: t('Sort'), onClick: handleSort, isLoading: isSorting }}
        cancelProps={{ onClick: onCancel }}
      />
    </DialogModal>
  );
}
