import React from 'react';
import { TField } from '@/types';
import Field from './Field';

type FieldsProps = {
  fields: TField[];
};

export default function Fields({ fields }: FieldsProps) {
  return (
    <>
      {fields.map((field) => (
        <Field {...field} key={field.name} />
      ))}
    </>
  );
}
