import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import asideLinks from '@/fixtures/asideLinks';
import useAsideState from '@/states/asideState';
import useAuthState from '@/states/authState';
import { cn } from '@/utils';

export default function Minimized() {
  const isMaximized = useAsideState((state) => state.isMaximized);
  const authUser = useAuthState((state) => state.user!);
  const { t } = useTranslation();

  if (isMaximized) return null;

  return asideLinks
    .flatMap((parent) => parent.links)
    .filter((link) => {
      if (!link.showOnMinimized) return false;
      if (!link.canRender) return true;
      return link.canRender(authUser);
    })
    .map(({ to, Icon, translation }) => {
      const text = translation(t);

      return (
        <li key={to}>
          <NavLink
            to={to}
            end={to === '/'}
            className={({ isActive }) =>
              cn('btn btn-md btn-block justify-start space-x-4 font-medium', {
                'btn-primary': isActive,
                'btn-ghost': !isActive,
              })
            }
          >
            <Icon className="h-6 w-6" strokeWidth={2} />
            <span className={cn({ 'sr-only': !isMaximized })}>{text}</span>
          </NavLink>
        </li>
      );
    });
}
