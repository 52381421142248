import React from 'react';
import Maximized from './Maximized';
import Minimized from './Minimized';

export default function Links() {
  return (
    <ul className="flex flex-col items-center space-y-4 px-4">
      <Minimized />
      <Maximized />
    </ul>
  );
}
