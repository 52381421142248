import { useMutation } from '@tanstack/react-query';
import { AuthForm } from '@/generated';
import api from '@/libs/api';
import { IAuthState } from '@/states/authState';

async function login(props: AuthForm): Promise<IAuthState> {
  const { data } = await api.api.loginAccessToken(props);
  return { user: data.user, accessToken: data.access_token, isLoggedIn: true, isTokenFresh: true };
}

export default function useLogin() {
  return useMutation({ mutationFn: login });
}
