import React from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import Actions from '@/components/recruitments/index/Actions';
import Questionsets from '@/components/recruitments/index/Questionsets';
import Renderer from '@/components/recruitments/index/Renderer';
import Title from '@/components/recruitments/index/Title';
import { RecruitmentWithQuestionSet } from '@/generated';
import useGetRecruitments from '@/services/recruitments';
import Head from '@/shared/custom/Head';
import DateColumn from '@/shared/views/columns/DateColumn';
import useSettingsState from '@/states/settingsState';

const columnHelper = createColumnHelper<RecruitmentWithQuestionSet>();

const columns = [
  columnHelper.accessor('title', {
    enableSorting: true,
    enableHiding: false,
    cell: (info) => <Title info={info} />,
    meta: { header: (t) => t('Title') },
  }),
  columnHelper.display({
    id: 'questionsets',
    cell: (info) => <Questionsets info={info} />,
    enableSorting: false,
    enableHiding: false,
  }),
  columnHelper.accessor('createdAt', {
    cell: (info) => <DateColumn date={info.getValue()} />,
    enableSorting: true,
    enableHiding: true,
    meta: { header: (t) => t('Created at') },
  }),
  columnHelper.accessor('updatedAt', {
    cell: (info) => <DateColumn date={info.getValue()} />,
    enableSorting: true,
    enableHiding: true,
    meta: { header: (t) => t('Updated at') },
  }),
  columnHelper.display({
    id: 'actions',
    cell: (info) => <Actions info={info} />,
    enableSorting: false,
    enableHiding: false,
  }),
];

export default function Index() {
  const { t } = useTranslation();
  const title = t('Recruitments');
  const includeArchived = useSettingsState((state) => state.recruitments.includeArchived);
  const params = useSettingsState((state) => state.recruitments.params);
  const { data, isLoading } = useGetRecruitments({
    ...params,
    includeArchived,
  });

  return (
    <>
      <Head title={title} description={title} />
      <Renderer data={data} isLoading={isLoading} title={title} columns={columns} />
    </>
  );
}
