import React from 'react';
import { useIsScreenMd } from '@/hooks/useMediaQuery';
import CenterModal from './CenterModal';
import FullModal from './FullModal';

type FormModalProps = {
  onClose: () => void;
  children: React.ReactNode;
  title: string;
  isOpen: boolean;
  maxWidth?: string;
};

export default function FormModal({ children, ...props }: FormModalProps) {
  const isScreenMd = useIsScreenMd();

  return isScreenMd ? (
    <CenterModal {...props}>{children}</CenterModal>
  ) : (
    <FullModal {...props}>{children}</FullModal>
  );
}
