import * as Yup from 'yup';
import { QuestionSummary, QuestionType } from '@/generated';
import { TField, TModelId, TQuestionsAnswers } from '@/types';

export function isQuestionTypeNumber(type: QuestionType) {
  const acceptableFields: QuestionType[] = [QuestionType.NUMBER, QuestionType.DECIMAL];
  return acceptableFields.includes(type);
}

export function isQuestionTypeChoice(type: QuestionType) {
  const acceptableFields: QuestionType[] = [
    QuestionType.SINGLE_CHOICE,
    QuestionType.MULTIPLE_CHOICES,
  ];
  return acceptableFields.includes(type);
}

function handleInputType(type: QuestionType) {
  return isQuestionTypeNumber(type) ? 'number' : 'text';
}

function handleChoiceType(type: QuestionType) {
  return type === QuestionType.SINGLE_CHOICE ? 'radio' : 'checkbox';
}

export function questionsToFields(questions: QuestionSummary[], startIndex = 0): TField[] {
  return questions.map(({ title, id, description, type, choices }, i) => ({
    isRequired: true,
    label: `${i + 1 + startIndex}- ${title}`,
    name: id.toString(),
    tooltip: { text: description ?? undefined },
    type: (isQuestionTypeChoice(type) ? 'choice' : 'input') as any,
    fieldProps: {
      type: isQuestionTypeChoice(type) ? handleChoiceType(type) : handleInputType(type),
      options: choices?.map((choice) => ({ label: choice.title, value: choice.title })),
    },
  }));
}

export function questionsToValidationSchema(questions: QuestionSummary[]) {
  return Yup.object().shape(
    questions.reduce(
      (prev, curr) => {
        let validationRule: any;
        switch (curr.type) {
          case QuestionType.NUMBER:
          case QuestionType.DECIMAL:
            validationRule = Yup.number()
              .required()
              .min(curr.properties!.minValue as number)
              .max(curr.properties!.maxValue as number);
            break;
          case QuestionType.SINGLE_CHOICE:
            validationRule = Yup.string().required();
            break;
          case QuestionType.MULTIPLE_CHOICES:
            validationRule = Yup.array().of(Yup.string().required()).required();
            break;
          default:
            validationRule = Yup.string().required();
            break;
        }
        prev[curr.id] = validationRule;
        return prev;
      },
      {} as Record<TModelId, any>,
    ),
  );
}

export function questionsToInitialValues(questions: QuestionSummary[]) {
  return questions.reduce((prev, curr) => {
    prev[curr.id] = '';
    return prev;
  }, {} as TQuestionsAnswers);
}
