import React from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { ISelectOption } from '@/interfaces';
import { TField } from '@/types';
import { cn } from '@/utils';
import ErrorMessage from './ErrorMessage';
import Checkbox from './Fields/Checkbox';
import Choice from './Fields/Choice';
import Input from './Fields/Input';
import ReactSelect from './Fields/ReactSelect';
import Select from './Fields/Select';
import Label from './Label';

export default function Field({
  name,
  label,
  tooltip,
  isRequired,
  type,
  bottomLabel,
  fieldProps,
  children,
}: TField) {
  const { errors, touched, setValues } = useFormikContext<{
    [key: string]: string;
  }>();

  const hasError: boolean = (get(touched, name) && get(errors, name) !== undefined) || false;

  return (
    <div
      className={cn('relative flex w-full flex-col gap-4', {
        'flex-row-reverse items-center': type === 'checkbox',
      })}
    >
      <Label tooltip={tooltip} isRequired={isRequired} htmlFor={name} label={label} />
      <div
        className={cn('flex gap-2', {
          'flex-col': type === 'choice',
        })}
      >
        {type === 'checkbox' && (
          <Checkbox {...fieldProps} name={name} id={name} hasError={hasError} />
        )}
        {type === 'input' && <Input {...fieldProps} name={name} id={name} hasError={hasError} />}
        {type === 'reactSelect' && (
          <ReactSelect
            {...fieldProps}
            onChange={(selected) => {
              const { value } = selected as ISelectOption<string>;
              setValues((prev) => ({ ...prev, [name]: value }));
            }}
            name={name}
            id={name}
          />
        )}
        {type === 'select' && <Select {...fieldProps} name={name} id={name} hasError={hasError} />}
        {type === 'choice' && <Choice {...fieldProps} name={name} id={name} hasError={hasError} />}
        {children}
      </div>
      {bottomLabel && (
        <div className="label font-medium">
          {bottomLabel.left && <span className="label-text-alt">{bottomLabel.left}</span>}
          {bottomLabel.right && <span className="label-text-alt">{bottomLabel.right}</span>}
        </div>
      )}
      <ErrorMessage name={name} />
    </div>
  );
}
