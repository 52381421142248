import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { ValidateOtpParams } from '@/generated';
import api from '@/libs/api';
import useAuthState from '@/states/authState';
import { isAxiosError } from '@/utils';

async function validateOtp(params: ValidateOtpParams) {
  const resp = await api.api.validateOtp(params);
  return resp.data;
}

export default function useValidateOtp() {
  const user = useAuthState((state) => state.user!);
  const setAuth = useAuthState((state) => state.setAuth);

  return useMutation({
    mutationFn: validateOtp,
    onError: (e) => {
      if (isAxiosError(e, 400)) {
        toast.error('OTP is invalid.');
      }
    },
    onSuccess: () => {
      toast.success("You've successfully activated your OTP.");
      setAuth({ user: { ...user, otpEnabled: true } });
    },
  });
}
