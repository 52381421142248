import { MutateOptions } from '@tanstack/react-query';
import { Setting } from '@/generated';
import useAuthState from '@/states/authState';
import useCreateResourceSetting from './create';
import useUpdateSetting from './update';

export default function useCreateOrUpdateSetting(settingName: string) {
  const { mutate: createResourceSetting, isPending: isCreating } = useCreateResourceSetting();
  const { mutate: updateSetting, isPending: isUpdating } = useUpdateSetting();
  const settings = useAuthState((state) => state.user?.settings);

  const setting = settings?.find(({ name }) => name === settingName);

  return {
    isLoading: isCreating || isUpdating,
    setting,
    mutate: (value: Record<string, any>, options?: MutateOptions<Setting, any, any, any>) =>
      setting
        ? updateSetting([setting.id, { name: settingName, value }], options)
        : createResourceSetting({ name: settingName, value }, options),
  } as const;
}
