import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateQuestionSetDeadlineParams } from '@/generated';
import api from '@/libs/api';

async function editDeadline(params: UpdateQuestionSetDeadlineParams) {
  const resp = await api.api.updateQuestionSetDeadline(params);
  return resp.data;
}

export default function useEditDeadline() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editDeadline,
    onSuccess: (questionset) => {
      queryClient.setQueryData(['questionsets', questionset.id], questionset);
      queryClient.invalidateQueries({ queryKey: ['recruitments'] });
    },
  });
}
