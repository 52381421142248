import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline';
import Container from '../Container';

export default function SelectRecruitmentMessage() {
  const { t } = useTranslation();

  return (
    <Container className="hidden flex-col items-center justify-center space-y-4 p-4 text-center md:flex">
      <ChatBubbleLeftEllipsisIcon className="text-primary" width={80} />
      <h1 className="h2 max-w-lg">{t('Choose a Recruitment to see the Messages')}</h1>
    </Container>
  );
}
