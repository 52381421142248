import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  QuestionMarkCircleIcon,
  RectangleStackIcon,
  Square3Stack3DIcon,
} from '@heroicons/react/24/solid';
import { Form, Formik } from 'formik';
import chunk from 'lodash/chunk';
import { QuestionSummary, Questionnaire } from '@/generated';
import { Fields } from '@/shared/form';
import { TModelId } from '@/types';
import {
  questionsToFields,
  questionsToInitialValues,
  questionsToValidationSchema,
} from '@/utils/preview';
import Actions from './Actions';
import Stat from './Stat';
import Sections from './sections';

type QuestionsWizardProps = {
  onSubmit: (values: Record<string, any>) => void;
  onCancel: () => void;
  data: Questionnaire;
  isSubmitDisabled?: boolean;
};

type TPage = {
  questions: QuestionSummary[];
  sectionId: TModelId;
};

export default function QuestionsWizard({
  data,
  onCancel,
  onSubmit,
  isSubmitDisabled,
}: QuestionsWizardProps) {
  const [activePageIndex, setActivePageIndex] = useState<number>(0);
  const { t } = useTranslation();

  const pages = data
    .sections!.sort((a, b) => a.position! - b.position!)
    .reduce((prev, curr) => {
      const sectionQuestions = data
        .questions!.filter((question) => question.sectionId === curr.id)
        .sort((a, b) => {
          const indexA = curr.questionIds!.indexOf(a.id);
          const indexB = curr.questionIds!.indexOf(b.id);
          return indexA - indexB;
        });
      chunk(sectionQuestions, curr.nbQuestionsPerPage ?? sectionQuestions.length + 1).forEach(
        (questions) => prev.push({ questions, sectionId: curr.id }),
      );
      return prev;
    }, [] as TPage[]);

  const questionInitialValues = questionsToInitialValues(data?.questions ?? []);

  const activePage = pages[activePageIndex];

  return (
    <Formik
      validationSchema={questionsToValidationSchema(data?.questions ?? [])}
      initialValues={questionInitialValues}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <div className="flex flex-col gap-4 rounded-lg bg-base-200 p-4 lg:mx-auto lg:w-[80%]">
          <Sections
            setSectionId={(sectionId) => {
              setActivePageIndex(pages.findIndex((page) => page.sectionId === sectionId)!);
            }}
            activeSectionId={activePage.sectionId}
            sections={data?.sections!}
          />
          <Form className="border-colors space-y-4 border-t py-4">
            <h2 className="h2">
              {t('Page')} {activePageIndex + 1}
            </h2>
            <Fields fields={questionsToFields(activePage.questions)} />
            <Actions
              onCancel={onCancel}
              activePageIndex={activePageIndex}
              totalPages={pages.length}
              setActivePageIndex={setActivePageIndex}
              isSubmitDisabled={isSubmitDisabled}
            />
            <hr className="border-colors" />
            <div className="border-colors stats flex w-full flex-col items-center justify-center overflow-hidden shadow md:flex-row">
              <Stat
                title={t('Answered Questions')}
                value={data?.nbQuestions ?? 0}
                coloredValue={Object.values(values).filter((value) => Boolean(value)).length}
                Icon={QuestionMarkCircleIcon}
              />
              <Stat
                coloredValue={activePageIndex + 1}
                title={t('Page')}
                value={pages.length}
                Icon={Square3Stack3DIcon}
              />
              <Stat
                coloredValue={
                  data.sections!.findIndex((section) => section.id === activePage.sectionId) + 1
                }
                title={t('Section')}
                value={data?.nbOfSections ?? 0}
                Icon={RectangleStackIcon}
              />
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}
