import React, { forwardRef } from 'react';
import { BeaconRenderProps } from 'react-joyride';

interface BeaconProps extends BeaconRenderProps {}

const Beacon = forwardRef<HTMLButtonElement, BeaconProps>((props, ref) => (
  <button
    {...props}
    ref={ref}
    type="button"
    className="animate-scale rounded-full border-primary bg-primary/40 p-3"
  >
    <div className="animate-pulse rounded-full bg-primary p-2" />
  </button>
));

export default Beacon;
