import { useMutation } from '@tanstack/react-query';
import { ExportFileType, ExportQuestionSetParams } from '@/generated';
import api from '@/libs/api';
import { download } from '@/utils';

const extensions: Record<ExportFileType, string> = {
  [ExportFileType.CSV]: 'csv',
  [ExportFileType.PDF]: 'pdf',
  [ExportFileType.EXCEL]: 'xlsx',
};

async function exportQuestionset(data: ExportQuestionSetParams) {
  const resp = await api.api.exportQuestionSet(data, { format: 'blob' });
  return resp.data;
}

export default function useExportQuestionset() {
  return useMutation({
    mutationFn: exportQuestionset,
    onSuccess: (data, variables) => {
      download(data, `questionset.${extensions[variables.fileType!]}`);
    },
  });
}
