import { ERenderAs } from '@/enums';
import { defaultParams } from '@/fixtures';
import { TResourceSettings } from '@/states/settingsState';

export const defaultResourceSettings: TResourceSettings = {
  visibility: {},
  isOpen: false,
  renderAs: ERenderAs.Table,
  isCardMaximized: true,
  params: defaultParams,
};
