import React from 'react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

type SizeControlProps = {
  isMaximized: boolean;
  setIsMaximized: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SizeControl({ isMaximized, setIsMaximized }: SizeControlProps) {
  const toggleMaximized = () => setIsMaximized((prev) => !prev);
  const animation = { rotate: isMaximized ? 0 : -180 };
  const tooltip = isMaximized ? 'Minimize card' : 'Maximize card';

  return (
    <div data-tip={tooltip} className="tooltip tooltip-secondary">
      <motion.button
        initial={animation}
        animate={animation}
        type="button"
        aria-label={tooltip}
        onClick={toggleMaximized}
        className="action"
      >
        <ChevronUpIcon strokeWidth={2} className="h-5 w-5" />
      </motion.button>
    </div>
  );
}
