import { useQuery } from '@tanstack/react-query';
import { GetApplicantsScoresParams } from '@/generated';
import api from '@/libs/api';

async function getScores(params: GetApplicantsScoresParams) {
  const resp = await api.api.getApplicantsScores(params);
  return resp.data;
}

export default function useGetApplicantsScores(params: GetApplicantsScoresParams) {
  return useQuery({
    queryKey: ['applicants', params, 'scores'],
    queryFn: () => getScores(params),
  });
}
