import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QuestionSetStatus } from '@/generated';
import api from '@/libs/api';
import { TModelId } from '@/types';

type TQuestionsetStatusEdit = {
  questionsetId: TModelId;
  status: QuestionSetStatus;
};

async function editStatus({ questionsetId, status }: TQuestionsetStatusEdit) {
  const resp = await api.api.updateQuestionSetStatus(questionsetId, status);
  return resp.data;
}

export default function useEditStatusJob() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['questionsets'] });
      queryClient.invalidateQueries({ queryKey: ['recruitments'] });
    },
  });
}
