import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { chartColors } from '@/fixtures/chartColors';
import { Applicant } from '@/generated';
import { useTable } from '@/hooks';
import Table from '@/shared/views/Table';
import { TModelId } from '@/types';
import Tag from '../Tag';
import Chart from './chart';

const columnHelper = createColumnHelper<Applicant & { questionId: TModelId }>();

const columns = [
  columnHelper.accessor('tag', {
    enableSorting: true,
    cell: (info) => <Tag backgroundColor={chartColors[info.row.index]}>{info.getValue()}</Tag>,
    enableHiding: false,
    meta: { header: (t) => t('Tag') },
  }),
  columnHelper.display({
    id: 'scoreValueNormalized',
    cell: (info) =>
      info.row.original.answersFull![info.row.original.questionId].scoreValueNormalized,
    meta: { header: (t) => t('Score') },
  }),
  columnHelper.display({
    id: 'answerValue',
    cell: (info) => info.row.original.answersFull![info.row.original.questionId].answerValue,
    meta: { header: (t) => t('Answer') },
  }),
];

export interface IAnswerQuestion {
  id: TModelId;
  title: string;
}

type QuestionProps = {
  question: IAnswerQuestion;
  topApplicants: Applicant[];
};

export default function Question({ question, topApplicants }: QuestionProps) {
  const { id, title } = question;
  const table = useTable({
    data: topApplicants.map((applicant) => ({ ...applicant, questionId: id })),
    columns,
  });

  const labels = topApplicants.map((applicant) => applicant.tag!);
  const data = topApplicants.map((applicant) => applicant.answersFull![id].scoreValueNormalized);

  return (
    <div className="border-colors space-y-4 border p-4 shadow">
      <h2 className="text-left text-lg font-medium">{title}</h2>
      <div className="grid grid-cols-1 items-center gap-4 md:grid-cols-2">
        <Chart data={data as number[]} labels={labels} />
        <Table table={table} />
      </div>
    </div>
  );
}
