import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ClientMe, UpdateClientLogoPayload } from '@/generated';
import api from '@/libs/api';

async function editLogo(payload: UpdateClientLogoPayload) {
  const formData = new FormData();
  formData.append('logo', payload.logo);

  const resp = await api.http.instance.postForm<ClientMe>('/api/v1/clients/logo/', formData);
  return resp.data;
}

export default function useEditClientLogo() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editLogo,
    onSuccess: (data) => {
      queryClient.setQueryData(['clients', 'me'], data);
    },
  });
}
