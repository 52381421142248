import React from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Recruitments from '@/components/messages/recruitments';
import { cn } from '@/utils';

export default function IndexLayout() {
  const params = useParams<'recruitmentId'>();
  const url = useLocation().pathname;
  const isViewingRecruitmentMessage = url.includes('/messages/');

  return (
    <div className="flex max-h-[84svh] flex-grow md:space-x-4">
      <Recruitments
        currentRecruitmentId={params.recruitmentId}
        className={cn({ 'hidden md:flex': isViewingRecruitmentMessage })}
      />
      <Outlet />
    </div>
  );
}
