import React from 'react';
import Container from '@/components/resources/index/Container';
import HeadingWithCreateLink from '@/components/resources/index/heading/HeadingWithCreateLink';
import Options from '@/components/resources/index/options';
import { ERenderAs } from '@/enums';
import { useTable } from '@/hooks';
import { IFilter } from '@/interfaces';
import Pagination from '@/shared/custom/Pagination';
import useSettingsState, { TResourceKeys } from '@/states/settingsState';
import { TPage, TTableColumns, TViewLink } from '@/types';
import Cards from '../views/Cards';
import ReactTable from '../views/Table';
import ActiveFilters from './activeFilters/ActiveFilters';

type IndexProps = {
  columns: TTableColumns;
  data: TPage | undefined;
  resource: TResourceKeys;
  title: string;
  viewLink?: TViewLink;
  filters?: IFilter[];
  create?: { canCreate?: boolean; createLink?: string; disabled?: boolean; tooltip?: string };
  isLoading?: boolean;
};

export default function Index({
  resource,
  title,
  columns,
  viewLink,
  data,
  filters,
  create,
  isLoading,
}: IndexProps) {
  const { renderAs, visibility } = useSettingsState((state) => state[resource]);
  const setResourceSettings = useSettingsState((state) => state.setResourceSettings);

  const table = useTable({
    isLoading,
    columns,
    data: data?.items,
    visibility,
    onVisibilityChange: (change) => {
      const value = typeof change === 'function' ? change(visibility) : change;
      setResourceSettings(resource, { visibility: value });
    },
  });

  return (
    <div className="flex justify-between md:space-x-4">
      <Container resource={resource}>
        <div className="flex flex-col">
          <HeadingWithCreateLink
            resource={resource}
            canCreate={create?.canCreate}
            title={title}
            create={create}
            createLink={create?.createLink ?? `/${resource}/create`}
          />
          <ActiveFilters resource={resource} filters={filters} />
        </div>
        {renderAs === ERenderAs.Table ? (
          <ReactTable viewLink={viewLink} table={table} />
        ) : (
          <Cards isLoading={isLoading} viewLink={viewLink} resource={resource} table={table} />
        )}
        <Pagination resource={resource} total={data?.total} />
      </Container>
      <Options resource={resource} table={table} filters={filters} />
    </div>
  );
}
