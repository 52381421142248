import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CopyQuestionSetFromParams } from '@/generated';
import api from '@/libs/api';

async function copyQuestionset(data: CopyQuestionSetFromParams) {
  const resp = await api.api.copyQuestionSetFrom(data);
  return resp.data;
}

export default function useCopyQuestionset() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: copyQuestionset,
    onSuccess: (questionset) => {
      queryClient.setQueryData(['questionsets', questionset.id], questionset);
    },
  });
}
