import { immer } from 'zustand/middleware/immer';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { Setting, User } from '@/generated';

export interface IAuthState {
  user: User | null;
  isLoggedIn: boolean | null;
  accessToken: string | null;
  isTokenFresh: boolean;
}

export const authInitialValues: IAuthState = {
  isLoggedIn: null,
  user: null,
  accessToken: null,
  isTokenFresh: false,
};

export interface IAuthAction {
  setAuth: (values: Partial<IAuthState>) => void;
  setAuthUserIsNotLoggedIn: () => void;
  setAuthUserSetting: (setting: Setting) => void;
  setAuthUserExistingSetting: (newSetting: Setting) => void;
  removeUserDevice: (data: string) => void;
}

const useAuthState = createWithEqualityFn<IAuthState & IAuthAction>()(
  immer((set) => ({
    ...authInitialValues,
    setAuth: (values) => set(() => ({ ...values })),
    setAuthUserIsNotLoggedIn: () => set({ ...authInitialValues, isLoggedIn: false }),
    setAuthUserSetting: (setting) => {
      set((state) => {
        state.user?.settings.push(setting);
      });
    },
    setAuthUserExistingSetting: (newSetting) => {
      set((state) => {
        const settingIndex = state.user?.settings.findIndex(({ id }) => id === newSetting.id);
        if (settingIndex) state.user!.settings[settingIndex] = newSetting;
      });
    },
    removeUserDevice: (data) => {
      set((state) => {
        state.user!.devices = state.user?.devices?.filter((value) => value !== data);
      });
    },
  })),
  shallow,
);

export default useAuthState;
