import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

async function getQuestionset(id: TModelId) {
  const resp = await api.api.getQuestionSet(id);
  return resp.data;
}

export default function useGetQuestionset(id: TModelId) {
  return useSuspenseQuery({ queryKey: ['questionsets', id], queryFn: () => getQuestionset(id) });
}

export function useGetApplicantQuestionset(id?: TModelId) {
  return useQuery({
    queryKey: ['questionsets', id],
    queryFn: () => getQuestionset(id!),
    enabled: Boolean(id),
  });
}
