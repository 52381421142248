import React from 'react';
import { FunnelIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '@/shared/form';
import { ButtonProps } from '@/shared/form/Button';

interface FilterProps extends ButtonProps {
  children: React.ReactNode;
  icon: 'filter' | 'search';
  canRemove: boolean;
}

export default function Filter({ children, canRemove, icon, ...props }: FilterProps) {
  const TypeIcon = {
    filter: FunnelIcon,
    search: MagnifyingGlassIcon,
  }[icon];

  return (
    <Button {...props} className="group btn btn-outline btn-sm gap-2">
      <TypeIcon className="h-4 w-4" />
      {children}
      {canRemove && <XMarkIcon className="hidden h-4 w-4 group-hover:block" />}
    </Button>
  );
}
