import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { TQuestionsetsEditLayout } from '@/layouts/questionsets/EditLayout';
import ButtonActions from '@/shared/custom/ButtonActions';
import WizardButtons from '@/shared/custom/WizardButtons';
import { TModelId } from '@/types';

type ActionsProps = {
  isSubmitting: boolean;
  onSubmit: () => void;
  questionsetId: TModelId;
  isNoOptionSelected: boolean;
};

export default function Actions({
  isSubmitting,
  onSubmit,
  questionsetId,
  isNoOptionSelected,
}: ActionsProps) {
  const { cancelProps, isInWizardMode, urlParameters } =
    useOutletContext<TQuestionsetsEditLayout>();
  const navigate = useNavigate();

  const submitProps = {
    text: 'Submit',
    disabled: isSubmitting || isNoOptionSelected,
    isLoading: isSubmitting,
    onClick: onSubmit,
  };

  if (isInWizardMode) {
    return (
      <WizardButtons
        submitProps={submitProps}
        previousProps={{
          onClick: () => navigate(`/questionsets/${questionsetId}/setup${urlParameters}`),
        }}
        cancelProps={cancelProps}
      />
    );
  }

  return (
    <ButtonActions
      submitProps={submitProps}
      cancelProps={{ onClick: () => navigate(`/questionsets/${questionsetId}/details`) }}
    />
  );
}
