import React from 'react';
import { useTranslation } from 'react-i18next';
import InteractiveHelp from '@/components/home/InteractiveHelp';
import Head from '@/shared/custom/Head';
import useAuthState from '@/states/authState';

export default function Home() {
  const { t } = useTranslation();
  const user = useAuthState((state) => state.user);
  const title = t('Home');

  return (
    <>
      <Head title={title} description={title} />
      <h1 className="h1">
        {t('Welcome')} <span className="text-primary">{user?.firstName}</span>
      </h1>
      <div className="divider" />
      <InteractiveHelp />
    </>
  );
}
