import React, { useEffect } from 'react';
import { ISelectOption } from '@/interfaces';
import useGetCollaborators from '@/services/recruitments/collaborators';
import useGetRecruitmentCollaborators from '@/services/recruitments/roles';
import ReactSelect from '@/shared/form/Fields/ReactSelect';
import { TModelId } from '@/types';

type CollaboratorsProps = {
  recruitmentId: TModelId | undefined;
  collaborators: TModelId[];
  setCollaborators: React.Dispatch<React.SetStateAction<TModelId[]>>;
};

export default function Collaborators({
  recruitmentId,
  collaborators,
  setCollaborators,
}: CollaboratorsProps) {
  const { data, isLoading: isCollaboratorsLoading } = useGetCollaborators();
  const { data: currentCollaborators, isLoading: isCurrentCollaboratorsLoading } =
    useGetRecruitmentCollaborators(recruitmentId);
  const isLoading = isCollaboratorsLoading || isCurrentCollaboratorsLoading;

  useEffect(() => {
    if (!currentCollaborators) return;
    setCollaborators(currentCollaborators.map((user) => user.userId));
  }, [currentCollaborators]);

  const options = data?.items.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
  }));

  return (
    <ReactSelect
      onChange={(selected) => {
        const ids = (selected as ISelectOption<string>[]).map(({ value }) => value);
        setCollaborators(ids);
      }}
      isLoading={isLoading}
      options={options}
      value={options?.filter((option) => collaborators.includes(option.value))}
      isMulti
      isSearchable
      isClearable
      placeholder="Select Collaborators"
    />
  );
}
