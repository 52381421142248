import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import Heading from '@/components/questionsets/details/Heading';
import Questions from '@/components/questionsets/details/questions';
import Sections from '@/components/questionsets/details/sections';
import { QuestionSetDetails, QuestionSetStatus } from '@/generated';
import useGetQuestionsetDetails from '@/services/questionsets/details';
import Head from '@/shared/custom/Head';
import { TModelId } from '@/types';

type TDetailsContext = {
  activeSectionId: TModelId | undefined;
  setActiveSectionId: React.Dispatch<React.SetStateAction<TModelId | undefined>>;
  clearQueryCache: () => void;
  questionsetId: TModelId;
  data: QuestionSetDetails;
};

export const detailsContext = createContext<TDetailsContext>({} as TDetailsContext);

export default function Details() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [activeSectionId, setActiveSectionId] = useState<TModelId | undefined>(undefined);
  const { questionsetId } = useParams<'questionsetId'>();

  const title = t('Questionset Details');
  const { data } = useGetQuestionsetDetails(questionsetId!);
  const hasAnyQuestions = data ? data.nbQuestions > 0 : false;

  const detailsValue: TDetailsContext = useMemo(
    () => ({
      activeSectionId,
      data,
      setActiveSectionId,
      questionsetId: questionsetId as string,
      clearQueryCache: () =>
        queryClient.invalidateQueries({ queryKey: ['questionsets', 'details', questionsetId] }),
    }),
    [activeSectionId, data, questionsetId],
  );

  useEffect(() => {
    if (!data?.sections!.find((section) => section.id === activeSectionId)) {
      setActiveSectionId(data?.sections!.at(0)?.id);
    }
  }, [data]);

  const currentSectionQuestions = useMemo(
    () =>
      data.questions
        ?.filter((question) => question.sectionId === activeSectionId)
        .sort((a, b) => a.number - b.number),
    [activeSectionId, data.questions],
  );

  if (!data) return null;

  return (
    <detailsContext.Provider value={detailsValue}>
      <Head title={title} description={title} />
      <Heading
        isStatusDraft={data.status === QuestionSetStatus.DRAFT}
        hasPreview={hasAnyQuestions}
      />
      <Sections sections={data.sections!} />
      {data.sections!.length > 0 && (
        <Questions sectionId={activeSectionId!} questions={currentSectionQuestions!} />
      )}
    </detailsContext.Provider>
  );
}
