import React from 'react';
import { cn } from '@/utils';

export default function Container({
  children,
  className,
  ...props
}: React.HtmlHTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props} className={cn('flex-1 rounded-btn bg-neutral', className)}>
      {children}
    </div>
  );
}
