import {
  AcademicCapIcon,
  BriefcaseIcon,
  ChatBubbleLeftIcon,
  Cog6ToothIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { EHelpCategory } from '@/enums';
import { TIcon, TTranslation } from '@/types';

export const faqCategories: { text: TTranslation; Icon: TIcon; name: EHelpCategory }[] = [
  {
    text: (t) => t('Recruitments'),
    Icon: BriefcaseIcon,
    name: EHelpCategory.Recruitments,
  },
  { text: (t) => t('Messages'), Icon: ChatBubbleLeftIcon, name: EHelpCategory.Messages },
  { text: (t) => t('Users'), Icon: UsersIcon, name: EHelpCategory.Users },
  { text: (t) => t('Applicants'), Icon: AcademicCapIcon, name: EHelpCategory.Applicants },
  { text: (t) => t('Settings'), Icon: Cog6ToothIcon, name: EHelpCategory.Settings },
  { text: (t) => t('Account'), Icon: UserIcon, name: EHelpCategory.Account },
];
