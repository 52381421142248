import { IParams } from '@/interfaces';
import { resources } from '@/libs/i18n';

export const defaultParams: IParams = {
  size: 10,
  page: 1,
  ascending: false,
  sortBy: 'createdAt',
  searchQuery: '',
};

export const translationResource = resources.en.translation;
