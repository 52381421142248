import { useSuspenseQuery } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

async function getQuestionnaire(questionsetId: TModelId, link: string) {
  const resp = await api.api.getQuestionnaire(questionsetId, link);
  return resp.data;
}

export default function useGetQuestionnaire(questionsetId: TModelId, link: string) {
  return useSuspenseQuery({
    queryKey: ['questionnaires', questionsetId, link],
    queryFn: () => getQuestionnaire(questionsetId, link),
  });
}
