import React, { useEffect } from 'react';
import config from '@/fixtures/config';
import axios from '@/libs/axios';
import useRefreshToken from '@/services/auth/refreshToken';
import useAuthState from '@/states/authState';
import Splash from './Splash';

type StarterProps = {
  children: JSX.Element;
};

export default function Starter({ children }: StarterProps): JSX.Element {
  const { isLoggedIn, accessToken } = useAuthState((state) => ({
    isLoggedIn: state.isLoggedIn,
    accessToken: state.accessToken,
  }));
  const { mutate: getNewAccessToken } = useRefreshToken();

  useEffect(getNewAccessToken, []);

  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  useEffect(() => {
    const interval = setInterval(() => {
      if (isLoggedIn) getNewAccessToken();
    }, config.refreshTokenIntervalMs);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [isLoggedIn]);

  return isLoggedIn === null ? <Splash /> : children;
}
