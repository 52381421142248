import { useMutation } from '@tanstack/react-query';
import { UserUpdateMe } from '@/generated';
import api from '@/libs/api';
import useAuthState from '@/states/authState';

async function editMe(data: UserUpdateMe) {
  const resp = await api.api.updateUserMe(data);
  return resp.data;
}

export default function useEditMe() {
  const setAuth = useAuthState((state) => state.setAuth);

  return useMutation({
    mutationFn: editMe,
    onSuccess: (user) => {
      setAuth({ user });
    },
  });
}
