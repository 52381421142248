import React from 'react';
import { useWizard } from 'react-use-wizard';
import { cn } from '@/utils';

export default function Steps() {
  const { activeStep } = useWizard();
  const steps = ['Authenticator App', 'Scan QR Code', 'Enter One Time Password'];

  return (
    <>
      <ul className="steps steps-vertical w-full md:steps-horizontal">
        {steps.map((step, i) => (
          <li className={cn('step', { 'step-primary': i <= activeStep })}>{step}</li>
        ))}
      </ul>
      <div className="divider" />
    </>
  );
}
