import React from 'react';
import { useTranslation } from 'react-i18next';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default function Empty() {
  const { t } = useTranslation();

  return (
    <div className="border-colors col-span-full flex items-center justify-center border p-20 text-center">
      <div className="flex flex-col items-center justify-center space-y-4">
        <div className="flex h-16 w-16 items-center justify-center rounded-full bg-primary/20 text-primary">
          <XMarkIcon strokeWidth={2} className="h-8 w-8" />
        </div>
        <div className="max-w-xs space-y-1">
          <h2 className="text-xl font-bold tracking-tight">{t("There's no record to be shown")}</h2>
        </div>
      </div>
    </div>
  );
}
