import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useDeleteQuestion from '@/services/questions/delete';
import useModalState from '@/states/modalState';
import { TModelId } from '@/types';
import CustomActions from '../../actions';
import { QUESTIONS_CREATE_MODAL_KEY, questionsContext } from '../Questions';

type ActionsProps = {
  onEdit: () => void;
  questionId: TModelId;
  sectionId: TModelId;
};

export default function Actions({ onEdit, questionId, sectionId }: ActionsProps) {
  const { onSort, canSort } = useContext(questionsContext);
  const { t } = useTranslation();
  const setModalKey = useModalState((state) => state.setModalKey);

  return (
    <CustomActions
      onEdit={onEdit}
      onSort={onSort}
      useDelete={useDeleteQuestion}
      resourceId={questionId}
      resourceName={t('Question')}
      canSort={canSort}
      onCreate={() => setModalKey(QUESTIONS_CREATE_MODAL_KEY(sectionId))}
    />
  );
}
