/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useDialogModal } from '@/hooks';
import { Button } from '@/shared/form';
import DeleteModal from '@/shared/modals/DeleteModal';

type TAction = {
  tooltip?: string;
  state?: 'hidden' | 'disabled' | false;
};

type TDelete = TAction & {
  isDeleting?: boolean;
  onDelete: (ref: RefObject<HTMLDialogElement>) => void;
  modalTitle: string;
};

type TEdit = TAction & {
  link?: string;
};

type ActionsProps = {
  editAction: TEdit;
  deleteAction: TDelete;
  children?: React.ReactNode;
};

export default function Actions({ deleteAction, editAction, children }: ActionsProps) {
  const { modalRef, handleCloseModal, handleOpenModal } = useDialogModal();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      className="flex items-center gap-1"
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <DeleteModal
        title={deleteAction.modalTitle}
        isDeleting={deleteAction.isDeleting}
        modalRef={modalRef}
        onClose={handleCloseModal}
        onDelete={() => deleteAction.onDelete(modalRef)}
      />
      {editAction.state !== 'hidden' && (
        <div data-tip={editAction.tooltip ?? t('Edit')} className="tooltip tooltip-secondary">
          <Button
            onClick={() => editAction.link && navigate(editAction.link)}
            disabled={editAction.state === 'disabled'}
            className="action"
            aria-label={t('Edit')}
          >
            <PencilSquareIcon strokeWidth={2} className="action-icon hover:text-success" />
          </Button>
        </div>
      )}
      {deleteAction.state !== 'hidden' && (
        <div data-tip={deleteAction.tooltip ?? t('Delete')} className="tooltip tooltip-secondary">
          <Button
            disabled={deleteAction.state === 'disabled'}
            onClick={handleOpenModal}
            className="action"
            aria-label={t('Delete')}
          >
            <TrashIcon strokeWidth={2} className="action-icon hover:text-error" />
          </Button>
        </div>
      )}
      {children}
    </div>
  );
}
