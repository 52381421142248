import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '@/libs/api';
import useSettingsState from '@/states/settingsState';
import { TModelId } from '@/types';

async function deleteQuestionset(id: TModelId) {
  const resp = await api.api.deleteQuestionSet(id);
  return resp.data;
}

export default function useDeleteQuestionset() {
  const queryClient = useQueryClient();
  const setApplicantsCompare = useSettingsState((state) => state.setApplicantsCompare);

  return useMutation({
    mutationFn: deleteQuestionset,
    onSuccess: () => {
      setApplicantsCompare({ questionsetId: undefined });
      queryClient.invalidateQueries({ queryKey: ['questionsets'] });
      queryClient.invalidateQueries({ queryKey: ['recruitments'] });
    },
  });
}
