import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import Link from '@/components/auth/Link';
import commonFields from '@/fixtures/commonFields';
import config from '@/fixtures/config';
import validations from '@/fixtures/validations';
import { useDialogModal } from '@/hooks';
import { IErrorDetail } from '@/interfaces';
import useLogin from '@/services/auth/login';
import useRememberDevice from '@/services/auth/rememberDevice';
import { Button, Fields } from '@/shared/form';
import useAuthState from '@/states/authState';
import useTourState from '@/states/tourState';
import { isAxiosError } from '@/utils';
import ActiveAccountModal from './ActiveAccountModal';

const validationSchema = Yup.object().shape({
  email: validations.email,
  password: validations.password,
  otp: Yup.string().nullable().length(6),
});

export default function Form() {
  const { t } = useTranslation();
  const { mutate: login, isPending } = useLogin();
  const setAuth = useAuthState((state) => state.setAuth);
  const { modalRef: activateModalRef, handleOpenModal: handleOpenActivateModal } = useDialogModal();
  const setTour = useTourState((state) => state.setTour);
  const { mutate: rememberDevice } = useRememberDevice();

  return (
    <Formik
      initialValues={{ email: '', password: '', otp: '', hasOtp: false, rememberDevice: false }}
      validationSchema={validationSchema}
      onSubmit={(values, { setErrors, setFieldValue }) => {
        login(
          { password: values.password, username: values.email, client_secret: values.otp },
          {
            onSuccess: (data) => {
              setAuth(data);
              toast.success(t("You've logged in successfully"));
              if (values.rememberDevice) rememberDevice();

              const tourSetting = data.user?.settings.find(({ name }) => name === config.tourKey);

              // if user has set showAgain to false we don't run the tour
              if (tourSetting && tourSetting.value?.showAgain === 1) {
                setTimeout(() => setTour({ isRunning: true }), 1000);
              }
            },
            onError: (e) => {
              if (isAxiosError<IErrorDetail>(e, 400)) {
                if (e.response?.data.detail.toLocaleLowerCase().includes('inactive')) {
                  handleOpenActivateModal();
                  return;
                }
                if (e.response?.data.detail.toLowerCase().includes('invalid otp')) {
                  setErrors({ otp: t('errors.invalid') });
                  return;
                }
                if (e.response?.data.detail.toLowerCase().includes('otp')) {
                  setFieldValue('hasOtp', true);
                  return;
                }
                setErrors({ email: t('These credentials do not match our records.') });
              }
            },
          },
        );
      }}
    >
      {({ values, setFieldValue }) => (
        <>
          <ActiveAccountModal email={values.email} modalRef={activateModalRef} />
          <FormikForm className="w-full space-y-4">
            {values.hasOtp ? (
              <Fields
                fields={[
                  {
                    isRequired: true,
                    type: 'input',
                    name: 'otp',
                    label: t('One Time Password'),
                    fieldProps: {},
                  },
                  {
                    isRequired: false,
                    label: t('Remember this Device'),
                    name: 'rememberDevice',
                    type: 'checkbox',
                    fieldProps: {},
                  },
                ]}
              />
            ) : (
              <Fields fields={[commonFields.email, commonFields.password]} />
            )}
            <Button
              isLoading={isPending}
              disabled={isPending}
              type="submit"
              className="btn btn-primary w-full text-lg font-semibold"
            >
              {t('Login')}
            </Button>
            <div className="flex flex-wrap items-center justify-between gap-4">
              {values.hasOtp ? (
                <Link
                  onClick={() => setFieldValue('hasOtp', false)}
                  to="/login"
                  text="Login to another Account"
                />
              ) : (
                <>
                  <Link to="/register" text={t('New Here ?')} />
                  <Link to="/forgot-password" text={t('Forgot Password ?')} />
                </>
              )}
            </div>
          </FormikForm>
        </>
      )}
    </Formik>
  );
}
