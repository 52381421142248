import React, { useContext } from 'react';
import { useIsScreenMd } from '@/hooks/useMediaQuery';
import FullModal from '@/shared/modals/FullModal';
import { optionsContext } from './Options';

type MobileContainerProps = {
  heading: string;
  children: React.ReactNode;
};

export default function MobileContainer({ children, heading }: MobileContainerProps) {
  const {
    options: { isOpen },
    setOptions,
  } = useContext(optionsContext);
  const isScreenMd = useIsScreenMd();

  const handleClose = () => setOptions({ isOpen: false });

  return (
    <FullModal onClose={handleClose} title={heading} isOpen={!isScreenMd && isOpen}>
      {children}
    </FullModal>
  );
}
