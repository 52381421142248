import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '@/hooks';
import useSettingsState from '@/states/settingsState';
import { optionsContext } from '../../Options';
import Container from '../Container';
import Header from '../Header';

export default function Search() {
  const { t } = useTranslation();
  const { resource } = useContext(optionsContext);
  const setResourceSettings = useSettingsState((state) => state.setResourceSettings);
  const params = useSettingsState((state) => state[resource].params);
  const [search, setSearch] = useDebounce('', (value) => {
    setResourceSettings(resource, { params: { ...params, searchQuery: value } });
  });

  useEffect(() => {
    if (!params.searchQuery) {
      setSearch('');
    }
  }, [params.searchQuery]);

  return (
    <Container id="optionsSearchContainer">
      <Header>{t('Search')}</Header>
      <input
        className="input input-bordered w-full"
        type="search"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
    </Container>
  );
}
