import { useMutation } from '@tanstack/react-query';
import { UserUpdate } from '@/generated';
import api from '@/libs/api';
import { TModelId } from '@/types';

type TUserEdit = UserUpdate & {
  userId: TModelId;
};

async function editUser({ userId, ...data }: TUserEdit) {
  const resp = await api.api.updateUser(userId, data);
  return resp.data;
}

export default function useEditUser() {
  return useMutation({ mutationFn: editUser });
}
