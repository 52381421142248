import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import commonFields from '@/fixtures/commonFields';
import validations from '@/fixtures/validations';
import useResetPassword from '@/services/auth/resetPassword';
import { Button, Fields } from '@/shared/form';
import { isAxiosError } from '@/utils';

export default function Form() {
  const { t } = useTranslation();
  const { mutate: resetPassword, isPending } = useResetPassword();
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  const validationSchema = Yup.object().shape({
    password: validations.password,
    passwordConfirmation: validations.password.oneOf(
      [Yup.ref('password')],
      t('errors.passwordsMatch'),
    ),
  });

  return (
    <Formik
      initialValues={{ password: '', passwordConfirmation: '' }}
      validationSchema={validationSchema}
      onSubmit={({ password }, { setErrors }) => {
        const data = { token: token!, newPassword: password };
        resetPassword(data, {
          onSuccess: () => {
            toast.success(t('Your password has been reset'));
            navigate('/login');
          },
          onError: (e) => {
            if (isAxiosError(e, 422)) {
              setErrors({ password: t('These credentials do not match our records.') });
            }
          },
        });
      }}
    >
      <FormikForm className="w-full space-y-4">
        <Fields fields={commonFields.passwordWithConfirmation} />
        <Button
          disabled={isPending}
          isLoading={isPending}
          type="submit"
          className="btn btn-primary w-full text-lg font-semibold"
        >
          {t('Reset Password')}
        </Button>
      </FormikForm>
    </Formik>
  );
}
