import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoltIcon, KeyIcon } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';
import useGetUsedLicenses from '@/services/licenses/used';
import NumberIncrementAnimation from '@/shared/animations/NumberIncrementAnimation';
import Head from '@/shared/custom/Head';
import { cn, humanReadableFormatter } from '@/utils';

function valuesToAvailablePercent(value: number, total: number): number {
  return +((value / total) * 100).toFixed(0);
}

export default function License() {
  const { data } = useGetUsedLicenses();
  const { t } = useTranslation();

  const charts = [
    {
      title: t('platform.license.monthlyScreenings'),
      usedValue: data.usedScreenings,
      totalValue: data.nbScreenings,
      description:
        data.nbScreenings > 100_000
          ? t('Unlimited')
          : `${humanReadableFormatter.format(data.nbScreenings)} ${t('Per Month')}`,
    },
    {
      title: t('platform.license.monthlyRecruitments'),
      usedValue: data.usedRecruitments,
      totalValue: data.nbRecruitments,
      description:
        data.nbRecruitments > 100_000
          ? t('Unlimited')
          : `${humanReadableFormatter.format(data.nbRecruitments)} ${t('Per Month')}`,
    },
    {
      title: t('platform.license.usedAdmins'),
      usedValue: data.usedAdmins,
      totalValue: data.nbAdmins,
      description: `${humanReadableFormatter.format(data.nbAdmins)} ${t('Admins')}`,
    },
    {
      title: t('platform.license.usedUsers'),
      usedValue: data.usedUsers,
      totalValue: data.nbUsers,
      description: `${humanReadableFormatter.format(data.nbUsers)} ${t('Users')}`,
    },
  ];

  return (
    <section className="flex flex-col gap-4">
      <Head title="License" description="License" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="stats card-bordered stats-vertical grid w-full grid-cols-1 md:stats-horizontal md:grid-cols-2"
      >
        <div className="stat place-items-center">
          <div className="stat-figure text-secondary">
            <KeyIcon className="h-6 w-6" />
          </div>
          <div className="stat-title">{t('Name')}</div>
          <div className="stat-value text-primary">{data.name}</div>
          <div className="stat-desc">{data.description}</div>
        </div>
        <div className="stat place-items-center">
          <div className="stat-figure text-secondary">
            <BoltIcon className="h-6 w-6" />
          </div>
          <div className="stat-title">{t('Tier')}</div>
          <span className="stat-value">
            {data.tier ? <NumberIncrementAnimation to={data.tier} /> : 'NA'}
          </span>
          <div className="stat-desc w-full">
            <div className="progress">
              <motion.div
                className="h-2 rounded-full bg-base-content"
                initial={{ width: 0 }}
                animate={{
                  width: data.tier ? `${data.tier * 10}%` : '0%',
                  transition: { duration: 1 },
                }}
              />
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="w-ful stats card-bordered stats-vertical grid grid-cols-1 md:stats-horizontal md:grid-cols-4"
      >
        {charts.map((chart) => {
          const percent = valuesToAvailablePercent(chart.usedValue, chart.totalValue);

          return (
            <div key={chart.title} className="stat place-items-center space-y-4">
              <div className="stat-title">{chart.title}</div>
              <div className="stat-value text-primary">
                <motion.div
                  initial={{ '--value': 0 } as any}
                  animate={{ '--value': percent, transition: { duration: 2 } } as any}
                  className={cn('radial-progress bg-base-200 text-base font-bold text-secondary', {
                    'text-warning': percent >= 80,
                    'text-error': percent >= 100,
                  })}
                  style={{ '--size': '6rem', '--thickness': '0.5rem' } as any}
                  role="progressbar"
                >
                  <p className="text-base-content">
                    <NumberIncrementAnimation to={percent} />%
                  </p>
                </motion.div>
              </div>
              <div className="stat-desc">{chart.description}</div>
            </div>
          );
        })}
      </motion.div>
    </section>
  );
}
