/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { ButtonProps } from '@/shared/form/Button';
import { TIcon } from '@/types';
import { cn } from '@/utils';
import Action from './Action';

interface DropDownProps extends Pick<ButtonProps, 'aria-label' | 'children'> {
  Icon: TIcon;
  tooltip?: string;
  hasIndicator?: boolean;
  dropdownClassName?: string;
  dropdownContentClassName?: string;
}

export default function DropDown({
  children,
  dropdownClassName,
  dropdownContentClassName,
  tooltip,
  ...props
}: DropDownProps) {
  return (
    <div className={cn('dropdown dropdown-end relative', dropdownClassName)}>
      <Action tooltip={tooltip} {...props} />
      <div
        tabIndex={0}
        className={cn(
          'border-colors menu dropdown-content z-10 w-72 space-y-4 rounded-box border bg-base-100 p-4 text-base shadow md:w-96',
          dropdownContentClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
}
