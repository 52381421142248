import React from 'react';
import { cn } from '@/utils';

type AuthLayoutProps = {
  illustration: React.ReactNode;
  children: React.ReactNode;
  containerClassName?: string;
};

export default function AuthLayout({
  illustration,
  children,
  containerClassName,
}: AuthLayoutProps) {
  return (
    <div className="grid flex-grow grid-cols-1 gap-6 md:grid-cols-2">
      <div className="flex items-center justify-center bg-base-200 px-2 py-2">{illustration}</div>
      <div
        className={cn(
          'container flex max-w-xl flex-col items-center space-y-8 pb-4 md:justify-center',
          containerClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
}
