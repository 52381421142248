import React from 'react';
import { Field } from 'formik';
import { IInput } from '@/interfaces';
import { cn } from '@/utils';

interface CheckboxProps extends IInput {
  hasError?: boolean;
}

export default function Checkbox({ hasError, className = '', ...props }: CheckboxProps) {
  return (
    <Field
      {...props}
      type="checkbox"
      className={cn('checkbox-primary checkbox', { 'checkbox-error': hasError }, className)}
    />
  );
}
