import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { optionsContext } from '../../Options';

export default function CardMode() {
  const { t } = useTranslation();
  const {
    options: { isCardMaximized, renderAs },
    setOptions,
  } = useContext(optionsContext);

  const handleChange = (isMaximized: boolean) => {
    setOptions({ isCardMaximized: isMaximized });
  };

  if (renderAs !== 'card') return null;

  return (
    <div className="flex items-center justify-between gap-4">
      <h3 className="text-lg font-bold">{t('Maximized Cards')}</h3>
      <input
        defaultChecked={isCardMaximized}
        onChange={(e) => handleChange(e.target.checked)}
        id="sortType"
        type="checkbox"
        className="toggle toggle-primary"
      />
    </div>
  );
}
