import { VisibilityState } from '@tanstack/react-table';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { ERenderAs, ESettingType } from '@/enums';
import { defaultParams } from '@/fixtures';
import { ApplicantStatus, UserRecruitmentRoleInput } from '@/generated';
import { IParams } from '@/interfaces';
import { TModelId } from '@/types';
import { defaultResourceSettings } from '@/utils/settings';

export type TResourceSettings = {
  visibility: VisibilityState;
  isOpen: boolean;
  renderAs: ERenderAs;
  isCardMaximized: boolean;
  params: IParams;
};

type TApplicantsSettings = {
  isTestingMode?: boolean;
};

type TRecruitmentsSettings = {
  includeArchived?: boolean;
};

export type TResourceKeys = 'recruitments' | 'questionsets' | 'users' | 'applicants' | 'clients';

export const resourcesDefaultSettings: Record<TResourceKeys, TResourceSettings> & {
  applicants: TApplicantsSettings;
  recruitments: TRecruitmentsSettings;
} = {
  questionsets: { ...defaultResourceSettings, renderAs: ERenderAs.Card },
  recruitments: {
    ...defaultResourceSettings,
    visibility: { updatedAt: false, createdAt: false },
    params: { ...defaultParams, roleAs: UserRecruitmentRoleInput.OWNER },
    renderAs: ERenderAs.Card,
    includeArchived: false,
  },
  users: defaultResourceSettings,
  applicants: {
    ...defaultResourceSettings,
    visibility: { updatedAt: false, createdAt: false, externalId: false, id: false },
    params: {
      ...defaultParams,
      sortBy: 'rank',
      ascending: true,
      applicantStatus: ApplicantStatus.UNDER_SCREENING,
    },
    isTestingMode: false,
  },
  clients: defaultResourceSettings,
};

type TApplicantsCompare = {
  recruitmentId: TModelId | null;
  questionsetId: TModelId | null;
};

export type TSettingsState = {
  type: ESettingType;
  applicantsCompare: TApplicantsCompare;
} & typeof resourcesDefaultSettings;

export interface ISettingsAction {
  resetSettingsToDefault: () => void;
  setSettingsType: (type: ESettingType) => void;
  setApplicantsCompare: (data: Partial<TApplicantsCompare>) => void;
  setResourceSettings: (resource: TResourceKeys, settings: Partial<TResourceSettings>) => void;
  setApplicantsSettings: (
    settings: Partial<TResourceSettings> & Partial<TApplicantsSettings>,
  ) => void;
  setRecruitmentsSettings: (
    settings: Partial<TResourceSettings> & Partial<TRecruitmentsSettings>,
  ) => void;
}

const settingsInitialState: TSettingsState = {
  type: ESettingType.Reset,
  applicantsCompare: { recruitmentId: null, questionsetId: null },
  ...resourcesDefaultSettings,
};

const useSettingsState = createWithEqualityFn<TSettingsState & ISettingsAction>()(
  persist(
    immer((set) => ({
      ...settingsInitialState,
      resetSettingsToDefault: () => set((state) => ({ ...settingsInitialState, type: state.type })),
      setSettingsType: (type) => set((state) => ({ ...state, type })),
      setApplicantsCompare: (data) => {
        set((state) => ({ ...state, applicantsCompare: { ...state.applicants, ...data } }));
      },
      setResourceSettings: (resource, settings) => {
        set((state) => {
          state[resource] = { ...state[resource], ...settings };
        });
      },
      setApplicantsSettings: (settings) => {
        set((state) => {
          state.applicants = { ...state.applicants, ...settings };
        });
      },
      setRecruitmentsSettings: (settings) => {
        set((state) => {
          state.recruitments = { ...state.recruitments, ...settings };
        });
      },
    })),
    { name: 'settings' },
  ),
  shallow,
);

export default useSettingsState;
