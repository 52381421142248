import { useMutation } from '@tanstack/react-query';
import { UserRegister } from '@/generated';
import api from '@/libs/api';

async function register(props: UserRegister) {
  await api.api.registerUser(props);
}

export default function useRegister() {
  return useMutation({ mutationFn: register });
}
