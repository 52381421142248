import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from '@/shared/form';
import Modal from './Modal';

type FullModalProps = {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export default function FullModal({ title, children, isOpen, onClose }: FullModalProps) {
  return (
    <Modal>
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              transition={{ duration: 0.3 }}
              initial={{ opacity: 0, y: '100%' }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: '100%' }}
              className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden"
            >
              <div className="border-colors flex h-full w-full flex-col space-y-4 divide-y overflow-y-auto bg-base-100 p-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-2xl font-bold">{title}</h3>
                  <Button className="btn btn-circle btn-ghost" onClick={onClose} aria-label="Close">
                    <XMarkIcon className="h-6 w-6" />
                  </Button>
                </div>
                <div className="pt-4">{children}</div>
              </div>
            </motion.div>
            <div className="fixed inset-0 z-[25] !mt-0 bg-black opacity-50" />
          </>
        )}
      </AnimatePresence>
    </Modal>
  );
}
