import { useQuery } from '@tanstack/react-query';
import { GetLicensesParams } from '@/generated';
import api from '@/libs/api';

async function getLicenses(params?: GetLicensesParams) {
  const resp = await api.api.getLicenses({ ...params });
  return resp.data;
}

export default function useGetLicenses(params?: GetLicensesParams) {
  return useQuery({
    queryKey: ['licenses'],
    queryFn: () => getLicenses(params),
  });
}
