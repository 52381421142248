import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { CellContext } from '@tanstack/react-table';
import BaseActions from '@/components/resources/index/actions';
import { QuestionSetStatus, QuestionSetType, RecruitmentWithQuestionSet } from '@/generated';
import { useDialogModal } from '@/hooks';
import useDeleteRecruitment from '@/services/recruitments/delete';
import useEditRecruitment from '@/services/recruitments/edit';
import ButtonActions from '@/shared/custom/ButtonActions';
import { Button } from '@/shared/form';
import DialogModal from '@/shared/modals/DialogModal';
import useAuthState from '@/states/authState';
import { conditionedTooltip } from '@/utils';

type ActionsProps = {
  info: CellContext<RecruitmentWithQuestionSet, unknown>;
};

export default function Actions({ info }: ActionsProps) {
  const { mutate: editRecruitment, isPending: isEditingRecruitment } = useEditRecruitment();
  const { t } = useTranslation();
  const authId = useAuthState((state) => state.user!.id);
  const { modalRef, handleOpenModal, handleCloseModal } = useDialogModal();
  const { mutate: deleteClient, isPending: isDeleting } = useDeleteRecruitment();
  const queryClient = useQueryClient();
  const isAllQuestionsetsInScreening =
    info.row.original.nbQuestionSets > 0 &&
    info.row.original.questionSetsShort
      ?.filter((questionset) => questionset.type === QuestionSetType.QUALIFICATION)
      .every((questionset) => questionset.status === QuestionSetStatus.SCREENING);
  const isTheOwner = authId === info.row.original.ownerId;
  const isQuestionsetsInDraft = info.row.original.questionSetsShort?.every(
    (questionset) => questionset.status === QuestionSetStatus.DRAFT,
  );

  const handleArchiveRecruitment = () => {
    editRecruitment(
      { recruitmentId: info.row.original.id, archivedTimestamp: new Date().toISOString() },
      {
        onSuccess: () => {
          toast.success('Recruitment archived successfully.');
        },
      },
    );
  };

  return (
    <>
      <DialogModal closeOnClickOutside modalRef={modalRef}>
        <h3 className="text-2xl font-bold">
          {t('Archive')} <span className="text-primary">{info.row.original.title}</span>
        </h3>
        <p className="description">
          {t('Do you want to archive the questionset? This action cannot be undone.')}
        </p>
        <ButtonActions
          submitProps={{
            disabled: isEditingRecruitment,
            isLoading: isEditingRecruitment,
            text: 'Archive',
            onClick: handleArchiveRecruitment,
          }}
          cancelProps={{ onClick: handleCloseModal }}
        />
      </DialogModal>
      <BaseActions
        editAction={{
          link: `/recruitments/${info.row.original.id}/edit`,
          tooltip: conditionedTooltip([{ condition: !isTheOwner, text: 'Not the Owner' }]),
          state: !isTheOwner && 'disabled',
        }}
        deleteAction={{
          modalTitle: `${t('Delete')} ${info.row.original.title}`,
          isDeleting,
          tooltip: conditionedTooltip([
            { condition: !isTheOwner, text: 'Not the Owner' },
            { condition: !isQuestionsetsInDraft, text: 'Questionsets are Published' },
          ]),
          state: (!isTheOwner || !isQuestionsetsInDraft) && 'disabled',
          onDelete: (deleteModalRef) => {
            deleteClient(info.row.original.id, {
              onSuccess: () => {
                toast.success(t('toast.deletedSuccessfully', { model: t('Recruitment') }));
                deleteModalRef.current?.close();
                queryClient.invalidateQueries({ queryKey: ['recruitments'] });
              },
            });
          },
        }}
      >
        {!info.row.original.archivedTimestamp && isTheOwner && isAllQuestionsetsInScreening && (
          <div data-tip="Archive Recruitment" className="group tooltip tooltip-secondary">
            <Button onClick={handleOpenModal} className="action">
              <ArchiveBoxIcon strokeWidth={2} className="action-icon" />
            </Button>
          </div>
        )}
      </BaseActions>
    </>
  );
}
