import React from 'react';
import { useTranslation } from 'react-i18next';
import Illustration from '@/assets/svg/auth/forgotPassword.svg?react';
import Heading from '@/components/auth/Heading';
import Form from '@/components/auth/forgotPassword/Form';
import AuthLayout from '@/layouts/AuthLayout';
import Head from '@/shared/custom/Head';

export default function ForgotPassword() {
  const { t } = useTranslation();
  const heading = t('Forgot your Password ?');

  return (
    <AuthLayout illustration={<Illustration className="md:h-[500px] md:w-[500px]" />}>
      <Heading heading={heading} />
      <Head
        title={heading}
        description="Forgot your password? You can reset your password here we'll send you the instructions to your email."
      />
      <Form />
    </AuthLayout>
  );
}
