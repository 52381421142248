import React from 'react';
import { useTranslation } from 'react-i18next';

type InfoProps = {
  total: number;
  page: number;
  perPage: number;
};

export default function Info({ total, page, perPage }: InfoProps) {
  const { t } = useTranslation();

  const startsFrom = page * perPage - perPage + 1;
  const pageCount = Math.ceil(total / perPage);
  const isLastPage = page === pageCount;
  const offSet = isLastPage ? total : page * perPage;

  return (
    <p className="text-sm font-medium">
      {t('pagination.showingFrom', { from: startsFrom, to: offSet, total })}
    </p>
  );
}
