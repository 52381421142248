import React, { Fragment } from 'react';
import { QuestionSetStatus } from '@/generated';
import Tag from '@/shared/views/columns/TagColumn';
import { cn } from '@/utils';

type CardProcessProps = {
  status: QuestionSetStatus;
  showTag?: boolean;
};

const statusLength = Object.keys(QuestionSetStatus).length;

export default function CardProcess({ status: cardStatus, showTag = true }: CardProcessProps) {
  return (
    <div className="flex min-w-max flex-wrap gap-2">
      <div className="group mr-2 flex flex-wrap items-center">
        {Object.keys(QuestionSetStatus).map((status, i) => (
          <Fragment key={status}>
            <span
              data-tip={status}
              className={cn('tooltip tooltip-secondary rounded-full border-2', {
                // 'border-none bg-primary': QuestionSetStatus[cardStatus] > QuestionSetStatus[status],
                'h-6 w-6 bg-primary': status === cardStatus,
                'h-4 w-4 border-secondary': status !== cardStatus,
              })}
            />
            {i + 1 !== statusLength && <hr className="w-4 border-secondary" />}
          </Fragment>
        ))}
      </div>
      {showTag && <Tag>{cardStatus}</Tag>}
    </div>
  );
}
