import React, { useContext } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useIsScreenMd } from '@/hooks/useMediaQuery';
import { Button } from '@/shared/form';
import { optionsContext } from './Options';

type DesktopContainerProps = {
  heading: string;
  children: React.ReactNode;
};

export default function DesktopContainer({ children, heading }: DesktopContainerProps) {
  const {
    options: { isOpen },
    setOptions,
  } = useContext(optionsContext);
  const isScreenMd = useIsScreenMd();
  const handleClose = () => setOptions({ isOpen: false });

  if (!isOpen || !isScreenMd) return null;

  return (
    <div className="right border-colors flex-1 space-y-4 divide-y">
      <div className="flex items-center justify-between">
        <h3 className="text-2xl font-bold">{heading}</h3>
        <Button className="btn btn-circle btn-ghost" onClick={handleClose} aria-label="Close">
          <XMarkIcon className="h-6 w-6" />
        </Button>
      </div>
      <div className="pt-4">{children}</div>
    </div>
  );
}
