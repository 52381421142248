import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateQuestionSetParams, QuestionSetCreate } from '@/generated';
import api from '@/libs/api';

export type TQuestionsetCreate = CreateQuestionSetParams & QuestionSetCreate;

async function createQuestionset({ recruitmentId, ...data }: TQuestionsetCreate) {
  const resp = await api.api.createQuestionSet({ recruitmentId }, { ...data, deadline: null });
  return resp.data;
}

export default function useCreateQuestionset() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createQuestionset,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['recruitments'] });
      queryClient.setQueryData(['questionsets', data.id], data);
    },
  });
}
