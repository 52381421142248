/* eslint-disable i18next/no-literal-string */
import React, { useState } from 'react';
import { ACTIONS, CallBackProps, EVENTS, LIFECYCLE, STATUS } from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import config from '@/fixtures/config';
import useCreateOrUpdateSetting from '@/services/settings/createOrUpdate';
import JoyRide, { JoyRideProps } from '@/shared/JoyRide';
import useSettingsState from '@/states/settingsState';
import useTourState from '@/states/tourState';

export default function Tour() {
  const { mutate: createOrUpdateSetting } = useCreateOrUpdateSetting(config.tourKey);
  const { isRunning, showAgain, setTour } = useTourState((state) => state);
  const [state, setState] = useState<Omit<JoyRideProps, 'steps' | 'run'>>({});
  const navigate = useNavigate();
  const setResourceSettings = useSettingsState((s) => s.setResourceSettings);

  const handleSetTour = (run: boolean) => setTour({ isRunning: run });
  const handleOpenOptions = () => setResourceSettings('recruitments', { isOpen: true });

  const handleCallback = ({ index, lifecycle, status, action, type }: CallBackProps) => {
    const prop = {
      0: { route: '/' },
      1: { route: '/recruitments' },
      2: { route: '/recruitments' },
      3: { route: '/recruitments', callback: handleOpenOptions },
      4: { route: '/recruitments' },
      5: { route: '/recruitments' },
      6: { route: '/recruitments' },
      7: { route: '/applicants' },
      8: { route: '/settings' },
    }[index];

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      handleSetTour(false);
      setState({ stepIndex: 0 });
      createOrUpdateSetting({ showAgain });
      return;
    }

    if (prop && lifecycle === LIFECYCLE.READY) {
      navigate(prop.route);
      if (prop.callback) prop.callback();
    }

    if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      if (index === 6) {
        handleSetTour(false);
        if (action === ACTIONS.PREV) navigate('/recruitments');
        setState({ stepIndex: action === ACTIONS.NEXT ? 7 : 5 });
        setTimeout(() => handleSetTour(true), 300);
      }
    }
  };

  return (
    <JoyRide
      {...state}
      run={isRunning}
      continuous
      showSkipButton
      callback={handleCallback}
      disableCloseOnEsc
      scrollToFirstStep
      disableOverlayClose
      steps={[
        {
          disableBeacon: true,
          target: 'main',
          title: 'Home',
          placement: 'center',
          content: (
            <p>
              Welcome to your Dashboard!
              <br />
              Here you will find a comprehensive list of latest the questionsets that you have
              created on our platform.
            </p>
          ),
        },
        {
          disableBeacon: true,
          target: 'main',
          title: 'Recruitments',
          placement: 'center',
          content: (
            <p>
              Here you see The Recruitments.
              <br />
              Here you will find a comprehensive list of recruitments and questionsets you&apos;ve
              created in our system.
            </p>
          ),
        },
        {
          target: '#create',
          title: 'Recruitment Creation',
          content: (
            <p>
              Creating recruitments are important part of system.
              <br />
              you will likely invest a significant amount of time generating relevant questions and
              reviewing applications from prospective candidates.
            </p>
          ),
        },
        {
          disableBeacon: true,
          target: '#toggleOptions',
          title: 'Options',
          content: (
            <p>
              The toggle button is a powerful feature that allows you to customize the display of
              search results according to your specific requirements.
            </p>
          ),
        },
        {
          disableBeacon: true,
          target: '#optionsSortByContainer',
          title: 'Sort By',
          content: (
            <p>
              You can sort results based on specific parameters. It allows to arrange information in
              a way that suit your needs, making it easier to find what you are looking for.
            </p>
          ),
        },
        {
          disableBeacon: true,
          target: '#optionsSearchContainer',
          title: 'Search',
          content: (
            <p>
              With the search feature, you can enter a keyword or phrase related to what you&apos;re
              looking for and get a list of relevant results.
            </p>
          ),
        },
        {
          disableBeacon: true,
          target: '#optionsFiltersContainer',
          title: 'Filters',
          content: (
            <p>
              You can filter results based on specific parameters. It allows to arrange information
              in a way that suit your needs, making it easier to find what you are looking for.
            </p>
          ),
        },
        {
          disableBeacon: true,
          target: 'main',
          title: 'Applicants',
          placement: 'center',
          content: (
            <p>
              The applicant comparison feature allows you to easily compare multiple candidates and
              make an informed decision based on their qualifications, experience, and other
              factors.
            </p>
          ),
        },
        {
          disableBeacon: true,
          target: 'main',
          title: 'Settings',
          placement: 'center',
          content: (
            <p>
              In the settings section, you have the option to save any changes in the settings you
              make either on your device or on the cloud.
            </p>
          ),
        },
      ]}
    />
  );
}
