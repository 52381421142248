/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import isNaN from 'lodash/isNaN';
import * as Yup from 'yup';
import { QuestionChoice, QuestionCreate, QuestionType } from '@/generated';
import ButtonActions from '@/shared/custom/ButtonActions';
import FormModal from '@/shared/modals/FormModal';
import { convertNullToEmptyString } from '@/utils';
import CommonFields from './CommonFields';
// import DecimalOrNumberFields from './DecimalOrNumberFields';
import Templates from './Templates';
import Choices from './choices';

export function calculateQuestionWeightFromSlider(sliderWeight: number) {
  try {
    return { 1: 0.5, 2: 1, 3: 2 }[sliderWeight];
  } catch {
    return 1;
  }
}

export function calculateQuestionWeightFromApi(apiWeight: number) {
  try {
    return { 0.5: 1, 1: 2, 2: 3 }[apiWeight];
  } catch {
    return 2;
  }
}

type CreateProps = {
  modalTitle: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: QuestionCreate) => void;
  initialValues: QuestionCreate;
  isSubmitting: boolean;
};

export default function Modal({
  modalTitle,
  isOpen,
  onClose,
  onSubmit,
  isSubmitting,
  initialValues,
}: CreateProps) {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required().min(3).max(500),
    description: Yup.string().nullable().min(0).max(500),
    type: Yup.string().required(),
    weight: Yup.number().required().min(1).max(3),
    properties: Yup.mixed().when('type', {
      is: (value: QuestionType) => [QuestionType.NUMBER, QuestionType.DECIMAL].includes(value),
      then: Yup.object().shape({
        minValue: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : parseFloat(value)))
          .required(),
        maxValue: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : parseFloat(value)))
          .required(),
        expectedMean: Yup.number()
          .when(['minValue', 'maxValue'], (minValue, maxValue) =>
            Yup.number().required().min(minValue).max(maxValue),
          )
          .required(),
        expectedVar: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : parseFloat(value)))
          .required(),
      }),
      otherwise: undefined,
    }),
    choices: Yup.mixed().when('type', {
      is: (value: QuestionType) => {
        const acceptableFields: QuestionType[] = [
          QuestionType.SINGLE_CHOICE,
          QuestionType.MULTIPLE_CHOICES,
        ];
        return acceptableFields.includes(value);
      },
      then: Yup.array()
        .min(2)
        .required()
        .of(
          Yup.object().shape({
            title: Yup.string().min(1).required(),
            score: Yup.number().min(0).max(1),
          }),
        )
        .test(
          'singleChoice',
          'It should at least have one choice with 100% score.',
          (_, context) => {
            if (context.parent.type !== QuestionType.SINGLE_CHOICE) return true;
            const { choices }: { choices: QuestionChoice[] } = context.parent;
            const hasHighScore = choices.some((choice) => choice.score === 1);
            if (hasHighScore) return true;
            return context.createError({
              path: `choices[${choices.length - 1}].score`,
              message: 'It should at least have one choice with 100% score.',
            });
          },
        )
        .test('multipleChoice', 'the sum of choices should be 1', (_, context) => {
          if (context.parent.type !== QuestionType.MULTIPLE_CHOICES) return true;
          const { choices }: { choices: QuestionChoice[] } = context.parent;
          const sumValue = choices.reduce((acc: number, item) => acc + item.score * 100, 0);
          if (sumValue === 100) return true;
          return context.createError({
            path: `choices[${choices.length - 1}].score`,
            message: 'The sum of scores should be 100%.',
          });
        }),
      otherwise: undefined,
    }),
  });

  return (
    <FormModal maxWidth="max-w-3xl" isOpen={isOpen} onClose={onClose} title={modalTitle}>
      <Formik
        validationSchema={validationSchema}
        initialValues={convertNullToEmptyString(initialValues)}
        onSubmit={onSubmit}
      >
        {({ values }) => {
          const isChoice =
            values.type === QuestionType.SINGLE_CHOICE ||
            values.type === QuestionType.MULTIPLE_CHOICES;

          return (
            <Form className="space-y-4">
              <CommonFields />
              {/* {(values.type === QuestionType.DECIMAL || values.type === QuestionType.NUMBER) && (
              <DecimalOrNumberFields />
            )} */}
              {isChoice && (
                <>
                  <div className="divider" />
                  <Choices choices={values.choices!} />
                  <div
                    tabIndex={0}
                    className="collapse collapse-arrow border border-base-300 bg-base-200"
                  >
                    <input type="checkbox" className="peer" />
                    <div className="collapse-title text-xl font-medium">{t('Advanced')}</div>
                    <div className="collapse-content">
                      <Templates />
                    </div>
                  </div>
                </>
              )}
              <ButtonActions
                submitProps={{ isLoading: isSubmitting, disabled: isSubmitting }}
                cancelProps={{ onClick: onClose }}
              />
            </Form>
          );
        }}
      </Formik>
    </FormModal>
  );
}
