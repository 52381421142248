import { useSuspenseQuery } from '@tanstack/react-query';
import api from '@/libs/api';

async function getUsedLicenses() {
  const resp = await api.api.getUsedLicenses({});
  return resp.data;
}

export default function useGetUsedLicenses() {
  return useSuspenseQuery({
    queryKey: ['licenses', 'used'],
    queryFn: getUsedLicenses,
  });
}
