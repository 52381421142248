import React, { createContext, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Section as TSection } from '@/generated';
import { useDialogModal } from '@/hooks';
import { detailsContext } from '@/pages/questionsets/Details';
import useSortSections from '@/services/sections/sort';
import useModalState from '@/states/modalState';
import { TModelId } from '@/types';
import FirstCreation from '../FirstCreation';
import SortModal from '../sort/SortModal';
import Create from './Create';
import Section from './section';

type SectionsProps = {
  sections?: TSection[];
};

export const sectionsContext = createContext({
  handleSort: () => {},
  canSort: false,
});

export const SECTIONS_CREATE_MODAL_KEY = 'createSections';

export default function Sections({ sections }: SectionsProps) {
  const { t } = useTranslation();
  const {
    modalRef: sortModalRef,
    handleCloseModal: handleCloseSortModal,
    handleOpenModal: handleOpenSortModal,
  } = useDialogModal();
  const { questionsetId, clearQueryCache } = useContext(detailsContext);
  const isCreateModalOpen = useModalState((state) => state.key) === SECTIONS_CREATE_MODAL_KEY;
  const { mutate: sortSections, isPending: isSorting } = useSortSections();
  const { setModalKey, clearModal } = useModalState((state) => ({
    setModalKey: state.setModalKey,
    clearModal: state.clearModalKey,
  }));

  const hasSections = sections && sections.length > 0;

  const setIsCreateModalOpen = (value: boolean) => {
    if (value) setModalKey(SECTIONS_CREATE_MODAL_KEY);
    else clearModal();
  };

  const handleSort = (ids: TModelId[]) => {
    sortSections(
      { questionSetId: questionsetId, ids },
      {
        onSuccess: () => {
          clearQueryCache();
          handleCloseSortModal();
        },
      },
    );
  };

  const value = useMemo(
    () => ({ handleSort: handleOpenSortModal, canSort: sections ? sections.length > 1 : false }),
    [sections],
  );

  return (
    <sectionsContext.Provider value={value}>
      <SortModal
        isSorting={isSorting}
        onSort={handleSort}
        onCancel={handleCloseSortModal}
        modalRef={sortModalRef}
        sortableItems={sections!.map((section) => ({ id: section.id, text: section.title }))}
      />
      <Create isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} />
      {hasSections ? (
        <ol className="flex gap-4 overflow-x-auto">
          {sections.map((section) => (
            <Section key={section.id} section={section} />
          ))}
        </ol>
      ) : (
        <FirstCreation
          title={t("You haven't created any resources yet", { resource: t('Sections') })}
          buttonText={t('Create Section')}
          onClick={() => setIsCreateModalOpen(true)}
        />
      )}
    </sectionsContext.Provider>
  );
}
