import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

async function deleteQuestion(id: TModelId) {
  const resp = await api.api.deleteQuestion(id);
  return resp.data;
}

export default function useDeleteQuestion() {
  return useMutation({ mutationFn: deleteQuestion });
}
