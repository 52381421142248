import React, { createContext, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Question as TQuestion } from '@/generated';
import { useDialogModal } from '@/hooks';
import { detailsContext } from '@/pages/questionsets/Details';
import useSortQuestions from '@/services/questions/sort';
import useModalState from '@/states/modalState';
import { TModelId } from '@/types';
import FirstCreation from '../FirstCreation';
import SortModal from '../sort/SortModal';
import Create from './Create';
import Question from './question';

export const questionsContext = createContext({
  onSort: () => {},
  canSort: false,
});

type QuestionsProps = {
  questions: TQuestion[];
  sectionId: TModelId;
};

export const QUESTIONS_CREATE_MODAL_KEY = (sectionId: TModelId) => `createQuestions#${sectionId}`;

export default function Questions({ questions, sectionId }: QuestionsProps) {
  const { t } = useTranslation();
  const createModalKey = QUESTIONS_CREATE_MODAL_KEY(sectionId);

  const {
    modalRef: sortModalRef,
    handleCloseModal: handleCloseSortModal,
    handleOpenModal: handleOpenSortModal,
  } = useDialogModal();
  const { clearQueryCache } = useContext(detailsContext);
  const isCreateModalOpen = useModalState((state) => state.key) === createModalKey;
  const { setModalKey, clearModal } = useModalState((state) => ({
    setModalKey: state.setModalKey,
    clearModal: state.clearModalKey,
  }));
  const { mutate: sortQuestions, isPending: isSorting } = useSortQuestions();

  const setIsCreateModalOpen = (value: boolean) => {
    if (value) setModalKey(createModalKey);
    else clearModal();
  };

  const handleSort = (ids: TModelId[]) => {
    sortQuestions(
      { sectionId, ids },
      {
        onSuccess: () => {
          clearQueryCache();
          handleCloseSortModal();
        },
      },
    );
  };

  const value = useMemo(
    () => ({
      onSort: handleOpenSortModal,
      canSort: questions.length > 1,
    }),
    [questions],
  );

  return (
    <div className="card card-bordered card-compact">
      <div className="border-colors card-body divide-y">
        <SortModal
          isSorting={isSorting}
          onSort={handleSort}
          onCancel={handleCloseSortModal}
          modalRef={sortModalRef}
          sortableItems={questions.map((question) => ({ id: question.id, text: question.title }))}
        />
        <Create
          sectionId={sectionId}
          onClose={() => setIsCreateModalOpen(false)}
          isOpen={isCreateModalOpen}
        />
        <questionsContext.Provider value={value}>
          {questions.map((question) => (
            <Question {...question} sectionId={sectionId} key={question.id} />
          ))}
        </questionsContext.Provider>
        {questions.length < 1 && (
          <FirstCreation
            title={t("You haven't created any resources yet", { resource: t('Questions') })}
            buttonText={t('Create Question')}
            onClick={() => setIsCreateModalOpen(true)}
          />
        )}
      </div>
    </div>
  );
}
