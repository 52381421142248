import React from 'react';
import { useTranslation } from 'react-i18next';
import Illustration from '@/assets/svg/auth/resetPassword.svg?react';
import Heading from '@/components/auth/Heading';
import Form from '@/components/auth/resetPassword/Form';
import AuthLayout from '@/layouts/AuthLayout';
import Head from '@/shared/custom/Head';

export default function ResetPassword() {
  const { t } = useTranslation();
  const heading = t('Reset your Password');

  return (
    <AuthLayout
      illustration={<Illustration className="h-[250px] w-[250px] md:h-[400px] md:w-[400px]" />}
    >
      <Heading heading={heading} />
      <Head title={heading} description={heading} />
      <Form />
    </AuthLayout>
  );
}
