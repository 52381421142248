export enum EHelpCategory {
  Recruitments = 'recruitments',
  Messages = 'messages',
  Users = 'users',
  Applicants = 'applicants',
  Settings = 'settings',
  Account = 'account',
}

export enum EResource {
  Users = 'users',
  Applicants = 'applicants',
  Recruitments = 'recruitments',
  Clients = 'clients',
}

export enum ERenderAs {
  Card = 'card',
  Table = 'table',
}

export enum ESettingType {
  Cloud = 'cloud',
  Local = 'local',
  Reset = 'reset',
}

export enum ENotificationFilter {
  All = 'all',
  Unread = 'unread',
}
