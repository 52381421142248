import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '@/libs/api';

async function clearAll() {
  const resp = await api.api.clearNotifications();
  return resp.data;
}

export default function useClearAllNotifications() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: clearAll,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
}
