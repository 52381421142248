import React from 'react';
import { useTranslation } from 'react-i18next';
import Illustration from '@/assets/svg/errors/404.svg?react';
import Container from '@/components/errors/Container';
import Description from '@/components/errors/Description';
import Head from '@/shared/custom/Head';
import { Button } from '@/shared/form';

type Error404Props = {
  onGoBackHome: () => void;
};

export default function Error404({ onGoBackHome }: Error404Props) {
  const { t } = useTranslation();

  return (
    <Container>
      <Head title="Page not Found" description="Page not Found" />
      <Illustration className="md:w-1/2" />
      <h1 className="h1">{t("Uh oh. That page doesn't exist.")}</h1>
      <Description>
        {t('Head to our homepage that does exist, or try double-checking the URL.')}
      </Description>
      <Button onClick={onGoBackHome} className="btn btn-primary btn-md">
        {t('Go back Home')}
      </Button>
    </Container>
  );
}
