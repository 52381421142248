import React from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import userExists from '@/assets/animations/userExists.json';

export default function AlreadyAnswered() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 items-center justify-center">
      <div className="card max-w-xl bg-base-300">
        <div className="card-body gap-4">
          <h1 className="card-title flex-wrap text-2xl font-bold">
            {t('You have already answered this Questionset')}
          </h1>
          <p className="description">
            {t(
              'If you wanna answer this questionset again please contact the recruiter to delete your Answer and try again.',
            )}
          </p>
          <Lottie
            loop
            animationData={userExists}
            play
            className="mx-auto h-60 w-60 md:h-72 md:w-72"
          />
        </div>
      </div>
    </div>
  );
}
