import React from 'react';
import useApplicantsState from '@/states/applicantsState';
import { TModelId } from '@/types';

type CheckboxProps = {
  id: TModelId;
};

export default function Checkbox({ id }: CheckboxProps) {
  const { customCompareApplicantsId, setCompareApplicantsId } = useApplicantsState((state) => ({
    customCompareApplicantsId: state.applicantsId,
    setCompareApplicantsId: state.setApplicantsId,
  }));
  const isInCustomApplicantsId = Boolean(customCompareApplicantsId.find((i) => i === id));

  const handleAddToCustomApplicantsId = () => {
    setCompareApplicantsId([...customCompareApplicantsId, id]);
  };

  const handleDeleteFromCustomApplicantsId = () => {
    setCompareApplicantsId(customCompareApplicantsId.filter((i) => i !== id));
  };

  return (
    <input
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
      checked={isInCustomApplicantsId}
      onChange={
        isInCustomApplicantsId ? handleDeleteFromCustomApplicantsId : handleAddToCustomApplicantsId
      }
      type="checkbox"
      className="checkbox-primary checkbox checkbox-sm"
    />
  );
}
