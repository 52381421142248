import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { chartColors } from '@/fixtures/chartColors';
import MainChart from '@/shared/Chart';
import Switch from './Switch';

type ChartProps = {
  labels: string[];
  data: number[];
};

type TChart = 'bar' | 'radar';

export default function Chart({ labels, data }: ChartProps) {
  const { t } = useTranslation();
  const [chartType, setChartType] = useState<TChart>('bar');
  const navigate = useNavigate();

  const charts: TChart[] = ['bar', 'radar'];

  return (
    <div className="max-w-xl space-y-4">
      <div className="flex items-center justify-center">
        {charts.map((chart) => (
          <Switch key={chart} isActive={chartType === chart} onClick={() => setChartType(chart)}>
            {t(`charts.types.${chart}`)}
          </Switch>
        ))}
      </div>
      <MainChart
        key={chartType}
        options={{
          labels,
          chart: {
            toolbar: {
              export: {
                csv: { headerCategory: t('Tag') },
              },
            },
            events: {
              dataPointSelection: (e, chart, config) => {
                const applicantId = config.w.config.labels[config.dataPointIndex];
                navigate(`../${applicantId}`);
              },
            },
          },
          // markers: { size: 6, colors: chartColors, strokeWidth: 2 },
          colors: chartType === 'radar' ? ['#4f46e5'] : chartColors,
          legend: { show: false },
          plotOptions: { bar: { distributed: true } },
          dataLabels: { enabled: false },
        }}
        series={[{ data, name: t('Score') }]}
        type={chartType}
      />
    </div>
  );
}
