import React, { Ref, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { UseMutationResult } from '@tanstack/react-query';
import ButtonActions from '@/shared/custom/ButtonActions';
import { Button } from '@/shared/form';
import DialogModal from '@/shared/modals/DialogModal';
import { copyTextToClipboard } from '@/utils';

type JobUrlModalProps = {
  onClose: () => void;
  modalRef: Ref<HTMLDialogElement>;
  mutationResult: UseMutationResult<string, Error, string, unknown>;
  isQuestionsetPublished: boolean;
};

export default function QuestionsetUrlModal({
  mutationResult,
  modalRef,
  onClose,
  isQuestionsetPublished,
}: JobUrlModalProps) {
  const { data, isPending } = mutationResult;
  const { t } = useTranslation();
  const qrCodeRef = useRef<any>(null);

  const handleDownloadQrCode = () => {
    const svgData = new XMLSerializer().serializeToString(qrCodeRef.current);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width + 40;
      canvas.height = img.height + 40;
      ctx.strokeRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 20, 20);
      const pngFile = canvas.toDataURL('image/png', 1.0);

      const downloadLink = document.createElement('a');
      downloadLink.download = 'qrcode';
      downloadLink.href = `${pngFile}`;
      downloadLink.target = '_blank';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      downloadLink.remove();
    };

    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const handleCopy = async () => {
    await copyTextToClipboard(data!);
    toast.success(t('URL copied to your clipboard successfully.'));
  };

  return (
    <DialogModal modalRef={modalRef} closeOnClickOutside>
      <div className="space-y-4">
        <h3 className="text-2xl font-bold">{t('Questionset Url')}</h3>
        {!isQuestionsetPublished && (
          <div role="alert" className="alert alert-warning">
            <ExclamationTriangleIcon className="h-6 w-6" />
            <span>{t('Questionset is not published yet!')}</span>
          </div>
        )}
        {isPending && <div className="skeleton h-28 w-full" />}
        {data && (
          <>
            <Link
              to={data}
              target="_blank"
              className="block select-all break-all text-primary underline"
            >
              {data}
            </Link>
            <QRCode ref={qrCodeRef} className="mx-auto" value={data} />
            <Button onClick={handleDownloadQrCode} className="btn btn-sm">
              {t('Download QRCode')}
            </Button>
          </>
        )}
        <ButtonActions
          submitProps={{ type: 'button', text: t('Copy'), disabled: !data, onClick: handleCopy }}
          cancelProps={{ onClick: onClose }}
        />
      </div>
    </DialogModal>
  );
}
