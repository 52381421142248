import React from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';

type FirstCreationProps = {
  title: string;
  buttonText: string;
  onClick: () => void;
};

export default function FirstCreation({ title, onClick, buttonText }: FirstCreationProps) {
  return (
    <div className="border-colors flex flex-col items-center justify-center space-y-4 border py-32 text-center">
      <XCircleIcon className="h-16 w-16 text-secondary" />
      <h2 className="h2">{title}</h2>
      <button onClick={onClick} type="button" className="btn btn-primary btn-md">
        {buttonText}
      </button>
    </div>
  );
}
