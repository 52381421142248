import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Form from '@/components/clients/Form';
import useCreateClient, { TClientCreate } from '@/services/clients/create';

export default function Create() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate, isPending } = useCreateClient();

  const handleSubmit = (values: TClientCreate) => {
    mutate(values, {
      onSuccess: () => {
        toast.success(t('toast.createdSuccessfully', { model: t('Client') }));
        navigate('/clients');
      },
    });
  };

  return (
    <Form
      title={t('Create Client')}
      onSubmit={handleSubmit}
      isSubmitting={isPending}
      initialValues={{
        name: '',
        domain: '',
        fullName: '',
        licenseId: '',
        tier: null,
        isActive: true,
      }}
    />
  );
}
