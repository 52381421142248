/* eslint-disable react/no-unused-prop-types */
import React, { createContext, useMemo } from 'react';
import { Table } from '@tanstack/react-table';
import { IFilter } from '@/interfaces';
import useSettingsState, { TResourceKeys, TResourceSettings } from '@/states/settingsState';
import Container from './Container';
import Filters from './sections/filters';
import Search from './sections/search';
import SortBy from './sections/sortBy';
import Views from './sections/views';
import { Hidden, Shown } from './sections/visibility';

interface OptionsProps {
  table: Table<unknown>;
  resource: TResourceKeys;
  filters?: IFilter[];
  topChildren?: React.ReactNode;
  bottomChildren?: React.ReactNode;
  hasMultipleViews?: boolean;
}

interface IOptionsContext extends OptionsProps {
  options: TResourceSettings;
  setOptions: (option: Partial<TResourceSettings>) => void;
}

export const optionsContext = createContext<IOptionsContext>({} as IOptionsContext);

export default function Options({ bottomChildren, topChildren, ...props }: OptionsProps) {
  const { resource } = props;
  const options = useSettingsState((state) => state[resource]);
  const setResourceSettings = useSettingsState((state) => state.setResourceSettings);

  const handleSetOptions = (data: Partial<TResourceSettings>) => {
    setResourceSettings(resource, data);
  };

  const hasMultipleViews = props.hasMultipleViews ?? true;

  const value: IOptionsContext = useMemo(
    () => ({
      ...props,
      hasMultipleViews,
      options,
      setOptions: handleSetOptions,
    }),
    [props, options],
  );

  return (
    <optionsContext.Provider value={value}>
      <Container>
        <div className="border-colors space-y-4 divide-y">
          {topChildren}
          {hasMultipleViews && <Views isTheFirst={!topChildren} />}
          <SortBy />
          <Search />
          <Filters />
          <Shown />
          <Hidden />
          {bottomChildren}
        </div>
      </Container>
    </optionsContext.Provider>
  );
}
