import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { QuestionSetStatus } from '@/generated';
import useEditDeadline from '@/services/questionsets/editDeadline';
import useEditStatusJob from '@/services/questionsets/status';
import ButtonActions from '@/shared/custom/ButtonActions';
import { Fields } from '@/shared/form';
import DialogModal from '@/shared/modals/DialogModal';
import { TField, TModelId } from '@/types';

const validationSchema = Yup.object({
  deadline: Yup.date()
    .min(new Date(new Date().getTime() + 1000), 'This field must be bigger than current time.')
    .required(),
});

type PublishQuestionsetProps = {
  modalRef: React.RefObject<HTMLDialogElement>;
  onCancel: () => void;
  questionsetId: TModelId;
};

const fields: TField[] = [
  {
    isRequired: true,
    type: 'input',
    name: 'deadline',
    label: (t) => t('Deadline'),
    fieldProps: { type: 'datetime-local', min: new Date().toISOString().slice(0, -8) },
  },
];

export default function PublishQuestionset({
  modalRef,
  questionsetId,
  onCancel,
}: PublishQuestionsetProps) {
  const { t } = useTranslation();
  const { mutateAsync: editStatus, isPending: isUpdatingStatus } = useEditStatusJob();
  const { mutateAsync: editDeadline, isPending: isEditingDeadline } = useEditDeadline();
  const isLoading = isEditingDeadline || isUpdatingStatus;

  return (
    <DialogModal closeOnClickOutside parentElement="div" modalRef={modalRef}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ deadline: '' }}
        onSubmit={async (values) => {
          await editDeadline({
            deadline: new Date(values.deadline).toISOString(),
            questionSetId: questionsetId,
          });
          await editStatus({ questionsetId, status: QuestionSetStatus.PUBLISHED });
          toast.success(t('Questionset published successfully.'));
          onCancel();
        }}
      >
        <Form className="space-y-4">
          <h3 className="h3">
            {t('Publish')} {t('Questionset')}
          </h3>
          <Fields fields={fields} />
          <ButtonActions
            submitProps={{ text: t('Publish'), disabled: isLoading, isLoading }}
            cancelProps={{ onClick: onCancel }}
          />
        </Form>
      </Formik>
    </DialogModal>
  );
}
