import React from 'react';
import { useTranslation } from 'react-i18next';
import Illustration from '@/assets/svg/errors/500.svg?react';
import Container from '@/components/errors/Container';
import Description from '@/components/errors/Description';
import Head from '@/shared/custom/Head';
import { Button } from '@/shared/form';

type Error505Props = {
  onGoBackHome: () => void;
  message?: string;
  onTryAgain: () => void;
};

export default function Error500({ onGoBackHome, message, onTryAgain }: Error505Props) {
  const { t } = useTranslation();

  return (
    <Container>
      <Head title="Server Error" description="Server Error" />
      <Illustration className="md:w-1/2" />
      <h1 className="h1">{t('Uh oh. An Error Occurred')}</h1>
      <Description>{t('Head to our homepage or try again later.')}</Description>
      <p className="text-lg text-error">{message}</p>
      <div className="flex space-x-4">
        <Button onClick={onTryAgain} className="btn btn-primary btn-md">
          {t('Try Again')}
        </Button>
        <Button onClick={onGoBackHome} className="btn btn-outline btn-primary btn-md">
          {t('Go back Home')}
        </Button>
      </div>
    </Container>
  );
}
