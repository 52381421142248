/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Field } from 'formik';
import { IChoice } from '@/interfaces';
import { cn } from '@/utils';
import Label from '../Label';

interface ChoiceProps extends IChoice {
  hasError: boolean;
}

export default function Choice({ hasError, name, options, type, ...props }: ChoiceProps) {
  return (
    <>
      {options?.map((option, i) => (
        <div key={i} className="flex items-center gap-2">
          <Field
            {...props}
            type={type}
            name={name}
            id={`${name}-${i}`}
            value={option.label}
            className={cn({
              'radio-primary radio radio-sm': type === 'radio',
              'radio-error': type === 'radio' && hasError,
              'checkbox-primary checkbox checkbox-sm': type === 'checkbox',
              'checkbox-error': type === 'checkbox' && hasError,
            })}
          />
          <Label htmlFor={`${name}-${i}`} label={option.label} />
        </div>
      ))}
    </>
  );
}
