import React from 'react';
import { useTranslation } from 'react-i18next';
import OTPInput from 'react-otp-input';

type TestOtpProps = {
  otp: string;
  setOtp: (otp: string) => void;
};

export default function TestOtp({ otp, setOtp }: TestOtpProps) {
  const { t } = useTranslation();

  return (
    <div className="space-y-2">
      <h3 className="h3">{t('Validate your OTP to enable it')}</h3>
      <p>{t("Please go to your app that you've downloaded and enter the code.")}</p>
      <div className="pt-4">
        <OTPInput
          value={otp}
          onChange={setOtp}
          skipDefaultStyles
          renderSeparator={<span>-</span>}
          renderInput={(props) => (
            <input {...props} className="input input-bordered h-10 w-12 text-center text-primary" />
          )}
          numInputs={6}
        />
      </div>
    </div>
  );
}
