import { useMutation } from '@tanstack/react-query';
import { MessageCreate } from '@/generated';
import api from '@/libs/api';

async function createMessage(data: MessageCreate) {
  const resp = await api.api.createMessage(data);
  return resp.data;
}

export default function useCreateMessage() {
  return useMutation({ mutationFn: createMessage });
}
