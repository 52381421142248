import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';
import useAuthState from '@/states/authState';

async function disableOtp() {
  const resp = await api.api.disableOtp({});
  return resp.data;
}

export default function useDisableOtp() {
  const user = useAuthState((state) => state.user!);
  const setAuth = useAuthState((state) => state.setAuth);

  return useMutation({
    mutationFn: disableOtp,
    onSuccess: () => {
      setAuth({ user: { ...user, otpEnabled: false } });
    },
  });
}
