import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

type SearchProps = {
  onSearchDebounced: (query: string) => void;
};

export default function Search({ onSearchDebounced }: SearchProps) {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const debounced = useDebouncedCallback(onSearchDebounced, 1000);

  return (
    <div className="form-control">
      <input
        type="search"
        placeholder={`${t('Search')} ...`}
        className="input input-bordered w-full"
        value={text}
        onChange={(e) => {
          setText(e.target.value);
          debounced(e.target.value);
        }}
      />
    </div>
  );
}
