import { useSuspenseQuery } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

async function getDetails(questionsetId: TModelId) {
  const resp = await api.api.getQuestionSetDetails(questionsetId);
  return resp.data;
}

export default function useGetQuestionsetDetails(questionsetId: TModelId) {
  return useSuspenseQuery({
    queryKey: ['questionsets', 'details', questionsetId],
    queryFn: () => getDetails(questionsetId),
  });
}
