import React, { Ref } from 'react';
import Lottie from 'react-lottie-player';
import robotJson from '@/assets/animations/robot.json';
import DialogModal from '@/shared/modals/DialogModal';

type AiWorkingModalProps = {
  modalRef: Ref<HTMLDialogElement>;
  heading: string;
  description: string;
};

export default function AiWorkingModal({ modalRef, heading, description }: AiWorkingModalProps) {
  return (
    <DialogModal
      onCancel={(e) => e.preventDefault()}
      modalRef={modalRef}
      parentClassName="w-11/12 max-w-2xl h-[70dvh] md:h-screen"
    >
      <h3 className="text-2xl font-bold">{heading}</h3>
      <p className="description">{description}</p>
      <Lottie loop animationData={robotJson} play className="w-full md:h-[65dvh]" />
    </DialogModal>
  );
}
