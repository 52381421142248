import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Form from '@/components/users/Form';
import { UserRole } from '@/generated';
import { TUserCreate } from '@/services/users/create';
import useEditUser from '@/services/users/edit';
import useGetUser from '@/services/users/show';
import useAuthState from '@/states/authState';
import { initialValues } from './Create';

export default function Edit() {
  const { t } = useTranslation();
  const authUser = useAuthState((state) => state.user!);
  const { mutate: editUser, isPending } = useEditUser();
  const params = useParams<'userId'>();
  const userId = params.userId!;

  const { data } = useGetUser(userId);

  const handleSubmit = (values: TUserCreate) => {
    const adminId = authUser.userRole === UserRole.ADMIN ? authUser.id : values.adminId;
    editUser(
      {
        ...values,
        adminId,
        clientId: authUser.clientId,
        userId,
      },
      {
        onSuccess: () => {
          toast.success(t('toast.updatedSuccessfully', { model: t('User') }));
        },
      },
    );
  };

  return (
    <Form
      showPasswords={false}
      title={t('Edit User')}
      onSubmit={handleSubmit}
      isSubmitting={isPending}
      initialValues={{
        ...initialValues,
        ...data,
        userRole: (data?.userRole ?? UserRole.USER) as any,
      }}
    />
  );
}
