import React, { useState } from 'react';
import { Wizard } from 'react-use-wizard';
import { useDialogModal } from '@/hooks';
import useDisableOtp from '@/services/otp/disable';
import useValidateOtp from '@/services/otp/validate';
import DialogModal from '@/shared/modals/DialogModal';
import useAuthState from '@/states/authState';
import Actions from './wizard/Actions';
import AuthenticatorApp from './wizard/AuthenticatorApp';
import QrCode from './wizard/QrCode';
import Steps from './wizard/Steps';
import TestOtp from './wizard/TestOtp';

export default function ToggleOtp() {
  const [isTokenFresh, otpEnabled, hasOtpFeature] = useAuthState((state) => [
    state.isTokenFresh,
    state.user?.otpEnabled,
    state.user?.userAccess.accessOtpFeature,
  ]);
  const [otp, setOtp] = useState('');
  const { mutate: validate, isPending: isValidating } = useValidateOtp();
  const { mutate: disable, isPending: isDisabling } = useDisableOtp();

  const { modalRef, handleOpenModal, handleCloseModal } = useDialogModal();
  const isPending = isValidating || isDisabling;
  const isOtpEnabled = Boolean(otpEnabled);

  const handleToggle = () => {
    if (isOtpEnabled) disable();
    else handleOpenModal();
  };

  const handleValidate = () => {
    validate({ otp }, { onSuccess: handleCloseModal });
  };

  return (
    <>
      <DialogModal
        parentClassName="w-11/12 max-w-3xl"
        parentElement="div"
        modalRef={modalRef}
        closeOnClickOutside
      >
        <Wizard
          header={<Steps />}
          footer={
            <Actions
              isSubmitDisabled={otp.length !== 6}
              isValidating={isValidating}
              onSubmit={handleValidate}
              onCancel={handleCloseModal}
            />
          }
        >
          <AuthenticatorApp />
          <QrCode />
          <TestOtp otp={otp} setOtp={setOtp} />
        </Wizard>
      </DialogModal>
      {hasOtpFeature && (
        <input
          type="checkbox"
          onChange={handleToggle}
          checked={isOtpEnabled}
          disabled={!isTokenFresh || isPending}
          className="toggle toggle-primary"
        />
      )}
    </>
  );
}
