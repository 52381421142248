import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { Button } from '@/shared/form';
import { ButtonProps } from '@/shared/form/Button';
import { cn } from '@/utils';

export default function LoadMore({ className, ...props }: ButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      {...props}
      className={cn('btn btn-link btn-primary btn-md btn-wide no-underline', className)}
    >
      <PlusCircleIcon className="h-6 w-6" />
      <span className="text-lg font-medium">{t('Load More')}</span>
    </Button>
  );
}
