import React, { createContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Illustration from '@/assets/svg/auth/register.svg?react';
import Heading from '@/components/auth/Heading';
import Form from '@/components/auth/register/Form';
import AuthLayout from '@/layouts/AuthLayout';
import Head from '@/shared/custom/Head';

type TRegisterContext = {
  isPremium: boolean;
  setIsPremium: React.Dispatch<React.SetStateAction<boolean>>;
};

export const registerContext = createContext<TRegisterContext>({} as TRegisterContext);

export default function Register() {
  const [isPremium, setIsPremium] = useState(false);
  const { t } = useTranslation();
  const heading = t(isPremium ? 'Register your Premium Account' : 'Register your Free Account');

  const value = useMemo(() => ({ isPremium, setIsPremium }), [isPremium]);

  return (
    <AuthLayout
      containerClassName="lg:max-w-none"
      illustration={<Illustration className="md:h-[500px] md:w-[500px]" />}
    >
      <Head
        title={heading}
        description="Register your account and access all of the DivERS recruitment features"
      />
      <Heading heading={heading} />
      <registerContext.Provider value={value}>
        <Form />
      </registerContext.Provider>
    </AuthLayout>
  );
}
