import mainAxios from 'axios';
import qs from 'qs';
import conf from '@/fixtures/config';

const axios = mainAxios.create({
  baseURL: conf.baseBackendUrl,
  headers: {
    common: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  paramsSerializer: { serialize: (p) => qs.stringify(p, { arrayFormat: 'repeat' }) },
  withCredentials: true,
});

function replaceEmptyStringsWithNull(obj: Record<string, any>) {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      replaceEmptyStringsWithNull(obj[key]);
    } else if (obj[key] === '') {
      obj[key] = null;
    }
  });
}

axios.interceptors.request.use(
  (config) => {
    if (config.method && ['post', 'put', 'patch'].includes(config.method) && config.data) {
      replaceEmptyStringsWithNull(config.data);
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default axios;
