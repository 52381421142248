import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Form from '@/components/recruitments/Form';
import { RecruitmentCreate } from '@/generated';
import useEditRecruitment from '@/services/recruitments/edit';
import useAssignCollaborator from '@/services/recruitments/roles/assign';
import useGetRecruitment from '@/services/recruitments/show';
import { TModelId } from '@/types';

export default function Edit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { recruitmentId } = useParams<'recruitmentId'>();
  const { mutateAsync: editRecruitment, isPending: isUpdating } = useEditRecruitment();
  const { mutateAsync: assignCollaborators, isPending: isAssigning } = useAssignCollaborator();
  const { data } = useGetRecruitment(recruitmentId!);
  const title = t('Edit Recruitment');

  const handleSubmit = async (values: RecruitmentCreate, collaborators: TModelId[]) => {
    await editRecruitment({ ...values, recruitmentId: recruitmentId! });
    await assignCollaborators({ recruitmentId: recruitmentId!, collaborators });
    toast.success(t('toast.updatedSuccessfully', { model: t('Recruitment') }));
    navigate('/recruitments');
  };

  return (
    <Form
      recruitmentId={recruitmentId}
      initialValues={{
        title: data?.title ?? '',
        description: data?.description ?? '',
        advertisement: data?.advertisement ?? '',
      }}
      onSubmit={handleSubmit}
      isSubmitting={isAssigning || isUpdating}
      title={title}
    />
  );
}
