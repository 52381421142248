import React from 'react';
import { ISelectOption } from '@/interfaces';
import ReactSelect from '@/shared/form/Fields/ReactSelect';

const options = [10, 25, 50, 75].map((value) => ({
  value,
  label: value,
}));

type PerPageProps = {
  perPage: number;
  onPerPage: (perPage: number) => void;
};

export default function PerPage({ perPage, onPerPage }: PerPageProps) {
  const value = options.find((option) => option.value === perPage);

  const handleChange = (selected: unknown) => {
    const { value: newPerPage } = selected as ISelectOption<number>;
    onPerPage(newPerPage);
  };

  return (
    <ReactSelect value={value} onChange={handleChange} options={options} menuPlacement="top" />
  );
}
