import React, { useContext } from 'react';
import { cn } from '@/utils';
import { cardContext } from './Card';

export default function Body() {
  const { isMaximized, card } = useContext(cardContext);
  const { data } = card;

  return (
    <div className={cn('flex flex-col gap-2 pt-2', { hidden: !isMaximized })}>
      {data.map(({ id, value, label }) => (
        <div className="flex flex-wrap items-center gap-4" key={id ?? label}>
          {label && <span className="description">{label}: </span>}
          {value}
        </div>
      ))}
    </div>
  );
}
