import { useEffect, useState } from 'react';
import { animate } from 'framer-motion';

type NumberIncrementAnimationProps = {
  to: number;
  from?: number;
  duration?: number;
};

export default function NumberIncrementAnimation({
  to,
  from = 0,
  duration = 1,
}: NumberIncrementAnimationProps) {
  const [number, setNumber] = useState<number>(from);

  useEffect(() => {
    const controls = animate(number, to, {
      duration,
      onUpdate: (value) => setNumber(+value.toFixed(0)),
    });
    return () => controls.stop();
  }, [number]);

  return number;
}
