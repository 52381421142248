import React, { createContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';
import Advertisement from '@/components/questionsets/ai/Advertisement';
import Questions from '@/components/questionsets/ai/Questions';
import Steps from '@/components/questionsets/ai/Steps';
import { GeneratedQuestionScore, Recruitment } from '@/generated';
import { TQuestionsetsEditLayout } from '@/layouts/questionsets/EditLayout';
import useGetRecruitment from '@/services/recruitments/show';
import Head from '@/shared/custom/Head';

type TAiContext = {
  qualifications: string;
  setQualifications: React.Dispatch<React.SetStateAction<string>>;
  recruitment: Recruitment;
  questions: TAiQuestion[];
  setQuestions: React.Dispatch<React.SetStateAction<TAiQuestion[]>>;
  nbQuestions: number;
  setNbQuestions: React.Dispatch<React.SetStateAction<number>>;
};

export type TAiQuestion = GeneratedQuestionScore & {
  weight: number;
};

export const aiContext = createContext<TAiContext>({} as TAiContext);

export default function Ai() {
  const { t } = useTranslation();
  const title = t('AI');
  const [qualifications, setQualifications] = useState('');
  const [questions, setQuestions] = useState<TAiQuestion[]>([]);
  const [nbQuestions, setNbQuestions] = useState(10);
  const { questionset } = useOutletContext<TQuestionsetsEditLayout>();
  const { data: recruitment } = useGetRecruitment(questionset!.recruitmentId);

  const value = useMemo(
    () => ({
      qualifications,
      nbQuestions,
      setNbQuestions,
      setQualifications,
      recruitment,
      questions,
      setQuestions,
    }),
    [
      qualifications,
      nbQuestions,
      setNbQuestions,
      setQualifications,
      recruitment,
      questions,
      setQuestions,
    ],
  );

  return (
    <aiContext.Provider value={value}>
      <h1 className="h1">{title}</h1>
      <Head title={title} description={title} />
      <Wizard startIndex={!qualifications ? 0 : 2} header={<Steps />}>
        <Advertisement />
        <Questions />
      </Wizard>
    </aiContext.Provider>
  );
}
