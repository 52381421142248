import { useMutation } from '@tanstack/react-query';
import { CreateSectionParams, SectionCreate } from '@/generated';
import api from '@/libs/api';

export type TSectionCreate = CreateSectionParams & SectionCreate;

async function createSection({ questionSetId, ...data }: TSectionCreate) {
  const resp = await api.api.createSection({ questionSetId }, data);
  return resp.data;
}

export default function useCreateSection() {
  return useMutation({ mutationFn: createSection });
}
