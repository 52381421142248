import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import Link from '@/components/auth/Link';
import commonFields from '@/fixtures/commonFields';
import validations from '@/fixtures/validations';
import { registerContext } from '@/pages/auth/Register';
import useRegister from '@/services/auth/register';
import useCheckRedemption from '@/services/redemptions/check';
import { Button, Fields } from '@/shared/form';
import Field from '@/shared/form/Field';
import { TField } from '@/types';
import { cn, isAxiosError } from '@/utils';
import AgreeToPolicy from './AgreeToPolicy';

const fields: TField[] = [
  {
    isRequired: true,
    type: 'input',
    name: 'firstName',
    label: (t) => t('First Name'),
    fieldProps: { type: 'text' },
  },
  {
    isRequired: true,
    type: 'input',
    name: 'lastName',
    label: (t) => t('Last Name'),
    fieldProps: { type: 'text' },
  },
  {
    isRequired: true,
    type: 'input',
    name: 'clientName',
    label: (t) => t('Company Name'),
    fieldProps: { type: 'text' },
  },

  {
    isRequired: true,
    type: 'input',
    name: 'email',
    label: (t) => t('Email'),
    fieldProps: { type: 'email' },
  },
  {
    isRequired: false,
    type: 'input',
    name: 'address',
    label: (t) => t('Address'),
    fieldProps: { type: 'text' },
  },
  {
    isRequired: false,
    type: 'input',
    name: 'phoneNumber',
    label: (t) => t('Phone Number'),
    fieldProps: { type: 'text' },
  },
  ...commonFields.passwordWithConfirmation,
];

export default function Form() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate: register, isPending } = useRegister();
  const {
    mutate: checkCode,
    isPending: isCheckingCode,
    isSuccess: isCodeValid,
  } = useCheckRedemption();
  const { setIsPremium } = useContext(registerContext);

  const validationSchema = Yup.object().shape({
    firstName: validations.name,
    lastName: validations.name,
    clientName: validations.name,
    email: validations.email,
    address: validations.description,
    phoneNumber: validations.phoneNumber(t),
    password: validations.password,
    agree: validations.agree,
    passwordConfirmation: validations.passwordConfirmation(t),
  });

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        clientName: '',
        phoneNumber: '',
        address: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        redemptionCode: '',
        agree: false,
        redemptionTempCode: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setErrors, resetForm, setFieldValue }) => {
        if (values.redemptionTempCode && !isCodeValid) {
          checkCode(
            { code: values.redemptionTempCode },
            {
              onSuccess: () => {
                setFieldValue('redemptionCode', values.redemptionTempCode);
                toast.success(t('Redemption Code is valid.'));
                setIsPremium(true);
              },
            },
          );
          return;
        }
        register(values, {
          onSuccess: () => {
            toast.success(t("You've registered successfully."));
            resetForm();
            navigate('/login');
          },
          onError: (e) => {
            if (isAxiosError<any>(e, 400)) {
              let errorField = 'email';
              if (
                e.response?.data?.detail &&
                !e.response?.data?.detail.toLowerCase().includes('email')
              ) {
                errorField = 'clientName';
              }
              setErrors({ [errorField]: t('errors.alreadyTaken') });
            } else if (isAxiosError(e, 422)) {
              setErrors({ phoneNumber: t('errors.invalid') });
            }
          },
        });
      }}
    >
      {({ values, setFieldValue }) => (
        <FormikForm className="grid w-full grid-cols-1 gap-4 lg:grid-cols-2">
          <Fields fields={fields} />
          <div className="col-span-full">
            <Field
              tooltip={{ text: t('Redemption Code'), className: 'tooltip-right' }}
              type="custom"
              isRequired={false}
              label="Code"
              name="redemptionCodeCheck"
            >
              <input
                id="redemptionCodeCheck"
                value={values.redemptionTempCode}
                onChange={(e) => setFieldValue('redemptionTempCode', e.target.value)}
                type="text"
                readOnly={isCodeValid}
                className={cn('input join-item input-bordered w-full', {
                  'input-success': isCodeValid,
                })}
              />
            </Field>
          </div>
          <AgreeToPolicy />
          <Button
            onClick={() => {
              if (values.agree) return;
              toast.error(t('errors.agree'));
            }}
            isLoading={isPending || isCheckingCode}
            disabled={isPending || isCheckingCode}
            type="submit"
            className="btn btn-primary w-full text-lg font-semibold"
          >
            {values.redemptionTempCode && !isCodeValid ? t('Redeem') : t('Register')}
          </Button>
          <Link to="/login" text={t('Have an Account ?')} />
        </FormikForm>
      )}
    </Formik>
  );
}
