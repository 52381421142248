import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import CountSelection from '@/components/applicants/compare/CountSelection';
import { TSection } from '@/components/applicants/compare/sections/Section';
import Sections from '@/components/applicants/compare/sections/Sections';
// import { chartColors, heatMapColors } from '@/fixtures/chartColors';
import { TIndexLayoutContext } from '@/layouts/applicants/IndexLayout';
// import Chart from '@/shared/Chart';
import Head from '@/shared/custom/Head';
import HeadingWithLinks from '@/shared/custom/HeadingWithLinks';

export default function Compare() {
  const { t } = useTranslation();
  const title = t('Compare Applicants');
  const { queryResult, topApplicants } = useOutletContext<TIndexLayoutContext>();
  const { isLoading } = queryResult;

  const sections = Object.entries(topApplicants.at(0)?.scoreSections || {}).reduce(
    (prev, [key, value]) => {
      if (key === 'total') return prev;
      const sectionId = key as unknown as number;
      prev.push({
        labels: topApplicants.map(({ tag }) => tag!),
        data: topApplicants.map(
          (applicant) => applicant.scoreSections![sectionId].scoreValueNormalized,
        ),
        sectionTitle: value.sectionTitle,
        sectionId,
      });
      return prev;
    },
    [] as TSection[],
  );

  return (
    <>
      <Head title={title} description={title} />
      <div className="flex flex-wrap items-center justify-between gap-4">
        <HeadingWithLinks
          links={[
            { text: 'Applicants', to: '/applicants' },
            { text: title, to: '#' },
          ]}
        />
        <CountSelection />
      </div>
      {/* {!isLoading && (
        <Chart
          options={{
            colors: chartColors,
            chart: { toolbar: { export: { csv: { headerCategory: 'Applicant ID' } } } },
            xaxis: { categories: sections[0]?.labels },
            plotOptions: {
              heatmap: {
                radius: 2,
                enableShades: false,
                colorScale: {
                  ranges: [
                    {
                      from: 1,
                      to: 4,
                      name: t('charts.ranges.low'),
                      color: heatMapColors.low,
                    },
                    {
                      from: 4,
                      to: 7,
                      name: t('charts.ranges.medium'),
                      color: heatMapColors.medium,
                    },
                    {
                      from: 7,
                      to: 10,
                      name: t('charts.ranges.high'),
                      color: heatMapColors.high,
                    },
                  ],
                },
              },
            },
          }}
          series={sections.map(({ data, sectionTitle }) => ({
            data,
            name: sectionTitle,
          }))}
          type="heatmap"
          height={250}
        />
      )} */}
      <Sections isLoading={isLoading} sections={sections} />
    </>
  );
}
