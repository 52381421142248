import React, { useContext, useState } from 'react';
import { Section as TSection } from '@/generated';
import { detailsContext } from '@/pages/questionsets/Details';
import Edit from '../Edit';
import Actions from './Actions';
import Container from './Container';

type SectionProps = {
  section: TSection;
};

export default function Section({ section }: SectionProps) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { activeSectionId, setActiveSectionId } = useContext(detailsContext);
  const { id, title } = section;
  const isActive = id === activeSectionId;

  return (
    <Container isActive={isActive} onClick={() => setActiveSectionId(id)}>
      <Edit
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        sectionId={id}
        initialValues={section}
      />
      <div className="flex items-center justify-between space-x-2">
        <h2>{title}</h2>
        {isActive && <Actions onClose={() => setIsEditModalOpen(true)} sectionId={id} />}
      </div>
    </Container>
  );
}
