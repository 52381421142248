/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import useAuthState from '@/states/authState';
import Devices from './otp/Devices';
import ToggleOtp from './otp/ToggleOtp';

export default function OtpSettings() {
  const isTokenFresh = useAuthState((state) => state.isTokenFresh);
  const hasOtpFeature = useAuthState((state) => state.user?.userAccess.accessOtpFeature);
  const { t } = useTranslation();

  return (
    <div className="card w-full bg-base-200">
      <div className="card-body gap-4">
        <div className="flex flex-wrap items-start justify-between gap-4">
          <h2 className="h3">{t('Two Factor Authentication Settings')}</h2>
          <ToggleOtp />
        </div>
        <div className="divider m-0" />
        {hasOtpFeature && (
          <>
            {isTokenFresh ? (
              <Devices />
            ) : (
              <div role="alert" className="alert alert-info">
                <InformationCircleIcon className="h-6 w-6" />
                <span>{t('Please logout and login again To change these settings.')}</span>
              </div>
            )}
          </>
        )}
        {!hasOtpFeature && (
          <div role="alert" className="alert alert-info">
            <InformationCircleIcon className="h-6 w-6" />
            <span>{t('You have to upgrade your plan to access this feature')}</span>
          </div>
        )}
      </div>
    </div>
  );
}
