import React from 'react';
import { motion } from 'framer-motion';
import { useIsScreenMd } from '@/hooks/useMediaQuery';
import useSettingsState, { TResourceKeys } from '@/states/settingsState';

type ContainerProps = {
  children: React.ReactNode;
  resource: TResourceKeys;
};

export default function Container({ children, resource }: ContainerProps) {
  const isResourceOptionsOpen = useSettingsState((state) => state[resource].isOpen);
  const isScreenMd = useIsScreenMd();

  const variants = {
    open: {
      width: isScreenMd ? '70%' : '100%',
    },
    closed: {
      width: '100%',
    },
  };

  const animate = isResourceOptionsOpen ? 'open' : 'closed';

  return (
    <motion.div initial={animate} animate={animate} variants={variants} className="space-y-4">
      {children}
    </motion.div>
  );
}
