import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import TestIllustration from '@/assets/svg/applicants/create/test.svg?react';
import FakeApplicants from '@/components/applicants/create/FakeApplicants';
import Option from '@/components/applicants/create/Option';
import Head from '@/shared/custom/Head';
import HeadingWithLinks from '@/shared/custom/HeadingWithLinks';

export default function Create() {
  const { t } = useTranslation();
  const title = t('Create Applicants');
  const navigate = useNavigate();
  const { questionsetId } = useParams<'questionsetId'>();

  const handleNavigateToJobPreview = () => navigate(`/questionsets/${questionsetId}/preview`);

  return (
    <>
      <Head title={title} description={title} />
      <HeadingWithLinks
        links={[
          { text: 'Applicants', to: '/applicants' },
          { text: title, to: '#' },
        ]}
      />
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <FakeApplicants questionsetId={questionsetId!} />
        <Option
          onClick={handleNavigateToJobPreview}
          heading={t('applicants.create.options.test.heading')}
          description={t('applicants.create.options.test.description')}
          Illustration={TestIllustration}
        />
      </div>
    </>
  );
}
