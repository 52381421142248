import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';

async function getQuestionsetUrl(questionsetId: TModelId): Promise<string> {
  const resp = await api.api.getQuestionSetUrl(questionsetId);
  return `${window.location.origin}${resp.data.question_set_link}`;
}

export default function useQuestionsetUrl() {
  return useMutation({ mutationFn: getQuestionsetUrl });
}
