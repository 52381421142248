import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Button } from '@/shared/form';

type NextAndPreviousProps = {
  onNext: () => void;
  onPrevious: () => void;
  hasNext: boolean;
  hasPrevious: boolean;
};

export default function NextAndPrevious({
  onNext,
  onPrevious,
  hasNext,
  hasPrevious,
}: NextAndPreviousProps) {
  return (
    <div className="flex items-center space-x-2">
      <Button onClick={onPrevious} disabled={!hasPrevious} className="btn btn-circle btn-md">
        <ChevronLeftIcon className="h-6 w-6" />
      </Button>
      <Button onClick={onNext} disabled={!hasNext} className="btn btn-circle btn-md">
        <ChevronRightIcon className="h-6 w-6" />
      </Button>
    </div>
  );
}
