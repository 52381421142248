import React from 'react';
import { useNavigate } from 'react-router-dom';
import { cn } from '@/utils';

type TrProps = {
  children: React.ReactNode;
  viewLink: string | undefined;
};

export default function Tr({ children, viewLink }: TrProps) {
  const navigate = useNavigate();
  const handleNavigate = () => viewLink && navigate(viewLink);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTableRowElement>) => {
    if (e.key === 'Enter') handleNavigate();
  };

  const properties = viewLink
    ? {
        onClick: handleNavigate,
        onKeyDown: handleKeyDown,
        tabIndex: 0,
        role: 'link',
      }
    : {};

  return (
    <tr
      {...properties}
      className={cn('text-base', {
        'cursor-pointer': viewLink,
      })}
    >
      {children}
    </tr>
  );
}
