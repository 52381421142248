import React from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useOutletContext } from 'react-router-dom';
import Form from '@/components/questionsets/Form';
import { QuestionSetCreate, QuestionSetType } from '@/generated';
import { TQuestionsetsLayout } from '@/layouts/questionsets/IndexLayout';
import useGetQuestionsets from '@/services/questionsets';
import useCreateQuestionset from '@/services/questionsets/create';
import Head from '@/shared/custom/Head';
import HeadingWithLinks from '@/shared/custom/HeadingWithLinks';
import WizardButtons from '@/shared/custom/WizardButtons';
import useAuthState from '@/states/authState';

export default function Create() {
  const { recruitmentId } = useOutletContext<TQuestionsetsLayout>();
  const { t } = useTranslation();
  const title = t('Create Questionset');
  const navigate = useNavigate();
  const { mutate: createQuestionset, isPending } = useCreateQuestionset();
  const hasInterviewFeature = useAuthState(
    (state) => state.user?.userAccess.accessInterviewFeature,
  );
  const { data: questionsets } = useGetQuestionsets({ recruitmentId });
  const createdTypes = questionsets.items.map((questionset) => questionset.type);

  const handleSubmit = (values: QuestionSetCreate) => {
    createQuestionset(
      { ...values, recruitmentId },
      {
        onSuccess: (questionset) => {
          navigate(
            `/questionsets/${questionset.id}/setup?${createSearchParams({ mode: 'wizard' })}`,
          );
        },
      },
    );
  };

  return (
    <>
      <Head title={title} description={title} />
      <HeadingWithLinks
        links={[
          { text: 'Recruitments', to: '/recruitments' },
          { text: title, to: '#' },
        ]}
      />
      <ul className="steps steps-vertical md:steps-horizontal">
        <li className="step step-primary">{t('Information')}</li>
        <li className="step">{t('Setup')}</li>
        <li className="step">{t('Finish')}</li>
      </ul>
      <Form
        onSubmit={handleSubmit}
        excludedTypes={createdTypes}
        initialValues={
          {
            title: '',
            description: '',
            deadline: '',
            type: QuestionSetType.QUALIFICATION,
            userOptions: { askForLinkedinProfile: false },
          } satisfies QuestionSetCreate
        }
      >
        {({ values }) => {
          const shouldUpgradePlan =
            !hasInterviewFeature && values.type === QuestionSetType.INTERVIEW;

          return (
            <WizardButtons
              previousProps={{ disabled: true }}
              submitProps={{
                type: 'submit',
                isLoading: isPending,
                disabled: shouldUpgradePlan || isPending || createdTypes.length >= 2,
                tooltip: shouldUpgradePlan
                  ? t('You have to upgrade your plan to access this feature')
                  : undefined,
                tooltipClassName: 'tooltip-left',
              }}
              cancelProps={{ onClick: () => navigate('/recruitments') }}
            />
          );
        }}
      </Form>
    </>
  );
}
