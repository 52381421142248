import React, { Fragment } from 'react';
import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';
import { PageNotification } from '@/generated';
import LoadMore from '@/shared/custom/LoadMore';
import Notification from './Notification';

type BodyProps = {
  queryResult: UseInfiniteQueryResult<InfiniteData<PageNotification, unknown>, Error>;
};

export default function Body({ queryResult }: BodyProps) {
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = queryResult;

  return (
    <div className="h-[300px] space-y-2 divide-y overflow-y-auto">
      {data?.pages.map((page) => (
        <Fragment key={page.page}>
          {page?.items.map((notification) => (
            <Notification key={notification.id} {...notification} />
          ))}
        </Fragment>
      ))}
      {hasNextPage && (
        <LoadMore
          onClick={() => fetchNextPage()}
          disabled={isFetchingNextPage}
          isLoading={isFetchingNextPage}
          className="pb-0"
        />
      )}
    </div>
  );
}
