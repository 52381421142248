import React from 'react';
import { useTranslation } from 'react-i18next';
import { HashtagIcon } from '@heroicons/react/24/outline';
import { Column } from '@tanstack/react-table';
import { TIcon } from '@/types';
import Container from '../Container';
import Header from '../Header';

type VisibilityProps = {
  heading: string;
  onTextButtonClick: () => void;
  textButton: string;
  columns: Column<unknown, unknown>[];
  ActionButtonIcon: TIcon;
};

export default function Visibility({
  heading,
  onTextButtonClick,
  textButton,
  columns,
  ActionButtonIcon,
}: VisibilityProps) {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="flex items-center justify-between">
        <Header>{heading}</Header>
        <button
          onClick={onTextButtonClick}
          type="button"
          className="btn btn-link btn-primary btn-sm no-underline"
        >
          {textButton}
        </button>
      </div>
      <div className="description flex flex-col">
        {columns.map((column) => (
          <div key={column.id} className="flex justify-between">
            <div className="flex items-center justify-center space-x-2">
              <HashtagIcon strokeWidth={2} className="h-4 w-4" />
              <p className="font-medium">{column.columnDef.meta?.header(t)}</p>
            </div>
            <button
              className="btn btn-circle btn-ghost btn-sm"
              onClick={() => column.toggleVisibility()}
              type="button"
            >
              <ActionButtonIcon aria-label="eye" strokeWidth={2} className="h-5 w-5" />
            </button>
          </div>
        ))}
      </div>
    </Container>
  );
}
