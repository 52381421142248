/* eslint-disable i18next/no-literal-string */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useEditClientLogo from '@/services/clients/me/logo';
import { Button, Label } from '@/shared/form';

type LogoUploaderProps = {
  logoUrl: string | null | undefined;
};

export default function LogoUploader({ logoUrl }: LogoUploaderProps) {
  const { t } = useTranslation();
  const { mutate, isPending } = useEditClientLogo();
  const [showInput, setShowInput] = useState(!logoUrl);
  const [logo, setLogo] = useState<File | null>(null);

  const handleUpload = () => {
    mutate(
      { logo: logo! },
      {
        onSuccess: () => {
          setLogo(null);
          setShowInput(false);
          toast.success(t('toast.updatedSuccessfully', { model: 'Logo' }));
        },
      },
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <Label
        tooltip={{
          className: 'tooltip-right',
          text: t('platform.information.logoTooltip'),
        }}
        htmlFor="logo"
        label={t('Logo')}
        isRequired={false}
      />
      {showInput ? (
        <input
          value={logo ? undefined : ''}
          onChange={(e) => {
            const file = e.target.files![0];
            if (!file) return;
            setLogo(file);
          }}
          name="logo"
          id="logo"
          accept="image/*"
          type="file"
          className="file-input file-input-bordered block w-full max-w-md"
        />
      ) : (
        <img className="h-32 w-32 object-contain" src={logoUrl!} alt="Logo" />
      )}
      <div className="card-actions">
        {showInput ? (
          <>
            <Button
              onClick={handleUpload}
              disabled={!logo || isPending}
              isLoading={isPending}
              className="btn btn-primary btn-md"
            >
              {t('Upload')}
            </Button>
            <Button onClick={() => setShowInput(false)} className="btn btn-md">
              {t('Cancel')}
            </Button>
          </>
        ) : (
          <Button
            onClick={() => setShowInput(true)}
            disabled={isPending}
            isLoading={isPending}
            className="btn btn-secondary btn-md"
          >
            {t('Change')}
          </Button>
        )}
      </div>
    </div>
  );
}
