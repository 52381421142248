import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import commonFields from '@/fixtures/commonFields';
import validations from '@/fixtures/validations';
import { SectionCreate } from '@/generated';
import ButtonActions from '@/shared/custom/ButtonActions';
import { Fields } from '@/shared/form';
import FormModal from '@/shared/modals/FormModal';
import { convertNullToEmptyString } from '@/utils';

const validationSchema = Yup.object().shape({
  title: validations.title,
  description: validations.description,
  nbQuestionsPerPage: Yup.number().min(1).required(),
});

type CreateProps = {
  modalTitle: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: SectionCreate) => void;
  initialValues: SectionCreate;
  isSubmitting: boolean;
};

export default function Modal({
  modalTitle,
  isOpen,
  onClose,
  onSubmit,
  isSubmitting,
  initialValues,
}: CreateProps) {
  return (
    <FormModal isOpen={isOpen} onClose={onClose} title={modalTitle}>
      <Formik
        validationSchema={validationSchema}
        initialValues={convertNullToEmptyString(initialValues)}
        onSubmit={onSubmit}
      >
        <Form className="space-y-4">
          <Fields
            fields={[
              commonFields.title,
              commonFields.description,
              {
                isRequired: true,
                label: (t) => t('Questions per Page'),
                type: 'input',
                name: 'nbQuestionsPerPage',
                fieldProps: { type: 'number', min: 1 },
              },
            ]}
          />
          <ButtonActions
            submitProps={{ isLoading: isSubmitting, disabled: isSubmitting }}
            cancelProps={{ onClick: onClose }}
          />
        </Form>
      </Formik>
    </FormModal>
  );
}
