import React from 'react';
import { createPortal } from 'react-dom';

type ModalProps = {
  children: React.ReactNode;
};

export const MODAL_ROOT_ID = 'modal';

export default function Modal({ children }: ModalProps) {
  // modal root div is in ThemeProvider.tsx to get the correct theme
  const root = document.getElementById(MODAL_ROOT_ID)!;
  return createPortal(children, root);
}
