import { useMutation } from '@tanstack/react-query';
import { ClientUpdate, UpdateClientParams } from '@/generated';
import api from '@/libs/api';

type TClientEdit = ClientUpdate & UpdateClientParams;

async function editClient({ clientId, licenseId, ...data }: TClientEdit) {
  const resp = await api.api.updateClient({ clientId, licenseId }, data);
  return resp.data;
}

export default function useEditClient() {
  return useMutation({ mutationFn: editClient });
}
