/* eslint-disable @typescript-eslint/naming-convention */
import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';
import useAuthState, { IAuthState } from '@/states/authState';

export async function postRefreshToken(): Promise<IAuthState> {
  const resp = await api.api.refreshToken();

  const { user, access_token } = resp.data;
  return { user: user as any, accessToken: access_token, isLoggedIn: true, isTokenFresh: false };
}

export default function useRefreshToken() {
  const setAuth = useAuthState((state) => state.setAuth);
  const setAuthUserIsNotLoggedIn = useAuthState((state) => state.setAuthUserIsNotLoggedIn);

  return useMutation({
    mutationFn: postRefreshToken,
    onSuccess: setAuth,
    onError: setAuthUserIsNotLoggedIn,
  });
}
