import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AcademicCapIcon, RocketLaunchIcon, ShareIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
// import { formatDistanceToNow } from 'date-fns';
import QuestionsetUrlModal from '@/components/questionsets/details/QuestionsetUrlModal';
import CardProcess from '@/components/questionsets/index/CardProcess';
import ExportQuestionset from '@/components/questionsets/index/ExportQuestionset';
import PublishQuestionset from '@/components/questionsets/index/PublishQuestionset';
import BaseActions from '@/components/resources/index/actions';
import { QuestionSetShort, QuestionSetStatus, QuestionSetType } from '@/generated';
import { useDialogModal } from '@/hooks';
import useDeleteQuestionset from '@/services/questionsets/delete';
import useQuestionsetUrl from '@/services/questionsets/url';
import { Button } from '@/shared/form';
import { conditionedTooltip } from '@/utils';
import Deadline from './Deadline';

type QuestionsetProps = QuestionSetShort & {
  isTheOwner: boolean;
};

export default function Questionset({
  id,
  title,
  status,
  deadline,
  type,
  isTheOwner,
  nbQuestions,
}: QuestionsetProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate: deleteQuestionset, isPending: isDeleting } = useDeleteQuestionset();
  const { modalRef, handleCloseModal, handleOpenModal } = useDialogModal();
  const questionsetUrl = useQuestionsetUrl();
  const {
    modalRef: urlModalRef,
    handleCloseModal: handleCloseUrlModal,
    handleOpenModal: handleOpenUrlModal,
  } = useDialogModal();

  const isStatusDraft = status === QuestionSetStatus.DRAFT;
  const isTypeInterview = type === QuestionSetType.INTERVIEW;
  const canPerformActions = isTheOwner && isStatusDraft;
  const actionTooltip = conditionedTooltip([
    { condition: !isTheOwner, text: 'Not the Owner' },
    { condition: !isStatusDraft, text: 'Not in Draft' },
  ]);
  const publishTooltip = conditionedTooltip([
    { condition: nbQuestions <= 0, text: "Doesn't have any questions." },
    { condition: true, text: 'Publish Questionset' },
  ]);
  const shareTooltip = conditionedTooltip([
    { condition: nbQuestions <= 0, text: "Doesn't have any questions." },
    { condition: true, text: 'Questionset URL' },
  ]);

  return (
    <div className="card card-bordered card-compact bg-base-200">
      <PublishQuestionset questionsetId={id} onCancel={handleCloseModal} modalRef={modalRef} />
      <QuestionsetUrlModal
        isQuestionsetPublished={!isStatusDraft}
        modalRef={urlModalRef}
        onClose={handleCloseUrlModal}
        mutationResult={questionsetUrl}
      />
      <div className="card-body">
        <div className="flex flex-wrap items-center justify-between border-b-2 pb-2">
          <Link className="card-title text-base" to={`/questionsets/${id}/details`}>
            {title}
          </Link>
          <BaseActions
            editAction={{
              link: `/questionsets/${id}/details`,
              // state: !canPerformActions && 'disabled',
              // tooltip: actionTooltip,
            }}
            deleteAction={{
              modalTitle: `${t('Delete')} ${title}`,
              isDeleting,
              state: !canPerformActions && 'disabled',
              tooltip: actionTooltip,
              onDelete: (deleteModalRef) => {
                deleteQuestionset(id, {
                  onSuccess: () => {
                    toast.success(t('toast.deletedSuccessfully', { model: t('Questionset') }));
                    deleteModalRef.current?.close();
                    queryClient.invalidateQueries({ queryKey: ['recruitments'] });
                  },
                });
              },
            }}
          >
            <ExportQuestionset questionsetId={id} />
            <Link className="action" to={`/questionsets/${id}/applicants`}>
              <AcademicCapIcon strokeWidth={2} className="action-icon hover:text-primary" />
            </Link>
            {!isTypeInterview && isTheOwner && isStatusDraft && (
              <div data-tip={publishTooltip} className="group tooltip tooltip-secondary">
                <Button
                  disabled={nbQuestions <= 0}
                  onClick={handleOpenModal}
                  className="action via-primary-600 animate-text bg-gradient-to-r from-indigo-600 to-red-600"
                >
                  <RocketLaunchIcon strokeWidth={2} className="action-icon text-base-100" />
                </Button>
              </div>
            )}
            {!isTypeInterview && (
              <div data-tip={shareTooltip} className="group tooltip tooltip-secondary">
                <Button
                  disabled={nbQuestions <= 0}
                  onClick={() => {
                    handleOpenUrlModal();
                    questionsetUrl.mutate(id);
                  }}
                  className="action"
                >
                  <ShareIcon strokeWidth={2} className="action-icon" />
                </Button>
              </div>
            )}
          </BaseActions>
        </div>
        <div className="mt-2 space-y-4">
          {!isTypeInterview && (
            <div className="flex flex-wrap gap-4">
              <span className="description">{t('Process')}: </span>
              <CardProcess showTag status={status} />
            </div>
          )}
          <Deadline deadline={deadline} questionsetId={id} />
        </div>
      </div>
    </div>
  );
}
