import React from 'react';
import Info from '@/components/resources/index/pagination/Info';
import Mobile from '@/components/resources/index/pagination/Mobile';
import CustomPagination from '@/components/resources/index/pagination/Pagination';
import PerPage from '@/components/resources/index/pagination/PerPage';
import useSettingsState, { TResourceKeys } from '@/states/settingsState';
import { hasMore } from '@/utils';

type PaginationProps = {
  total?: number;
  resource: TResourceKeys;
};

export default function Pagination({ total = 0, resource }: PaginationProps) {
  const setResourceSettings = useSettingsState((state) => state.setResourceSettings);
  const params = useSettingsState((state) => state[resource].params);

  const hasNextPage = hasMore({ total, page: params.page, perPage: params.size });
  const hasPrevPage = params.page !== 1;

  const handleChangePage = (newPage: number) => {
    setResourceSettings(resource, { params: { ...params, page: newPage } });
  };

  const handlePerPage = (newPerPage: number) => {
    setResourceSettings(resource, { params: { ...params, size: newPerPage } });
  };

  if (total === 0) return null;
  return (
    <div className="flex flex-col items-center justify-between space-y-4 md:flex-row md:space-y-0">
      <Info total={total} page={params.page} perPage={params.size} />
      <div className="hidden flex-col space-y-4 sm:flex-row sm:space-x-2 sm:space-y-0 md:flex">
        <PerPage perPage={params.size} onPerPage={handlePerPage} />
        <CustomPagination
          total={total}
          perPage={params.size}
          page={params.page}
          onPageChange={handleChangePage}
        />
      </div>
      <Mobile
        page={params.page}
        onPageChange={handleChangePage}
        hasPrevPage={hasPrevPage}
        hasNextPage={hasNextPage}
      >
        <PerPage perPage={params.size} onPerPage={handlePerPage} />
      </Mobile>
    </div>
  );
}
