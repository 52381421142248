import { LicenseType } from '@/generated';
import { IFilter, ISelectOption } from '@/interfaces';

export const clientFilters: IFilter[] = [
  {
    name: 'license',
    placeholder: (t) => t('License'),
    isMulti: true,
    options: [
      { label: 'Free', value: LicenseType.FREE },
      { label: 'Basic', value: LicenseType.BASIC },
      { label: 'Premium', value: LicenseType.PREMIUM },
      { label: 'Enterprise', value: LicenseType.ENTERPRISE },
      { label: 'All', value: LicenseType.ALL },
    ] satisfies ISelectOption<LicenseType>[],
  },
];
