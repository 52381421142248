import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { faqCategories } from '@/fixtures/faq';
import { Button } from '@/shared/form';
import { cn } from '@/utils';

export default function Categories() {
  const { t } = useTranslation();
  const [params, setSearchParams] = useSearchParams();

  return (
    <ul className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
      {faqCategories.map(({ Icon, text, name }) => {
        const isActive = params.get('category') === name;

        return (
          <li key={name}>
            <Button
              className={cn('card flex w-full items-center justify-center border-2', {
                'border-primary': isActive,
                'card-bordered': !isActive,
              })}
              onClick={() =>
                setSearchParams((prev) => {
                  if (isActive) prev.delete('category');
                  else prev.set('category', name);
                  return prev;
                })
              }
            >
              <div className="card-body items-center">
                <Icon className="h-8 w-8" />
                <h2 className="card-title text-base md:text-xl">{text(t)}</h2>
              </div>
            </Button>
          </li>
        );
      })}
    </ul>
  );
}
