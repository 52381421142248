import { useMutation } from '@tanstack/react-query';
import { RecruitmentCreate } from '@/generated';
import api from '@/libs/api';
import useRefreshToken from '@/services/auth/refreshToken';

async function createRecruitment(data: RecruitmentCreate) {
  const resp = await api.api.createRecruitment(data);
  return resp.data;
}

export default function useCreateRecruitment() {
  const { mutate: refreshUser } = useRefreshToken();

  return useMutation({
    mutationFn: createRecruitment,
    onSuccess: () => {
      refreshUser();
    },
  });
}
