import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { detailsContext } from '@/pages/questionsets/Details';
import useDeleteSection from '@/services/sections/delete';
import useModalState from '@/states/modalState';
import { TModelId } from '@/types';
import CustomActions from '../../actions';
import { SECTIONS_CREATE_MODAL_KEY, sectionsContext } from '../Sections';

type ActionsProps = {
  sectionId: TModelId;
  onClose: () => void;
};

export default function Actions({ sectionId, onClose }: ActionsProps) {
  const { t } = useTranslation();
  const { setActiveSectionId } = useContext(detailsContext);
  const { handleSort, canSort } = useContext(sectionsContext);
  const setModalKey = useModalState((state) => state.setModalKey);

  return (
    <CustomActions
      onEdit={onClose}
      onSort={handleSort}
      useDelete={useDeleteSection}
      resourceId={sectionId}
      resourceName={t('Section')}
      onSuccessfullyDeleted={() => setActiveSectionId(undefined)}
      canSort={canSort}
      onCreate={() => setModalKey(SECTIONS_CREATE_MODAL_KEY)}
    />
  );
}
