import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonActions from '@/shared/custom/ButtonActions';
import CenterModal from '@/shared/modals/CenterModal';

type ConfirmationModalProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export default function ConfirmationModal({ isOpen, onClose, onConfirm }: ConfirmationModalProps) {
  const { t } = useTranslation();

  return (
    <CenterModal isOpen={isOpen} title="Confirmation">
      <div className="space-y-4">
        <p className="description">
          {t('Do you really want to import from template? You might your data.')}
        </p>
        <ButtonActions
          cancelProps={{ onClick: onClose }}
          submitProps={{
            onClick: onConfirm,
            className: 'btn-error',
            text: 'Import',
          }}
        />
      </div>
    </CenterModal>
  );
}
