import React from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import Actions from '@/components/clients/index/Actions';
import { clientFilters } from '@/fixtures/resources/clients';
import { Client } from '@/generated';
import useGetClients from '@/services/clients';
import Head from '@/shared/custom/Head';
import ResourceIndex from '@/shared/resources/Index';
import DateColumn from '@/shared/views/columns/DateColumn';
import TagColumn from '@/shared/views/columns/TagColumn';
import useSettingsState from '@/states/settingsState';

const columnHelper = createColumnHelper<Client>();

const columns = [
  columnHelper.accessor('name', {
    enableSorting: true,
    enableHiding: false,
    meta: { header: (t) => t('Name') },
  }),
  columnHelper.accessor('license.name', {
    cell: (info) => <TagColumn>{info.getValue()}</TagColumn>,
    enableSorting: false,
    enableHiding: true,
    meta: { header: (t) => t('License') },
  }),
  columnHelper.accessor('tier', {
    enableHiding: true,
    meta: { header: () => 'Tier' },
  }),
  columnHelper.accessor('license.nbUsers', {
    enableSorting: false,
    enableHiding: true,
    meta: { header: (t) => t('Number of Users') },
  }),
  columnHelper.accessor('license.nbRecruitments', {
    enableSorting: false,
    enableHiding: true,
    meta: { header: (t) => t('Number of Recruitments') },
  }),
  columnHelper.accessor('createdAt', {
    cell: (info) => <DateColumn date={info.getValue()} />,
    enableSorting: true,
    enableHiding: true,
    meta: { header: (t) => t('Created at') },
  }),
  columnHelper.accessor('updatedAt', {
    cell: (info) => <DateColumn date={info.getValue()} />,
    enableSorting: true,
    enableHiding: true,
    meta: { header: (t) => t('Updated at') },
  }),
  columnHelper.display({
    id: 'actions',
    cell: (info) => <Actions info={info} />,
    enableSorting: false,
    enableHiding: false,
  }),
];

export default function Index() {
  const { t } = useTranslation();
  const title = t('Clients');
  const params = useSettingsState((state) => state.clients.params);
  const { data, isLoading } = useGetClients(params);

  return (
    <>
      <Head title={title} description={title} />
      <ResourceIndex
        resource="clients"
        title={title}
        data={data}
        isLoading={isLoading}
        columns={columns}
        filters={clientFilters}
      />
    </>
  );
}
