import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import useResendActivation from '@/services/auth/resendActivation';
import { Button } from '@/shared/form';
import DialogModal, { DialogModalProps } from '@/shared/modals/DialogModal';

type ActiveAccountModalProps = Pick<DialogModalProps, 'modalRef'> & {
  email: string;
};

export default function ActiveAccountModal({ email, ...props }: ActiveAccountModalProps) {
  const { t } = useTranslation();
  const { mutate: resend, isPending, isSuccess } = useResendActivation();

  return (
    <DialogModal closeOnClickOutside {...props}>
      <h3 className="h3">{t('Active your Account')}</h3>
      {isSuccess ? (
        <div role="alert" className="alert alert-success">
          <CheckCircleIcon className="h-6 w-6" />
          <span>{t('auth.activeAccountModal.sentDescription')}</span>
        </div>
      ) : (
        <>
          <p>{t('auth.activeAccountModal.description')}</p>
          <Button
            onClick={() => resend(email)}
            disabled={isPending}
            isLoading={isPending}
            className="btn btn-primary btn-md btn-block"
          >
            {t('Resend')}
          </Button>
        </>
      )}
    </DialogModal>
  );
}
