import React, { Fragment, createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Container from '@/components/messages/Container';
import Message from '@/components/messages/message';
import Form from '@/components/messages/show/Form';
import { defaultParams } from '@/fixtures';
import { Message as TMessage } from '@/generated';
import useGetRecruitmentMessages from '@/services/messages/show';
import Head from '@/shared/custom/Head';
import HeadingWithLinks from '@/shared/custom/HeadingWithLinks';
import LoadMore from '@/shared/custom/LoadMore';
import useAuthState from '@/states/authState';
import { TModelId } from '@/types';

type TShowContext = {
  scrollToTop: Function;
  editMessage: TMessage | null;
  setEditMessage: React.Dispatch<React.SetStateAction<TMessage | null>>;
  recruitmentId: TModelId;
};

export const showContext = createContext<TShowContext>({
  scrollToTop: () => {},
  editMessage: null,
  setEditMessage: () => {},
  recruitmentId: '',
});

export default function Show() {
  const { t } = useTranslation();
  const title = t('Messages');
  const [editMessage, setEditMessage] = useState<TMessage | null>(null);
  const { recruitmentId } = useParams<'recruitmentId'>();
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useGetRecruitmentMessages({
    ...defaultParams,
    recruitmentId: recruitmentId!,
    size: 20,
  });
  const authUser = useAuthState((state) => state.user!);
  const topRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => topRef.current?.scrollIntoView({ behavior: 'smooth' });
  const value: TShowContext = useMemo(
    () => ({ scrollToTop, editMessage, setEditMessage, recruitmentId: recruitmentId! }),
    [editMessage, recruitmentId],
  );

  useEffect(() => {
    // reset edit message when authUser navigates to different job
    setEditMessage(null);
  }, [recruitmentId]);

  return (
    <div className="flex w-full flex-col">
      <showContext.Provider value={value}>
        <Head title={title} description={title} />
        <HeadingWithLinks
          links={[
            { text: 'Messages', to: '/messages' },
            { text: title, to: '#' },
          ]}
        />
        <Container className="scroll-none relative flex flex-col justify-between overflow-y-auto">
          <div className="flex flex-col space-y-4 p-4">
            <div ref={topRef} />
            {data?.pages.map((page) => (
              <Fragment key={page.page}>
                {page.items.map((message) => (
                  <Message key={message.id} userId={authUser.id} {...message} />
                ))}
              </Fragment>
            ))}
          </div>
          {hasNextPage && (
            <LoadMore
              disabled={isFetchingNextPage}
              isLoading={isFetchingNextPage}
              onClick={() => fetchNextPage()}
            />
          )}
          <Form recruitmentId={recruitmentId!} />
        </Container>
      </showContext.Provider>
    </div>
  );
}
