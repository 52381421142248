import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import isArray from 'lodash/isArray';
import { IFilter } from '@/interfaces';
import useSettingsState, { TResourceKeys } from '@/states/settingsState';
import Filter from './Filter';

type ActiveFiltersProps = {
  resource: TResourceKeys;
  filters?: IFilter[];
};

export default function ActiveFilters({ filters, resource }: ActiveFiltersProps) {
  const { t } = useTranslation();
  const params = useSettingsState((state) => state[resource].params);
  const setResourceSettings = useSettingsState((state) => state.setResourceSettings);

  const activeFilters = filters?.map((filter) => {
    const values = params[filter.name];
    const activeValues: any = isArray(values) ? values : [values];
    const activeOptions = filter.options.filter(({ value }) => activeValues.includes(value));
    return { ...filter, options: activeOptions };
  });

  const handleClearSearchQuery = () => {
    setResourceSettings(resource, { params: { ...params, searchQuery: '' } });
  };

  return (
    <div className="flex flex-wrap gap-2">
      {params.searchQuery && (
        <Filter canRemove onClick={handleClearSearchQuery} icon="search">
          {params.searchQuery}
        </Filter>
      )}
      {activeFilters?.map(({ options, name }) => (
        <Fragment key={name}>
          {options.map((option) => (
            <Filter canRemove={false} icon="filter" key={option.value}>
              {typeof option.label === 'string' ? option.label : option.label(t)}
            </Filter>
          ))}
        </Fragment>
      ))}
    </div>
  );
}
