import { useMutation } from '@tanstack/react-query';
import { SectionUpdate } from '@/generated';
import api from '@/libs/api';
import { TModelId } from '@/types';

export type TSectionEdit = SectionUpdate & {
  sectionId: TModelId;
};

async function editSection({ sectionId, ...data }: TSectionEdit) {
  const resp = await api.api.updateSection(sectionId, data);
  return resp.data;
}

export default function useEditSection() {
  return useMutation({ mutationFn: editSection });
}
