import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';
import useAuthState from '@/states/authState';

async function forgetDevice(data: string) {
  const resp = await api.api.forgetDevice(data);
  return resp.data;
}

export default function useForgetDevice() {
  const removeUserDevice = useAuthState((state) => state.removeUserDevice);

  return useMutation({
    mutationFn: forgetDevice,
    onSuccess: (resp, data) => {
      removeUserDevice(data);
    },
  });
}
