import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import LogoUploader from '@/components/platform/LogoUploader';
import commonFields from '@/fixtures/commonFields';
import validations from '@/fixtures/validations';
import useEditClientMe from '@/services/clients/me/edit';
import useGetClientMe from '@/services/clients/me/get';
import Head from '@/shared/custom/Head';
import { Button, Fields } from '@/shared/form';

const validationSchema = Yup.object({
  name: validations.name,
  fullName: validations.name.nullable().notRequired(),
});

export default function Information() {
  const { t } = useTranslation();
  const { mutate: editClient, isPending } = useEditClientMe();
  const { data: client } = useGetClientMe();

  const title = t('Information');

  return (
    <>
      <Head title={title} description={title} />
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values) => {
          editClient(values, {
            onSuccess: () => {
              toast.success(t('toast.updatedSuccessfully', { model: t('Client') }));
            },
          });
        }}
        initialValues={client}
      >
        <Form className="space-y-4">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <Fields
              fields={[
                {
                  ...commonFields.title,
                  name: 'name',
                  label: t('Name'),
                  tooltip: { text: 'The name of Your Company', className: 'tooltip-right' },
                },
                {
                  ...commonFields.title,
                  isRequired: false,
                  name: 'fullName',
                  label: t('Full Name'),
                  tooltip: { text: 'The Name of the Owner' },
                },
              ]}
            />
          </div>
          <Button
            disabled={isPending}
            isLoading={isPending}
            type="submit"
            className="btn btn-primary btn-md"
          >
            {t('Submit')}
          </Button>
        </Form>
      </Formik>
      <div className="divider" />
      <LogoUploader logoUrl={client.logoUrl} />
    </>
  );
}
