import React from 'react';
import { Button } from '@/shared/form';
import { TIcon } from '@/types';

interface ActionProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  Icon: TIcon;
  tooltip?: string;
  hasIndicator?: boolean;
}

export default function Action({ Icon, tooltip, hasIndicator, ...props }: ActionProps) {
  return (
    <div data-tip={tooltip} className="tooltip tooltip-bottom tooltip-secondary">
      <Button tabIndex={0} {...props} className="btn btn-square btn-ghost btn-neutral">
        <div className="indicator">
          {hasIndicator && <span className="badge indicator-item badge-primary badge-xs" />}
          <Icon className="h-6 w-6" strokeWidth={2} />
        </div>
      </Button>
    </div>
  );
}
