import { useMutation } from '@tanstack/react-query';
import { UpdateQuestionsOrderParams } from '@/generated';
import api from '@/libs/api';
import { TModelId } from '@/types';

export type TSectionSort = UpdateQuestionsOrderParams & {
  ids: TModelId[];
};

async function sortQuestions({ sectionId, ids }: TSectionSort) {
  const resp = await api.api.updateQuestionsOrder({ sectionId }, ids);
  return resp.data;
}

export default function useSortQuestions() {
  return useMutation({ mutationFn: sortQuestions });
}
