import React from 'react';
import { TModelId } from '@/types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export type TSortItem = {
  id: TModelId;
  text: string;
};

export default function Card({ id, text }: TSortItem) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className="card card-bordered card-compact w-full bg-base-200">
        <div className="card-body">
          <h3 className="card-title text-base">{text}</h3>
        </div>
      </div>
    </div>
  );
}
