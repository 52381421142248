import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';

async function activate(token: string) {
  await api.api.activateUser(token);
}

export default function useActivateUser() {
  return useMutation({ mutationFn: activate });
}
