import React from 'react';
import { useTranslation } from 'react-i18next';
import DoNotSave from '@/assets/svg/settings/doNotSave.svg?react';
import SaveOnCloud from '@/assets/svg/settings/saveOnCloud.svg?react';
import SaveOnThisDevice from '@/assets/svg/settings/saveOnThisDevice.svg?react';
import Tabs from '@/components/account/Tabs';
import Option from '@/components/settings/Option';
import ResetToDefault from '@/components/settings/ResetToDefault';
import { ESettingType } from '@/enums';
import Head from '@/shared/custom/Head';
import useSettingsState from '@/states/settingsState';
import { TImportedSvg } from '@/types';

export interface ISettingOption {
  type: ESettingType;
  Icon: TImportedSvg;
}

export default function Index() {
  const { t } = useTranslation();
  const settingsType = useSettingsState((state) => state.type);
  const setSettingsType = useSettingsState((state) => state.setSettingsType);

  const title = t('Settings');
  const options: ISettingOption[] = [
    { type: ESettingType.Cloud, Icon: SaveOnCloud },
    { type: ESettingType.Local, Icon: SaveOnThisDevice },
    { type: ESettingType.Reset, Icon: DoNotSave },
  ];

  return (
    <>
      <Head title={title} description={title} />
      <div className="flex items-center justify-between">
        <h1 className="h1">{title}</h1>
        <ResetToDefault />
      </div>
      <Tabs />
      <ul className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
        {options.map((option) => (
          <Option
            {...option}
            key={option.type}
            isActive={settingsType === option.type}
            onActive={() => setSettingsType(option.type)}
          />
        ))}
      </ul>
    </>
  );
}
