import { useSuspenseQuery } from '@tanstack/react-query';
import api from '@/libs/api';

async function getClientSettings() {
  const resp = await api.api.getClientSettings({ size: 100 });
  return resp.data;
}

export default function useGetClientSettings() {
  return useSuspenseQuery({
    queryFn: getClientSettings,
    staleTime: undefined,
    queryKey: ['clientSettings'],
  });
}
