import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionSetType } from '@/generated';
import { detailsContext } from '@/pages/questionsets/Details';
import useEditQuestionset from '@/services/questionsets/edit';
import { Label } from '@/shared/form';
import useAuthState from '@/states/authState';

export default function AskForLinkedinProfileToggle() {
  const { t } = useTranslation();
  const hasLinkedinFeature = useAuthState((state) => state.user?.userAccess.accessLinkedinFeature);

  const { data } = useContext(detailsContext);
  const { mutate: editQuestionset } = useEditQuestionset();

  if (data.type !== QuestionSetType.QUALIFICATION) return null;

  return (
    <li>
      <div
        data-tip={
          hasLinkedinFeature ? undefined : t('You have to upgrade your plan to access this feature')
        }
        className="tooltip tooltip-left tooltip-secondary flex flex-row items-center"
      >
        <Label
          className="w-auto text-base font-normal"
          htmlFor="askForLinkedinProfile"
          label={t('Ask for Linkedin Profile')}
        />
        <input
          disabled={!hasLinkedinFeature}
          onChange={(e) => {
            if (!data) return;
            editQuestionset({
              questionsetId: data.id,
              userOptions: { askForLinkedinProfile: e.target.checked },
            });
          }}
          defaultChecked={data?.userOptions?.askForLinkedinProfile ?? false}
          type="checkbox"
          id="askForLinkedinProfile"
          className="toggle toggle-primary toggle-sm"
        />
      </div>
    </li>
  );
}
