import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/shared/form';
import Heading, { HeadingProps } from './Heading';

interface HeadingWithCreateLinkProps extends HeadingProps {
  createLink: string;
  canCreate?: boolean;
  create?: { disabled?: boolean; tooltip?: string };
}

export default function HeadingWithCreateLink({
  createLink,
  canCreate = true,
  create,
  children,
  ...props
}: HeadingWithCreateLinkProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Heading {...props}>
      {children}
      {canCreate && (
        <div data-tip={create?.tooltip} className="tooltip tooltip-left tooltip-secondary">
          <Button
            onClick={() => navigate(createLink)}
            disabled={create?.disabled}
            id="create"
            className="btn btn-primary btn-md"
          >
            {t('Create')}
          </Button>
        </div>
      )}
    </Heading>
  );
}
