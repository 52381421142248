import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type TAvailableThemes = 'light' | 'dark';

export interface IThemeState {
  theme: TAvailableThemes;
}

export interface IThemeAction {
  toggleTheme: () => void;
}

const useThemeState = createWithEqualityFn<IThemeState & IThemeAction>()(
  persist(
    immer((set) => ({
      theme: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
      toggleTheme: () => {
        set((state) => {
          state.theme = state.theme === 'dark' ? 'light' : 'dark';
        });
      },
    })),
    { name: 'theme' },
  ),
  shallow,
);

export default useThemeState;
