import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GetApplicantsParams } from '@/generated';
import api from '@/libs/api';

async function applicants(params: GetApplicantsParams) {
  const resp = await api.api.getApplicants(params);
  return resp.data;
}

export default function useGetApplicants(params: GetApplicantsParams) {
  return useQuery({
    queryKey: ['applicants', params],
    queryFn: () => applicants(params),
    placeholderData: keepPreviousData,
    enabled: Boolean(params.questionSetId),
    structuralSharing: false,
  });
}
