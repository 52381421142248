import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { GetQuestionSetsParams } from '@/generated';
import api from '@/libs/api';

async function getQuestionsets(params: GetQuestionSetsParams) {
  const resp = await api.api.getQuestionSets(params);
  return resp.data;
}

export default function useGetQuestionsets(params: GetQuestionSetsParams) {
  return useSuspenseQuery({
    queryKey: ['questionsets', params],
    queryFn: () => getQuestionsets(params),
  });
}

export function useGetApplicantsQuestionsets(params: GetQuestionSetsParams) {
  return useQuery({
    queryKey: ['questionsets', params],
    queryFn: () => getQuestionsets(params),
  });
}
