import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Form from '@/components/clients/Form';
import { ClientCreate } from '@/generated';
import useEditClient from '@/services/clients/edit';
import useGetClient from '@/services/clients/show';

export default function Edit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<'clientId'>();
  const { mutate, isPending } = useEditClient();
  const clientId = params.clientId!;
  const { data } = useGetClient(clientId);

  const handleSubmit = (values: ClientCreate) => {
    mutate(
      { ...values, clientId },
      {
        onSuccess: () => {
          toast.success(t('toast.updatedSuccessfully', { model: t('Client') }));
          navigate('/clients');
        },
      },
    );
  };

  return (
    <Form
      title={t('Edit Client')}
      onSubmit={handleSubmit}
      isSubmitting={isPending}
      initialValues={{
        name: data?.name ?? '',
        domain: data?.domain ?? '',
        fullName: data?.fullName ?? '',
        licenseId: data?.licenseId ?? '',
        isActive: data?.isActive ?? true,
      }}
    />
  );
}
