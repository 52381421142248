import React, { Suspense, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Aside from '@/components/navigation/aside';
import Header from '@/components/navigation/navbar/header';
import { useIsScreenMd } from '@/hooks/useMediaQuery';
import LoadingFallback from '@/shared/LoadingFallback';
import useAsideState from '@/states/asideState';
import { cn } from '@/utils';

export default function AppLayout() {
  const { isOpen, isMaximized, setIsOpen } = useAsideState((state) => state);
  const isScreenMd = useIsScreenMd();
  const location = useLocation();

  // closing aside on route change for mobile devices
  useEffect(() => {
    if (isOpen && !isScreenMd) setIsOpen(false);
  }, [location.pathname]);

  const padding = isMaximized ? 'md:pl-72' : 'md:pl-20';

  return (
    <>
      <Aside />
      <div className={cn('flex flex-grow flex-col transition-all duration-300', isOpen && padding)}>
        <Header />
        <main className="flex flex-grow flex-col space-y-4 overflow-x-hidden px-4 py-4 md:px-8">
          <Suspense fallback={<LoadingFallback />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </>
  );
}
