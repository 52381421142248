import React from 'react';

type ContainerProps = {
  children: React.ReactNode;
};

export default function Container({ children }: ContainerProps) {
  return (
    <div className="container flex flex-grow flex-col items-center justify-center space-y-4 text-center">
      {children}
    </div>
  );
}
