import React from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import Actions from '@/components/users/index/Actions';
import { userFilters } from '@/fixtures/resources/users';
import { User } from '@/generated';
import useGetUsers from '@/services/users';
import Head from '@/shared/custom/Head';
import ResourceIndex from '@/shared/resources/Index';
import DateColumn from '@/shared/views/columns/DateColumn';
import TagColumn from '@/shared/views/columns/TagColumn';
import useAuthState from '@/states/authState';
import useSettingsState from '@/states/settingsState';

const columnHelper = createColumnHelper<User>();

const columns = [
  columnHelper.accessor('firstName', {
    enableSorting: true,
    enableHiding: false,
    meta: { header: (t) => t('First Name') },
  }),
  columnHelper.accessor('lastName', {
    enableSorting: true,
    enableHiding: false,
    meta: { header: (t) => t('Last Name') },
  }),
  columnHelper.accessor('email', {
    enableSorting: true,
    enableHiding: true,
    meta: { header: (t) => t('Email') },
  }),
  columnHelper.accessor('phoneNumber', {
    enableSorting: false,
    enableHiding: true,
    meta: { header: (t) => t('Phone Number') },
  }),
  columnHelper.accessor('userRole', {
    cell: (info) => <TagColumn>{info.getValue()}</TagColumn>,
    enableSorting: false,
    enableHiding: true,
    meta: { header: (t) => t('Role') },
  }),
  columnHelper.accessor('createdAt', {
    cell: (info) => <DateColumn date={info.getValue()} />,
    enableSorting: true,
    enableHiding: true,
    meta: { header: (t) => t('Created at') },
  }),
  columnHelper.accessor('updatedAt', {
    cell: (info) => <DateColumn date={info.getValue()} />,
    enableSorting: true,
    enableHiding: true,
    meta: { header: (t) => t('Updated at') },
  }),
  columnHelper.display({
    id: 'actions',
    cell: (info) => <Actions info={info} />,
    enableSorting: false,
    enableHiding: false,
  }),
];

export default function Index() {
  const { t } = useTranslation();
  const title = t('Users');
  const params = useSettingsState((state) => state.users.params);
  const canCreateUser = useAuthState((state) => state.user?.userAccess.createUser);
  const { data, isLoading } = useGetUsers(params);

  return (
    <>
      <Head title={title} description={title} />
      <ResourceIndex
        resource="users"
        title={title}
        columns={columns}
        data={data}
        isLoading={isLoading}
        filters={userFilters}
        create={{
          disabled: !canCreateUser,
          tooltip: canCreateUser
            ? undefined
            : t('You have to upgrade your plan to access this feature'),
        }}
      />
    </>
  );
}
