import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Notification as TNotification } from '@/generated';
import useSeenNotification from '@/services/notifications/seen';
import { Button } from '@/shared/form';
import { cn } from '@/utils';

export default function Notification({ payload, isNew, id, startDate }: TNotification) {
  const { mutate: seenNotification } = useSeenNotification();

  return (
    <Button
      onClick={() => seenNotification(id)}
      className={cn('card card-compact duration-150', {
        'bg-base-100': !isNew,
        'bg-primary/20': isNew,
      })}
    >
      <div className="card-body text-start">
        <p className="font-medium">{payload}</p>
        {startDate && (
          <p className="description text-xs">
            {formatDistanceToNow(startDate, { addSuffix: true })}
          </p>
        )}
      </div>
    </Button>
  );
}
