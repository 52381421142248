import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { GetRecruitmentsParams } from '@/generated';
import api from '@/libs/api';
import { getNextPageParam } from '@/utils';

async function getRecruitments(params: GetRecruitmentsParams) {
  const resp = await api.api.getRecruitments(params);
  return resp.data;
}

export default function useGetRecruitments(params: GetRecruitmentsParams) {
  return useQuery({
    queryKey: ['recruitments', params],
    queryFn: () => getRecruitments(params),
    placeholderData: keepPreviousData,
  });
}

export function useGetInfiniteRecruitments(params: GetRecruitmentsParams) {
  return useInfiniteQuery({
    queryKey: ['recruitments', 'infinite', params],
    queryFn: ({ pageParam }) => getRecruitments({ ...params, page: pageParam }),
    initialPageParam: params.page,
    getNextPageParam,
  });
}
