import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { TModelId } from '@/types';

export interface IApplicantsState {
  compareCount: number;
  applicantsId: TModelId[];
}

export interface IApplicantsAction {
  setCompareCount: (count: number) => void;
  setApplicantsId: (ids: TModelId[]) => void;
  clearApplicantsId: () => void;
}

const useApplicantsState = createWithEqualityFn<IApplicantsState & IApplicantsAction>(
  (set) => ({
    compareCount: 5,
    applicantsId: [],
    setCompareCount: (count) => set((state) => ({ ...state, count })),
    setApplicantsId: (ids) => set((state) => ({ ...state, applicantsId: ids })),
    clearApplicantsId: () => set((state) => ({ ...state, applicantsId: [] })),
  }),
  shallow,
);

export default useApplicantsState;
