import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';
import WizardButtons from '@/shared/custom/WizardButtons';

type ActionsProps = {
  activePageIndex: number;
  totalPages: number;
  setActivePageIndex: React.Dispatch<React.SetStateAction<number>>;
  onCancel: () => void;
  isSubmitDisabled?: boolean;
};

export default function Actions({
  activePageIndex,
  totalPages,
  setActivePageIndex,
  onCancel,
  isSubmitDisabled,
}: ActionsProps) {
  const { errors } = useFormikContext();
  const { t } = useTranslation();
  const isTheLastPage = totalPages > 0 && activePageIndex === totalPages - 1;
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (isTheLastPage) setTimeout(() => setIsSubmit(true), 300);
    else setIsSubmit(false);
  }, [isTheLastPage]);

  const handlePreviousPage = () => {
    setActivePageIndex(activePageIndex - 1);
  };

  const handleNextPage = () => {
    if (totalPages - 1 > activePageIndex) {
      setActivePageIndex(activePageIndex + 1);
    }
  };

  return (
    <WizardButtons
      submitProps={{
        onClick: () => {
          if (!isTheLastPage) handleNextPage();
          if (isSubmit && Object.keys(errors).length > 0) {
            toast.error('Please answer all of the questions carefully.');
          }
        },
        disabled: isSubmitDisabled,
        type: isSubmit ? 'submit' : 'button',
        text: isTheLastPage ? t('Submit') : t('Next'),
      }}
      previousProps={{
        onClick: handlePreviousPage,
        disabled: activePageIndex === 0,
      }}
      cancelProps={{ onClick: onCancel }}
    />
  );
}
