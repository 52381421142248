import React from 'react';
import { Helmet } from 'react-helmet-async';

type HeadProps = {
  title: string;
  description: string;
  children?: React.ReactNode;
};

export default function Head({ children, title, description }: HeadProps) {
  const staticText = 'DivERS';

  return (
    <Helmet>
      <title>{`${title} - ${staticText}`}</title>
      <meta name="description" content={`${description} - ${staticText}`} />
      {children}
    </Helmet>
  );
}
