import React, { Suspense } from 'react';
import { Navigate, Outlet, useNavigate, useRoutes } from 'react-router-dom';
import SettingsSync from '@/components/SettingsSync';
import Starter from '@/components/starter';
import Splash from '@/components/starter/Splash';
import AppLayout from '@/layouts/AppLayout';
import PlatformLayout from '@/layouts/PlatformLayout';
import ApplicantsLayout from '@/layouts/applicants/IndexLayout';
import MessagesLayout from '@/layouts/messages/IndexLayout';
import QuestionsetsEditLayout from '@/layouts/questionsets/EditLayout';
import QuestionsetsIndexLayout from '@/layouts/questionsets/IndexLayout';
import Account from '@/pages/Account';
import Help from '@/pages/Help';
import Home from '@/pages/Home';
import Questionnaire from '@/pages/Questionnaire';
import ApplicantsCompare from '@/pages/applicants/Compare';
import ApplicantsCreate from '@/pages/applicants/Create';
import ApplicantsIndex from '@/pages/applicants/Index';
import ApplicantsSectionCompare from '@/pages/applicants/SectionCompare';
import ApplicantsSelection from '@/pages/applicants/Selection';
import ApplicantsShow from '@/pages/applicants/Show';
import ActivateUser from '@/pages/auth/ActivateUser';
import ForgotPassword from '@/pages/auth/ForgotPassword';
import Login from '@/pages/auth/Login';
import Register from '@/pages/auth/Register';
import ResetPassword from '@/pages/auth/ResetPassword';
import ClientsCreate from '@/pages/clients/Create';
import ClientsEdit from '@/pages/clients/Edit';
import ClientsIndex from '@/pages/clients/Index';
import Error403 from '@/pages/errors/403';
import Error404 from '@/pages/errors/404';
import MessagesIndex from '@/pages/messages/Index';
import MessagesShow from '@/pages/messages/Show';
import PlatformInformation from '@/pages/platform/Information';
import PlatformLicense from '@/pages/platform/License';
import PlatformSettings from '@/pages/platform/Settings';
import QuestionsetsAi from '@/pages/questionsets/Ai';
import QuestionsetsCopy from '@/pages/questionsets/Copy';
import QuestionsetsCreate from '@/pages/questionsets/Create';
import QuestionsetsDetails from '@/pages/questionsets/Details';
import QuestionsetsEdit from '@/pages/questionsets/Edit';
import QuestionsetsPreview from '@/pages/questionsets/Preview';
import QuestionsetsSetup from '@/pages/questionsets/Setup';
import QuestionsetsTemplates from '@/pages/questionsets/Templates';
import RecruitmentsCreate from '@/pages/recruitments/Create';
import RecruitmentsEdit from '@/pages/recruitments/Edit';
import RecruitmentsIndex from '@/pages/recruitments/Index';
import SettingsIndex from '@/pages/settings/Index';
import UsersCreate from '@/pages/users/Create';
import UsersEdit from '@/pages/users/Edit';
import UsersIndex from '@/pages/users/Index';
import useAuthState from '@/states/authState';
import WhatIsNew from './pages/WhatIsNew';

export default function App() {
  const { authUser, isLoggedIn } = useAuthState((state) => ({
    isLoggedIn: state.isLoggedIn,
    authUser: state.user,
  }));
  const navigate = useNavigate();

  const handleRedirectToHome = () => navigate('/');

  return useRoutes([
    {
      path: '/',
      element: (
        <Starter>
          <SettingsSync>
            <Outlet />
          </SettingsSync>
        </Starter>
      ),
      children: [
        {
          path: '/',
          element: isLoggedIn ? <Navigate to="/" /> : <Outlet />,
          children: [
            { path: '/activate-user', element: <ActivateUser /> },
            { path: '/login', element: <Login /> },
            { path: '/register', element: <Register /> },
            { path: '/forgot-password', element: <ForgotPassword /> },
            { path: '/reset-password', element: <ResetPassword /> },
          ],
        },
        { path: '/questions/:jobId', element: <Questionnaire /> },
        {
          path: '/',
          element: isLoggedIn ? <AppLayout /> : <Navigate to="/login" />,
          children: [
            { index: true, element: <Home /> },
            { path: '/recruitments', element: <RecruitmentsIndex /> },
            { path: '/recruitments/create', element: <RecruitmentsCreate /> },
            { path: '/recruitments/:recruitmentId/edit', element: <RecruitmentsEdit /> },
            {
              path: '/recruitments/:recruitmentId/questionsets',
              element: <QuestionsetsIndexLayout />,
              children: [{ path: 'create', element: <QuestionsetsCreate /> }],
            },
            {
              path: '/questionsets/:questionsetId',
              element: <QuestionsetsEditLayout />,
              children: [
                { path: 'details', element: <QuestionsetsDetails /> },
                { path: 'setup', element: <QuestionsetsSetup /> },
                { path: 'edit', element: <QuestionsetsEdit /> },
                { path: 'templates', element: <QuestionsetsTemplates /> },
                { path: 'ai', element: <QuestionsetsAi /> },
                { path: 'copy', element: <QuestionsetsCopy /> },
              ],
            },
            { path: '/questionsets/:questionsetId/preview', element: <QuestionsetsPreview /> },
            { path: '/applicants', element: <ApplicantsSelection /> },
            {
              path: '/questionsets/:questionsetId/applicants',
              element: <ApplicantsLayout />,
              children: [
                { index: true, element: <ApplicantsIndex /> },
                { path: ':applicantId', element: <ApplicantsShow /> },
                { path: 'create', element: <ApplicantsCreate /> },
                { path: 'compare', element: <ApplicantsCompare /> },
                { path: 'compare/:sectionId', element: <ApplicantsSectionCompare /> },
              ],
            },
            {
              path: '/users',
              element: authUser?.userAccess.viewUserPage ? (
                <Outlet />
              ) : (
                <Error403 onGoBackHome={handleRedirectToHome} />
              ),
              children: [
                { index: true, element: <UsersIndex /> },
                { path: 'create', element: <UsersCreate /> },
                { path: ':userId/edit', element: <UsersEdit /> },
              ],
            },
            {
              path: '/clients',
              element: authUser?.userAccess.viewClientPage ? (
                <Outlet />
              ) : (
                <Error403 onGoBackHome={handleRedirectToHome} />
              ),
              children: [
                { index: true, element: <ClientsIndex /> },
                { path: 'create', element: <ClientsCreate /> },
                { path: ':clientId/edit', element: <ClientsEdit /> },
              ],
            },
            {
              path: '/messages',
              element: <MessagesLayout />,
              children: [
                { index: true, element: <MessagesIndex /> },
                { path: ':recruitmentId', element: <MessagesShow /> },
              ],
            },
            {
              path: '/platform',
              element: <PlatformLayout />,
              children: [
                { index: true, element: <Navigate to="/platform/settings" /> },
                { path: '/platform/settings', element: <PlatformSettings /> },
                { path: '/platform/License', element: <PlatformLicense /> },
                {
                  path: '/platform/information',
                  element: authUser?.userAccess.viewClientInformationPage ? (
                    <PlatformInformation />
                  ) : (
                    <Error403 onGoBackHome={handleRedirectToHome} />
                  ),
                },
              ],
            },
            { path: '/settings', element: <SettingsIndex /> },
            { path: '/account', element: <Account /> },
            { path: '/help', element: <Help /> },
            { path: '/what-is-new', element: <WhatIsNew /> },
          ],
        },
      ],
    },
    {
      path: '/questionnaire/:questionsetId/:link',
      element: (
        <Suspense fallback={<Splash />}>
          <Questionnaire />
        </Suspense>
      ),
    },
    { path: '*', element: <Error404 onGoBackHome={handleRedirectToHome} /> },
  ]);
}
