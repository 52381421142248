import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EyeSlashIcon } from '@heroicons/react/24/outline';
import { optionsContext } from '../../Options';
import Visibility from './Visibility';

export default function Hidden() {
  const { table } = useContext(optionsContext);
  const { t } = useTranslation();

  const columns = table
    .getAllColumns()
    .filter((column) => column.getCanHide() && !column.getIsVisible());

  if (columns.length === 0) return null;

  const handleShowAll = () => table.toggleAllColumnsVisible(true);

  return (
    <Visibility
      heading={t('Hidden')}
      onTextButtonClick={handleShowAll}
      textButton={t('Show All')}
      columns={columns}
      ActionButtonIcon={EyeSlashIcon}
    />
  );
}
