import React from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { CellContext } from '@tanstack/react-table';
import BaseActions from '@/components/resources/index/actions';
import { Applicant } from '@/generated';
import { useDialogModal } from '@/hooks';
import { TIndexLayoutContext } from '@/layouts/applicants/IndexLayout';
import useDeleteApplicant from '@/services/applicants/delete';
import useRejectApplicants from '@/services/applicants/reject';
import { Button } from '@/shared/form';
import useApplicantsState from '@/states/applicantsState';
import RejectModal, { TOnRejectValues } from '../RejectModal';

type ActionsProps = {
  info: CellContext<Applicant, unknown>;
};

export default function Actions({ info }: ActionsProps) {
  const { t } = useTranslation();
  const { mutate: deleteApplicant, isPending: isDeleting } = useDeleteApplicant();
  const queryClient = useQueryClient();
  const { modalRef, handleOpenModal, handleCloseModal } = useDialogModal();
  const { mutate: rejectApplicants, isPending: isRejecting } = useRejectApplicants();
  const { questionset } = useOutletContext<TIndexLayoutContext>();
  const applicantsId = useApplicantsState((state) => state.applicantsId);
  const isInSelectedApplicants = applicantsId.includes(info.row.original.id);

  const handleReject = (values: TOnRejectValues) => {
    rejectApplicants(
      {
        includeApplicantScore: values.includeApplicantScore,
        questionsetId: questionset.id,
        rejectedApplicants: [{ applicantId: info.row.original.id, rejectedReason: values.reason }],
      },
      {
        onSuccess: () => {
          toast.success("You've rejected an applicant successfully.");
          handleCloseModal();
        },
      },
    );
  };

  if (isInSelectedApplicants) return null;

  return (
    <BaseActions
      editAction={{ state: 'hidden' }}
      deleteAction={{
        modalTitle: `${t('Delete')} ${info.row.original.tag}`,
        isDeleting,
        onDelete: (deleteModalRef) => {
          deleteApplicant(info.row.original.id, {
            onSuccess: () => {
              toast.success(t('toast.deletedSuccessfully', { model: t('Applicant') }));
              deleteModalRef.current?.close();
              queryClient.invalidateQueries({ queryKey: ['applicants'] });
            },
          });
        },
      }}
    >
      <RejectModal
        title="Reject Applicant"
        modalRef={modalRef}
        onClose={handleCloseModal}
        isRejecting={isRejecting}
        onReject={handleReject}
      />
      {!info.row.original.rejectedTimestamp && (
        <div data-tip="Reject" className="tooltip tooltip-secondary">
          <Button onClick={handleOpenModal} className="action">
            <XCircleIcon className="h-6 w-6" />
          </Button>
        </div>
      )}
    </BaseActions>
  );
}
