import { useMutation } from '@tanstack/react-query';
import { ClientCreate, CreateClientParams } from '@/generated';
import api from '@/libs/api';

export type TClientCreate = CreateClientParams & ClientCreate;

async function createClient({ licenseId, ...data }: TClientCreate) {
  const resp = await api.api.createClient({ licenseId }, data);
  return resp.data;
}

export default function useCreateClient() {
  return useMutation({ mutationFn: createClient });
}
