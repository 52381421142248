import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BellIcon } from '@heroicons/react/24/outline';
import { ENotificationFilter } from '@/enums';
import { defaultParams } from '@/fixtures';
import useGetNotification from '@/services/notifications';
import useDeleteNotifications from '@/services/notifications/delete';
import { Button } from '@/shared/form';
import DropDown from '../header/DropDown';
import Body from './Body';
import Filters from './Filters';
import Header from './Header';

export default function Notifications() {
  const [currentFilter, setCurrentFilter] = useState<ENotificationFilter>(ENotificationFilter.All);
  const queryResult = useGetNotification({
    ...defaultParams,
    onlyActive: true,
    onlyNotSeen: currentFilter === ENotificationFilter.Unread,
  });
  const { mutate: deleteNotifications, isPending } = useDeleteNotifications();
  const { t } = useTranslation();

  const { data } = queryResult;
  const allCount = data?.pages[0].total;
  const unreadCount =
    data?.pages.flatMap((page) => page.items.filter((notification) => notification.isNew)).length ??
    0;

  return (
    <DropDown
      hasIndicator={unreadCount > 0}
      tooltip={t('Notifications')}
      Icon={BellIcon}
      aria-label={t('Notifications')}
    >
      <Header />
      <Filters
        setCurrentFilter={setCurrentFilter}
        currentFilter={currentFilter}
        allCount={allCount}
        unreadCount={unreadCount}
      />
      <Body queryResult={queryResult} />
      <div className="divider" />
      <Button
        onClick={() => deleteNotifications()}
        isLoading={isPending}
        disabled={isPending}
        className="btn btn-outline btn-error btn-sm btn-block"
      >
        {t('Clear All')}
      </Button>
    </DropDown>
  );
}
