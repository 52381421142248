/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table as TTable, flexRender } from '@tanstack/react-table';
import Tr from '@/components/table/Tr';
import Empty from '@/shared/views/Empty';
import { TModelId, TViewLink } from '@/types';
import { cn } from '@/utils';

export type TableProps = {
  table: TTable<unknown>;
  viewLink?: TViewLink;
  selectedIds?: TModelId[];
};

export default function Table({ table, viewLink, selectedIds }: TableProps) {
  const { t } = useTranslation();

  if (table.getRowModel().rows.length <= 0) return <Empty />;

  return (
    <div className="overflow-x-auto overflow-y-hidden rounded-lg">
      <div className="relative overflow-x-scroll">
        <table className="table table-zebra table-lg">
          <thead className="bg-base-200 text-sm">
            {table.getHeaderGroups().map((headerGroups) => (
              <tr key={headerGroups.id}>
                {headerGroups.headers.map((header) => (
                  <th colSpan={header.colSpan} key={header.id}>
                    <div
                      {...{
                        className: cn('flex items-center gap-2', {
                          'cursor-pointer': header.column.getCanSort(),
                        }),
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.getContext().column.columnDef.meta?.header(t) ??
                          header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr viewLink={viewLink && viewLink(row)} key={row.id}>
                {row.getVisibleCells().map((call) => (
                  <th
                    className={cn('font-normal', {
                      'bg-primary/15': selectedIds?.includes(row.id),
                    })}
                    key={call.id}
                  >
                    {flexRender(call.column.columnDef.cell, call.getContext())}
                  </th>
                ))}
              </Tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
