/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ComputerDesktopIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { UAParser } from 'ua-parser-js';
import useForgetDevice from '@/services/auth/forgetDevice';
import { Button } from '@/shared/form';
import useAuthState from '@/states/authState';

const getIcon = (osName?: string) => {
  if (!osName) return ComputerDesktopIcon;
  if (['Linux', 'Windows', 'Mac'].includes(osName)) return ComputerDesktopIcon;
  return PhoneIcon;
};

export default function Devices() {
  const devices = useAuthState((state) => state.user!.devices);
  const { mutate: forgetDevice } = useForgetDevice();
  const { t } = useTranslation();

  return (
    <>
      <h3 className="text-xl font-bold text-primary">{t('Saved Devices')}</h3>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {devices?.map((device, i) => {
          const [ip, userAgent] = device.split('___');
          const agent = UAParser(userAgent);
          const Icon = getIcon(agent.os.name);

          return (
            <div key={i} className="card bg-base-300">
              <div className="card-body flex-row flex-wrap items-center justify-between gap-4">
                <div className="flex items-center gap-4">
                  <Icon className="h-8 w-8" />
                  <div className="flex-col">
                    <h4 className="card-title">
                      {agent.os.name} ({agent.browser.name})
                    </h4>
                    <p className="text-sm font-medium">{ip}</p>
                  </div>
                </div>
                <div className="card-actions">
                  <Button onClick={() => forgetDevice(device)} className="btn btn-error btn-sm">
                    {t('Forget')}
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
