import { TFunction } from 'i18next';
import * as Yup from 'yup';
import 'yup-phone';

const validations = {
  name: Yup.string().required().min(2).max(255),
  email: Yup.string().email().required().max(255),
  password: Yup.string().min(8).max(128).required(),
  passwordConfirmation: (t: TFunction, passwordField = 'password') =>
    validations.password.oneOf([Yup.ref(passwordField)], t('Passwords must Match')).required(),
  title: Yup.string().required().min(3).max(100),
  description: Yup.string().nullable().min(3).max(255),
  longTitle: Yup.string().required().min(3).max(1_000),
  longDescription: Yup.string().nullable().min(3).max(10_000),
  phoneNumber: (t: TFunction) =>
    Yup.string()
      .nullable()
      .min(3)
      .max(50)
      .when({
        is: (value: string) => value && value.length > 0,
        then: Yup.string().phone('IN', false, t('errors.string.phone')),
      }),
  agree: Yup.boolean().isTrue().required(),
};

export default validations;
