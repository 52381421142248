import React, { useState } from 'react';
import { Question as TQuestion } from '@/generated';
import { Label } from '@/shared/form';
import Edit from '../Edit';
import Actions from './Actions';
import Field from './Field';

export default function Question(question: TQuestion) {
  const { id, number, title, description, type, choices, sectionId } = question;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const inputId = `question-${id}`;

  return (
    <div className="space-y-4 py-4">
      <Edit
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        questionId={id}
        initialValues={question}
      />
      <div className="flex flex-col gap-2 md:flex-row md:items-center">
        <Label
          isRequired={false}
          htmlFor={inputId}
          tooltip={{ text: description ?? undefined }}
          label={`${number} - ${title}`}
        />
        <Actions onEdit={() => setIsEditModalOpen(true)} questionId={id} sectionId={sectionId} />
      </div>
      <Field type={type} choices={choices!} id={id} />
    </div>
  );
}
