import React from 'react';

export default function RecruitmentSkeleton() {
  return (
    <div className="card card-compact h-28 w-full bg-base-200">
      <div className="card-body flex-row gap-4">
        <div className="skeleton h-20 w-20 rounded-full" />
        <div className="space-y-2">
          <div className="skeleton h-7 w-40" />
          <div className="skeleton h-12 w-52" />
        </div>
      </div>
    </div>
  );
}
