import { useMutation } from '@tanstack/react-query';
import api from '@/libs/api';
import { TModelId } from '@/types';
import { download } from '@/utils';

async function exportApplicants(questionSetId: TModelId) {
  const resp = await api.api.exportApplicants({ questionSetId }, { format: 'blob' });
  return resp.data;
}

export default function useExportApplicants() {
  return useMutation({
    mutationFn: exportApplicants,
    onSuccess: (data) => download(data, 'applicants.xlsx'),
  });
}
