const config = {
  baseBackendUrl: import.meta.env.VITE_BASE_BACKEND_URL,
  apiUrl: import.meta.env.VITE_API_URL,
  isProduction: import.meta.env.PROD,
  websiteUrl: import.meta.env.VITE_WEBSITE_URL,
  messageRefreshIntervalMs: 1000 * 60 * 2, // 2 min
  refreshTokenIntervalMs: 1000 * 60 * 28, // access token expires in 30 min, so we refresh it on 28 min
  settingsKey: 'settings',
  tourKey: 'tour',
  whatIsNewKey: 'whatIsNew',
  recruitmentBookmarkSettingName: 'bookmarked_recruitments',
};

export default config;
