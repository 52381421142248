import { useMutation } from '@tanstack/react-query';
import { UserUpdatePassword } from '@/generated';
import api from '@/libs/api';

async function changePassword(data: UserUpdatePassword) {
  const resp = await api.api.changePassword(data);
  return resp.data;
}

export default function useChangePassword() {
  return useMutation({ mutationFn: changePassword });
}
