import { initReactI18next } from 'react-i18next';
import i18n, { TFunction } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { setLocale } from 'yup';
import da from '@/assets/locales/da.json';
import en from '@/assets/locales/en.json';
import config from '@/fixtures/config';

export const defaultLang = 'en';
export const resources = {
  en: { translation: en },
  da: { translation: da },
} as const;

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      debug: !config.isProduction,
      fallbackLng: defaultLang,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      resources,
    },
    (_: unknown, t: TFunction) => {
      setLocale({
        mixed: { required: t('errors.mixed.required') },
        string: {
          email: t('errors.string.email'),
          min: ({ min }) => t('errors.string.min', { min }),
          max: ({ max }) => t('errors.string.max', { max }),
          length: ({ length }) => t('errors.string.length', { length }),
        },
        number: {
          min: ({ min }) => t('errors.number.min', { min }),
          max: ({ max }) => t('errors.number.max', { max }),
        },
      });
    },
  );
