import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Button } from '@/shared/form';

type MobileProps = {
  children: React.ReactNode;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  onPageChange: (page: number) => void;
  page: number;
};

export default function Mobile({
  children,
  hasPrevPage,
  hasNextPage,
  onPageChange,
  page,
}: MobileProps) {
  const { t } = useTranslation();

  const handleNextPage = () => onPageChange(page + 1);
  const handlePreviousPage = () => onPageChange(page - 1);
  const className = 'btn btn-circle btn-sm';

  return (
    <div className="flex w-full items-center justify-between md:hidden">
      <Button
        className={className}
        disabled={!hasNextPage}
        onClick={handleNextPage}
        aria-label={t('Next Page')}
      >
        <ChevronLeftIcon className="h-6 w-6" />
      </Button>
      {children}
      <Button
        className={className}
        disabled={!hasPrevPage}
        onClick={handlePreviousPage}
        aria-label={t('Previous Page')}
      >
        <ChevronRightIcon className="h-6 w-6" />
      </Button>
    </div>
  );
}
