/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '@/shared/form';
import useTourState from '@/states/tourState';

export default function ShowAgain() {
  const showAgain = useTourState((state) => state.showAgain);
  const { t } = useTranslation();
  const setTour = useTourState((state) => state.setTour);

  const handleToggleShowAgain = () => setTour({ showAgain: !showAgain });

  return (
    <div className="mb-4 flex items-center">
      <input
        onChange={handleToggleShowAgain}
        type="checkbox"
        className="toggle toggle-primary"
        checked={showAgain}
      />
      <Label className="ml-3" label={t('Show me Again')} htmlFor="showAgain" />
    </div>
  );
}
