import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { cn } from '@/utils';

type TooltipProps = {
  text?: string;
  className?: string;
};

export default function Tooltip({ text, className }: TooltipProps) {
  if (!text) return null;

  return (
    <div data-tip={text} className={cn('group tooltip tooltip-secondary z-40', className)}>
      <InformationCircleIcon
        aria-label="information circle"
        strokeWidth={2}
        className="inline-block h-5 w-5"
      />
    </div>
  );
}
