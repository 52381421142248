/* eslint-disable */

/* tslint:disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

/** ApiApplicant */
export interface ApiApplicant {
  /** Createdat */
  createdAt: string | null;
  /** Externalid */
  externalId: string;
  /** Name */
  name: string;
}

/** ApiJob */
export interface ApiJob {
  /** Advertisement */
  advertisement: string | null;
  /** Createdat */
  createdAt: string | null;
  /** Deadline */
  deadline: string | null;
  /** Description */
  description: string | null;
  /** Externalid */
  externalId: string;
  /** Status */
  status: string | null;
  /** Title */
  title: string;
}

/** Applicant */
export interface Applicant {
  /**
   * Answersfull
   * @default {}
   */
  answersFull?: Record<string, ApplicantAnswerFull> | null;
  /** Completedtimestamp */
  completedTimestamp?: string | null;
  /** Createdat */
  createdAt: string | null;
  /** Email */
  email?: string | null;
  /**
   * Externalid
   * @maxLength 255
   */
  externalId: string;
  /** @default {} */
  extraInfo?: ApplicantExtraInfo | null;
  /** Id */
  id: string;
  /** Rank */
  rank?: number | null;
  /** Rejectedtimestamp */
  rejectedTimestamp?: string | null;
  /**
   * Scoresections
   * @default {}
   */
  scoreSections?: Record<string, ApplicantScoreSection> | null;
  /** @default {} */
  scoreTotal?: ApplicantScoreTotal | null;
  /** Tag */
  tag?: string | null;
  type: ApplicantType;
  /** Updatedat */
  updatedAt: string | null;
}

/** ApplicantAnswerFull */
export interface ApplicantAnswerFull {
  /**
   * Answervalue
   * @maxLength 4096
   */
  answerValue: string;
  /** Questiondescription */
  questionDescription?: string | null;
  /**
   * Questiontitle
   * @maxLength 4096
   */
  questionTitle: string;
  questionType: QuestionType;
  /** Questionweight */
  questionWeight: number;
  /** Scorevalue */
  scoreValue?: number | null;
  /** Scorevaluenormalized */
  scoreValueNormalized?: number | null;
  /** Sectionid */
  sectionId: string;
}

/** ApplicantCreate */
export interface ApplicantCreate {
  /**
   * Answers
   * @default {}
   */
  answers?: object | null;
  /** Completedtimestamp */
  completedTimestamp?: string | null;
  /** Email */
  email?: string | null;
  /** Emailencrypted */
  emailEncrypted?: string | null;
  /**
   * Externalid
   * @maxLength 255
   */
  externalId: string;
  /**
   * Extrainfo
   * @default {}
   */
  extraInfo?: object | null;
  /** Rejectedtimestamp */
  rejectedTimestamp?: string | null;
  /** Tag */
  tag?: string | null;
  type: ApplicantType;
}

/** ApplicantEducation */
export interface ApplicantEducation {
  /** Degree */
  degree?: string | null;
  /** Enddate */
  endDate?: string | null;
  /** Field */
  field?: string | null;
  /** Institution */
  institution?: string | null;
  /** Startdate */
  startDate?: string | null;
}

/** ApplicantExperience */
export interface ApplicantExperience {
  /** Company */
  company?: string | null;
  /** Enddate */
  endDate?: string | null;
  /** Startdate */
  startDate?: string | null;
  /** Title */
  title?: string | null;
}

/** ApplicantExternal */
export interface ApplicantExternal {
  /** Email */
  email?: string | null;
  /**
   * Externalid
   * @maxLength 255
   */
  externalId: string;
  /** Id */
  id: string;
  /** Rejectedtimestamp */
  rejectedTimestamp?: string | null;
  /** Tag */
  tag?: string | null;
  type: ApplicantType;
}

/** ApplicantExtraInfo */
export interface ApplicantExtraInfo {
  /** Educations */
  educations?: ApplicantEducation[] | null;
  /** Experiences */
  experiences?: ApplicantExperience[] | null;
}

/** ApplicantRejected */
export interface ApplicantRejected {
  /** Applicantid */
  applicantId: string | number;
  /** Rejectedreason */
  rejectedReason?: string | null;
}

/** ApplicantScoreSection */
export interface ApplicantScoreSection {
  /** Iscompleted */
  isCompleted: boolean;
  /** Scorevalue */
  scoreValue: number;
  /** Scorevaluenormalized */
  scoreValueNormalized: number;
  /** Sectiontitle */
  sectionTitle: string;
}

/** ApplicantScoreTotal */
export interface ApplicantScoreTotal {
  /** Iscompleted */
  isCompleted: boolean;
  /** Scorevalue */
  scoreValue: number;
  /** Scorevaluenormalized */
  scoreValueNormalized: number;
}

/** ApplicantStatus */
export enum ApplicantStatus {
  ALL = 'ALL',
  REJECTED = 'REJECTED',
  UNDER_SCREENING = 'UNDER_SCREENING',
}

/** ApplicantType */
export enum ApplicantType {
  FAKE = 'FAKE',
  TEST = 'TEST',
  REAL = 'REAL',
}

/** ApplicantUpdate */
export interface ApplicantUpdate {
  /**
   * Answers
   * @default {}
   */
  answers?: object | null;
  /** Email */
  email?: string | null;
  /** Externalid */
  externalId?: string | null;
  /** Rejectedtimestamp */
  rejectedTimestamp?: string | null;
  /** Tag */
  tag?: string | null;
  type?: ApplicantType | null;
}

/** AsyncTask */
export interface AsyncTask {
  /** Createdat */
  createdAt: string | null;
  /** Finishedat */
  finishedAt: string | null;
  /** Id */
  id: string;
  /**
   * Payload
   * @default {}
   */
  payload?: object;
  state: AsyncTaskState;
  /** Type */
  type: string;
  /** Updatedat */
  updatedAt: string | null;
}

/** AsyncTaskState */
export enum AsyncTaskState {
  QUEUED = 'QUEUED',
  SUBMITTED = 'SUBMITTED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  MISSED = 'MISSED',
}

/** AuthForm */
export interface AuthForm {
  /** Client Id */
  client_id?: string | null;
  /** Client Secret */
  client_secret?: string | null;
  /** Grant Type */
  grant_type?: string | null;
  /** Password */
  password: string;
  /**
   * Scope
   * @default ""
   */
  scope?: string;
  /** Username */
  username: string;
}

/** Candidate */
export interface Candidate {
  /** Address */
  address?: string | null;
  /** Clientid */
  clientId?: string | null;
  /** Createdat */
  createdAt: string | null;
  /** Department */
  department?: string | null;
  /** Email */
  email?: string | null;
  /** Firstname */
  firstName?: string | null;
  /** Id */
  id: string;
  /** Image */
  image?: string | null;
  /** Lastname */
  lastName?: string | null;
  /** Phonenumber */
  phoneNumber?: string | null;
  type: CandidateType;
  /**
   * Uniqueid
   * @maxLength 255
   */
  uniqueId: string;
  /** Updatedat */
  updatedAt: string | null;
  /** Username */
  username?: string | null;
}

/** CandidateCreate */
export interface CandidateCreate {
  /** Address */
  address?: string | null;
  /** Department */
  department?: string | null;
  /** Email */
  email?: string | null;
  /** Firstname */
  firstName?: string | null;
  /** Image */
  image?: string | null;
  /** Lastname */
  lastName?: string | null;
  /** Phonenumber */
  phoneNumber?: string | null;
  /**
   * Uniqueid
   * @maxLength 255
   */
  uniqueId: string;
  /** Username */
  username?: string | null;
}

/** CandidateType */
export enum CandidateType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

/** CandidateUpdate */
export interface CandidateUpdate {
  /** Address */
  address?: string | null;
  /** Department */
  department?: string | null;
  /** Email */
  email?: string | null;
  /** Firstname */
  firstName?: string | null;
  /** Image */
  image?: string | null;
  /** Lastname */
  lastName?: string | null;
  /** Phonenumber */
  phoneNumber?: string | null;
  /**
   * Uniqueid
   * @maxLength 255
   */
  uniqueId: string;
  /** Username */
  username?: string | null;
}

export interface CheckRedemptionCodeParams {
  /** Code */
  code: string;
}

/** Client */
export interface Client {
  /** Createdat */
  createdAt: string | null;
  /** Domain */
  domain?: string | null;
  /** Fullname */
  fullName?: string | null;
  /** Id */
  id: string;
  /**
   * Isactive
   * @default true
   */
  isActive?: boolean;
  license: License;
  /** Licenseid */
  licenseId: string;
  /**
   * Name
   * @maxLength 255
   */
  name: string;
  /** Tier */
  tier?: number | null;
  /** Updatedat */
  updatedAt: string | null;
}

/** ClientCreate */
export interface ClientCreate {
  /** Domain */
  domain?: string | null;
  /** Fullname */
  fullName?: string | null;
  /**
   * Isactive
   * @default true
   */
  isActive?: boolean;
  /**
   * Name
   * @maxLength 255
   */
  name: string;
  /** Tier */
  tier?: number | null;
}

/** ClientMe */
export interface ClientMe {
  /** Domain */
  domain?: string | null;
  /** Fullname */
  fullName?: string | null;
  /** Licenseid */
  licenseId: string;
  /** Logourl */
  logoUrl?: string | null;
  /** Name */
  name?: string | null;
  /** Tier */
  tier?: number | null;
}

/** ClientSetting */
export interface ClientSetting {
  /** Clientid */
  clientId: string;
  /** Id */
  id: string;
  name: ClientSettingName;
  /**
   * Value
   * @maxLength 4096
   */
  value: string;
}

/** ClientSettingCreate */
export interface ClientSettingCreate {
  name: ClientSettingName;
  /**
   * Value
   * @maxLength 4096
   */
  value: string;
}

/** ClientSettingName */
export enum ClientSettingName {
  QUESTIONNAIRE_INTRO = 'QUESTIONNAIRE_INTRO',
  QUESTIONNAIRE_SUBMIT = 'QUESTIONNAIRE_SUBMIT',
  APPLICANT_REJECT_EMAIL = 'APPLICANT_REJECT_EMAIL',
  APPLICANT_CONFIRM_EMAIL = 'APPLICANT_CONFIRM_EMAIL',
}

/** ClientSettingUpdate */
export interface ClientSettingUpdate {
  name?: ClientSettingName | null;
  /** Value */
  value?: string | null;
}

/** ClientUpdate */
export interface ClientUpdate {
  /** Domain */
  domain?: string | null;
  /** Fullname */
  fullName?: string | null;
  /** Isactive */
  isActive?: boolean | null;
  /** Name */
  name?: string | null;
  /** Tier */
  tier?: number | null;
}

/** ClientUpdateMe */
export interface ClientUpdateMe {
  /** Fullname */
  fullName?: string | null;
  /** Name */
  name?: string | null;
}

/** Collaborator */
export interface Collaborator {
  /** Id */
  id: string;
  /** Name */
  name: string;
}

/** Contact */
export interface Contact {
  /** Country */
  country: string | null;
  /** Createdat */
  createdAt: string | null;
  /**
   * Email
   * @format email
   */
  email: string;
  /**
   * Firstname
   * @maxLength 255
   */
  firstName: string;
  /** Id */
  id: string;
  /**
   * Lastname
   * @maxLength 255
   */
  lastName: string;
  /**
   * Message
   * @maxLength 4096
   */
  message: string;
  /**
   * Subject
   * @maxLength 255
   */
  subject: string;
  /** Updatedat */
  updatedAt: string | null;
}

/** ContactCreate */
export interface ContactCreate {
  /** Country */
  country: string | null;
  /**
   * Email
   * @format email
   */
  email: string;
  /**
   * Firstname
   * @maxLength 255
   */
  firstName: string;
  /**
   * Lastname
   * @maxLength 255
   */
  lastName: string;
  /**
   * Message
   * @maxLength 4096
   */
  message: string;
  /**
   * Subject
   * @maxLength 255
   */
  subject: string;
}

export interface CopyQuestionSetFromParams {
  /**
   * Overwrite
   * @default false
   */
  overwrite?: boolean;
  /** Questionsetid */
  questionSetId: string | number;
  /** Sourcequestionsetid */
  sourceQuestionSetId: string | number;
}

export interface CreateApplicantParams {
  /** Questionsetid */
  questionSetId: string | number;
}

export interface CreateCandidateParams {
  /** Clientid */
  clientId?: (string | number) | null;
}

export interface CreateClientParams {
  /** Licenseid */
  licenseId: string | number;
}

export interface CreateFakeApplicantsParams {
  /**
   * Nbofapplicants
   * @min 1
   * @max 50
   */
  nbOfApplicants: number;
  /** Questionsetid */
  questionSetId: string | number;
}

export interface CreateQuestionParams {
  /** Sectionid */
  sectionId: string | number;
}

export interface CreateQuestionSetParams {
  /** Recruitmentid */
  recruitmentId: string | number;
}

export interface CreateQuestionsParams {
  /**
   * Nbquestionsperpage
   * @default 1
   */
  nbQuestionsPerPage?: number;
  /** Questionsetid */
  questionSetId: string | number;
  /**
   * Sectiontitle
   * @default "Questions"
   */
  sectionTitle?: string;
}

/** Questions In */
export type CreateQuestionsPayload = QuestionCreate[];

export interface CreateSectionParams {
  /** Questionsetid */
  questionSetId: string | number;
}

export interface CreateUserParams {
  /**
   * Userrole
   * @default "USER"
   */
  userRole?: UserRole;
}

export interface DeleteApplicantsParams {
  /** Questionsetid */
  questionSetId: string | number;
}

/** Body */
export type DeleteApplicantsPayload = DeletedApplicant;

export interface DeleteCandidatesParams {
  /** Clientid */
  clientId: string | number;
}

export interface DeleteNotificationsParams {
  /**
   * Onlyactive
   * @default true
   */
  onlyActive?: boolean;
}

export interface DeleteQuestionsParams {
  /** Questionsetid */
  questionSetId: string | number;
}

export interface DeleteSectionsParams {
  /** Questionsetid */
  questionSetId: string | number;
}

/** DeletedApplicant */
export interface DeletedApplicant {
  /**
   * Applicantids
   * List of applicant IDs, if empty all fake applicants will be deleted.
   * @default []
   */
  applicantIds?: (string | number)[];
}

/** Demo */
export interface Demo {
  /** Company */
  company: string | null;
  /** Country */
  country: string | null;
  /** Createdat */
  createdAt: string | null;
  /**
   * Email
   * @format email
   */
  email: string;
  /** Firstname */
  firstName: string | null;
  /** Id */
  id: string;
  /** Lastname */
  lastName: string | null;
  /** Phonenumber */
  phoneNumber: string | null;
  /** Updatedat */
  updatedAt: string | null;
}

/** DemoCreate */
export interface DemoCreate {
  /** Company */
  company: string | null;
  /** Country */
  country: string | null;
  /**
   * Email
   * @format email
   */
  email: string;
  /** Firstname */
  firstName: string | null;
  /** Lastname */
  lastName: string | null;
  /** Phonenumber */
  phoneNumber: string | null;
}

export interface ExportApplicantsParams {
  /**
   * Applicanttypes
   * @default []
   */
  applicantTypes?: ApplicantType[];
  /** Questionsetid */
  questionSetId: string | number;
}

/** ExportFileType */
export enum ExportFileType {
  CSV = 'CSV',
  EXCEL = 'EXCEL',
  PDF = 'PDF',
}

export interface ExportQuestionSetParams {
  /**
   * Filetype
   * @default "PDF"
   */
  fileType?: ExportFileType;
  /** Questionsetid */
  questionSetId: string | number;
}

export interface ExportQuestionsParams {
  /**
   * Filename
   * @default "questions"
   */
  filename?: string;
  /** Outputcolumns */
  outputColumns?: string[] | null;
  /** Sectionid */
  sectionId: string | number;
}

export interface ExportRedemptionsParams {
  /**
   * Isactive
   * @default true
   */
  isActive?: boolean;
  /**
   * Isused
   * @default false
   */
  isUsed?: boolean;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
}

export interface ExtractQuestionsNewParams {
  /**
   * Nbquestions
   * @min 1
   * @max 15
   * @default 5
   */
  nbQuestions?: number;
  /** Questionsetid */
  questionSetId: string | number;
  /**
   * Temperature
   * @min 0
   * @max 1
   * @default 0.5
   */
  temperature?: number;
}

/**
 * Requirement
 * @minLength 100
 * @maxLength 10000
 */
export type ExtractQuestionsNewPayload = string;

export interface FetchApplicantsParams {
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /** Externaljobid */
  externalJobId: string;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
}

export interface FetchJobsParams {
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
}

/** Device Payload */
export type ForgetDevicePayload = string;

export interface GenerateRandomAnswersParams {
  /** Questionsetid */
  questionSetId: string | number;
}

/** GeneratedQuestionScore */
export interface GeneratedQuestionScore {
  /** Choices */
  choices: QuestionScoreChoice[];
  /** Question */
  question: string;
  /** @default "SINGLE_CHOICE" */
  type?: QuestionTypeML | null;
}

export interface GetApplicantsParams {
  /**
   * Applicantstatus
   * @default "UNDER_SCREENING"
   */
  applicantStatus?: ApplicantStatus;
  /**
   * Applicanttypes
   * @default []
   */
  applicantTypes?: ApplicantType[];
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /** Questionsetid */
  questionSetId: string | number;
  /**
   * Revealemails
   * @default false
   */
  revealEmails?: boolean;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
}

export interface GetApplicantsScoresParams {
  /** Questionsetid */
  questionSetId: string | number;
}

export interface GetAsyncTasksParams {
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Taskstates
   * @default []
   */
  taskStates?: AsyncTaskState[];
}

export interface GetClientCandidatesParams {
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /** Clientid */
  clientId?: (string | number) | null;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
}

export interface GetClientSettingsParams {
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
}

export interface GetClientsParams {
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /**
   * License
   * @default []
   */
  license?: LicenseType[];
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
}

export interface GetCollaboratorsParams {
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
}

export interface GetLicensesParams {
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
}

export interface GetMessagesParams {
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
}

export interface GetNotificationsParams {
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /**
   * Onlyactive
   * @default true
   */
  onlyActive?: boolean;
  /**
   * Onlynotseen
   * @default true
   */
  onlyNotSeen?: boolean;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
}

export interface GetQuestionSetsParams {
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Questionsetstatus
   * @default []
   */
  questionSetStatus?: QuestionSetStatus[];
  /** Recruitmentid */
  recruitmentId?: string | number;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
}

export interface GetQuestionsParams {
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /** Sectionid */
  sectionId: string | number;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
}

export interface GetRecruitmentMessagesParams {
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /** Recruitmentid */
  recruitmentId: string | number;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
}

export interface GetRecruitmentsParams {
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /**
   * Includearchived
   * @default false
   */
  includeArchived?: boolean | null;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /** Questionsetstatus */
  questionSetStatus?: QuestionSetStatus | null;
  /**
   * Recruitmenttype
   * @default "EXTERNAL"
   */
  recruitmentType?: RecruitmentType | null;
  /**
   * Roleas
   * @default "ALL"
   */
  roleAs?: UserRecruitmentRoleInput;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
}

export interface GetRedemptionsParams {
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /**
   * Isactive
   * @default true
   */
  isActive?: boolean;
  /**
   * Isused
   * @default false
   */
  isUsed?: boolean;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
}

export interface GetSectionsParams {
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /** Questionsetid */
  questionSetId: string | number;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
}

export interface GetSettingsParams {
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
}

export interface GetTemplatesParams {
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /** Questionsettype */
  questionSetType?: QuestionSetType;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
}

export interface GetUsersParams {
  /**
   * Ascending
   * @default false
   */
  ascending?: boolean;
  /**
   * Page
   * @min 1
   * @default 1
   */
  page?: number;
  /**
   * Searchquery
   * @default ""
   */
  searchQuery?: string;
  /**
   * Size
   * @min 1
   * @max 100
   * @default 50
   */
  size?: number;
  /**
   * Sortby
   * @default "createdAt"
   */
  sortBy?: string;
  /**
   * Userrole
   * @default []
   */
  userRole?: UserRole[];
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

export interface ImportApplicantsParams {
  /** Questionsetid */
  questionSetId: string | number;
}

/** Body_import_applicants_api_v1_applicants_import__post */
export interface ImportApplicantsPayload {
  /**
   * Uploaded File
   * @format binary
   */
  uploaded_file: File;
}

export interface ImportQuestionsParams {
  /** Sectionid */
  sectionId: string | number;
}

/** Body_import_questions_api_v1_questions_import__post */
export interface ImportQuestionsPayload {
  /**
   * Uploaded File
   * @format binary
   */
  uploaded_file: File;
}

/** JobAdvertisement */
export interface JobAdvertisement {
  /** Companyinfo */
  companyInfo?: string | null;
  /** Jobintroduction */
  jobIntroduction?: string | null;
  /** Qualification */
  qualification?: string | null;
  /** Responsibility */
  responsibility?: string | null;
}

/** License */
export interface License {
  /** Description */
  description: string | null;
  /** Id */
  id: string;
  /**
   * Name
   * @maxLength 255
   */
  name: string;
  /** Nbadmins */
  nbAdmins: number;
  /** Nbrecruitments */
  nbRecruitments: number;
  /** Nbscreenings */
  nbScreenings: number;
  /** Nbusers */
  nbUsers: number;
}

/** LicenseType */
export enum LicenseType {
  FREE = 'FREE',
  BASIC = 'BASIC',
  PREMIUM = 'PREMIUM',
  ENTERPRISE = 'ENTERPRISE',
  ALL = 'ALL',
}

/** MLQuestionScore */
export interface MLQuestionScore {
  /** Createdat */
  createdAt: string | null;
  /** Executiontime */
  executionTime?: number | null;
  /**
   * Generatedquestions
   * @default []
   */
  generatedQuestions?: GeneratedQuestionScore[] | null;
  /** Id */
  id: string;
  /** Nbquestions */
  nbQuestions: number;
  /**
   * Oldquestions
   * @default []
   */
  oldQuestions?: GeneratedQuestionScore[] | null;
  /** Ownerid */
  ownerId: string;
  /** Questionsetid */
  questionSetId: string;
  /**
   * Requirement
   * @minLength 100
   * @maxLength 10000
   */
  requirement: string;
  /** Temperature */
  temperature: number;
  /** Updatedat */
  updatedAt: string | null;
}

/** Message */
export interface Message {
  /**
   * Body
   * @maxLength 4096
   */
  body: string;
  /** Createdat */
  createdAt: string | null;
  /** Id */
  id: string;
  /**
   * Isnew
   * @default false
   */
  isNew?: boolean | null;
  /** Recruitmentid */
  recruitmentId: string;
  sender: UserInfo;
  /** Senderid */
  senderId: string;
  /** Updatedat */
  updatedAt: string | null;
}

/** MessageCreate */
export interface MessageCreate {
  /**
   * Body
   * @maxLength 4096
   */
  body: string;
  /** Recruitmentid */
  recruitmentId: string | number;
}

/** MessageUpdate */
export interface MessageUpdate {
  /** Body */
  body?: string | null;
}

/** Notification */
export interface Notification {
  /** Createdat */
  createdAt: string | null;
  /** Enddate */
  endDate?: string | null;
  /** Extraid */
  extraId?: string | null;
  /** Id */
  id: string;
  /**
   * Importancelevel
   * @default 1
   */
  importanceLevel?: number;
  /** Isactive */
  isActive: boolean;
  /** Isnew */
  isNew: boolean;
  /** Name */
  name?: string | null;
  /** Payload */
  payload?: string | null;
  /** Startdate */
  startDate?: string | null;
  type: NotificationType;
  /** Updatedat */
  updatedAt: string | null;
}

/** NotificationType */
export enum NotificationType {
  NEW_MESSAGE = 'NEW_MESSAGE',
  QUESTION_SET_DEADLINE = 'QUESTION_SET_DEADLINE',
  NEW_USER = 'NEW_USER',
  ASSIGNED_AS_COLLABORATOR = 'ASSIGNED_AS_COLLABORATOR',
  EDITED_BY_COLLABORATOR = 'EDITED_BY_COLLABORATOR',
}

/** NotificationUpdate */
export interface NotificationUpdate {
  /** Seendate */
  seenDate?: string | null;
}

/** Page[ApiApplicant] */
export interface PageApiApplicant {
  /** Items */
  items: ApiApplicant[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[ApiJob] */
export interface PageApiJob {
  /** Items */
  items: ApiJob[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[Applicant] */
export interface PageApplicant {
  /** Items */
  items: Applicant[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[AsyncTask] */
export interface PageAsyncTask {
  /** Items */
  items: AsyncTask[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[Candidate] */
export interface PageCandidate {
  /** Items */
  items: Candidate[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[Client] */
export interface PageClient {
  /** Items */
  items: Client[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[ClientSetting] */
export interface PageClientSetting {
  /** Items */
  items: ClientSetting[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[License] */
export interface PageLicense {
  /** Items */
  items: License[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[Message] */
export interface PageMessage {
  /** Items */
  items: Message[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[Notification] */
export interface PageNotification {
  /** Items */
  items: Notification[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[Question] */
export interface PageQuestion {
  /** Items */
  items: Question[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[QuestionSet] */
export interface PageQuestionSet {
  /** Items */
  items: QuestionSet[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[QuestionSetTemplate] */
export interface PageQuestionSetTemplate {
  /** Items */
  items: QuestionSetTemplate[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[RecruitmentWithQuestionSet] */
export interface PageRecruitmentWithQuestionSet {
  /** Items */
  items: RecruitmentWithQuestionSet[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[Redemption] */
export interface PageRedemption {
  /** Items */
  items: Redemption[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[Section] */
export interface PageSection {
  /** Items */
  items: Section[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[Setting] */
export interface PageSetting {
  /** Items */
  items: Setting[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[User] */
export interface PageUser {
  /** Items */
  items: User[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Page[UserInfo] */
export interface PageUserInfo {
  /** Items */
  items: UserInfo[];
  /**
   * Page
   * @min 1
   */
  page: number;
  /**
   * Size
   * @min 1
   */
  size: number;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** Question */
export interface Question {
  /**
   * Choices
   * @default []
   */
  choices?: QuestionChoice[] | null;
  /** Createdat */
  createdAt: string | null;
  /** Description */
  description?: string | null;
  /** Id */
  id: string;
  /** Number */
  number: number;
  /** Ownerid */
  ownerId: string;
  /** Position */
  position?: number | null;
  properties?: QuestionProperties | null;
  /** Sectionid */
  sectionId: string;
  /**
   * Title
   * @maxLength 4096
   */
  title: string;
  type: QuestionType;
  /** Updatedat */
  updatedAt: string | null;
  /**
   * Weight
   * @default 1
   */
  weight?: number;
}

/** QuestionChoice */
export interface QuestionChoice {
  /**
   * Score
   * @min 0
   * @max 1
   */
  score: number;
  /**
   * Title
   * @maxLength 4096
   */
  title: string;
}

/** QuestionChoiceTitleOnly */
export interface QuestionChoiceTitleOnly {
  /**
   * Title
   * @maxLength 4096
   */
  title: string;
}

/** QuestionCreate */
export interface QuestionCreate {
  /**
   * Choices
   * @default []
   */
  choices?: QuestionChoice[] | null;
  /** Description */
  description?: string | null;
  properties?: QuestionProperties | null;
  /**
   * Title
   * @maxLength 4096
   */
  title: string;
  type: QuestionType;
  /**
   * Weight
   * @default 1
   */
  weight?: number;
}

/** QuestionProperties */
export interface QuestionProperties {
  /**
   * Expectedmean
   * @default 0
   */
  expectedMean?: number | null;
  /**
   * Expectedvar
   * @default 0
   */
  expectedVar?: number | null;
  /**
   * Maxvalue
   * @default 0
   */
  maxValue?: number | null;
  /**
   * Minvalue
   * @default 0
   */
  minValue?: number | null;
}

/** QuestionScoreChoice */
export interface QuestionScoreChoice {
  /**
   * Score
   * @min 0
   * @max 1
   */
  score: number;
  /**
   * Title
   * @maxLength 4096
   */
  title: string;
}

/** QuestionSet */
export interface QuestionSet {
  /** Collaboratorids */
  collaboratorIds: string[];
  /**
   * Collaboratorslist
   * @default []
   */
  collaboratorsList?: Collaborator[];
  /** Createdat */
  createdAt: string | null;
  /** Deadline */
  deadline?: string | null;
  /** Description */
  description?: string | null;
  /** Id */
  id: string;
  /** Nbofsections */
  nbOfSections: number;
  /** Nbquestions */
  nbQuestions: number;
  /** Ownerid */
  ownerId: string;
  /** Recruitmentid */
  recruitmentId: string;
  /** Recruitmenttitle */
  recruitmentTitle: string;
  status: QuestionSetStatus;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  type: QuestionSetType;
  /** Updatedat */
  updatedAt: string | null;
  userOptions?: UserOption | null;
}

/** QuestionSetCreate */
export interface QuestionSetCreate {
  /** Deadline */
  deadline?: string | null;
  /** Description */
  description?: string | null;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  type: QuestionSetType;
  userOptions?: UserOption | null;
}

/** QuestionSetDetails */
export interface QuestionSetDetails {
  /** Collaboratorids */
  collaboratorIds: string[];
  /**
   * Collaboratorslist
   * @default []
   */
  collaboratorsList?: Collaborator[];
  /** Createdat */
  createdAt: string | null;
  /** Deadline */
  deadline?: string | null;
  /** Description */
  description?: string | null;
  /** Id */
  id: string;
  /** Nbofsections */
  nbOfSections: number;
  /** Nbquestions */
  nbQuestions: number;
  /** Ownerid */
  ownerId: string;
  /**
   * Questions
   * @default []
   */
  questions?: Question[] | null;
  /** Recruitmentid */
  recruitmentId: string;
  /** Recruitmenttitle */
  recruitmentTitle: string;
  /**
   * Sections
   * @default []
   */
  sections?: SectionDetails[] | null;
  status: QuestionSetStatus;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  type: QuestionSetType;
  /** Updatedat */
  updatedAt: string | null;
  userOptions?: UserOption | null;
}

/** QuestionSetShort */
export interface QuestionSetShort {
  /** Deadline */
  deadline?: string | null;
  /** Id */
  id: string;
  /** Nbquestions */
  nbQuestions: number;
  status: QuestionSetStatus;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  type: QuestionSetType;
}

/** QuestionSetStatus */
export enum QuestionSetStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  SCREENING = 'SCREENING',
}

/** QuestionSetTemplate */
export interface QuestionSetTemplate {
  /** Createdat */
  createdAt: string | null;
  /** Description */
  description?: string | null;
  /** Id */
  id: string;
  /** Imagepath */
  imagePath?: string | null;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  type: QuestionSetType;
  /** Updatedat */
  updatedAt: string | null;
}

/** QuestionSetTemplateDetails */
export interface QuestionSetTemplateDetails {
  /** Createdat */
  createdAt: string | null;
  /** Description */
  description?: string | null;
  /** Id */
  id: string;
  /** Imagepath */
  imagePath?: string | null;
  /** Sections */
  sections: SectionTemplate[] | null;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  type: QuestionSetType;
  /** Updatedat */
  updatedAt: string | null;
}

/** QuestionSetType */
export enum QuestionSetType {
  INTERVIEW = 'INTERVIEW',
  TEST = 'TEST',
  QUALIFICATION = 'QUALIFICATION',
  PERSONALITY = 'PERSONALITY',
  SURVEY = 'SURVEY',
}

/** QuestionSetUpdate */
export interface QuestionSetUpdate {
  /** Deadline */
  deadline?: string | null;
  /** Description */
  description?: string | null;
  /** Title */
  title?: string | null;
  type?: QuestionSetType | null;
  userOptions?: UserOption | null;
}

/** QuestionSummary */
export interface QuestionSummary {
  /**
   * Choices
   * @default []
   */
  choices?: QuestionChoiceTitleOnly[] | null;
  /** Description */
  description?: string | null;
  /** Id */
  id: string;
  /** Number */
  number?: number | null;
  /** Position */
  position?: number | null;
  properties?: QuestionProperties | null;
  /** Sectionid */
  sectionId: string;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  type: QuestionType;
}

/** QuestionTemplate */
export interface QuestionTemplate {
  /** Createdat */
  createdAt: string | null;
  /** Description */
  description?: string | null;
  /** Id */
  id: string;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  type: QuestionType;
  /** Updatedat */
  updatedAt: string | null;
  /**
   * Weight
   * @default 1
   */
  weight?: number;
}

/** QuestionType */
export enum QuestionType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DECIMAL = 'DECIMAL',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICES = 'MULTIPLE_CHOICES',
}

/** QuestionTypeML */
export enum QuestionTypeML {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICES = 'MULTIPLE_CHOICES',
  TEXT = 'TEXT',
}

/** QuestionUpdate */
export interface QuestionUpdate {
  /**
   * Choices
   * @default []
   */
  choices?: QuestionChoice[] | null;
  /** Description */
  description?: string | null;
  properties?: QuestionProperties | null;
  /** Sectionid */
  sectionId?: string | number | null;
  /** Title */
  title?: string | null;
  type?: QuestionType | null;
  /** Weight */
  weight?: number | null;
}

/** Questionnaire */
export interface Questionnaire {
  /**
   * Askforlinkedinprofile
   * @default false
   */
  askForLinkedinProfile?: boolean | null;
  /** Deadline */
  deadline?: string | null;
  /** Logourl */
  logoUrl?: string | null;
  /** Nbofsections */
  nbOfSections?: number | null;
  /** Nbquestions */
  nbQuestions?: number | null;
  /** Questionsetadvertisement */
  questionSetAdvertisement?: string | null;
  /** Questionsettitle */
  questionSetTitle?: string | null;
  /** Questionnaireintro */
  questionnaireIntro?: string | null;
  /** Questionnairesubmit */
  questionnaireSubmit?: string | null;
  /**
   * Questions
   * @default []
   */
  questions?: QuestionSummary[] | null;
  /**
   * Sections
   * @default []
   */
  sections?: SectionSummary[] | null;
}

/** Recruitment */
export interface Recruitment {
  /** Advertisement */
  advertisement?: string | null;
  /** Archivedtimestamp */
  archivedTimestamp?: string | null;
  /** Clientid */
  clientId: string;
  /** Collaboratorids */
  collaboratorIds?: string[] | null;
  /** Createdat */
  createdAt: string | null;
  /** Description */
  description?: string | null;
  /** Id */
  id: string;
  jobAdvertisement?: JobAdvertisement | null;
  /** Lastmessagedatetime */
  lastMessageDatetime?: string | null;
  /** Lastmessagetext */
  lastMessageText?: string | null;
  /** Nbquestionsets */
  nbQuestionSets: number;
  /** Ownerid */
  ownerId: string;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  /** @default "EXTERNAL" */
  type?: RecruitmentType;
  /** Updatedat */
  updatedAt: string | null;
}

/** RecruitmentCreate */
export interface RecruitmentCreate {
  /** Advertisement */
  advertisement?: string | null;
  /** Description */
  description?: string | null;
  jobAdvertisement?: JobAdvertisement | null;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  /** @default "EXTERNAL" */
  type?: RecruitmentType;
}

/** RecruitmentType */
export enum RecruitmentType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

/** RecruitmentUpdate */
export interface RecruitmentUpdate {
  /** Advertisement */
  advertisement?: string | null;
  /** Archivedtimestamp */
  archivedTimestamp?: string | null;
  /** Description */
  description?: string | null;
  jobAdvertisement?: JobAdvertisement | null;
  /** Title */
  title?: string | null;
  /** @default "EXTERNAL" */
  type?: RecruitmentType;
}

/** RecruitmentWithQuestionSet */
export interface RecruitmentWithQuestionSet {
  /** Advertisement */
  advertisement?: string | null;
  /** Archivedtimestamp */
  archivedTimestamp?: string | null;
  /** Clientid */
  clientId: string;
  /** Collaboratorids */
  collaboratorIds?: string[] | null;
  /** Createdat */
  createdAt: string | null;
  /** Description */
  description?: string | null;
  /** Id */
  id: string;
  jobAdvertisement?: JobAdvertisement | null;
  /** Lastmessagedatetime */
  lastMessageDatetime?: string | null;
  /** Lastmessagetext */
  lastMessageText?: string | null;
  /** Nbquestionsets */
  nbQuestionSets: number;
  /** Ownerid */
  ownerId: string;
  /** Questionsetsshort */
  questionSetsShort?: QuestionSetShort[] | null;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  /** @default "EXTERNAL" */
  type?: RecruitmentType;
  /** Updatedat */
  updatedAt: string | null;
}

/** Redemption */
export interface Redemption {
  /** Clientid */
  clientId?: number | null;
  /** Createdat */
  createdAt: string | null;
  /**
   * Extrainfo
   * @default {}
   */
  extraInfo?: object | null;
  /** Id */
  id: string;
  /**
   * Isactive
   * @default true
   */
  isActive?: boolean | null;
  /**
   * Isused
   * @default false
   */
  isUsed?: boolean | null;
  /** Updatedat */
  updatedAt: string | null;
  /**
   * Value
   * @maxLength 255
   */
  value: string;
}

/** RedemptionUpdate */
export interface RedemptionUpdate {
  /** Clientid */
  clientId?: number | null;
  /**
   * Extrainfo
   * @default {}
   */
  extraInfo?: object | null;
  /**
   * Isactive
   * @default true
   */
  isActive?: boolean | null;
  /**
   * Isused
   * @default false
   */
  isUsed?: boolean | null;
  /**
   * Value
   * @maxLength 255
   */
  value: string;
}

/** Referral */
export interface Referral {
  /** Createdat */
  createdAt: string | null;
  /**
   * Email
   * @format email
   */
  email: string;
  /**
   * Firstname
   * @maxLength 255
   */
  firstName: string;
  /** Id */
  id: string;
  /**
   * Lastname
   * @maxLength 255
   */
  lastName: string;
  /**
   * Nominatedcompany
   * @maxLength 255
   */
  nominatedCompany: string;
  /**
   * Nominationreason
   * @maxLength 4096
   */
  nominationReason: string;
  /** Updatedat */
  updatedAt: string | null;
}

/** ReferralCreate */
export interface ReferralCreate {
  /**
   * Email
   * @format email
   */
  email: string;
  /**
   * Firstname
   * @maxLength 255
   */
  firstName: string;
  /**
   * Lastname
   * @maxLength 255
   */
  lastName: string;
  /**
   * Nominatedcompany
   * @maxLength 255
   */
  nominatedCompany: string;
  /**
   * Nominationreason
   * @maxLength 4096
   */
  nominationReason: string;
}

export interface RefreshQuestionParams {
  /** Mlquestionscoreid */
  mlQuestionScoreId: string | number;
  /**
   * Refreshingquestionnumber
   * @min 1
   */
  refreshingQuestionNumber: number;
  /**
   * Temperature
   * @min 0
   * @max 1
   * @default 0.5
   */
  temperature?: number;
}

export interface RejectApplicantsParams {
  /**
   * Includeapplicantscore
   * @default true
   */
  includeApplicantScore?: boolean;
  /** Questionsetid */
  questionSetId: string | number;
}

/** RejectedApplicant */
export interface RejectedApplicant {
  /** Rejectedapplicants */
  rejectedApplicants: ApplicantRejected[];
}

/** ResetPassword */
export interface ResetPassword {
  /**
   * Newpassword
   * @maxLength 24
   */
  newPassword: string;
  /** Token */
  token: string;
}

/** ResponseOK */
export interface ResponseOK {
  /**
   * Code
   * @default "success"
   */
  code?: string;
  /** Msg */
  msg: string;
  /**
   * Status
   * @default 200
   */
  status?: number;
}

export interface SaveQuestionnaireAnswersParams {
  /** Email */
  email: string;
  /** Linkedinprofileurl */
  linkedinProfileUrl?: string | null;
  /** Questionsetid */
  questionSetId: string | number;
  /** Questionsetlink */
  questionSetLink: string;
}

/** Answers */
export type SaveQuestionnaireAnswersPayload = object;

/** Section */
export interface Section {
  /** Createdat */
  createdAt: string | null;
  /** Description */
  description?: string | null;
  /** Id */
  id: string;
  /**
   * Nbquestionsperpage
   * @default 1
   */
  nbQuestionsPerPage?: number | null;
  /** Number */
  number: number;
  /** Ownerid */
  ownerId: string;
  /** Position */
  position: number;
  /** Questionsetid */
  questionSetId: string;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  /** Updatedat */
  updatedAt: string | null;
}

/** SectionCreate */
export interface SectionCreate {
  /** Description */
  description?: string | null;
  /**
   * Nbquestionsperpage
   * @default 1
   */
  nbQuestionsPerPage?: number | null;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
}

/** SectionDetails */
export interface SectionDetails {
  /** Createdat */
  createdAt: string | null;
  /** Description */
  description?: string | null;
  /** Id */
  id: string;
  /**
   * Nbquestionsperpage
   * @default 1
   */
  nbQuestionsPerPage?: number | null;
  /** Number */
  number: number;
  /** Ownerid */
  ownerId: string;
  /** Position */
  position: number;
  /**
   * Questionids
   * @default []
   */
  questionIds?: string[] | null;
  /** Questionsetid */
  questionSetId: string;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  /** Updatedat */
  updatedAt: string | null;
}

/** SectionSummary */
export interface SectionSummary {
  /** Description */
  description?: string | null;
  /** Id */
  id: string;
  /**
   * Nbquestionsperpage
   * @default 1
   */
  nbQuestionsPerPage?: number | null;
  /** Number */
  number?: number | null;
  /** Position */
  position?: number | null;
  /**
   * Questionids
   * @default []
   */
  questionIds?: string[] | null;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
}

/** SectionTemplate */
export interface SectionTemplate {
  /** Createdat */
  createdAt: string | null;
  /** Description */
  description?: string | null;
  /** Id */
  id: string;
  /** Questions */
  questions: QuestionTemplate[] | null;
  /**
   * Title
   * @maxLength 255
   */
  title: string;
  /** Updatedat */
  updatedAt: string | null;
}

/** SectionUpdate */
export interface SectionUpdate {
  /** Description */
  description?: string | null;
  /**
   * Nbquestionsperpage
   * @default 1
   */
  nbQuestionsPerPage?: number | null;
  /** Title */
  title?: string | null;
}

/** Setting */
export interface Setting {
  /** Id */
  id: string;
  /**
   * Name
   * @maxLength 255
   */
  name: string;
  /** Ownerid */
  ownerId: string;
  /** Value */
  value: Record<string, string | number | any[] | object>;
}

/** SettingCreate */
export interface SettingCreate {
  /**
   * Name
   * @maxLength 255
   */
  name: string;
  /** Value */
  value: Record<string, string | number | any[] | object>;
}

/** SettingUpdate */
export interface SettingUpdate {
  /**
   * Name
   * @maxLength 255
   */
  name: string;
  /** Value */
  value: Record<string, string | number | any[] | object>;
}

/** Token */
export interface Token {
  /** Access Token */
  access_token: string;
  /** Token Type */
  token_type: string;
  user: User;
}

/** Body_update_client_logo_api_v1_clients_logo__post */
export interface UpdateClientLogoPayload {
  /**
   * Logo
   * @format binary
   */
  logo: File;
}

export interface UpdateClientParams {
  /** Clientid */
  clientId: string | number;
  /** Licenseid */
  licenseId?: (string | number) | null;
}

export interface UpdateQuestionSetDeadlineParams {
  /**
   * Deadline
   * @format date-time
   */
  deadline: string;
  /** Questionsetid */
  questionSetId: string | number;
}

export interface UpdateQuestionsOrderParams {
  /** Sectionid */
  sectionId: string | number;
}

/**
 * Questionids
 * The list should includes all question IDs for a section
 * @default []
 */
export type UpdateQuestionsOrderPayload = (string | number)[];

export interface UpdateSectionsOrderParams {
  /** Questionsetid */
  questionSetId: string | number;
}

/**
 * Sectionids
 * The list should includes all section IDs for a question_set
 */
export type UpdateSectionsOrderPayload = (string | number)[];

/** UsedLicense */
export interface UsedLicense {
  /** Description */
  description: string | null;
  /**
   * Features
   * @default []
   */
  features?: string[] | null;
  /**
   * Name
   * @maxLength 255
   */
  name: string;
  /** Nbadmins */
  nbAdmins: number;
  /** Nbrecruitments */
  nbRecruitments: number;
  /** Nbscreenings */
  nbScreenings: number;
  /** Nbusers */
  nbUsers: number;
  /** Tier */
  tier?: number | null;
  /** Totalrecruitments */
  totalRecruitments: number;
  /** Totalscreenings */
  totalScreenings: number;
  /** Usedadmins */
  usedAdmins: number;
  /** Usedrecruitments */
  usedRecruitments: number;
  /** Usedscreenings */
  usedScreenings: number;
  /** Usedusers */
  usedUsers: number;
}

/** User */
export interface User {
  /** Address */
  address?: string | null;
  /** Adminid */
  adminId?: string | null;
  /** Clientid */
  clientId: string;
  /** Createdat */
  createdAt: string | null;
  /**
   * Devices
   * @default []
   */
  devices?: string[] | null;
  /**
   * Email
   * @format email
   */
  email: string;
  /**
   * Firstname
   * @maxLength 255
   */
  firstName: string;
  /** Id */
  id: string;
  /** Isactive */
  isActive: boolean;
  /** Issuperuser */
  isSuperuser: boolean;
  /**
   * Lastname
   * @maxLength 255
   */
  lastName: string;
  /** Otpenabled */
  otpEnabled?: boolean | null;
  /** Phonenumber */
  phoneNumber?: string | null;
  /** Screeningsisrunningout */
  screeningsIsRunningOut: boolean;
  /** Settings */
  settings: Setting[];
  /** Updatedat */
  updatedAt: string | null;
  userAccess: UserAccess;
  userRole: UserRole;
}

/** UserAccess */
export interface UserAccess {
  /**
   * Accessinterviewfeature
   * @default false
   */
  accessInterviewFeature?: boolean;
  /**
   * Accesslinkedinfeature
   * @default false
   */
  accessLinkedinFeature?: boolean;
  /**
   * Accessotpfeature
   * @default false
   */
  accessOtpFeature?: boolean;
  /**
   * Createadmin
   * @default false
   */
  createAdmin?: boolean;
  /**
   * Createapplicant
   * @default false
   */
  createApplicant?: boolean;
  /**
   * Createrecruitment
   * @default false
   */
  createRecruitment?: boolean;
  /**
   * Createuser
   * @default false
   */
  createUser?: boolean;
  /**
   * Exportapplicant
   * @default false
   */
  exportApplicant?: boolean;
  /**
   * Exportrecruitment
   * @default false
   */
  exportRecruitment?: boolean;
  /**
   * Viewclientinformationpage
   * @default false
   */
  viewClientInformationPage?: boolean;
  /**
   * Viewclientpage
   * @default false
   */
  viewClientPage?: boolean;
  /**
   * Viewplatformpage
   * @default false
   */
  viewPlatformPage?: boolean;
  /**
   * Viewuserpage
   * @default false
   */
  viewUserPage?: boolean;
  /**
   * Viewuserpagewithadmin
   * @default false
   */
  viewUserPageWithAdmin?: boolean;
  /**
   * Viewuserpagewithclient
   * @default false
   */
  viewUserPageWithClient?: boolean;
}

/**
 * UserCreate
 * @example {"address":"Somewhere in the universe","email":"test@diverspros.com","firstName":"Test","lastName":"Test","password":"test12345","phoneNumber":"+45-55555555"}
 */
export interface UserCreate {
  /** Address */
  address?: string | null;
  /** Adminid */
  adminId?: string | number | null;
  /** Clientid */
  clientId?: string | number | null;
  /**
   * Email
   * @format email
   */
  email: string;
  /**
   * Firstname
   * @maxLength 255
   */
  firstName: string;
  /**
   * Lastname
   * @maxLength 255
   */
  lastName: string;
  /** Otpenabled */
  otpEnabled?: boolean | null;
  /**
   * Password
   * @maxLength 24
   */
  password: string;
  /** Phonenumber */
  phoneNumber?: string | null;
}

/** UserInfo */
export interface UserInfo {
  /**
   * Email
   * @format email
   */
  email: string;
  /** Firstname */
  firstName: string;
  /** Id */
  id: string;
  /** Lastname */
  lastName: string;
}

/** UserOption */
export interface UserOption {
  /** Applicantconfirmemail */
  applicantConfirmEmail?: string | null;
  /** Applicantrejectemail */
  applicantRejectEmail?: string | null;
  /**
   * Askforlinkedinprofile
   * @default false
   */
  askForLinkedinProfile?: boolean | null;
  /** Questionnaireintro */
  questionnaireIntro?: string | null;
  /** Questionnairesubmit */
  questionnaireSubmit?: string | null;
}

/** UserRecruitment */
export interface UserRecruitment {
  /** Recruitmentid */
  recruitmentId: string;
  roleType: UserRecruitmentRoleOutput;
  /** Userid */
  userId: string;
  /** Username */
  userName: string;
}

/** UserRecruitmentRole */
export enum UserRecruitmentRoleInput {
  ALL = 'ALL',
  OWNER = 'OWNER',
  RESPONSIBLE = 'RESPONSIBLE',
  COLLABORATOR = 'COLLABORATOR',
}

/** UserRecruitmentRole */
export enum UserRecruitmentRoleOutput {
  COLLABORATOR = 'COLLABORATOR',
}

/** UserRegister */
export interface UserRegister {
  /** Address */
  address?: string | null;
  /**
   * Clientname
   * @maxLength 255
   */
  clientName: string;
  /**
   * Email
   * @format email
   */
  email: string;
  /**
   * Firstname
   * @maxLength 255
   */
  firstName: string;
  /**
   * Lastname
   * @maxLength 255
   */
  lastName: string;
  /**
   * Password
   * @maxLength 24
   */
  password: string;
  /** Phonenumber */
  phoneNumber?: string | null;
  /** Redemptioncode */
  redemptionCode?: string | null;
}

/** UserRole */
export enum UserRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
  SUPERUSER = 'SUPERUSER',
}

/** UserRoleList */
export interface UserRoleList {
  /**
   * Collaboratorids
   * @default []
   */
  collaboratorIds?: (string | number)[];
}

/** UserUpdate */
export interface UserUpdate {
  /** Address */
  address?: string | null;
  /** Adminid */
  adminId?: string | number | null;
  /** Clientid */
  clientId?: string | number | null;
  /** Email */
  email?: string | null;
  /** Firstname */
  firstName?: string | null;
  /** Lastname */
  lastName?: string | null;
  /** Otpenabled */
  otpEnabled?: boolean | null;
  /** Password */
  password?: string | null;
  /** Phonenumber */
  phoneNumber?: string | null;
  userRole?: UserRole;
}

/** UserUpdateMe */
export interface UserUpdateMe {
  /** Address */
  address?: string | null;
  /** Email */
  email?: string | null;
  /** Firstname */
  firstName?: string | null;
  /** Lastname */
  lastName?: string | null;
  /** Otpenabled */
  otpEnabled?: boolean | null;
  /** Phonenumber */
  phoneNumber?: string | null;
}

/** UserUpdatePassword */
export interface UserUpdatePassword {
  /**
   * Newpassword
   * @maxLength 24
   */
  newPassword: string;
}

export interface ValidateOtpParams {
  /** Otp */
  otp: string;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title DivERS API Documentation
 * @version 0.6.0
 *
 * Developed by DivERS Team
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  api = {
    /**
     * @description Activate a user.
     *
     * @tags auth
     * @name ActivateUser
     * @summary Activate User
     * @request POST:/api/v1/auth/activate-user/{token}
     */
    activateUser: (token: string, params: RequestParams = {}) =>
      this.http.request<User, HTTPValidationError>({
        path: `/api/v1/auth/activate-user/${token}`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Assign (or update) a role to a list of users for a recruitment.
     *
     * @tags users-recruitments
     * @name AssignRecruitmentRole
     * @summary Assign Recruitment Role
     * @request POST:/api/v1/recruitments/{recruitmentId}/roles/
     * @secure
     */
    assignRecruitmentRole: (
      recruitmentId: string | number,
      data: UserRoleList,
      params: RequestParams = {},
    ) =>
      this.http.request<UserRecruitment[], HTTPValidationError>({
        path: `/api/v1/recruitments/${recruitmentId}/roles/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change current user password.
     *
     * @tags users
     * @name ChangePassword
     * @summary Change Password
     * @request PUT:/api/v1/users/change-password/
     * @secure
     */
    changePassword: (data: UserUpdatePassword, params: RequestParams = {}) =>
      this.http.request<User, HTTPValidationError>({
        path: `/api/v1/users/change-password/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Check redemption code.
     *
     * @tags auth
     * @name CheckRedemptionCode
     * @summary Check Redemption Code
     * @request POST:/api/v1/auth/check-redemption/
     */
    checkRedemptionCode: (query: CheckRedemptionCodeParams, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/auth/check-redemption/`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Clear all active notifications (set all seenDate).
     *
     * @tags notifications
     * @name ClearNotifications
     * @summary Clear Notifications
     * @request PUT:/api/v1/notifications/clear-all/
     * @secure
     */
    clearNotifications: (params: RequestParams = {}) =>
      this.http.request<ResponseOK, any>({
        path: `/api/v1/notifications/clear-all/`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Clear refresh token.
     *
     * @tags auth
     * @name ClearToken
     * @summary Clear Token
     * @request POST:/api/v1/auth/clear-token/
     * @secure
     */
    clearToken: (params: RequestParams = {}) =>
      this.http.request<ResponseOK, any>({
        path: `/api/v1/auth/clear-token/`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new contact (max 10 per email address).
     *
     * @tags website
     * @name ContactUs
     * @summary Contact Us
     * @request POST:/api/v1/website/contact/
     */
    contactUs: (data: ContactCreate, params: RequestParams = {}) =>
      this.http.request<Contact, HTTPValidationError>({
        path: `/api/v1/website/contact/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Copy a question set (sourceQuestionSetId) to another one (questionSetId) (replace the current details of overwrite is True).
     *
     * @tags questionsets
     * @name CopyQuestionSetFrom
     * @summary Copy Question Set From
     * @request PUT:/api/v1/questionsets/{questionSetId}/copy-from/{sourceQuestionSetId}
     * @secure
     */
    copyQuestionSetFrom: (
      { questionSetId, sourceQuestionSetId, ...query }: CopyQuestionSetFromParams,
      params: RequestParams = {},
    ) =>
      this.http.request<QuestionSet, HTTPValidationError>({
        path: `/api/v1/questionsets/${questionSetId}/copy-from/${sourceQuestionSetId}`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Copy a template to a question set.
     *
     * @tags templates
     * @name CopyToQuestionSet
     * @summary Copy To Question Set
     * @request POST:/api/v1/templates/{templateId}/copy-to/{questionSetId}/
     * @secure
     */
    copyToQuestionSet: (
      templateId: string | number,
      questionSetId: string | number,
      params: RequestParams = {},
    ) =>
      this.http.request<QuestionSetDetails, HTTPValidationError>({
        path: `/api/v1/templates/${templateId}/copy-to/${questionSetId}/`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new applicant.
     *
     * @tags applicants
     * @name CreateApplicant
     * @summary Create Applicant
     * @request POST:/api/v1/applicants/
     * @secure
     */
    createApplicant: (
      query: CreateApplicantParams,
      data: ApplicantCreate,
      params: RequestParams = {},
    ) =>
      this.http.request<Applicant, HTTPValidationError>({
        path: `/api/v1/applicants/`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new candidate.
     *
     * @tags candidates
     * @name CreateCandidate
     * @summary Create Candidate
     * @request POST:/api/v1/candidates/
     * @secure
     */
    createCandidate: (
      query: CreateCandidateParams,
      data: CandidateCreate,
      params: RequestParams = {},
    ) =>
      this.http.request<Candidate, HTTPValidationError>({
        path: `/api/v1/candidates/`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new client.
     *
     * @tags clients
     * @name CreateClient
     * @summary Create Client
     * @request POST:/api/v1/clients/
     * @secure
     */
    createClient: (query: CreateClientParams, data: ClientCreate, params: RequestParams = {}) =>
      this.http.request<Client, HTTPValidationError>({
        path: `/api/v1/clients/`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new client setting.
     *
     * @tags client-settings
     * @name CreateClientSetting
     * @summary Create Client Setting
     * @request POST:/api/v1/client-settings/
     * @secure
     */
    createClientSetting: (data: ClientSettingCreate, params: RequestParams = {}) =>
      this.http.request<ClientSetting, HTTPValidationError>({
        path: `/api/v1/client-settings/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a set of fake applicants.
     *
     * @tags applicants
     * @name CreateFakeApplicants
     * @summary Create Fake Applicants
     * @request POST:/api/v1/applicants/fake/
     * @secure
     */
    createFakeApplicants: (query: CreateFakeApplicantsParams, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/applicants/fake/`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new message.
     *
     * @tags messages
     * @name CreateMessage
     * @summary Create Message
     * @request POST:/api/v1/messages/
     * @secure
     */
    createMessage: (data: MessageCreate, params: RequestParams = {}) =>
      this.http.request<Message, HTTPValidationError>({
        path: `/api/v1/messages/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new question.
     *
     * @tags questions
     * @name CreateQuestion
     * @summary Create Question
     * @request POST:/api/v1/questions/
     * @secure
     */
    createQuestion: (
      query: CreateQuestionParams,
      data: QuestionCreate,
      params: RequestParams = {},
    ) =>
      this.http.request<Question, HTTPValidationError>({
        path: `/api/v1/questions/`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a set of questions.
     *
     * @tags questions
     * @name CreateQuestions
     * @summary Create Questions
     * @request POST:/api/v1/questions/question-group/
     * @secure
     */
    createQuestions: (
      query: CreateQuestionsParams,
      data: CreateQuestionsPayload,
      params: RequestParams = {},
    ) =>
      this.http.request<Question[], HTTPValidationError>({
        path: `/api/v1/questions/question-group/`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new question_set.
     *
     * @tags questionsets
     * @name CreateQuestionSet
     * @summary Create Question Set
     * @request POST:/api/v1/questionsets/
     * @secure
     */
    createQuestionSet: (
      query: CreateQuestionSetParams,
      data: QuestionSetCreate,
      params: RequestParams = {},
    ) =>
      this.http.request<QuestionSet, HTTPValidationError>({
        path: `/api/v1/questionsets/`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new recruitment.
     *
     * @tags recruitments
     * @name CreateRecruitment
     * @summary Create Recruitment
     * @request POST:/api/v1/recruitments/
     * @secure
     */
    createRecruitment: (data: RecruitmentCreate, params: RequestParams = {}) =>
      this.http.request<Recruitment, HTTPValidationError>({
        path: `/api/v1/recruitments/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new section.
     *
     * @tags sections
     * @name CreateSection
     * @summary Create Section
     * @request POST:/api/v1/sections/
     * @secure
     */
    createSection: (query: CreateSectionParams, data: SectionCreate, params: RequestParams = {}) =>
      this.http.request<Section, HTTPValidationError>({
        path: `/api/v1/sections/`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new setting.
     *
     * @tags settings
     * @name CreateSetting
     * @summary Create Setting
     * @request POST:/api/v1/settings/
     * @secure
     */
    createSetting: (data: SettingCreate, params: RequestParams = {}) =>
      this.http.request<Setting, HTTPValidationError>({
        path: `/api/v1/settings/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new user.
     *
     * @tags users
     * @name CreateUser
     * @summary Create User
     * @request POST:/api/v1/users/
     * @secure
     */
    createUser: (query: CreateUserParams, data: UserCreate, params: RequestParams = {}) =>
      this.http.request<User, HTTPValidationError>({
        path: `/api/v1/users/`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a single applicant.
     *
     * @tags applicants
     * @name DeleteApplicant
     * @summary Delete Applicant
     * @request DELETE:/api/v1/applicants/{applicantId}/
     * @secure
     */
    deleteApplicant: (applicantId: string | number, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/applicants/${applicantId}/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete multiple applicants for a question_set.
     *
     * @tags applicants
     * @name DeleteApplicants
     * @summary Delete Applicants
     * @request DELETE:/api/v1/applicants/
     * @secure
     */
    deleteApplicants: (
      query: DeleteApplicantsParams,
      data: DeleteApplicantsPayload,
      params: RequestParams = {},
    ) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/applicants/`,
        method: 'DELETE',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a single candidate.
     *
     * @tags candidates
     * @name DeleteCandidate
     * @summary Delete Candidate
     * @request DELETE:/api/v1/candidates/{candidateId}/
     * @secure
     */
    deleteCandidate: (candidateId: string | number, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/candidates/${candidateId}/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete all candidates of a client.
     *
     * @tags candidates
     * @name DeleteCandidates
     * @summary Delete Candidates
     * @request DELETE:/api/v1/candidates/
     * @secure
     */
    deleteCandidates: (query: DeleteCandidatesParams, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/candidates/`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a client.
     *
     * @tags clients
     * @name DeleteClient
     * @summary Delete Client
     * @request DELETE:/api/v1/clients/{clientId}/
     * @secure
     */
    deleteClient: (clientId: string | number, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/clients/${clientId}/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a client_setting.
     *
     * @tags client-settings
     * @name DeleteClientSetting
     * @summary Delete Client Setting
     * @request DELETE:/api/v1/client-settings/{clientSettingId}/
     * @secure
     */
    deleteClientSetting: (clientSettingId: string | number, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/client-settings/${clientSettingId}/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete all client settings.
     *
     * @tags client-settings
     * @name DeleteClientSettings
     * @summary Delete Client Settings
     * @request DELETE:/api/v1/client-settings/
     * @secure
     */
    deleteClientSettings: (params: RequestParams = {}) =>
      this.http.request<ResponseOK, any>({
        path: `/api/v1/client-settings/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a single message.
     *
     * @tags messages
     * @name DeleteMessage
     * @summary Delete Message
     * @request DELETE:/api/v1/messages/{messageId}/
     * @secure
     */
    deleteMessage: (messageId: string | number, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/messages/${messageId}/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete all notifications for a user.
     *
     * @tags notifications
     * @name DeleteNotifications
     * @summary Delete Notifications
     * @request DELETE:/api/v1/notifications/
     * @secure
     */
    deleteNotifications: (query: DeleteNotificationsParams, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/notifications/`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a single question.
     *
     * @tags questions
     * @name DeleteQuestion
     * @summary Delete Question
     * @request DELETE:/api/v1/questions/{questionId}/
     * @secure
     */
    deleteQuestion: (questionId: string | number, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/questions/${questionId}/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete all questions of a question_set.
     *
     * @tags questions
     * @name DeleteQuestions
     * @summary Delete Questions
     * @request DELETE:/api/v1/questions/
     * @secure
     */
    deleteQuestions: (query: DeleteQuestionsParams, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/questions/`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a question_set.
     *
     * @tags questionsets
     * @name DeleteQuestionSet
     * @summary Delete Question Set
     * @request DELETE:/api/v1/questionsets/{questionSetId}/
     * @secure
     */
    deleteQuestionSet: (questionSetId: string | number, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/questionsets/${questionSetId}/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a single recruitment.
     *
     * @tags recruitments
     * @name DeleteRecruitment
     * @summary Delete Recruitment
     * @request DELETE:/api/v1/recruitments/{recruitmentId}/
     * @secure
     */
    deleteRecruitment: (recruitmentId: string | number, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/recruitments/${recruitmentId}/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete all recruitments of the current user.
     *
     * @tags recruitments
     * @name DeleteRecruitments
     * @summary Delete Recruitments
     * @request DELETE:/api/v1/recruitments/
     * @secure
     */
    deleteRecruitments: (params: RequestParams = {}) =>
      this.http.request<ResponseOK, any>({
        path: `/api/v1/recruitments/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a redemption.
     *
     * @tags redemptions
     * @name DeleteRedemption
     * @summary Delete Redemption
     * @request DELETE:/api/v1/redemptions/{redemptionId}/
     * @secure
     */
    deleteRedemption: (redemptionId: string | number, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/redemptions/${redemptionId}/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a single section.
     *
     * @tags sections
     * @name DeleteSection
     * @summary Delete Section
     * @request DELETE:/api/v1/sections/{sectionId}/
     * @secure
     */
    deleteSection: (sectionId: string | number, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/sections/${sectionId}/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete all sections of a question_set.
     *
     * @tags sections
     * @name DeleteSections
     * @summary Delete Sections
     * @request DELETE:/api/v1/sections/
     * @secure
     */
    deleteSections: (query: DeleteSectionsParams, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/sections/`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a setting.
     *
     * @tags settings
     * @name DeleteSetting
     * @summary Delete Setting
     * @request DELETE:/api/v1/settings/{settingId}/
     * @secure
     */
    deleteSetting: (settingId: string | number, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/settings/${settingId}/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete all settings of a user.
     *
     * @tags settings
     * @name DeleteSettings
     * @summary Delete Settings
     * @request DELETE:/api/v1/settings/
     * @secure
     */
    deleteSettings: (params: RequestParams = {}) =>
      this.http.request<ResponseOK, any>({
        path: `/api/v1/settings/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a user.
     *
     * @tags users
     * @name DeleteUser
     * @summary Delete User
     * @request DELETE:/api/v1/users/{userId}/
     * @secure
     */
    deleteUser: (userId: string | number, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/users/${userId}/`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Disable OTP for the user.
     *
     * @tags auth
     * @name DisableOtp
     * @summary Disable Otp
     * @request POST:/api/v1/auth/otp/disable/
     * @secure
     */
    disableOtp: (params: RequestParams = {}) =>
      this.http.request<ResponseOK, any>({
        path: `/api/v1/auth/otp/disable/`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Enable OTP for the user.
     *
     * @tags auth
     * @name EnableOtp
     * @summary Enable Otp
     * @request POST:/api/v1/auth/otp/enable/
     * @secure
     */
    enableOtp: (params: RequestParams = {}) =>
      this.http.request<ResponseOK, any>({
        path: `/api/v1/auth/otp/enable/`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Export applicants to a excel file.
     *
     * @tags applicants
     * @name ExportApplicants
     * @summary Export Applicants
     * @request GET:/api/v1/applicants/export/
     * @secure
     */
    exportApplicants: (query: ExportApplicantsParams, params: RequestParams = {}) =>
      this.http.request<any, HTTPValidationError>({
        path: `/api/v1/applicants/export/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Export questions to an excel file.
     *
     * @tags questions
     * @name ExportQuestions
     * @summary Export Questions
     * @request GET:/api/v1/questions/export/
     * @secure
     */
    exportQuestions: (query: ExportQuestionsParams, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/questions/export/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Export a question set as a pdf file.
     *
     * @tags questionsets
     * @name ExportQuestionSet
     * @summary Export Question Set
     * @request GET:/api/v1/questionsets/{questionSetId}/export/
     * @secure
     */
    exportQuestionSet: (
      { questionSetId, ...query }: ExportQuestionSetParams,
      params: RequestParams = {},
    ) =>
      this.http.request<any, HTTPValidationError>({
        path: `/api/v1/questionsets/${questionSetId}/export/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all redemptions. - **is_active**: Redemptions that are active. Set to false to get all. - **is_used**: Redemptions that are used. Set to false to get all.
     *
     * @tags redemptions
     * @name ExportRedemptions
     * @summary Export Redemptions
     * @request POST:/api/v1/redemptions/export/
     * @secure
     */
    exportRedemptions: (query: ExportRedemptionsParams, params: RequestParams = {}) =>
      this.http.request<PageRedemption, HTTPValidationError>({
        path: `/api/v1/redemptions/export/`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ml
     * @name ExtractQuestionsNew
     * @summary Extract Questions New
     * @request POST:/api/v1/ml/question-score/
     * @secure
     */
    extractQuestionsNew: (
      query: ExtractQuestionsNewParams,
      data: ExtractQuestionsNewPayload,
      params: RequestParams = {},
    ) =>
      this.http.request<MLQuestionScore, HTTPValidationError>({
        path: `/api/v1/ml/question-score/`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Fetch applicants from the external API.
     *
     * @tags integration
     * @name FetchApplicants
     * @summary Fetch Applicants
     * @request GET:/api/v1/integration/applicants/
     * @secure
     */
    fetchApplicants: (query: FetchApplicantsParams, params: RequestParams = {}) =>
      this.http.request<PageApiApplicant, HTTPValidationError>({
        path: `/api/v1/integration/applicants/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Fetch jobs from the external API.
     *
     * @tags integration
     * @name FetchJobs
     * @summary Fetch Jobs
     * @request GET:/api/v1/integration/jobs/
     * @secure
     */
    fetchJobs: (query: FetchJobsParams, params: RequestParams = {}) =>
      this.http.request<PageApiJob, HTTPValidationError>({
        path: `/api/v1/integration/jobs/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Forget a user device, it needs a fresh token.
     *
     * @tags auth
     * @name ForgetDevice
     * @summary Forget Device
     * @request POST:/api/v1/auth/otp/forget-device/
     * @secure
     */
    forgetDevice: (data: ForgetDevicePayload, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/auth/otp/forget-device/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Generate QR code for 2FA.
     *
     * @tags auth
     * @name GenerateQrCode
     * @summary Generate Qr Code
     * @request GET:/api/v1/auth/otp/generate-qr/
     * @secure
     */
    generateQrCode: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/api/v1/auth/otp/generate-qr/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Generate a set of random answers for fake applicants.
     *
     * @tags applicants
     * @name GenerateRandomAnswers
     * @summary Generate Random Answers
     * @request PUT:/api/v1/applicants/fake/random-answers/
     * @secure
     */
    generateRandomAnswers: (query: GenerateRandomAnswersParams, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/applicants/fake/random-answers/`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a single applicant.
     *
     * @tags applicants
     * @name GetApplicant
     * @summary Get Applicant
     * @request GET:/api/v1/applicants/{applicantId}/
     * @secure
     */
    getApplicant: (applicantId: string | number, params: RequestParams = {}) =>
      this.http.request<Applicant, HTTPValidationError>({
        path: `/api/v1/applicants/${applicantId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve applicants for a question_set.
     *
     * @tags applicants
     * @name GetApplicants
     * @summary Get Applicants
     * @request GET:/api/v1/applicants/
     * @secure
     */
    getApplicants: (query: GetApplicantsParams, params: RequestParams = {}) =>
      this.http.request<PageApplicant, HTTPValidationError>({
        path: `/api/v1/applicants/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a list of ranked scores for all questions.
     *
     * @tags applicants
     * @name GetApplicantsScores
     * @summary Get Applicants Scores
     * @request GET:/api/v1/applicants/scores/
     * @secure
     */
    getApplicantsScores: (query: GetApplicantsScoresParams, params: RequestParams = {}) =>
      this.http.request<Record<string, number[]>, HTTPValidationError>({
        path: `/api/v1/applicants/scores/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all async tasks.
     *
     * @tags async-tasks
     * @name GetAsyncTasks
     * @summary Get Async Tasks
     * @request GET:/api/v1/async-tasks/
     * @secure
     */
    getAsyncTasks: (query: GetAsyncTasksParams, params: RequestParams = {}) =>
      this.http.request<PageAsyncTask, HTTPValidationError>({
        path: `/api/v1/async-tasks/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a single candidate.
     *
     * @tags candidates
     * @name GetCandidate
     * @summary Get Candidate
     * @request GET:/api/v1/candidates/{candidateId}/
     * @secure
     */
    getCandidate: (candidateId: string | number, params: RequestParams = {}) =>
      this.http.request<Candidate, HTTPValidationError>({
        path: `/api/v1/candidates/${candidateId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a single page.
     *
     * @tags clients
     * @name GetClient
     * @summary Get Client
     * @request GET:/api/v1/clients/{clientId}/
     * @secure
     */
    getClient: (clientId: string | number, params: RequestParams = {}) =>
      this.http.request<Client, HTTPValidationError>({
        path: `/api/v1/clients/${clientId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all candidates, per client depending on the query.
     *
     * @tags candidates
     * @name GetClientCandidates
     * @summary Get Client Candidates
     * @request GET:/api/v1/candidates/
     * @secure
     */
    getClientCandidates: (query: GetClientCandidatesParams, params: RequestParams = {}) =>
      this.http.request<PageCandidate, HTTPValidationError>({
        path: `/api/v1/candidates/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get current client.
     *
     * @tags clients
     * @name GetClientMe
     * @summary Get Client Me
     * @request GET:/api/v1/clients/me/
     * @secure
     */
    getClientMe: (params: RequestParams = {}) =>
      this.http.request<ClientMe, any>({
        path: `/api/v1/clients/me/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all clients.
     *
     * @tags clients
     * @name GetClients
     * @summary Get Clients
     * @request GET:/api/v1/clients/
     * @secure
     */
    getClients: (query: GetClientsParams, params: RequestParams = {}) =>
      this.http.request<PageClient, HTTPValidationError>({
        path: `/api/v1/clients/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a client_setting.
     *
     * @tags client-settings
     * @name GetClientSetting
     * @summary Get Client Setting
     * @request GET:/api/v1/client-settings/{clientSettingId}/
     * @secure
     */
    getClientSetting: (clientSettingId: string | number, params: RequestParams = {}) =>
      this.http.request<ClientSetting, HTTPValidationError>({
        path: `/api/v1/client-settings/${clientSettingId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all client settings.
     *
     * @tags client-settings
     * @name GetClientSettings
     * @summary Get Client Settings
     * @request GET:/api/v1/client-settings/
     * @secure
     */
    getClientSettings: (query: GetClientSettingsParams, params: RequestParams = {}) =>
      this.http.request<PageClientSetting, HTTPValidationError>({
        path: `/api/v1/client-settings/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all possible collaborators.
     *
     * @tags users
     * @name GetCollaborators
     * @summary Get Collaborators
     * @request GET:/api/v1/users/collaborators/
     * @secure
     */
    getCollaborators: (query: GetCollaboratorsParams, params: RequestParams = {}) =>
      this.http.request<PageUserInfo, HTTPValidationError>({
        path: `/api/v1/users/collaborators/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get default client settings, if it is empty, default texts will be used.
     *
     * @tags client-settings
     * @name GetDefaultOptions
     * @summary Get Default Options
     * @request GET:/api/v1/client-settings/defaults/
     * @secure
     */
    getDefaultOptions: (params: RequestParams = {}) =>
      this.http.request<Record<string, string>, any>({
        path: `/api/v1/client-settings/defaults/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all licenses.
     *
     * @tags licenses
     * @name GetLicenses
     * @summary Get Licenses
     * @request GET:/api/v1/licenses/
     * @secure
     */
    getLicenses: (query: GetLicensesParams, params: RequestParams = {}) =>
      this.http.request<PageLicense, HTTPValidationError>({
        path: `/api/v1/licenses/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a single message.
     *
     * @tags messages
     * @name GetMessage
     * @summary Get Message
     * @request GET:/api/v1/messages/{messageId}/
     * @secure
     */
    getMessage: (messageId: string | number, params: RequestParams = {}) =>
      this.http.request<Message, HTTPValidationError>({
        path: `/api/v1/messages/${messageId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all sent messages.
     *
     * @tags messages
     * @name GetMessages
     * @summary Get Messages
     * @request GET:/api/v1/messages/
     * @secure
     */
    getMessages: (query: GetMessagesParams, params: RequestParams = {}) =>
      this.http.request<PageMessage, HTTPValidationError>({
        path: `/api/v1/messages/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ml
     * @name GetMlQuestion
     * @summary Get Ml Question
     * @request GET:/api/v1/ml/{mlQuestionScoreId}
     * @secure
     */
    getMlQuestion: (mlQuestionScoreId: string | number, params: RequestParams = {}) =>
      this.http.request<MLQuestionScore, HTTPValidationError>({
        path: `/api/v1/ml/${mlQuestionScoreId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all notifications. - **only_active**: Notifications are active when startDate < now < endDate. Set to false to get all. - **only_not_seen**: Only notifications that has not been seen (seenDate is Null). Set to false to get all.
     *
     * @tags notifications
     * @name GetNotifications
     * @summary Get Notifications
     * @request GET:/api/v1/notifications/
     * @secure
     */
    getNotifications: (query: GetNotificationsParams, params: RequestParams = {}) =>
      this.http.request<PageNotification, HTTPValidationError>({
        path: `/api/v1/notifications/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a single question.
     *
     * @tags questions
     * @name GetQuestion
     * @summary Get Question
     * @request GET:/api/v1/questions/{questionId}/
     * @secure
     */
    getQuestion: (questionId: string | number, params: RequestParams = {}) =>
      this.http.request<Question, HTTPValidationError>({
        path: `/api/v1/questions/${questionId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get questionnaire.
     *
     * @tags questionnaire
     * @name GetQuestionnaire
     * @summary Get Questionnaire
     * @request GET:/api/v1/questionnaire/{questionSetId}/{questionSetLink}/
     */
    getQuestionnaire: (
      questionSetId: string | number,
      questionSetLink: string,
      params: RequestParams = {},
    ) =>
      this.http.request<Questionnaire, HTTPValidationError>({
        path: `/api/v1/questionnaire/${questionSetId}/${questionSetLink}/`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all questions for a section.
     *
     * @tags questions
     * @name GetQuestions
     * @summary Get Questions
     * @request GET:/api/v1/questions/
     * @secure
     */
    getQuestions: (query: GetQuestionsParams, params: RequestParams = {}) =>
      this.http.request<PageQuestion, HTTPValidationError>({
        path: `/api/v1/questions/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a question_set.
     *
     * @tags questionsets
     * @name GetQuestionSet
     * @summary Get Question Set
     * @request GET:/api/v1/questionsets/{questionSetId}/
     * @secure
     */
    getQuestionSet: (questionSetId: string | number, params: RequestParams = {}) =>
      this.http.request<QuestionSet, HTTPValidationError>({
        path: `/api/v1/questionsets/${questionSetId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a question_set details.
     *
     * @tags questionsets
     * @name GetQuestionSetDetails
     * @summary Get Question Set Details
     * @request GET:/api/v1/questionsets/{questionSetId}/details/
     * @secure
     */
    getQuestionSetDetails: (questionSetId: string | number, params: RequestParams = {}) =>
      this.http.request<QuestionSetDetails, HTTPValidationError>({
        path: `/api/v1/questionsets/${questionSetId}/details/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all relevant question_sets depending status.
     *
     * @tags questionsets
     * @name GetQuestionSets
     * @summary Get Question Sets
     * @request GET:/api/v1/questionsets/
     * @secure
     */
    getQuestionSets: (query: GetQuestionSetsParams, params: RequestParams = {}) =>
      this.http.request<PageQuestionSet, HTTPValidationError>({
        path: `/api/v1/questionsets/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get a link for accessing question_set.
     *
     * @tags questionsets
     * @name GetQuestionSetUrl
     * @summary Get Question Set Url
     * @request GET:/api/v1/questionsets/{questionSetId}/url/
     * @secure
     */
    getQuestionSetUrl: (questionSetId: string | number, params: RequestParams = {}) =>
      this.http.request<any, HTTPValidationError>({
        path: `/api/v1/questionsets/${questionSetId}/url/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a single recruitment.
     *
     * @tags recruitments
     * @name GetRecruitment
     * @summary Get Recruitment
     * @request GET:/api/v1/recruitments/{recruitmentId}/
     * @secure
     */
    getRecruitment: (recruitmentId: string | number, params: RequestParams = {}) =>
      this.http.request<Recruitment, HTTPValidationError>({
        path: `/api/v1/recruitments/${recruitmentId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all recruitment messages.
     *
     * @tags messages
     * @name GetRecruitmentMessages
     * @summary Get Recruitment Messages
     * @request GET:/api/v1/messages/recruitments/{recruitmentId}/
     * @secure
     */
    getRecruitmentMessages: (
      { recruitmentId, ...query }: GetRecruitmentMessagesParams,
      params: RequestParams = {},
    ) =>
      this.http.request<PageMessage, HTTPValidationError>({
        path: `/api/v1/messages/recruitments/${recruitmentId}/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get the recruitment roles.
     *
     * @tags users-recruitments
     * @name GetRecruitmentRoles
     * @summary Get Recruitment Roles
     * @request GET:/api/v1/recruitments/{recruitmentId}/roles/
     * @secure
     */
    getRecruitmentRoles: (recruitmentId: string | number, params: RequestParams = {}) =>
      this.http.request<UserRecruitment[], HTTPValidationError>({
        path: `/api/v1/recruitments/${recruitmentId}/roles/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all recruitments for current user depending on role_as, recruitment_type, question_set_status and include_archived.
     *
     * @tags recruitments
     * @name GetRecruitments
     * @summary Get Recruitments
     * @request GET:/api/v1/recruitments/
     * @secure
     */
    getRecruitments: (query: GetRecruitmentsParams, params: RequestParams = {}) =>
      this.http.request<PageRecruitmentWithQuestionSet, HTTPValidationError>({
        path: `/api/v1/recruitments/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a redemption.
     *
     * @tags redemptions
     * @name GetRedemption
     * @summary Get Redemption
     * @request GET:/api/v1/redemptions/{redemptionId}/
     * @secure
     */
    getRedemption: (redemptionId: string | number, params: RequestParams = {}) =>
      this.http.request<Redemption, HTTPValidationError>({
        path: `/api/v1/redemptions/${redemptionId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all redemptions. - **is_active**: Redemptions that are active. Set to false to get all. - **is_used**: Redemptions that are used. Set to false to get all.
     *
     * @tags redemptions
     * @name GetRedemptions
     * @summary Get Redemptions
     * @request GET:/api/v1/redemptions/
     * @secure
     */
    getRedemptions: (query: GetRedemptionsParams, params: RequestParams = {}) =>
      this.http.request<PageRedemption, HTTPValidationError>({
        path: `/api/v1/redemptions/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a single section.
     *
     * @tags sections
     * @name GetSection
     * @summary Get Section
     * @request GET:/api/v1/sections/{sectionId}/
     * @secure
     */
    getSection: (sectionId: string | number, params: RequestParams = {}) =>
      this.http.request<Section, HTTPValidationError>({
        path: `/api/v1/sections/${sectionId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all sections, per user/question_set depending on the query.
     *
     * @tags sections
     * @name GetSections
     * @summary Get Sections
     * @request GET:/api/v1/sections/
     * @secure
     */
    getSections: (query: GetSectionsParams, params: RequestParams = {}) =>
      this.http.request<PageSection, HTTPValidationError>({
        path: `/api/v1/sections/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a setting.
     *
     * @tags settings
     * @name GetSetting
     * @summary Get Setting
     * @request GET:/api/v1/settings/{settingId}/
     * @secure
     */
    getSetting: (settingId: string | number, params: RequestParams = {}) =>
      this.http.request<Setting, HTTPValidationError>({
        path: `/api/v1/settings/${settingId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all settings.
     *
     * @tags settings
     * @name GetSettings
     * @summary Get Settings
     * @request GET:/api/v1/settings/
     * @secure
     */
    getSettings: (query: GetSettingsParams, params: RequestParams = {}) =>
      this.http.request<PageSetting, HTTPValidationError>({
        path: `/api/v1/settings/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a template details.
     *
     * @tags templates
     * @name GetTemplate
     * @summary Get Template
     * @request GET:/api/v1/templates/{templateId}/
     * @secure
     */
    getTemplate: (templateId: string | number, params: RequestParams = {}) =>
      this.http.request<QuestionSetTemplateDetails, HTTPValidationError>({
        path: `/api/v1/templates/${templateId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all templates.
     *
     * @tags templates
     * @name GetTemplates
     * @summary Get Templates
     * @request GET:/api/v1/templates/
     * @secure
     */
    getTemplates: (query: GetTemplatesParams, params: RequestParams = {}) =>
      this.http.request<PageQuestionSetTemplate, HTTPValidationError>({
        path: `/api/v1/templates/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all used spaces from the license.
     *
     * @tags licenses
     * @name GetUsedLicenses
     * @summary Get Used Licenses
     * @request GET:/api/v1/licenses/used
     * @secure
     */
    getUsedLicenses: (params: RequestParams = {}) =>
      this.http.request<UsedLicense, any>({
        path: `/api/v1/licenses/used`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get a single user.
     *
     * @tags users
     * @name GetUser
     * @summary Get User
     * @request GET:/api/v1/users/{userId}/
     * @secure
     */
    getUser: (userId: string | number, params: RequestParams = {}) =>
      this.http.request<User, HTTPValidationError>({
        path: `/api/v1/users/${userId}/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get current user.
     *
     * @tags users
     * @name GetUserMe
     * @summary Get User Me
     * @request GET:/api/v1/users/me/
     * @secure
     */
    getUserMe: (params: RequestParams = {}) =>
      this.http.request<User, any>({
        path: `/api/v1/users/me/`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve all relevant users.
     *
     * @tags users
     * @name GetUsers
     * @summary Get Users
     * @request GET:/api/v1/users/
     * @secure
     */
    getUsers: (query: GetUsersParams, params: RequestParams = {}) =>
      this.http.request<PageUser, HTTPValidationError>({
        path: `/api/v1/users/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Import a list of applicants from a table. - **uploaded_file: Should be either excel or csv file. It should include 6 columns: ["type", "external_id", "answers"].
     *
     * @tags applicants
     * @name ImportApplicants
     * @summary Import Applicants
     * @request POST:/api/v1/applicants/import/
     * @secure
     */
    importApplicants: (
      query: ImportApplicantsParams,
      data: ImportApplicantsPayload,
      params: RequestParams = {},
    ) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/applicants/import/`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Import a list of questions from a table. - **uploaded_file: Should be either excel or csv file. It should include 6 columns: ["type", "title", "description", "choices", "properties", "weight"].
     *
     * @tags questions
     * @name ImportQuestions
     * @summary Import Questions
     * @request POST:/api/v1/questions/import/
     * @secure
     */
    importQuestions: (
      query: ImportQuestionsParams,
      data: ImportQuestionsPayload,
      params: RequestParams = {},
    ) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/questions/import/`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description OAuth2 compatible token, get an access token for future requests.
     *
     * @tags auth
     * @name LoginAccessToken
     * @summary Login Access Token
     * @request POST:/api/v1/auth/access-token/
     */
    loginAccessToken: (data: AuthForm, params: RequestParams = {}) =>
      this.http.request<Token, HTTPValidationError>({
        path: `/api/v1/auth/access-token/`,
        method: 'POST',
        body: data,
        type: ContentType.UrlEncoded,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a referral request (max 5 per email address).
     *
     * @tags website
     * @name MakeReferral
     * @summary Make Referral
     * @request POST:/api/v1/website/referral/
     */
    makeReferral: (data: ReferralCreate, params: RequestParams = {}) =>
      this.http.request<Referral, HTTPValidationError>({
        path: `/api/v1/website/referral/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Password Recovery.
     *
     * @tags auth
     * @name RecoverPassword
     * @summary Recover Password
     * @request POST:/api/v1/auth/password-recovery/{email}/
     */
    recoverPassword: (email: string, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/auth/password-recovery/${email}/`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ml
     * @name RefreshQuestion
     * @summary Refresh Question
     * @request POST:/api/v1/ml/refresh-question/
     * @secure
     */
    refreshQuestion: (query: RefreshQuestionParams, params: RequestParams = {}) =>
      this.http.request<MLQuestionScore, HTTPValidationError>({
        path: `/api/v1/ml/refresh-question/`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get the new access token using refresh token.
     *
     * @tags auth
     * @name RefreshToken
     * @summary Refresh Token
     * @request POST:/api/v1/auth/refresh-token/
     * @secure
     */
    refreshToken: (params: RequestParams = {}) =>
      this.http.request<Token, any>({
        path: `/api/v1/auth/refresh-token/`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Register a new user.
     *
     * @tags auth
     * @name RegisterUser
     * @summary Register User
     * @request POST:/api/v1/auth/register/
     */
    registerUser: (data: UserRegister, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/auth/register/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Reject a list of applicants (only for real applicants, when question set status is not draft).
     *
     * @tags applicants
     * @name RejectApplicants
     * @summary Reject Applicants
     * @request POST:/api/v1/applicants/reject/
     * @secure
     */
    rejectApplicants: (
      query: RejectApplicantsParams,
      data: RejectedApplicant,
      params: RequestParams = {},
    ) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/applicants/reject/`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Remember device for the user.
     *
     * @tags auth
     * @name RememberDevice
     * @summary Remember Device
     * @request POST:/api/v1/auth/otp/remember-device/
     * @secure
     */
    rememberDevice: (params: RequestParams = {}) =>
      this.http.request<ResponseOK, any>({
        path: `/api/v1/auth/otp/remember-device/`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a demo request (max 5 per email address).
     *
     * @tags website
     * @name RequestDemo
     * @summary Request Demo
     * @request POST:/api/v1/website/demo/
     */
    requestDemo: (data: DemoCreate, params: RequestParams = {}) =>
      this.http.request<Demo, HTTPValidationError>({
        path: `/api/v1/website/demo/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name ResendActivationEmail
     * @summary Resend Activation Email
     * @request POST:/api/v1/auth/resend-activation/{email}
     */
    resendActivationEmail: (email: string, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/auth/resend-activation/${email}`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Reset password.
     *
     * @tags auth
     * @name ResetPassword
     * @summary Reset Password
     * @request POST:/api/v1/auth/reset-password/
     */
    resetPassword: (data: ResetPassword, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/auth/reset-password/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Save answers to questionnaire, create a new applicant.
     *
     * @tags questionnaire
     * @name SaveQuestionnaireAnswers
     * @summary Save Questionnaire Answers
     * @request POST:/api/v1/questionnaire/{questionSetId}/{questionSetLink}/applicant/
     */
    saveQuestionnaireAnswers: (
      { questionSetId, questionSetLink, ...query }: SaveQuestionnaireAnswersParams,
      data: SaveQuestionnaireAnswersPayload,
      params: RequestParams = {},
    ) =>
      this.http.request<ApplicantExternal, HTTPValidationError>({
        path: `/api/v1/questionnaire/${questionSetId}/${questionSetLink}/applicant/`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Test access token.
     *
     * @tags auth
     * @name TestToken
     * @summary Test Token
     * @request POST:/api/v1/auth/test-token/
     * @secure
     */
    testToken: (params: RequestParams = {}) =>
      this.http.request<User, any>({
        path: `/api/v1/auth/test-token/`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a single applicant.
     *
     * @tags applicants
     * @name UpdateApplicant
     * @summary Update Applicant
     * @request PUT:/api/v1/applicants/{applicantId}/
     * @secure
     */
    updateApplicant: (
      applicantId: string | number,
      data: ApplicantUpdate,
      params: RequestParams = {},
    ) =>
      this.http.request<Applicant, HTTPValidationError>({
        path: `/api/v1/applicants/${applicantId}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a single candidate.
     *
     * @tags candidates
     * @name UpdateCandidate
     * @summary Update Candidate
     * @request PUT:/api/v1/candidates/{candidateId}/
     * @secure
     */
    updateCandidate: (
      candidateId: string | number,
      data: CandidateUpdate,
      params: RequestParams = {},
    ) =>
      this.http.request<Candidate, HTTPValidationError>({
        path: `/api/v1/candidates/${candidateId}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a client.
     *
     * @tags clients
     * @name UpdateClient
     * @summary Update Client
     * @request PUT:/api/v1/clients/{clientId}/
     * @secure
     */
    updateClient: (
      { clientId, ...query }: UpdateClientParams,
      data: ClientUpdate,
      params: RequestParams = {},
    ) =>
      this.http.request<Client, HTTPValidationError>({
        path: `/api/v1/clients/${clientId}/`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update client logo.
     *
     * @tags clients
     * @name UpdateClientLogo
     * @summary Update Client Logo
     * @request POST:/api/v1/clients/logo/
     * @secure
     */
    updateClientLogo: (data: UpdateClientLogoPayload, params: RequestParams = {}) =>
      this.http.request<ClientMe, HTTPValidationError>({
        path: `/api/v1/clients/logo/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update current client.
     *
     * @tags clients
     * @name UpdateClientMe
     * @summary Update Client Me
     * @request PUT:/api/v1/clients/me/
     * @secure
     */
    updateClientMe: (data: ClientUpdateMe, params: RequestParams = {}) =>
      this.http.request<ClientMe, HTTPValidationError>({
        path: `/api/v1/clients/me/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a client_setting.
     *
     * @tags client-settings
     * @name UpdateClientSetting
     * @summary Update Client Setting
     * @request PUT:/api/v1/client-settings/{clientSettingId}/
     * @secure
     */
    updateClientSetting: (
      clientSettingId: string | number,
      data: ClientSettingUpdate,
      params: RequestParams = {},
    ) =>
      this.http.request<ClientSetting, HTTPValidationError>({
        path: `/api/v1/client-settings/${clientSettingId}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a single message.
     *
     * @tags messages
     * @name UpdateMessage
     * @summary Update Message
     * @request PUT:/api/v1/messages/{messageId}/
     * @secure
     */
    updateMessage: (messageId: string | number, data: MessageUpdate, params: RequestParams = {}) =>
      this.http.request<Message, HTTPValidationError>({
        path: `/api/v1/messages/${messageId}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a notification.
     *
     * @tags notifications
     * @name UpdateNotification
     * @summary Update Notification
     * @request PUT:/api/v1/notifications/{notificationId}/
     * @secure
     */
    updateNotification: (
      notificationId: string | number,
      data: NotificationUpdate,
      params: RequestParams = {},
    ) =>
      this.http.request<Notification, HTTPValidationError>({
        path: `/api/v1/notifications/${notificationId}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a single question.
     *
     * @tags questions
     * @name UpdateQuestion
     * @summary Update Question
     * @request PUT:/api/v1/questions/{questionId}/
     * @secure
     */
    updateQuestion: (
      questionId: string | number,
      data: QuestionUpdate,
      params: RequestParams = {},
    ) =>
      this.http.request<Question, HTTPValidationError>({
        path: `/api/v1/questions/${questionId}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a question_set.
     *
     * @tags questionsets
     * @name UpdateQuestionSet
     * @summary Update Question Set
     * @request PUT:/api/v1/questionsets/{questionSetId}/
     * @secure
     */
    updateQuestionSet: (
      questionSetId: string | number,
      data: QuestionSetUpdate,
      params: RequestParams = {},
    ) =>
      this.http.request<QuestionSet, HTTPValidationError>({
        path: `/api/v1/questionsets/${questionSetId}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update/Set the deadline for a question_set.
     *
     * @tags questionsets
     * @name UpdateQuestionSetDeadline
     * @summary Update Question Set Deadline
     * @request PUT:/api/v1/questionsets/{questionSetId}/deadline/
     * @secure
     */
    updateQuestionSetDeadline: (
      { questionSetId, ...query }: UpdateQuestionSetDeadlineParams,
      params: RequestParams = {},
    ) =>
      this.http.request<QuestionSet, HTTPValidationError>({
        path: `/api/v1/questionsets/${questionSetId}/deadline/`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change the question_set status to a new state.
     *
     * @tags questionsets
     * @name UpdateQuestionSetStatus
     * @summary Update Question Set Status
     * @request PUT:/api/v1/questionsets/{questionSetId}/status/{goToState}/
     * @secure
     */
    updateQuestionSetStatus: (
      questionSetId: string | number,
      goToState: QuestionSetStatus,
      params: RequestParams = {},
    ) =>
      this.http.request<QuestionSet, HTTPValidationError>({
        path: `/api/v1/questionsets/${questionSetId}/status/${goToState}/`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Order questions for a section.
     *
     * @tags questions
     * @name UpdateQuestionsOrder
     * @summary Update Questions Order
     * @request PUT:/api/v1/questions/order-questions/
     * @secure
     */
    updateQuestionsOrder: (
      query: UpdateQuestionsOrderParams,
      data: UpdateQuestionsOrderPayload,
      params: RequestParams = {},
    ) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/questions/order-questions/`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a single recruitment.
     *
     * @tags recruitments
     * @name UpdateRecruitment
     * @summary Update Recruitment
     * @request PUT:/api/v1/recruitments/{recruitmentId}/
     * @secure
     */
    updateRecruitment: (
      recruitmentId: string | number,
      data: RecruitmentUpdate,
      params: RequestParams = {},
    ) =>
      this.http.request<Recruitment, HTTPValidationError>({
        path: `/api/v1/recruitments/${recruitmentId}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a redemption.
     *
     * @tags redemptions
     * @name UpdateRedemption
     * @summary Update Redemption
     * @request PUT:/api/v1/redemptions/{redemptionId}/
     * @secure
     */
    updateRedemption: (
      redemptionId: string | number,
      data: RedemptionUpdate,
      params: RequestParams = {},
    ) =>
      this.http.request<Redemption, HTTPValidationError>({
        path: `/api/v1/redemptions/${redemptionId}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a single section.
     *
     * @tags sections
     * @name UpdateSection
     * @summary Update Section
     * @request PUT:/api/v1/sections/{sectionId}/
     * @secure
     */
    updateSection: (sectionId: string | number, data: SectionUpdate, params: RequestParams = {}) =>
      this.http.request<Section, HTTPValidationError>({
        path: `/api/v1/sections/${sectionId}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Order sections for a question_set.
     *
     * @tags sections
     * @name UpdateSectionsOrder
     * @summary Update Sections Order
     * @request PUT:/api/v1/sections/order-sections/
     * @secure
     */
    updateSectionsOrder: (
      query: UpdateSectionsOrderParams,
      data: UpdateSectionsOrderPayload,
      params: RequestParams = {},
    ) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/sections/order-sections/`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a setting.
     *
     * @tags settings
     * @name UpdateSetting
     * @summary Update Setting
     * @request PUT:/api/v1/settings/{settingId}/
     * @secure
     */
    updateSetting: (settingId: string | number, data: SettingUpdate, params: RequestParams = {}) =>
      this.http.request<Setting, HTTPValidationError>({
        path: `/api/v1/settings/${settingId}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update a user.
     *
     * @tags users
     * @name UpdateUser
     * @summary Update User
     * @request PUT:/api/v1/users/{userId}/
     * @secure
     */
    updateUser: (userId: string | number, data: UserUpdate, params: RequestParams = {}) =>
      this.http.request<User, HTTPValidationError>({
        path: `/api/v1/users/${userId}/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update current user.
     *
     * @tags users
     * @name UpdateUserMe
     * @summary Update User Me
     * @request PUT:/api/v1/users/me/
     * @secure
     */
    updateUserMe: (data: UserUpdateMe, params: RequestParams = {}) =>
      this.http.request<User, HTTPValidationError>({
        path: `/api/v1/users/me/`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Validate an OTP and activate it for the user.
     *
     * @tags auth
     * @name ValidateOtp
     * @summary Validate Otp
     * @request GET:/api/v1/auth/otp/validate/
     * @secure
     */
    validateOtp: (query: ValidateOtpParams, params: RequestParams = {}) =>
      this.http.request<ResponseOK, HTTPValidationError>({
        path: `/api/v1/auth/otp/validate/`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
