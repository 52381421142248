import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import SizeControl from '@/shared/custom/SizeControl';
import { cn } from '@/utils';
import { cardContext } from './Card';

export default function Head() {
  const { card, isMaximized, setIsMaximized } = useContext(cardContext);
  const { title, actions, viewLink } = card;
  const titleClassName = 'card-title';

  return (
    <div className="border-colors flex flex-wrap items-center justify-between gap-2 border-b pb-4">
      {viewLink ? (
        <Link
          to={viewLink}
          className={cn(titleClassName, 'hover:text-primary-focus hover:underline')}
        >
          {title}
        </Link>
      ) : (
        <h3 className={titleClassName}>{title}</h3>
      )}
      <div className="flex items-center gap-1">
        <SizeControl isMaximized={isMaximized} setIsMaximized={setIsMaximized} />
        {actions}
      </div>
    </div>
  );
}
