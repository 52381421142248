import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import compact from 'lodash/compact';
import Ai from '@/assets/svg/questionsets/setup/ai.svg?react';
import Copy from '@/assets/svg/questionsets/setup/copy.svg?react';
import Custom from '@/assets/svg/questionsets/setup/custom.svg?react';
// import Templates from '@/assets/svg/questionsets/setup/templates.svg?react';
import Option from '@/components/questionsets/starter/Option';
import { TQuestionsetsEditLayout } from '@/layouts/questionsets/EditLayout';
import Head from '@/shared/custom/Head';
import WizardButtons from '@/shared/custom/WizardButtons';
import { TImportedSvg } from '@/types';
import { cn } from '@/utils';

type TSetupType = 'ai' | 'templates' | 'copy' | 'custom';

export interface ISetupOption {
  type: TSetupType;
  Icon: TImportedSvg;
}

export default function Setup() {
  const { t } = useTranslation();
  const title = t('Setup');
  const navigate = useNavigate();
  const { cancelProps, questionsetId, urlParameters } = useOutletContext<TQuestionsetsEditLayout>();
  const [selectedType, setSelectedType] = useState<TSetupType>('custom');
  const steps = t('questionsets.steps', { returnObjects: true });

  const options: ISetupOption[] = compact([
    { type: 'ai', Icon: Ai },
    // { type: 'templates', Icon: Templates },
    { type: 'copy', Icon: Copy },
    { type: 'custom', Icon: Custom },
  ]);

  const mapSteps = (stepKey: keyof typeof steps) =>
    Object.entries(steps[stepKey]).map(([key, value]) => (
      <li key={key} className="step">
        {value}
      </li>
    ));

  const handleSubmit = () => {
    const url = {
      ai: 'ai',
      templates: 'templates',
      copy: 'copy',
      custom: 'details',
    }[selectedType];

    navigate(`/questionsets/${questionsetId}/${url}${urlParameters}`);
  };

  const handlePrevious = () => navigate(`/questionsets/${questionsetId}/edit${urlParameters}`);

  return (
    <>
      <Head title={title} description={title} />
      <h1 className="h1">{title}</h1>
      <ul className="steps steps-vertical md:steps-horizontal">
        <li className="step step-primary">{t('Information')}</li>
        <li className="step step-primary">{t('Setup')}</li>
        {selectedType === 'templates' && mapSteps('templates')}
        {selectedType === 'ai' && mapSteps('ai')}
        {selectedType === 'copy' && mapSteps('copy')}
        <li
          className={cn('step', {
            'step-primary': selectedType === 'custom',
          })}
        >
          {t('Finish')}
        </li>
      </ul>
      <ul className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {options.map((option) => (
          <Option
            key={option.type}
            {...option}
            onClick={() => setSelectedType(option.type)}
            isSelected={selectedType === option.type}
          />
        ))}
      </ul>
      <WizardButtons
        submitProps={{
          onClick: handleSubmit,
          text: selectedType === 'custom' ? t('Submit') : t('Next'),
        }}
        cancelProps={cancelProps}
        previousProps={{ onClick: handlePrevious }}
      />
    </>
  );
}
