import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import AlreadyAnswered from '@/components/questions/AlreadyAnswered';
import AnswerCreated from '@/components/questions/AnswerCreated';
import InformationStarter from '@/components/questions/InformationStarter';
import Navbar from '@/components/questions/Navbar';
import QuestionsWizard from '@/components/questions/QuestionsWizard';
import QuestionsetIsExpired from '@/components/questions/QuestionsetIsExpired';
import { useDialogModal } from '@/hooks';
import useCreateQuestionnaire from '@/services/questionnaire/create';
import useGetQuestionnaire from '@/services/questionnaire/show';
import ButtonActions from '@/shared/custom/ButtonActions';
import Head from '@/shared/custom/Head';
import DialogModal from '@/shared/modals/DialogModal';
import { isAxiosError } from '@/utils';

export type TInformation = {
  email: string;
  linkedinProfileUrl: string | null;
};

const defaultInformation: TInformation = {
  email: '',
  linkedinProfileUrl: null,
};

export default function Questionnaire() {
  const { questionsetId, link } = useParams<'questionsetId' | 'link'>();
  const { data } = useGetQuestionnaire(questionsetId!, link!);
  const { t } = useTranslation();
  const title = `${data.questionSetTitle ?? 'Job'} ${t('Questions')}`;
  const [answers, setAnswers] = useState<object>({});
  const {
    mutate: createQuestionnaire,
    isPending: isSubmitting,
    failureReason,
    isSuccess: hasAnswered,
  } = useCreateQuestionnaire();
  const { modalRef, handleOpenModal, handleCloseModal } = useDialogModal();
  const {
    modalRef: cancelModalRef,
    handleOpenModal: handleOpenCancelModal,
    handleCloseModal: handleCloseCancelModal,
  } = useDialogModal();
  const [information, setInformation] = useState<TInformation>(defaultInformation);

  const isExpired = new Date().getTime() > new Date(data.deadline!).getTime();

  const handleCancel = () => {
    setInformation(defaultInformation);
    handleCloseCancelModal();
  };

  const handleSubmit = () => {
    createQuestionnaire(
      {
        ...information,
        questionSetId: questionsetId!,
        questionSetLink: link!,
        data: answers,
      },
      { onSettled: handleCloseModal },
    );
  };

  const main = useMemo(() => {
    if (data.deadline && isExpired) return <QuestionsetIsExpired />;
    if (hasAnswered) return <AnswerCreated description={data.questionnaireSubmit} />;
    if (!information.email)
      return (
        <InformationStarter
          isDisabled={(data.nbQuestions ?? 1) <= 0}
          askForLinkedInProfile={data.askForLinkedinProfile ?? false}
          questionnaireIntro={data.questionnaireIntro}
          onSubmit={(values) => {
            setInformation(values);
            // check if this email already answered this question
            createQuestionnaire({
              ...values,
              questionSetId: questionsetId!,
              questionSetLink: link!,
              data: {},
            });
          }}
          jobTitle={title}
        />
      );
    if (
      isAxiosError<any>(failureReason, 409) &&
      failureReason?.response?.data.detail.includes('same email')
    )
      return <AlreadyAnswered />;
    return (
      <QuestionsWizard
        isSubmitDisabled={!data.deadline}
        data={data}
        onSubmit={(values) => {
          setAnswers(values);
          handleOpenModal();
        }}
        onCancel={handleOpenCancelModal}
      />
    );
  }, [hasAnswered, information, title, data, failureReason]);

  return (
    <div className="flex flex-1 flex-col space-y-4 overflow-x-hidden">
      <Head title={title} description={title} />
      <Navbar />
      <DialogModal id="submitModal" closeOnClickOutside modalRef={modalRef}>
        <h2 className="text-2xl font-bold md:text-3xl">{t('Submit your Answers')}</h2>
        <p className="description">{t('Do you want to submit your answers?')}</p>
        <ButtonActions
          submitProps={{
            type: 'button',
            onClick: handleSubmit,
            isLoading: isSubmitting,
            disabled: isSubmitting,
          }}
          cancelProps={{ onClick: handleCloseModal }}
        />
      </DialogModal>
      <DialogModal id="cancelModal" closeOnClickOutside modalRef={cancelModalRef}>
        <h2 className="text-2xl font-bold md:text-3xl">{t('Cancel answering the Questionset')}</h2>
        <p className="description">
          {t('Do you want to cancel and discard your current answers?')}
        </p>
        <ButtonActions
          submitProps={{ text: 'Discard & Close', onClick: handleCancel, className: 'btn-error' }}
          cancelProps={{ text: 'Continue', onClick: handleCloseCancelModal }}
        />
      </DialogModal>
      <main className="flex flex-1 flex-col gap-4 px-4 py-4 md:px-8">
        {!data.deadline && (
          <div role="alert" className="alert alert-warning">
            <ExclamationTriangleIcon className="h-6 w-6" />
            <span>
              {t(
                'This Questionset is not Published yet but you can view the questions but you cannot submit any answers.',
              )}
            </span>
          </div>
        )}
        {(data.nbQuestions ?? 1) <= 0 && (
          <div role="alert" className="alert alert-warning">
            <ExclamationTriangleIcon className="h-6 w-6" />
            <span>{t('This Questionset does not have any questions.')}</span>
          </div>
        )}
        {main}
      </main>
    </div>
  );
}
