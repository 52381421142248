import React from 'react';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import { CellContext } from '@tanstack/react-table';
import { RecruitmentWithQuestionSet } from '@/generated';
import Bookmark from './Bookmark';

type TitleProps = {
  info: CellContext<RecruitmentWithQuestionSet, unknown>;
};

export default function Title({ info }: TitleProps) {
  return (
    <div className="flex items-center justify-center gap-2">
      <div data-tip="Archived Recruitment" className="tooltip tooltip-right tooltip-secondary">
        {info.row.original.archivedTimestamp && <ArchiveBoxIcon className="size-6" />}
      </div>
      <Bookmark id={info.row.original.id} />
      <h3 className="block">{info.row.original.title}</h3>
    </div>
  );
}
