import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RecruitmentUpdate } from '@/generated';
import api from '@/libs/api';
import { TModelId } from '@/types';

type TRecruitmentEdit = RecruitmentUpdate & {
  recruitmentId: TModelId;
};

async function editRecruitment({ recruitmentId, ...data }: TRecruitmentEdit) {
  const resp = await api.api.updateRecruitment(recruitmentId, data);
  return resp.data;
}

export default function useEditRecruitment() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editRecruitment,
    onSuccess: (recruitment) => {
      queryClient.setQueryData(['recruitments', recruitment.id], recruitment);
      queryClient.invalidateQueries({ queryKey: ['recruitments'] });
    },
  });
}
