import { useSuspenseQuery } from '@tanstack/react-query';
import { ClientSettingName } from '@/generated';
import api from '@/libs/api';

async function getDefaults() {
  const resp = await api.api.getDefaultOptions({});
  return resp.data as Record<ClientSettingName, string>;
}

export default function useGetDefaultClientSettings() {
  return useSuspenseQuery({
    queryKey: ['clientSettings', 'defaults'],
    staleTime: undefined,
    queryFn: getDefaults,
  });
}
