import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Actions from '@/components/questionsets/Actions';
import { ISelectOption } from '@/interfaces';
import { TQuestionsetsEditLayout } from '@/layouts/questionsets/EditLayout';
import useCopyQuestionset from '@/services/questionsets/copy';
import useGetRecruitments from '@/services/recruitments';
import Head from '@/shared/custom/Head';
import HeadingWithLinks from '@/shared/custom/HeadingWithLinks';
import { Label } from '@/shared/form';
import ReactSelect from '@/shared/form/Fields/ReactSelect';
import { TModelId } from '@/types';
import { cn } from '@/utils';

export default function Copy() {
  const { t } = useTranslation();
  const title = t('Copy');
  const { questionsetId, isInWizardMode, questionset } =
    useOutletContext<TQuestionsetsEditLayout>();
  const [selectedId, setSelectedId] = useState('');
  const [overwrite, setOverwrite] = useState(false);
  const { mutate: copy, isPending } = useCopyQuestionset();
  const navigate = useNavigate();

  const { data: recruitments, isLoading } = useGetRecruitments({
    size: 50,
  });
  const options = recruitments?.items
    .flatMap((recruitment) =>
      recruitment.questionSetsShort!.map((mapQuestionset) => ({
        label: `${recruitment.title} - ${mapQuestionset.title}`,
        value: mapQuestionset.id,
      })),
    )
    .filter((option) => option.value !== questionset?.id);

  const handleCopy = () => {
    copy(
      { overwrite, questionSetId: questionsetId, sourceQuestionSetId: selectedId },
      { onSuccess: () => navigate(`/questionsets/${questionsetId}/details`) },
    );
  };

  return (
    <>
      <Head title={title} description={title} />
      {isInWizardMode ? (
        <h1 className="h1">{title}</h1>
      ) : (
        <HeadingWithLinks
          links={[
            { text: 'Questionset Details', to: `/questionsets/${questionsetId}/details` },
            { text: title, to: '#' },
          ]}
        />
      )}
      {isInWizardMode && (
        <ul className="steps steps-vertical md:steps-horizontal">
          <li className="step step-primary">{t('Information')}</li>
          <li className="step step-primary">{t('Setup')}</li>
          <li className="step step-primary">{t('questionsets.steps.copy.choose')}</li>
          <li
            className={cn('step', {
              'step-primary': Boolean(selectedId),
            })}
          >
            {t('Finish')}
          </li>
        </ul>
      )}
      <ReactSelect
        isLoading={isLoading}
        options={options}
        isSearchable
        onChange={(selected) => {
          setSelectedId((selected as ISelectOption<TModelId>).value);
        }}
        placeholder="Select a Questionset"
      />
      <div className="mb-4 flex items-center">
        <input
          onChange={(e) => setOverwrite(e.target.checked)}
          id="overwriteQuestions"
          type="checkbox"
          className="toggle toggle-primary"
          checked={overwrite}
        />
        <Label className="ml-3" label={t('Overwrite Questions')} htmlFor="overwriteQuestions" />
      </div>
      <Actions
        isNoOptionSelected={!selectedId}
        questionsetId={questionsetId}
        isSubmitting={isPending}
        onSubmit={handleCopy}
      />
    </>
  );
}
