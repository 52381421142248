import React from 'react';
import { Button } from '@/shared/form';
import { TImportedSvg } from '@/types';

type OptionProps = {
  Illustration: TImportedSvg;
  heading: string;
  description: string;
  onClick?: () => void;
};

export default function Option({ Illustration, heading, description, onClick }: OptionProps) {
  return (
    <Button onClick={onClick} className="border-colors card card-bordered">
      <div className="card-body space-y-4">
        <Illustration className="h-full w-full" />
        <h2 className="card-heading h3">
          <span className="text-primary">{heading}</span> {description}
        </h2>
      </div>
    </Button>
  );
}
