import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Categories from '@/components/help/Categories';
import Faqs from '@/components/help/Faqs';
import Search from '@/components/help/Search';
import Head from '@/shared/custom/Head';
import { Button } from '@/shared/form';
import useTourState from '@/states/tourState';

export default function Help() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const title = t('Help');
  const setTour = useTourState((state) => state.setTour);

  const handleRunTour = () => {
    setTour({ isRunning: true });
    navigate('/');
  };

  return (
    <>
      <Head title={title} description={title} />
      <div className="flex items-center justify-between">
        <h1 className="h1">{title}</h1>
        <Button onClick={handleRunTour} className="btn btn-neutral btn-md">
          {t('Run Tour')}
        </Button>
      </div>
      <Search />
      <Categories />
      <Faqs />
    </>
  );
}
