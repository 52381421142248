import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ChevronDownIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import asideLinks from '@/fixtures/asideLinks';
import { Button } from '@/shared/form';
import useAsideState from '@/states/asideState';
import useAuthState from '@/states/authState';
import { cn } from '@/utils';

export default function Maximized() {
  const [maximizedGroups, setMaximizedGroups] = useState(asideLinks.map((parent) => parent.name));
  const { t } = useTranslation();
  const isMaximized = useAsideState((state) => state.isMaximized);
  const authUser = useAuthState((state) => state.user!);

  const filteredAsideLinks = useMemo(
    () =>
      asideLinks
        .map((parent) => ({
          ...parent,
          links: parent.links.filter((link) => {
            if (!link.canRender) return true;
            return link.canRender(authUser);
          }),
        }))
        .filter((parent) => parent.links.length > 0),
    [asideLinks, authUser],
  );

  const handleToggleMaximizedGroup = (name: string) => {
    if (maximizedGroups.includes(name)) {
      setMaximizedGroups([...maximizedGroups.filter((groupName) => groupName !== name)]);
    } else {
      setMaximizedGroups([...maximizedGroups, name]);
    }
  };

  if (!isMaximized) return null;

  return filteredAsideLinks.map((parent) => {
    const parentText = parent.translation(t);
    const isGroupMaximized = maximizedGroups.includes(parent.name);

    return (
      <li key={parentText} className="w-full space-y-4">
        {parent.showGroupTitle !== false && (
          <Button
            onClick={() => handleToggleMaximizedGroup(parent.name)}
            className="flex w-full items-center justify-between"
          >
            <p className="font-medium text-secondary">{parentText}</p>
            <ChevronDownIcon
              className={cn('h-6 w-6 transition-transform duration-75', {
                'rotate-180': !isGroupMaximized,
              })}
            />
          </Button>
        )}
        <ul className="space-y-2">
          {isGroupMaximized &&
            parent.links.map(({ to, Icon, translation, hasWarning }) => {
              const text = translation(t);
              const showWarning = hasWarning && hasWarning(authUser);

              return (
                <li key={to} className="w-full">
                  <NavLink
                    to={to}
                    end={to === '/'}
                    className={({ isActive }) =>
                      cn('btn btn-md btn-block justify-start space-x-4 font-medium', {
                        'btn-primary': isActive,
                        'btn-ghost': !showWarning && !isActive,
                        'btn-warning bg-warning/70': showWarning,
                      })
                    }
                  >
                    <Icon className="h-6 w-6" strokeWidth={2} />
                    <span className={cn({ 'sr-only': !isMaximized })}>{text}</span>
                    {showWarning && <ExclamationTriangleIcon className="size-6" />}
                  </NavLink>
                </li>
              );
            })}
        </ul>
      </li>
    );
  });
}
