import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import config from '@/fixtures/config';
import api from '@/libs/api';
import { TModelId } from '@/types';
import useRefreshToken from '../auth/refreshToken';
import useCreateOrUpdateSetting from '../settings/createOrUpdate';

async function deleteRecruitment(id: TModelId) {
  const resp = await api.api.deleteRecruitment(id);
  return resp.data;
}

export default function useDeleteRecruitment() {
  const queryClient = useQueryClient();
  const { mutate: refreshUser } = useRefreshToken();
  const { mutate: mutateBookmarks, setting: bookmarkSetting } = useCreateOrUpdateSetting(
    config.recruitmentBookmarkSettingName,
  );

  return useMutation({
    mutationFn: deleteRecruitment,
    onSuccess: (resp, id) => {
      queryClient.invalidateQueries({ queryKey: ['recruitments'] });
      const isBookmarked = Object.keys(bookmarkSetting?.value ?? {}).find((i) => i === id);
      if (isBookmarked) {
        const result = produce(bookmarkSetting?.value ?? {}, (draft) => {
          delete draft[id];
        });
        mutateBookmarks(result);
      }
      refreshUser();
    },
  });
}
