import React, { useMemo, useState } from 'react';
import {
  OnChangeFn,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TTableColumns } from '@/types';

type UseTableProps = {
  data: any[] | undefined;
  columns: TTableColumns;
  visibility?: VisibilityState;
  onVisibilityChange?: OnChangeFn<VisibilityState>;
  isLoading?: boolean;
  sorting?: { defaultSort: SortingState };
};

export default function useTable({
  isLoading = false,
  data,
  columns,
  visibility,
  onVisibilityChange,
  sorting: sortingProp,
}: UseTableProps) {
  const [sorting, setSorting] = useState<SortingState>(() => {
    if (!sortingProp) return [];
    return sortingProp.defaultSort;
  });

  const tableData = useMemo(() => (isLoading ? Array(10).fill({}) : data ?? []), [isLoading, data]);

  const tableColumns: TTableColumns = useMemo(() => {
    if (!isLoading) return columns;
    return columns.map((column) => ({
      ...column,
      cell: () => <div className="s-20 skeleton h-6" />,
    }));
  }, [isLoading, columns]);

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    initialState: { columnVisibility: { email: false } },
    state: { sorting, columnVisibility: visibility },
    getSortedRowModel: sortingProp ? getSortedRowModel() : undefined,
    onSortingChange: setSorting,
    onColumnVisibilityChange: onVisibilityChange,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row, i) => row.id ?? i,
  });

  return table;
}
