import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { produce } from 'immer';
import { QuestionChoice, QuestionCreate } from '@/generated';
import { Label } from '@/shared/form';

export default function Templates() {
  const { t } = useTranslation();
  const { setValues, values } = useFormikContext<QuestionCreate>();

  const templates = {
    none: t('questions.templates.none.heading'),
    threeLevel: t('questions.templates.threeLevel.heading'),
    treeLevelTheory: t('questions.templates.treeLevelTheory.heading'),
    yesNo: t('questions.templates.yesNo'),
    noYes: t('questions.templates.noYes'),
  };

  type TTemplates = keyof typeof templates;

  const templateChoices: Record<TTemplates, QuestionChoice[]> = {
    threeLevel: [
      { title: t('questions.templates.threeLevel.advanced'), score: 1 },
      { title: t('questions.templates.threeLevel.intermediate'), score: 0.5 },
      { title: t('questions.templates.threeLevel.beginner'), score: 0 },
    ],
    treeLevelTheory: [
      { title: t('questions.templates.treeLevelTheory.fullScore'), score: 1 },
      { title: t('questions.templates.treeLevelTheory.halfScore'), score: 0.5 },
      { title: t('questions.templates.treeLevelTheory.noScore'), score: 0 },
    ],
    yesNo: [
      { title: t('Yes'), score: 1 },
      { title: t('No'), score: 0 },
    ],
    noYes: [
      { title: t('Yes'), score: 0 },
      { title: t('No'), score: 1 },
    ],
    none: [
      { title: '', score: 0 },
      { title: '', score: 0 },
    ],
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTemplate = e.target.value as TTemplates;
    const newValues = produce(values, (draft) => {
      draft.choices = templateChoices[selectedTemplate];
    });
    setValues(newValues);
  };

  return (
    <div className="flex flex-col space-y-2">
      <Label htmlFor="templates" label={t('Templates')} />
      <select className="select select-bordered" onChange={handleChange} name="templates">
        {Object.entries(templates).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
}
