import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import NextAndPreviousDesign from '@/components/applicants/NextAndPrevious';
import { Applicant } from '@/generated';
import { TModelId } from '@/types';

type NextAndPreviousProps = {
  applicants: Applicant[];
  applicantId: TModelId;
};

function NextAndPrevious({ applicants, applicantId }: NextAndPreviousProps) {
  const navigate = useNavigate();

  const currentIndex = applicants.findIndex(({ id }) => id === applicantId);
  const hasNext = currentIndex !== applicants.length - 1;
  const hasPrevious = currentIndex !== 0;

  const handleNext = () => {
    const nextId = applicants[currentIndex + 1].id;
    navigate(`../${nextId}`);
  };

  const handlePrevious = () => {
    const nextId = applicants[currentIndex - 1].id;
    navigate(`../${nextId}`);
  };

  return (
    <NextAndPreviousDesign
      onNext={handleNext}
      onPrevious={handlePrevious}
      hasNext={hasNext}
      hasPrevious={hasPrevious}
    />
  );
}

export default memo(NextAndPrevious);
