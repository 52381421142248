import { toast } from 'react-toastify';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { isAxiosError } from '@/utils';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (e) => {
      if (isAxiosError(e, 500)) {
        toast.error('Something went wrong please try again later.');
      }
    },
  }),
  mutationCache: new MutationCache({
    onSuccess: (data, variables, context, mutation) => {
      const toastMeta = mutation.meta?.success?.toast;
      if (toastMeta) {
        toast.success(toastMeta.content, toastMeta);
      }
    },
    onError: (e, variables, context, mutation) => {
      const disabledErrors = mutation.meta?.error?.disableToastForStatusCodes ?? [];

      if (isAxiosError(e, 500) || e.message.includes('ERR_FAILED')) {
        toast.error('Something went wrong please try again later.');
      }
      if (!disabledErrors.includes(400) && isAxiosError<any>(e, 400)) {
        const errorMessage = e.response?.data?.details ?? e.response?.data?.detail;
        if (errorMessage) toast.error(errorMessage);
      }
      if (!disabledErrors.includes(409) && isAxiosError<any>(e, 409)) {
        const errorMessage = e.response?.data?.details ?? e.response?.data?.detail;
        if (errorMessage) toast.error(errorMessage);
      }
      if (!disabledErrors.includes(403) && isAxiosError(e, 403)) {
        toast.error("You don't have enough permission to perform this action.");
      }
      if (!disabledErrors.includes(429) && isAxiosError(e, 429)) {
        toast.error("You've tried so many times in a short time period. Please try again later.");
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export default queryClient;
