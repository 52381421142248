import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { CellContext } from '@tanstack/react-table';
import BaseActions from '@/components/resources/index/actions';
import { User } from '@/generated';
import useDeleteUser from '@/services/users/delete';

type ActionsProps = {
  info: CellContext<User, unknown>;
};

export default function Actions({ info }: ActionsProps) {
  const { t } = useTranslation();
  const { mutate: deleteUser, isPending: isDeleting } = useDeleteUser();
  const queryClient = useQueryClient();
  const fullName = `${info.row.original.firstName} ${info.row.original.lastName}`;

  return (
    <BaseActions
      editAction={{ link: `/users/${info.row.original.id}/edit` }}
      deleteAction={{
        modalTitle: `${t('Delete')} ${fullName}`,
        isDeleting,
        onDelete: (deleteModalRef) => {
          deleteUser(info.row.original.id, {
            onSuccess: () => {
              toast.success(t('toast.deletedSuccessfully', { model: t('User') }));
              deleteModalRef.current?.close();
              queryClient.invalidateQueries({ queryKey: ['users'] });
            },
          });
        },
      }}
    />
  );
}
