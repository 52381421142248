import React from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import deadlineReached from '@/assets/animations/deadlineReached.json';

export default function QuestionsetIsExpired() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-1 items-center justify-center">
      <div className="card max-w-xl bg-base-300">
        <div className="card-body gap-4">
          <h1 className="card-title flex-wrap text-2xl font-bold">{t('Questionset Is Expired')}</h1>
          <p className="description">
            {t(
              'The questionset deadline is reached. You can ask the recruiter to update the deadline or give you a new link.',
            )}
          </p>
          <Lottie
            animationData={deadlineReached}
            play
            className="mx-auto h-60 w-60 md:h-72 md:w-72"
          />
        </div>
      </div>
    </div>
  );
}
