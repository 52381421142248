import React from 'react';
import Tooltip from '@/shared/Tooltip';
import { cn } from '@/utils';

interface HeaderProps extends React.HTMLAttributes<HTMLHeadingElement> {
  tooltip?: string;
}

export default function Header({ children, className, tooltip, ...props }: HeaderProps) {
  return (
    <div className="flex items-center gap-2">
      <h2 {...props} className={cn('flex items-center gap-2 text-xl font-bold', className)}>
        {children}
      </h2>
      <Tooltip text={tooltip} />
    </div>
  );
}
