import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Actions from '@/components/questionsets/Actions';
import ConfirmationModal from '@/components/questionsets/templates/ConfirmationModal';
import Template from '@/components/questionsets/templates/Template';
import { QuestionSetTemplate } from '@/generated';
import { TQuestionsetsEditLayout } from '@/layouts/questionsets/EditLayout';
import useGetTemplates from '@/services/templates';
import useCopyTemplateToJob from '@/services/templates/copy';
import Head from '@/shared/custom/Head';
import HeadingWithLinks from '@/shared/custom/HeadingWithLinks';
import { cn, generateBaseBackendUrl } from '@/utils';

export default function Templates() {
  const { t } = useTranslation();
  const title = t('Templates');
  const { questionsetId, isInWizardMode, questionset } =
    useOutletContext<TQuestionsetsEditLayout>();
  const [selectedId, setSelectedId] = useState('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const navigate = useNavigate();
  const { data } = useGetTemplates({ questionSetType: questionset?.type });
  const { mutate: copy, isPending: isSubmitting } = useCopyTemplateToJob();

  const handleTemplateClick = (template: QuestionSetTemplate) => {
    if (selectedId === template.id) setSelectedId('');
    else setSelectedId(template.id);
  };

  const handleCopy = () => {
    copy(
      { questionsetId, templateId: selectedId },
      { onSuccess: () => navigate(`/questionsets/${questionsetId}/details`) },
    );
  };

  const handleImport = () => {
    if (questionset?.nbOfSections === 0) handleCopy();
    else setIsConfirmModalOpen(true);
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={() => {
          setIsConfirmModalOpen(false);
          handleCopy();
        }}
      />
      <Head title={title} description={title} />
      {isInWizardMode ? (
        <h1 className="h1">{title}</h1>
      ) : (
        <HeadingWithLinks
          links={[
            { text: 'Questionset Details', to: `/questionsets/${questionsetId}/details` },
            { text: title, to: '#' },
          ]}
        />
      )}
      {isInWizardMode && (
        <ul className="steps steps-vertical md:steps-horizontal">
          <li className="step step-primary">{t('Information')}</li>
          <li className="step step-primary">{t('Setup')}</li>
          <li className="step step-primary">{t('questionsets.steps.templates.choose')}</li>
          <li
            className={cn('step', {
              'step-primary': Boolean(selectedId),
            })}
          >
            {t('Finish')}
          </li>
        </ul>
      )}
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {data?.items?.map((template) => (
          <Template
            {...template}
            onClick={() => handleTemplateClick(template)}
            isSelected={selectedId === template.id}
            key={template.id}
            imagePath={generateBaseBackendUrl(template.imagePath!)}
          />
        ))}
      </div>
      <Actions
        isNoOptionSelected={!selectedId}
        questionsetId={questionsetId}
        isSubmitting={isSubmitting}
        onSubmit={handleImport}
      />
    </>
  );
}
