import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form as FormikForm, FormikProps } from 'formik';
import compact from 'lodash/compact';
import * as Yup from 'yup';
import validations from '@/fixtures/validations';
import { QuestionSetCreate, QuestionSetType } from '@/generated';
import { Fields } from '@/shared/form';
import useAuthState from '@/states/authState';

const validationSchema = Yup.object().shape({
  type: Yup.string().required(),
  description: validations.description,
});

type FormProps = {
  onSubmit: (values: QuestionSetCreate) => void;
  initialValues: QuestionSetCreate;
  children: React.ReactNode | ((props: FormikProps<QuestionSetCreate>) => React.ReactNode);
  excludedTypes?: QuestionSetType[];
  hasTypeSelection?: boolean;
};

export default function Form({
  onSubmit,
  initialValues,
  excludedTypes = [],
  hasTypeSelection = true,
  children,
}: FormProps) {
  const { t } = useTranslation();
  const hasLinkedinFeature = useAuthState((state) => state.user?.userAccess.accessLinkedinFeature);
  const selectOptions = {
    [QuestionSetType.INTERVIEW]: t('Interview'),
    [QuestionSetType.PERSONALITY]: t('Personality'),
    [QuestionSetType.QUALIFICATION]: t('Qualification'),
    [QuestionSetType.SURVEY]: t('Survey'),
    [QuestionSetType.TEST]: t('Test'),
  };

  return (
    <Formik
      onSubmit={(values) => {
        onSubmit({ ...values, title: selectOptions[values.type] });
      }}
      validationSchema={validationSchema}
      initialValues={initialValues}
      enableReinitialize
    >
      {(props) => (
        <FormikForm className="space-y-4">
          <Fields
            fields={compact([
              {
                isRequired: true,
                type: 'select',
                name: 'type',
                label: t('Type'),
                fieldProps: {
                  disabled: !hasTypeSelection,
                  options: [
                    {
                      label: t('Interview'),
                      value: QuestionSetType.INTERVIEW,
                    },
                    // { label: selectOptions[QuestionSetType.PERSONALITY], value: QuestionSetType.PERSONALITY },
                    {
                      label: selectOptions[QuestionSetType.QUALIFICATION],
                      value: QuestionSetType.QUALIFICATION,
                    },
                    // { label: t('Survey'), value: QuestionSetType.SURVEY },
                    // { label: t('Test'), value: QuestionSetType.TEST },
                  ].filter((option) => !excludedTypes.includes(option.value)),
                },
              },
              props.values.type === QuestionSetType.QUALIFICATION && {
                isRequired: false,
                type: 'checkbox',
                name: 'userOptions.askForLinkedinProfile',
                label: t('Ask for Linkedin Profile'),
                tooltip: {
                  text: hasLinkedinFeature
                    ? undefined
                    : t('You have to upgrade your plan to access this feature'),
                },
                fieldProps: { disabled: !hasLinkedinFeature },
              },
            ])}
          />
          {typeof children === 'function' ? children(props) : children}
        </FormikForm>
      )}
    </Formik>
  );
}
