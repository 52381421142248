import { useMutation } from '@tanstack/react-query';
import { SettingCreate } from '@/generated';
import api from '@/libs/api';
import useAuthState from '@/states/authState';

async function createSetting(data: SettingCreate) {
  const resp = await api.api.createSetting(data);
  return resp.data;
}

export default function useCreateResourceSetting() {
  const setAuthUserSetting = useAuthState((state) => state.setAuthUserSetting);

  return useMutation({ mutationFn: createSetting, onSuccess: setAuthUserSetting });
}
