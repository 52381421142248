import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { SectionCreate } from '@/generated';
import { detailsContext } from '@/pages/questionsets/Details';
import useCreateSection from '@/services/sections/create';
import Modal from './Modal';

type CreateProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function Create({ isOpen, onClose }: CreateProps) {
  const { t } = useTranslation();
  const { mutate: createSection, isPending } = useCreateSection();
  const { questionsetId, clearQueryCache } = useContext(detailsContext);

  const handleSubmit = (values: SectionCreate) => {
    createSection(
      { ...values, questionSetId: questionsetId },
      {
        onSuccess: () => {
          toast.success(t('toast.createdSuccessfully', { model: t('Section') }));
          clearQueryCache();
          onClose();
        },
      },
    );
  };

  return (
    <Modal
      modalTitle={t('Create Section')}
      isOpen={isOpen}
      initialValues={{ title: '', description: '', nbQuestionsPerPage: 1 }}
      isSubmitting={isPending}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
}
