import { useSuspenseQuery } from '@tanstack/react-query';
import api from '@/libs/api';

async function getClientMe() {
  const resp = await api.api.getClientMe({});
  return resp.data;
}

export default function useGetClientMe() {
  return useSuspenseQuery({
    queryKey: ['clients', 'me'],
    queryFn: getClientMe,
  });
}
