import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ClipboardIcon } from '@heroicons/react/24/outline';
import { Button } from '@/shared/form';
import { copyTextToClipboard } from '@/utils';

type TextCopyColumnProps = {
  text: string;
  canCopy?: boolean;
};

export default function TextCopyColumn({ text, canCopy }: TextCopyColumnProps) {
  const { t } = useTranslation();
  const [showIcon, setShowIcon] = useState(true);

  useEffect(() => {
    if (!canCopy) return;
    setTimeout(() => setShowIcon(false), 3000);
  }, [setShowIcon, canCopy]);

  return (
    <Button
      className="flex items-center gap-4"
      onClick={(e) => {
        e.stopPropagation();
        if (!canCopy) return;
        copyTextToClipboard(text);
        toast.success(t('Email copied to your clipboard successfully.'));
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      {text}{' '}
      {canCopy && showIcon && (
        <div className="animate-pulse rounded-full bg-base-300 p-1">
          <ClipboardIcon className="size-5" />
        </div>
      )}
    </Button>
  );
}
